<template>
  <div class="Works-douyin">
    <div class="userListBox">
      <ul>
        <li
          v-for="item in userList"
          :key="item.openId"
          :class="item.openId === openId?'liActive':''"
          @click="changeUser(item.openId)"
        >
          <el-image style="width: 50px; height: 50px" :src="item.avatar"></el-image>
        </li>
        <li>
          <el-image style="width: 50px; height: 50px" :src="add" @click="getDouyinVerifyGetCode"></el-image>
        </li>
      </ul>
    </div>
    <div class="tableWorks">
      <el-button type="text" @click="postDouyinVerifyGetVideoList">
        <i class="el-icon-refresh-right"></i>更新数据
      </el-button>
      <el-table :data="WorksList" style="width: 100%">
        <el-table-column prop="title" label="作品名称"></el-table-column>
        <el-table-column prop="gender" label="审核状态">
          <template slot-scope="scope">
            <el-tag size="small">{{scope.row.videoStatus}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="playCount" label="播放量"></el-table-column>
        <el-table-column prop="diggCount" label="点赞量"></el-table-column>
        <el-table-column prop="shareCount" label="分享量"></el-table-column>
        <el-table-column prop="commentCount" label="评论量"></el-table-column>
        <el-table-column prop="forwardCount" label="转发量"></el-table-column>
        <el-table-column prop="downloadCount" label="下载量"></el-table-column>
        <el-table-column prop="createTime" label="发布时间" width="180"></el-table-column>
        <el-table-column label="评论管理">
          <template slot-scope="scope">
            <el-button type="text" @click="getDouyinCommentList(scope.row.itemId)">管理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible" class="commentDialog" :show-close="false">
      <div v-if="CommentList.length >0">
        <div v-for="(item,index) in CommentList" :key="index">
          <div class="disflexCommentList">
            <el-avatar :src="item.avatar"></el-avatar>
            <div class="disflexName">
              <span>
                <span class="nickName">{{item.nickname }}</span>
                <span v-show="item.commentUserId === openId">
                  <el-tag effect="dark" size="mini" color="red">作者</el-tag>
                </span>
              </span>
              <span class="date">{{item.createTime }}</span>
            </div>
          </div>
          <div class="comment">
            <span>{{item.content }}</span>
            <div class="commentBtnList">
              <span class="iconfont fs12">&#xe65c; {{item.diggCount}}</span>
              <span class="iconfont fs12">&#xe63c; 回复</span>
              <span class="iconfont fs12">&#xe634; 删除</span>
            </div>
          </div>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="totalComment"
          @current-change="handleCurrentChangeComment"
          :current-page="pageNoComment"
          :hide-on-single-page="true"
        ></el-pagination>
      </div>
      <div v-else>暂无评论</div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDouyinBackendAnthorOpenid,
  getDouyinVideoList,
  getDouyinVideoCommentList,
  postDouyinVerifyGetVideoList,
  getDouyinVerifyGetCode
} from "@/service/douyin";
import { Message } from "element-ui";
export default {
  components: {},
  data() {
    return {
      add: require("@/assets/douyinadd.png"),
      WorksList: [],
      userList: [],
      openId: null,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      CommentList: [],
      dialogVisible: false,
      pageNoComment: 1,
      totalComment: 0
    };
  },
  computed: {},
  created() {
    this.getDouyinBackendAnthorOpenid();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取已授权用户列表
    getDouyinBackendAnthorOpenid() {
      getDouyinBackendAnthorOpenid().then(res => {
        if (res && res.length !== 0) {
          this.userList = res;
          this.openId = res[0].openId;
          this.getDouyinVideoList();
        }
      });
    },
    changeUser(openId) {
      this.openId = openId;
      this.pageNo = 1;
      this.getDouyinVideoList();
    },
    // 获取跳转url
    getDouyinVerifyGetCode() {
      getDouyinVerifyGetCode().then(res => {
        if (res) {
          window.open(res, "_blank");
        }
      });
    },
    getDouyinVideoList() {
      var data = {
        ownerId: this.openId,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      getDouyinVideoList({ ...data }).then(res => {
        this.WorksList = res.list;
        this.total = res.num;
      });
    },
    getDouyinCommentList(id) {
      var data = {
        itemId: id,
        pageNo: this.pageNoComment,
        pageSize: 5
      };
      getDouyinVideoCommentList({ ...data }).then(res => {
        this.CommentList = res.list;
        this.totalComment = res.num;
        this.dialogVisible = true;
      });
    },
    postDouyinVerifyGetVideoList() {
      var data = {
        open_id: this.openId
      };
      postDouyinVerifyGetVideoList({ ...data }).then(res => {
        Message.success("更新成功");
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
       this.getDouyinVideoList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
       this.getDouyinVideoList();
    },
    handleCurrentChangeComment(val) {
      console.log(`当前页: ${val}`);
      this.pageNoComment = val;
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
