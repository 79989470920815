import {
  get,
  post,
  upload
} from '../Api/axios'
import {
  URL_ACCOUNT_AUTO_REPLY_LIST,
  URL_OFFICIAL_ACCOUNT_ADD_AUTO_REPLY,
  URL_OFFICIAL_ACCOUNT_ADD_FOREVER_MATERIAL,
  URL_ACCOUNT_AUTO_REPLY_DELETE,
  URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY,
  URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY_DETAIL, URL_OFFICIAL_ACCOUNT_MENU_LIST, URL_OFFICIAL_ACCOUNT_CREATE_MENU_LIST
} from '@/Api/api.js'

export function getReplyList(params = {}) {
  return get(URL_ACCOUNT_AUTO_REPLY_LIST, {
    ...params
  })
}

export function postAddAutoReply(params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_ADD_AUTO_REPLY, {
    ...params
  })
}

export function postEditAutoReply(params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY, {
    ...params
  })
}

export function postForeverMaterial(params = {}) {
  return upload(URL_OFFICIAL_ACCOUNT_ADD_FOREVER_MATERIAL, {
    ...params
  })
}

export function delReply(params = {}) {
  return get(URL_ACCOUNT_AUTO_REPLY_DELETE, {
    ...params
  })
}

export function getAutoReplyDetail(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY_DETAIL, {
    ...params
  })
}

export function getMenuList(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_MENU_LIST, {
    ...params
  })
}

export function createMenuList(params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_CREATE_MENU_LIST, {
    ...params
  })
}
