<template>
  <div>
    <el-upload
      class="upload-demo"
      action="www.baidu.com"
      ref="upload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :http-request="updateFile"
      multiple
      :limit="1"
      :on-exceed="handleExceed"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: ["resetlist"],
  data() {
    return {
      fileList: []
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    resetlist() {
      this.resetUploda();
    }
  },
  methods: {
    updateFile(file) {
      this.$emit("handleFile", file.file);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1 个文件");
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    resetUploda() {
      if (this.resetlist) {
        this.$refs.upload.clearFiles();
      }
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
</style>
