import {
  get,
  post,
  upload
} from '../Api/axios'
import {
  URL_WECHAT_CONTACT_LIST,
  URL_GET_SOP_PERSON_LIST,
  URL_QUERY_ADD_USER_LIST,
  URL_QUERY_LABEL_LIST,
  URL_ADD_FILE_MATERIAL,
  URL_WECHAT_CONTACT_ADD_WAY,
  URL_WECHAT_CONTACT_DEL_WAY,
  URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY_LIST,
  URL_WECHAT_GROUP_GROUP_DETAIL,
  URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY,
  URL_WECHAT_GROUPCHAT_DEL_JOIN_WAY,
  URL_WECHAT_GROUPCHAT_UPDETA_JOIN_WAY
} from '@/Api/api.js'

// 列表
export function getAdduserList(params = {}) {
  return post(URL_WECHAT_CONTACT_LIST, {
    ...params
  })
}

// URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY
export function getGroupDrainageList(params = {}) {
  return post(URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY_LIST, {
    ...params
  })
}

export function addUserList(params = {}) {
  return get(URL_GET_SOP_PERSON_LIST, {
    ...params
  })
}

// 获取使用人员
export function getUseUserList(params = {}) {
  return get(URL_QUERY_ADD_USER_LIST, {
    ...params
  })
}

// 获取标签列表
export function getLabelList(params = {}) {
  return get(URL_QUERY_LABEL_LIST, {
    ...params
  })
}

// 上传图片
export function uploadFile(params = {}) {
  return upload(URL_ADD_FILE_MATERIAL, {
    ...params
  })
}

// 新建活码
export function postAddContactWay(params = {}) {
  return post(URL_WECHAT_CONTACT_ADD_WAY, {
    ...params
  })
}

// 删除
export function postDelContactWay(params = {}) {
  return post(URL_WECHAT_CONTACT_DEL_WAY, {
    ...params
  })
}

// URL_WECHAT_GROUP_GROUP_DETAIL
export function getGroupDetail(params = {}) {
  return get(URL_WECHAT_GROUP_GROUP_DETAIL, {
    ...params
  })
}

// URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY
export function postAddJoinGrouptWay(params = {}) {
  return post(URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY, {
    ...params
  })
}

// URL_WECHAT_GROUPCHAT_DEL_JOIN_WAY
export function delGroupJoin(params = {}) {
  return get(URL_WECHAT_GROUPCHAT_DEL_JOIN_WAY, {
    ...params
  })
}

// URL_WECHAT_GROUPCHAT_UPDETA_JOIN_WAY
export function postUpdateJoinGrouptWay(params = {}) {
  return post(URL_WECHAT_GROUPCHAT_UPDETA_JOIN_WAY, {
    ...params
  })
}
