import {
  get,
  post
} from '../Api/axios'
import {
  URL_DOUYIN_BACKEND_GET_CONFIG,
  URL_DOUYIN_BACKEND_UPDATE_CONFIG,
  URL_DOUYIN_BACKEND_GET_FANS_LIST,
  URL_DOUYIN_BACKEND_GET_ANTHIRIZATION_OPENID,
  URL_DOUYIN_VERIFY_GET_CODE,
  URL_DOUYIN_BACKEND_PROVINVE_CITY_LIST,
  URL_DOUYIN_VERIFY_GET_FANS_LIST,
  URL_DOUYIN_VERIFY_GET_ACCESS_TOKEN,
  URL_DOUYIN_BACKEND_GET_VIDEO_LIST,
  URL_DOUYIN_BACKEND_GET_COMMENT_LIST,
  URL_DOUYIN_VERIFY_GET_VIDEO_LIST,
  URL_DOUYIN_CALLBACK_GET_ACCESS_TOKEN,
  URL_DOUYIN_VERIFY_GET_USERINFO
} from '@/Api/api.js'

export function getDouyinBackendGetConfig(params = {}) {
  return get(URL_DOUYIN_BACKEND_GET_CONFIG, {
    ...params
  })
}

export function postDouyinBackendUpdateConfig(params = {}) {
  return post(URL_DOUYIN_BACKEND_UPDATE_CONFIG, {
    ...params
  })
}

export function postDouyinBackendGetFansList(params = {}) {
  return post(URL_DOUYIN_BACKEND_GET_FANS_LIST, {
    ...params
  })
}

export function getDouyinBackendAnthorOpenid(params = {}) {
  return get(URL_DOUYIN_BACKEND_GET_ANTHIRIZATION_OPENID, {
    ...params
  })
}

export function getDouyinVerifyGetCode(params = {}) {
  return get(URL_DOUYIN_VERIFY_GET_CODE, {
    ...params
  })
}

export function getDouyinProvinveCityList(params = {}) {
  return get(URL_DOUYIN_BACKEND_PROVINVE_CITY_LIST, {
    ...params
  })
}

export function postDouyinVerifyGetFansList(params = {}) {
  return post(URL_DOUYIN_VERIFY_GET_FANS_LIST, {
    ...params
  })
}

export function postDouyinVerifyGetAccessToken(params = {}) {
  return post(URL_DOUYIN_VERIFY_GET_ACCESS_TOKEN, {
    ...params
  })
}

export function getDouyinVideoList(params = {}) {
  return get(URL_DOUYIN_BACKEND_GET_VIDEO_LIST, {
    ...params
  })
}

export function getDouyinVideoCommentList(params = {}) {
  return get(URL_DOUYIN_BACKEND_GET_COMMENT_LIST, {
    ...params
  })
}

export function postDouyinVerifyGetVideoList(params = {}) {
  return post(URL_DOUYIN_VERIFY_GET_VIDEO_LIST, {
    ...params
  })
}

export function getDouyinCallbackGetAccessToken(params = {}) {
  return get(URL_DOUYIN_CALLBACK_GET_ACCESS_TOKEN, {
    ...params
  })
}

export function postDouyinVerifyGetUserinfo(params = {}) {
  return post(URL_DOUYIN_VERIFY_GET_USERINFO, {
    ...params
  })
}
