<template>
  <div class="code-main">
    <div class="code-file">
      <el-row :gutter="20" class="textRow">
        <el-col :span="18">
          <div class="code-box">
            <el-input type="textarea" :rows="2" placeholder="请输入文字内容" v-model="textarea">
            </el-input>
            <el-row>
              <el-button type="success" @click="creatQRCode()">生成二维码</el-button>
            </el-row>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="code-box">
            <el-image :src="imgUrl" @click="downloadQRcode()">
              <div slot="error" class="image-slot">
                <img src="@/assets/code.svg" alt="" />
              </div>
            </el-image>
            <div class="code-text" v-if="!imgUrl">未生成</div>
            <div class="code-text" v-if="imgUrl">点击可以下载</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import { creatQRCode } from '@/service/code'
import qs from 'qs'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      textarea: '',
      imgUrl: '',
      width: 350,
      height: 350,
      downLoadUrl: 'https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    // 生成二维码
    creatQRCode() {
      if (this.textarea === '') {
        Message.info('请输入文字内容')
      } else {
        creatQRCode({
          width: this.width,
          height: this.height,
          text: this.textarea
        }).then(res => {
          this.imgUrl = res
        })
      }
    },
    // 下载二维码
    downloadQRcode() {
      if (this.imgUrl !== '') {
        window.open(
          this.downLoadUrl + qs.stringify({ urlString: this.imgUrl }), '_self'
        )
      }
    }
  },
  components: {
  }
}
</script>

<style  lang="less">
  @import url("./index.less");
</style>
