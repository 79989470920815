<template>
  <div class="enterprise-settings">
    <el-dialog
      title="企微设置"
      :visible.sync="dialogFormVisible"
      :before-close="back"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="企业名称" prop="corpName">
          <el-input v-model="ruleForm.corpName"></el-input>
        </el-form-item>
        <el-form-item label="企业ID(CorpId)" prop="corpId">
          <el-input v-model="ruleForm.corpId"></el-input>
        </el-form-item>
        <el-form-item label="客户联系Secret" prop="customerSecret">
          <el-input v-model="ruleForm.customerSecret"></el-input>
        </el-form-item>
        <el-form-item label="通讯录Secret" prop="addressSecret">
          <el-input v-model="ruleForm.addressSecret"></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          > -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnterpriseWechatConfig, postEnterpriseWechatConfig } from '@/service/enterprise'
import {
  Message
} from 'element-ui'
export default {
  components: {

  },
  props: ['show'],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        corpName: '',
        corpId: '',
        customerSecret: '',
        addressSecret: ''
      },
      rules: {
        corpName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        corpId: [
          { required: true, message: '请输入企业ID(CorpId)', trigger: 'blur' }
        ],
        customerSecret: [
          { required: true, message: '请输入客户联系Secret', trigger: 'blur' }
        ],
        addressSecret: [
          { required: true, message: '请输入通讯录Secret', trigger: 'blur' }
        ]
      }

    }
  },
  computed: {

  },
  created() {
    this.getEnterpriseWechatConfig()
  },
  mounted() {

  },
  watch: {
    show(val) {
      this.dialogFormVisible = val
    }
  },
  methods: {
    getEnterpriseWechatConfig() {
      getEnterpriseWechatConfig().then(res => {
        this.ruleForm = res
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postEnterpriseWechatConfig({ ...this.ruleForm }).then(res => {
            console.log('🚀 ~ file: index.vue ~ line 86 ~ postEnterpriseWechatConfig ~ res', res)
            this.dialogFormVisible = false
            this.$emit('visableShow', false)
            Message.success('添加成功')
          }).catch(function (error) {
            // 处理 getJSON 和 前一个回调函数运行时发生的错误
            console.log('发生错误！', error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit('visableShow', false);
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
