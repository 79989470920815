<!-- 线性图 -->
  <template>
  <el-table :data="tableData"
    style="width: 100%">
    <el-table-column prop="date"
      label="日期">
    </el-table-column>
    <el-table-column v-for="(item,index) in key"
      :key="item"
      :prop="item"
      :label="'第'+(index+1)+'天'"
      align="center">
      <template slot-scope="scope">
        <div>
          <p>{{scope.row[item]?scope.row[item].people:''}}</p>
          <p>{{scope.row[item]?scope.row[item].percent:''}}</p>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: ['mapData'],
  data () {
    /* eslint-disable */
    return {
      tableData: [],
      key: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.filterData()
  },
  methods: {
    filterData () {
      if (this.mapData.dataList) {
        this.key = []
        const data = []
        this.mapData.dataList.forEach((item, index) => {
          this.key.push(`key${index}`)
        })
        console.log('%c 🥟 this.key: ', 'font-size:20px;background-color: #42b983;color:#fff;', this.key);
        this.key.forEach((item, index) => {
          data[index] = {}
          this.mapData.detailList[index].subs.forEach((g, m) => {
            data[index][`key${m}`] = g ? g : {}
          })
        })
        this.mapData.dataList.forEach((item, index) => {
          data[index].date = item
        })
        this.tableData = data
        console.log('%c 🍱 data: ', 'font-size:20px;background-color: #6EC1C2;color:#fff;', data);
      }
    }
  },
  watch: {
    mapData () {
      this.filterData()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>