<template>
  <div class="home-pages">
    <el-row :gutter="12">
      <el-col :span="16" class="pd-r5">
        <el-row :gutter="12">
          <el-col :span="6">
            <el-card shadow="always" class="cardH20">
              <h2>{{summary.usernum}}人</h2>
              <h4>总好友数</h4>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" class="cardH20">
              <h2>{{summary.today_add}}人</h2>
              <h4>新好友数量</h4>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" class="cardH20">
              <h2>{{Number(summary.tag_rate*100).toFixed(1)}}%</h2>
              <h4>有标签好友比例</h4>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" class="cardH20">
              <h2>{{Number(summary.descrip_rate*100).toFixed(1)}}%</h2>
              <h4>有备注好友比例</h4>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :span="12">
            <el-card shadow="always" class="cardH30">
              <h3>新好友趋势</h3>
              <div class="flex-echats">
                <div id="addTendency"></div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="always" class="cardH30">
              <h3>活跃好友排名</h3>
              <el-empty :image-size="100"></el-empty>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :span="12">
            <el-card shadow="always" class="cardH30 h95">
              <h3>Top好友标签</h3>
              <div class="tagflex">
                <p>标签</p>
                <p>好友数量</p>
              </div>
              <div class="infinite-list"  style="overflow:auto">
                <el-empty :image-size="100" v-if="tagRank.length==0"></el-empty>
                <div v-else v-for="(item,index) in tagRank" v-bind:key="index">
                  <div class="tagflex" v-bind:key="i" v-for="(value, key,i) in item">
                    <a>{{key}}</a>
                    <div class="progressFlex">
                      <span class="proValue">{{value}}</span>
                      <el-progress :percentage="value/summary.usernum *100" :show-text="false" :stroke-width='10'></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="always" class="cardH30">
              <h3>已删除或拉黑好友列表</h3>
              <el-empty :image-size="100" v-show="deleteFriend.length==0"></el-empty>
              <div v-show="deleteFriend.length>0"  style="width: 90%">
                  <div class="deleteFriend pd10">
                     <span>头像</span>
                     <span>姓名</span>
                     <span>类型</span>
                     <span>删除时间</span>
                  </div>
                  <div v-for="o in deleteFriend" :key="o.add_time" class="deleteFriend fontS12">
                     <!-- <img :src="o.pic_url"> -->
                     <el-avatar :size="50" :src="o.pic_url"></el-avatar>
                     <span>{{o.name}}</span>
                     <span>{{o.add_type}}</span>
                     <span class="w70">{{o.del_time}}</span>
                  </div>
                 <!-- <el-table
                  :data="deleteFriend"
                  style="width: 100%">
                  <el-table-column
                    prop="date"
                    label="日期"
                    width="180">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="姓名"
                    width="180">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="地址">
                  </el-table-column>
                </el-table> -->
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card cardH28">
          <h3>好友性别分布</h3>
          <div class="flex-echats">
            <div id="genderDistribute"></div>
          </div>
        </el-card>
        <el-card class="box-card cardH28">
          <h3>好友来源</h3>
          <div class="flex-echats">
            <div id="comeFrom"></div>
          </div>
        </el-card>
        <el-card class="box-card cardH28">
          <h3>好友地区分布</h3>
          <el-empty :image-size="100"></el-empty>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { getGeneralView } from '@/service/login'

export default {
  components: {},
  data() {
    return {
      summary: {
        usernum: 0,
        descrip_rate: 0,
        groupusernum: 0,
        tag_rate: 0,
        groupnum: 0,
        del: 0,
        today_add: 0
      },
      tagRank: [],
      genderOption: {
        color: ['#79838F', '#5F9CDA', '#E28F8A'],
        legend: {
          orient: 'vertical',
          x: 'left',
          y: 'center',
          width: 500
        },
        icon: 'circle',
        title: {
          text: '总计',
          left: '59%',
          top: '55%',
          textStyle: {
            color: '#D8D8D8',
            fontSize: 14,
            align: 'center'
          }
        },
        graphic: {
          type: 'text',
          left: '59%',
          top: '45%',
          style: {
            text: '0人',
            textAlign: 'center',
            fill: '#333',
            fontSize: 20,
            fontWeight: 600
          }
        },
        series: [
          {
            name: '好友性别分布',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            left: 120,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      lineOption: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            name: '好友数量',
            itemStyle: {
              color: '#7B7694'
            },
            areaStyle: {
              color: '#CFC3CF'
            }
          }
        ]
      },
      deleteFriend: []
    }
  },
  computed: {},
  created() {
    this.getView()
  },
  mounted() {},
  watch: {},
  methods: {
    getView() {
      getGeneralView().then(res => {
        console.log('res', res)
        this.summary = res.summary
        this.setGender(res)
        this.setcomeFrom(res)
        this.setAddTendency(res)
        this.tagRank = res.tagRank
        this.deleteFriend = res.deleteFriend
      })
    },
    setAddTendency(res) {
      var chartDom = document.getElementById('addTendency')
      var myChart = echarts.init(chartDom)
      var xdata = []
      var data = []
      res.addTendency.map(item => {
        xdata.push(item.date)
        data.push(item.num)
      })
      this.lineOption.xAxis.data = [...xdata]
      this.lineOption.series[0].data = [...data]
      this.lineOption.legend.data = ['好友数量']
      this.lineOption.legend.x = 'left'
      myChart.setOption(this.lineOption)
    },
    setGender(res) {
      // 好友比例饼图
      var chartDom = document.getElementById('genderDistribute')
      var myChart = echarts.init(chartDom)
      var optiont = JSON.stringify(this.genderOption)
      var option = JSON.parse(optiont)
      option.graphic.style.text = res.summary.usernum + '人'
      var data = []
      var other =
        1 - (res.genderDistribute.man_rate + res.genderDistribute.woman_rate)
      data.push({
        value: other,
        name: '未知 ' + Number(other * 100).toFixed(1) + '%'
      })
      data.push({
        value: res.genderDistribute.man_rate,
        name:
          '男 ' + Number(res.genderDistribute.man_rate * 100).toFixed(1) + '%'
      })
      data.push({
        value: res.genderDistribute.woman_rate,
        name:
          '女 ' + Number(res.genderDistribute.woman_rate * 100).toFixed(1) + '%'
      })
      option.series[0].data = [...data]
      myChart.setOption(option)
    },
    setcomeFrom(res) {
      // 好友来源饼图
      var chartDom = document.getElementById('comeFrom')
      var myChart = echarts.init(chartDom)
      var optiont = JSON.stringify(this.genderOption)
      var option = JSON.parse(optiont)
      option.color = ['#DDA774', '#7071AA', '#C7CDD1', '#594DD4']
      option.graphic.style.text = res.summary.usernum + '人'
      var data = []
      res.comeFrom.map(item => {
        data.push({
          value: item.rate,
          name: item.add_way + ' ' + Number(item.rate * 100).toFixed(1) + '%'
        })
      })
      option.series[0].data = [...data]
      option.series[0].name = '好友来源'
      option.legend.formatter = function(params) {
        // 超过十个字符就换行展示
        var newParamsName = '' // 最终拼接成的字符串
        var paramsNameNumber = params.length // 实际标签的个数
        var provideNumber = 11 // 每行能显示的字的个数
        var rowNumber = Math.ceil(paramsNameNumber / provideNumber) // 换行的话，需要显示几行，向上取整

        // 条件等同于rowNumber>1
        if (paramsNameNumber > provideNumber) {
          for (var p = 0; p < rowNumber; p++) {
            var tempStr = '' // 表示每一次截取的字符串
            var start = p * provideNumber // 开始截取的位置
            var end = start + provideNumber // 结束截取的位置
            // 此处特殊处理最后一行的索引值
            if (p === rowNumber - 1) {
              // 最后一次不换行
              tempStr = params.substring(start, paramsNameNumber)
            } else {
              // 每一次拼接字符串并换行
              tempStr = params.substring(start, end) + '\n'
            }
            newParamsName += tempStr // 最终拼成的字符串
          }
        } else {
          // 将旧标签的值赋给新标签
          newParamsName = params
        }
        // 将最终的字符串返回
        return newParamsName
      }
      console.log('ddddd=>', option)
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
