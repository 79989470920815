<template>
  <div class="group-analysis">
    <!-- 基础信息 start -->
    <el-form label-position="top" ref="_fromRef" :model="form">
      <el-row style="margin-bottom:10px;">
        <div class="group-analysis-header">
          <span class="header-text">基础信息</span>
        </div>
      </el-row>
      <el-row>
        <el-form-item label="分群显示名" size="small" prop="groupName" :rules="[{required: true, message: '请输入分群显示名', trigger: ['change','blur']}]">
          <el-input class="w400" v-model.trim="form.groupName" placeholder="请输入显示名" />
        </el-form-item>
        <el-form-item label="分群名称" size="small">
          <el-input class="w400" v-model="groupId" placeholder readonly>
            <template slot="prepend">user_group_</template>
          </el-input>
        </el-form-item>
        <el-form-item label="备注(选填)" size="small">
          <el-input class="w400" v-model="form.comment" type="textarea" placeholder="请输入备注" rows="5" />
        </el-form-item>
      </el-row>
      <!-- 基础信息 end -->

      <!-- 分群规则 start -->
      <el-row style="margin-bottom:10px;">
        <div class="group-analysis-header">
          <span class="header-text">分群规则</span>
        </div>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <!-- 用户属性满足 start -->
          <el-row style="margin-bottom:10px">
            <el-card shadow="never">
              <el-row type="flex" justify="space-between" class="user-prop-title">
                <!-- <div><i class="el-icon-minus delete-all"/>用户属性满足</div> -->
                <div style="font-weight: bold;">用户属性满足</div>
                <div class="add_rows" @click="handleAddPropsRow('propertyRules','ruleDetails')">
                  <i class="el-icon-plus add-all" />
                  <span>添加</span>
                </div>
              </el-row>
              <el-row :gutter="20" type="flex">
                <el-col :span="1" style="width:40px" v-if="propertyRules.ruleDetails.length > 1">
                  <div class="relationship">
                    <div class="relationship-bar"></div>
                    <div class="relationship-text" @click="handleTurnOn('propertyRules')">{{propertyRules.relationship | relationship}}</div>
                  </div>
                </el-col>
                <el-col :span="propertyRules.ruleDetails.length > 1 ? 23 :24">
                  <el-row style="margin:10px 0;" :gutter="20" v-for="(item,index) in propertyRules.ruleDetails" :key="index">
                    <el-col :span="5">
                      <el-select v-model="item.key" placeholder="请选择" @change="e => handelSelectKey(e,item)" size="small">
                        <el-row class="option-search">
                          <el-input v-model="levelTwoFilter" @change="handleChangeFilter" prefix-icon="el-icon-search" clearable size="small" />
                        </el-row>
                        <div v-for="(e,i) in levelOneOption" :key="i">
                          <el-row class="leveltwo-option-header">{{`${e.levelOne}(${e.propertyList.length})`}}</el-row>
                          <el-option v-for="item in e.propertyList" :key="item.leveltTwoCode" :label="item.leveltTwo" :value="item.leveltTwoCode"></el-option>
                        </div>
                      </el-select>
                    </el-col>
                    <el-col :span="5">
                      <el-select v-model="item.condition" placeholder="请选择" size="small" @change="clearGroupNum">
                        <el-option v-for="f in conditionOption" :key="f.value" :label="f.text" :value="f.value"></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="5">
                      <el-select v-if="item.enumValue.length" v-model="item.value" placeholder="请选择" size="small" @change="clearGroupNum">
                        <el-option v-for="f in item.enumValue" :key="f" :label="f" :value="f"></el-option>
                      </el-select>
                      <el-input v-else v-model="item.value" size="small" @input="clearGroupNum" />
                    </el-col>
                    <el-col :span="9" style="text-align:right;padding:0;margin-top:10px;">
                      <span @click="handleDeletePropsRow(index,'propertyRules','ruleDetails')" style="cursor: pointer;">
                        <i class="el-tag__close el-icon-close"></i>
                      </span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-row>
          <!-- 用户属性满足 end -->

          <!-- 用户行为满足 start -->
          <el-row>
            <el-card shadow="never">
              <el-form>
                <el-row type="flex" justify="space-between" class="user-prop-title">
                  <!-- <div><i class="el-icon-minus delete-all"/>用户行为满足</div> -->
                  <div style="font-weight: bold;">用户行为满足</div>
                  <div class="add_rows" @click="handleAddPropsRow('actRules','actDetails')">
                    <i class="el-icon-plus add-all" />
                    <span>添加</span>
                  </div>
                </el-row>
                <el-row :gutter="20" type="flex">
                  <el-col :span="1" style="width:40px" v-if="actRules.actDetails.length > 1">
                    <div class="relationship">
                      <div class="relationship-bar"></div>
                      <div class="relationship-text" @click="handleTurnOn('actRules')">{{actRules.relationship | relationship}}</div>
                    </div>
                  </el-col>
                  <el-col :span="actRules.actDetails.length > 1 ? 23 :24">
                    <el-row v-for="(item,index) in actRules.actDetails" :key="index">
                      <el-col :span="21">
                        <!-- 第一行 -->
                        <el-row :gutter="20" style="margin:10px 0;">
                          <el-col :span="6" style="display:flex;width:320px;">
                            <div v-if="actRules.actDetails.length > 1" class="index" style="margin-right:5px;background: #00bf8a;">{{String.fromCharCode(65+index)}}</div>
                            <el-date-picker v-model="item.date" type="daterange" range-separator="至" size="small" class="date_picker" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="false" @change="(e) => handleChangeActDetailsDate(e,item)"></el-date-picker>
                          </el-col>
                          <el-col :span="2" style="width:120px;">
                            <el-select v-model="item.actType" placeholder="请选择" size="small" @change="clearGroupNum">
                              <el-option v-for="item in actTypeOption" :key="item.value" :label="item.text" :value="item.value"></el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="5">
                            <el-select v-model="item.eventCode" placeholder="请选择" size="small" @change="clearGroupNum">
                              <el-row class="option-search">
                                <el-input v-model="eventListFilter" prefix-icon="el-icon-search" clearable size="small" />
                              </el-row>
                              <el-option v-for="item in eventListOptionFilter" :key="item.event_code" :label="item.event_name" :value="item.event_code"></el-option>
                            </el-select>
                          </el-col>
                        </el-row>
                        <!-- 第二行 -->
                        <el-row :gutter="20" type="flex" style="margin:0;">
                          <el-col :span="2" style="display:flex;width:120px;">
                            <div style="margin-right:5px;padding-left:20px;" v-if="actRules.actDetails.length > 1"></div>
                            <el-input size="small" value="总次数" readonly />
                          </el-col>
                          <el-col :span="1" style="width: 65px">
                            <el-input size="small" value="≥" readonly />
                          </el-col>
                          <el-col :span="4" style="display:flex;align-items:center;width:155px;">
                            <el-input-number v-model="item.num" :precision="0" :controls="false" size="small" class="input_num" @change="clearGroupNum" />
                            <span>次</span>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="3" :style="`text-align:right;padding-right:${actRules.actDetails.length > 1 ? '5' : '0'}px;margin-top:10px;`">
                        <span @click="handleDeletePropsRow(index,'actRules','actDetails')" style="cursor: pointer;">
                          <i class="el-tag__close el-icon-close"></i>
                        </span>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form>
            </el-card>
          </el-row>
          <!-- 用户行为满足 end -->
        </el-col>

        <!-- 评估 -->
        <el-col :span="4">
          <el-form-item prop="groupNum" :rules="[{validator:(rules,value,callback)=>validateGroupNum(rules,value,callback),trigger:'change'}]">
            <el-input :value="form.groupNum" type="text" readonly />
          </el-form-item>
          <el-button type="primary" size="small" v-loading="calculateBtn" @click="handleCalculate">评估</el-button>
        </el-col>
      </el-row>
      <!-- 分群规则 end -->

      <!-- 提交 -->
      <el-row style="margin-top:10px;">
        <el-button style="background-color:#38d474;color:#fff;" v-loading="saveBtn" @click="handleSave">创建</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  filters: {
    relationship (val) {
      if (val) {
        return val == "and" ? "且" : "或";
      }
    }
  },
  data () {
    let validateGroupNum = (rules, value, callback) => {
      if (!this.form.groupNum && this.form.groupNum != "0") {
        return callback(new Error("请进行评估"));
      }
      return callback();
    };
    return {
      form: {
        groupName: "",
        comment: "",
        groupId: "",
        ruleType: "all",
        propertyRules: {},
        actRules: {},
        groupNum: undefined
      },
      propertyRules: {
        relationship: "and",
        ruleDetails: []
      },
      actRules: {
        relationship: "and",
        actDetails: []
      },
      actDetailsDate: [],
      levelOneOption: [],
      levelOne: [],
      levelTwoFilter: "",
      eventListFilter: "",
      conditionOption: [
        {
          text: "等于",
          value: "="
        },
        {
          text: "不等于",
          value: "!="
        },
        {
          text: "大于等于",
          value: ">="
        },
        {
          text: "小于等于",
          value: "<="
        },
        {
          text: "包含",
          value: "like"
        },
        {
          text: "不包含",
          value: "not like"
        }
      ],
      actTypeOption: [
        {
          text: "做过",
          value: "="
        },
        {
          text: "没做过",
          value: "!="
        }
      ],
      eventListOption: [],
      calculateBtn: false,
      saveBtn: false,
      validateGroupNum,
      type: "add"
    };
  },
  created () {
    let params = this.$route.params;
    this.getEventList();
    if (JSON.stringify(params) !== "{}") {
      this.getFilterList(() => {
        this.form = params;
        this.form.groupNum = params.num;
        this.propertyRules = params.propertyRules || this.propertyRules;
        this.actRules = params.actRules || this.actRules;
        if (this.actRules.actDetails && this.actRules.actDetails.length > 0) {
          this.actRules.actDetails.forEach(e => {
            this.$set(e, "date", [e.startDate, e.endDate]);
          });
        }
        this.propertyRules.ruleDetails.forEach(e => {
          this.handelSelectKey(e.key, e, "edit");
        });
      });
    } else {
      this.getGroupId();
      this.getFilterList();
    }
  },
  computed: {
    groupId () {
      const { groupId } = this.form;
      return groupId.split("_")[2];
    },
    eventListOptionFilter () {
      if (this.eventListFilter) {
        let arr = this.eventListOption.filter(e =>
          e.event_name.includes(this.eventListFilter)
        );
        if (arr.length) {
          return arr;
        }
        return this.eventListOption;
      }
      return this.eventListOption;
    }
  },
  methods: {
    async getGroupId () {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/propertyAndAct/getGroupId"
      );
      if (data.flag) {
        this.form.groupId = data.data;
      }
    },
    async getEventList () {
      const { data } = await this.$http.post(
        "https://cdpengine.cn/scrm-platform/v1/DataAnalysis/eventList"
      );
      if (data.code == 0) {
        this.eventListOption = data.data;
      }
    },
    async getFilterList (callback) {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/property/list"
      );
      if (data.flag) {
        this.levelOneOption = data.data;
        this.levelOne = [...this.levelOneOption];
        if (callback) callback();
      }
    },
    handelSelectKey (val, data, type = "add") {
      if (type == "add") this.clearGroupNum();
      let three = [];
      for (let i = 0; i < this.levelOneOption.length; i++) {
        const element = this.levelOneOption[i];
        for (let j = 0; j < element.propertyList.length; j++) {
          const item = element.propertyList[j];
          if (item.leveltTwoCode == val) {
            three = item.enumValue;
            break;
          }
        }
      }
      if (three.length) {
        if (type == "add") {
          data.value = three[0];
        }
        data.enumValue = three;
      } else {
        if (type == "add") {
          data.value = "";
        }
        data.enumValue = [];
      }
    },
    handleChangeFilter () {
      if (!this.levelTwoFilter) return this.levelOne;
      let levelOne = [];
      for (let i = 0; i < this.levelOne.length; i++) {
        const element = this.levelOne[i];
        if (i != 0 && !levelOne[i - 1].propertyList.length) {
          levelOne.splice(i - 1, 1, null);
        }
        levelOne.push({
          levelOne: element.levelOne,
          propertyList: []
        });
        for (let j = 0; j < element.propertyList.length; j++) {
          const item = element.propertyList[j];
          if (item.leveltTwo.includes(this.levelTwoFilter)) {
            levelOne[i]["propertyList"].push(item);
          }
        }
      }
      if (!levelOne[levelOne.length - 1].propertyList.length) {
        levelOne.splice(levelOne.length - 1, 1);
      }
      if (levelOne.length == 0) {
        levelOne = [...this.levelOne];
      }
      this.levelOneOption = [...levelOne];
    },
    handleAddPropsRow (prop, prop2) {
      let obj = {};
      if (prop == "actRules") {
        obj = {
          date: [this.format(), this.format()],
          startDate: this.format(),
          endDate: this.format(),
          actType: "=",
          eventCode: this.eventListOption[0].event_code,
          num: "0"
        };
      } else {
        obj = {
          value: this.levelOneOption[0].propertyList[0]["enumValue"][0],
          key: this.levelOneOption[0].propertyList[0].leveltTwoCode,
          condition: "=",
          enumValue: this.levelOneOption[0].propertyList[0]["enumValue"]
        };
      }
      this[prop][prop2].push(obj);
      if (this[prop][prop2].length > 1 && !this[prop].relationship) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
    handleDeletePropsRow (i, prop, prop2) {
      this[prop][prop2].splice(i, 1);
      if (this[prop][prop2].length <= 1) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
    handleTurnOn (prop) {
      let data = this[prop];
      if (data.relationship == "and") {
        data.relationship = "or";
      } else {
        data.relationship = "and";
      }
    },
    handleChangeActDetailsDate (val, item) {
      item.startDate = val[0];
      item.endDate = val[1];
      this.clearGroupNum();
    },
    getParams () {
      let params = this.form;
      params.propertyRules = this.propertyRules;
      params.actRules = this.actRules;
      params.ruleType = "all";
      if (
        this.propertyRules.ruleDetails.length &&
        this.actRules.actDetails.length
      ) {
        params.ruleType = "all";
      } else if (
        this.propertyRules.ruleDetails.length &&
        !this.actRules.actDetails.length
      ) {
        params.ruleType = "property";
      } else if (
        !this.propertyRules.ruleDetails.length &&
        this.actRules.actDetails.length
      ) {
        params.ruleType = "act";
      }
      return params;
    },
    async handleCalculate () {
      let params = this.getParams();
      if (
        !this.propertyRules.ruleDetails.length &&
        !this.actRules.actDetails.length
      ) {
        this.$message.warning("请填写用户属性满足或用户行为满足");
        return;
      }
      this.calculateBtn = true;
      const { data: res } = await this.$http.post(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/propertyAndAct/calculate",
        params
      );
      if (res.flag) {
        this.form.groupNum = res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.calculateBtn = false;
    },
    handleSave () {
      try {
        this.$refs._fromRef.validate(valid => {
          if (valid) {
            let params = this.getParams();
            this.saveBtn = true;
            this.$http
              .post(
                "https://cdpengine.cn/scrm-platform/v1/UserGroup/propertyAndAct/calculate/save",
                params
              )
              .then(({ data: res }) => {
                console.log(res);
                if (res.flag) {
                  this.$message.success("创建成功");
                  this.handleCancel();
                } else {
                  this.$message.error(res.msg);
                }
                this.saveBtn = false;
              });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleCancel () {
      this.$router.push({ name: "GroupList" });
    },
    format () {
      let da = new Date();
      let year = da.getFullYear();
      let month =
        da.getMonth() + 1 < 10 ? "0" + (da.getMonth() + 1) : da.getMonth() + 1;
      let date = da.getDate() < 10 ? "0" + da.getDate() : da.getDate();
      return [year, month, date].join("-");
    },
    clearGroupNum () {
      this.form.groupNum = "";
      this.calculateBtn = true;
      this.calculateBtn = false;
    }
  }
};
</script>

<style lang="less" scoped>
.group-analysis {
  text-align: left;
  padding: 20px;
  .group-analysis-header {
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    .header-text {
      font-size: 14px;
      font-weight: bold;
      padding-left: 5px;
      border-left-style: 4px;
      border-left-color: #00bf8a;
      border-left-style: solid;
    }
  }
  .user-prop-title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .add-all {
    background-color: #00bf8a;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    margin-right: 3px;
    cursor: pointer;
  }
  .delete-all {
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #000;
  }
  .add_rows {
    font-size: 14px;
    cursor: pointer;
  }
  .date_picker {
    &.el-input__inner {
      width: auto;
    }
  }

  .index {
    width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.input_num {
  margin-right: 5px;
}
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left !important;
}
.leveltwo-option-header {
  font-size: 12px !important;
  padding: 5px 0 !important;
  background-color: #f5f7fa;
  color: #888;
}
.relationship {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #38d474;
  .relationship-bar {
    height: 100%;
    width: 2px;
    background-color: rgba(53, 219, 171, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
  .relationship-text {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    background-color: #d6efe8;
    cursor: pointer;
  }
}
.option-search {
  .el-input__inner {
    border: 0;
    border-bottom: 1px solid #888;
  }
}
.w400 {
  width: 400px;
}
</style>
