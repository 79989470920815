import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '@/layouts/BasicLayout'
import Home from '@/views/Home'
import CustomerManagePageList from '@/views/CustomerManage/CustomerPageList'
import CustomerManageCustomerDetails from '@/views/CustomerManage/CustomerDetails'
import Label from '@/views/Label'
import MaterialLibrary from '@/views/MaterialLibrary'
import Test from '@/test/index.vue'
import CustomerStage from '@/views/CustomerStageNew/Main'
import Smstemplete from '@/views/Smstemplete'
import Smstask from '@/views/Smstask'
import GroupList from '@/views/GroupList'
import GroupDataStatistics from '@/views/GroupDataStatistics'
import GroupSOPList from '@/views/GroupSOP/List'
import GroupCreateRule from '@/views/GroupSOP/CreateRule'
import GroupRuleDetails from '@/views/GroupSOP/RuleDetails'
import PersonalSOPList from '@/views/PersonalSOP/SopPgaeList'
import PersonalCreateRule from '@/views/PersonalSOP/CreateRule'
import PersonalRuleDetails from '@/views/PersonalSOP/RuleDetails'
import GroupLabel from '@/views/GroupLabel'
import File from '@/views/CodeManagement/File'
import Text from '@/views/CodeManagement/Text'
import Pic from '@/views/CodeManagement/Pic'
import Video from '@/views/CodeManagement/Video'
import DrainagePageList from '@/views/Drainage/DrainagePageList'
import AddNewStaffCode from '@/views/Drainage/addNewStaffCode'
import DrainageDetails from '@/views/Drainage/DrainageDetails'
import GroupWelcome from '@/views/GroupWelcome/GroupWelcome'
import CustomerLost from '@/views/CustomerLost'
import AddAndEditWelcome from '@/views/GroupWelcome/AddAndEditWelcome'
import SmsSettings from '@/views/Sms/Settings'
import SmsPackage from '@/views/Sms/Package'
import SmsStatistics from '@/views/Sms/Statistics'
import EnterpriseSettings from '@/views/EnterpriseManage/Settings'
import EnterpriseAddressBook from '@/views/EnterpriseManage/AddressBook'
import CustomerStagetest from '@/views/CustomerStage'
import RolesSystem from '@/views/RolesSystem'
import SystemUse from '@/views/UserSystem'
import AuthorityManagement from '@/views/AuthorityManagement'
import Login from '@/views/Login'
import Sop from '@/views/Sop/Tabs'
import SopDetails from '@/views/Sop/Detail'
import GroupDrainagePageList from '@/views/GroupDrainage/DrainagePageList'
import KeywordReply from '@/views/AutomaticReply/KeywordReply'
import AddKeywordReply from '@/views/AutomaticReply/KeywordReply/addKeywordReply'
import MessageReply from '@/views/AutomaticReply/MessageReply'
import ChannelQRCode from '@/views/ParameterQRCode/ChannelQRCode'
import AddChannelQRcode from '@/views/ParameterQRCode/ChannelQRCode/addChannelQRcode'
import PictureMaterial from '@/views/MaterialManagement/PictureMaterial'
import CustomizeMenu from '@/views/MaterialManagement/CustomizeMenu'
import OfficialAccountLabel from '@/views/FanManagement/OfficialAccountLabel'
import FanList from '@/views/FanManagement/FanList'
import editMessageReply from '@/views/AutomaticReply/MessageReply/editMessageReply'
import AttentionReply from '@/views/AutomaticReply/AttentionReply'
import editAttentionReply from '@/views/AutomaticReply/AttentionReply/editAttentionReply'
import KPI from '@/views/App/KPI'
import Channel from '@/views/App/Channel'
import Tag from '@/views/App/Tag'
import Tagedit from '@/views/App/TagEdit'
import User from '@/views/App/User'
import CodeManagement from '@/views/CodeManagement'
// 事件分析
import Event from '@/views/App/EventAnalyse'
// 漏斗分析
import FunnelAnalysis from '@/views/App/FunnelAnalysis'
// 留存分析
import Preserve from '@/views/App/Preserve'
// 分布分析
import DistributionAnalysis from '@/views/App/DistributionAnalysis'
import GroupListApp from '@/views/App/GroupList'
// 人群分析
import Crowd from '@/views/App/Crowd'
import GroupAnalysis from '@/views/App/GroupAnalysis'
// 定时触发
import ScheduledTask from '@/views/App/ScheduledTask'
// 行为触发
import Behavior from '@/views/App/Behavior'
import TagDetails from '@/views/App/TagDetails'
import MessagePush from '@/views/MessagePush'
// 小程序
import Personas from '@/views/MiniProgram/Personas'
import BehaviorAnalysis from '@/views/MiniProgram/BehaviorAnalysis'
import Access from '@/views/MiniProgram/Access'
import CustomerManagement from '@/views/MiniProgram/CustomerManagement'
import MiniMessagePush from '@/views/MiniProgram/MessagePush'
// 群发消息
import SendToGroup from '@/views/SendToGroup'
// 聊天敏感词
import SensitiveWordChat from '@/views/SensitiveWordChat'
// 在职继承
import InheritJob from '@/views/InheritJob'

// 自定义表单
import CustomerForm from '@/views/CustomerForm/index.vue'
// 自定义表单h5
import CustomerFormH5 from '@/views/CustomerForm/components/h5.vue'
import CustomerFormH5Success from '@/views/CustomerForm/components/success.vue'
import DesktopGetUserAllInfos from '@/views/DesktopGetUserAllInfos/index.vue'
// 抖音粉丝列表
import FansList from '@/views/OthersChannels/DouYin/Fans/index.vue'
import TikTokAssagin from '@/views/OthersChannels/DouYin/Assagin/index.vue'
import WorksList from '@/views/OthersChannels/DouYin/Works/index.vue'
// 小鹅通列表
import XiaoErtongList from '@/views/OthersChannels/XiaoErtong/List/index.vue'
import OrderList from '@/views/OthersChannels/XiaoErtong/OrderList/index.vue'
import MarketingAutomation from '@/views/MarketingAutomation/index.vue'

// 活动 人气排行榜
import CustomerActivity from '@/views/CustomerActivity/index.vue'
import CustomerActivityH5 from '@/views/CustomerActivity/components/h5.vue'
import CustomerActivityCreate from '@/views/CustomerActivity/components/create.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/h5/form',
    name: 'h5',
    meta: { title: '活动报名' },
    component: CustomerFormH5
  },
  {
    path: '/h5/success',
    name: 'h5Success',
    meta: { title: '报名成功' },
    component: CustomerFormH5Success
  },
  {
    path: '/h5/activity',
    name: 'h5Activity',
    meta: { title: '个人排名榜' },
    component: CustomerActivityH5
  },
  {
    path: '/desktop/getUserAllInfos',
    name: 'desktopGetUserAllInfos',
    meta: { title: '客户信息' },
    component: DesktopGetUserAllInfos
  },
  {
    path: '/tiktok/assagin',
    name: 'TikTokAssagin',
    component: TikTokAssagin
  },
  {
    path: '/',
    name: 'base-layout',
    component: BasicLayout,
    redirect: '/home',
    children: [{
      path: '/home',
      component: Home
    },
    {
      path: '/customer/manage',
      component: CustomerManagePageList
    },
    {
      path: '/customer/userDetails',
      component: CustomerManageCustomerDetails
    },
    {
      path: '/label',
      component: Label
    },
    {
      path: '/material/library',
      component: MaterialLibrary
    },
    {
      path: '/customer/stage',
      component: CustomerStage
    },
    {
      path: '/customer/smstemplete',
      component: Smstemplete
    },
    {
      path: '/customer/smstask',
      component: Smstask
    },
    {
      path: '/customer/lost',
      component: CustomerLost
    },
    {
      path: '/group/list',
      component: GroupList
    },
    {
      path: '/group/statistics',
      component: GroupDataStatistics
    },
    {
      path: '/groupSop/list',
      component: GroupSOPList
    },
    {
      path: '/groupSop/create/rule',
      component: GroupCreateRule
    },
    {
      path: '/groupSop/rule/details',
      component: GroupRuleDetails
    },
    {
      path: '/personalSop/list',
      component: PersonalSOPList
    },
    {
      path: '/personalSop/create/rule',
      component: PersonalCreateRule
    },
    {
      path: '/personalSop/rule/details',
      component: PersonalRuleDetails
    },
    {
      path: '/group/label',
      component: GroupLabel
    },
    {
      path: '/code/text',
      component: Text
    },
    {
      path: '/code/file',
      component: File
    },
    {
      path: '/code/pic',
      component: Pic
    },
    {
      path: '/code/video',
      component: Video
    },
    {
      path: '/drainage/activitycode',
      component: DrainagePageList
    },
    {
      path: '/drainage/newstaffcode',
      component: AddNewStaffCode
    },
    {
      path: '/drainage/details',
      component: DrainageDetails
    },
    {
      path: '/group/welcome',
      component: GroupWelcome
    },
    {
      path: '/group/addwelcome',
      component: AddAndEditWelcome
    },
    {
      path: '/sms/settings',
      component: SmsSettings
    },
    {
      path: '/sms/package',
      component: SmsPackage
    },
    {
      path: '/sms/statistics',
      component: SmsStatistics
    },
    {
      path: '/enterpriseSettings/settings',
      component: EnterpriseSettings
    },
    {
      path: '/enterpriseSettings/address',
      component: EnterpriseAddressBook
    },
    {
      path: '/enterpriseSettings/chat',
      component: SensitiveWordChat
    },
    {
      path: '/enterpriseSettings/job',
      component: InheritJob
    },
    {
      path: '/CustomerStagetest',
      component: CustomerStagetest
    },
    {
      path: '/test',
      component: Test
    },
    {
      path: '/RolesSystem',
      component: RolesSystem
    },
    {
      path: '/SystemUse',
      component: SystemUse
    },
    {
      path: '/AuthorityManagement',
      component: AuthorityManagement
    },
    {
      path: '/Sop',
      component: Sop
    },
    {
      path: '/Sop/Details',
      component: SopDetails
    },
    {
      path: '/Group/DrainagePageList',
      component: GroupDrainagePageList
    },
    {
      path: '/Wechat/AutomaticReply/keyword',
      component: KeywordReply
    },
    {
      path: '/Wechat/AutomaticReply/addkeyword',
      component: AddKeywordReply
    },
    {
      path: '/Wechat/AutomaticReply/messageReply',
      component: MessageReply
    },
    {
      path: '/Wechat/ParameterQRCode/activity',
      component: CustomerActivity
    },
    {
      path: '/Wechat/ParameterQRCode/create',
      component: CustomerActivityCreate
    },
    {
      path: '/Wechat/ParameterQRCode/channelQRCode',
      component: ChannelQRCode
    },
    {
      path: '/Wechat/ParameterQRCode/addChannelQRcode',
      component: AddChannelQRcode
    },
    {
      path: '/Wechat/MaterialManagement/PictureMaterial',
      component: PictureMaterial
    },
      {
      path: '/Wechat/MaterialManagement/CustomizeMenu',
      component: CustomizeMenu
    },
    {
      path: '/Wechat/FanManagement/OfficialAccountLabell',
      component: OfficialAccountLabel
    },
    {
      path: '/Wechat/FanManagement/FanList',
      component: FanList
    },
    {
      path: '/Wechat/AutomaticReply/editMessage',
      component: editMessageReply
    },
    {
      path: '/Wechat/AutomaticReply/AttentionReply',
      component: AttentionReply
    },
    {
      path: '/Wechat/AutomaticReply/editAttention',
      component: editAttentionReply
    },
    {
      path: '/App/kpi',
      component: KPI
    },
    {
      path: '/App/channel',
      component: Channel
    },
    {
      path: '/App/tag',
      component: Tag
    },
    {
      path: '/App/tagedit',
      component: Tagedit
    },
    {
      path: '/App/user',
      component: User
    },
    {
      path: '/App/event',
      component: Event
    },
    {
      path: '/App/keep',
      component: Preserve
    },
    {
      path: '/App/funnel',
      component: FunnelAnalysis
    },
    {
      path: '/App/distributeAnalysis',
      component: DistributionAnalysis
    },
    {
      path: '/App/group',
      name: 'GroupList',
      component: GroupListApp
    },
    {
      path: '/App/crowd',
      component: Crowd
    },
    {
      path: '/App/groupanalysis',
      name: 'GroupAnalysis',
      component: GroupAnalysis
    },
    {
      path: '/App/ScheduledTask',
      name: 'scheduledTask',
      component: ScheduledTask
    },
    {
      path: '/App/behavior',
      name: 'behavior',
      component: Behavior
    },
    {
      path: '/App/details',
      name: 'TagDetails',
      component: TagDetails
    },
    {
      path: '/group/code',
      name: 'CodeManagement',
      component: CodeManagement
    },
    {
      path: '/Wechat/Message/Push',
      name: 'MessagePush',
      component: MessagePush
    },
    {
      path: '/Mini/personas',
      name: 'Personas',
      component: Personas
    },
    {
      path: '/Mini/behavior',
      name: 'BehaviorAnalysis',
      component: BehaviorAnalysis
    },
    {
      path: '/Mini/access',
      name: 'Access',
      component: Access
    },
    {
      path: '/Mini/customerManagement',
      name: 'CustomerManagement',
      component: CustomerManagement
    },
    {
      path: '/Mini/messagepush',
      name: 'MiniMessagePush',
      component: MiniMessagePush
    },
    {
      path: '/Wechat/Message/sendToGroup',
      name: 'SendToGroup',
      component: SendToGroup
    },
    {
      path: '/CustomerForm',
      name: 'CustomerForm',
      component: CustomerForm
    },
    {
      path: '/Other/tiktok/fanslist',
      name: 'FansList',
      component: FansList
    },
    {
      path: '/Other/xiaoe/list',
      name: 'XiaoErtongList',
      component: XiaoErtongList
    },
    {
      path: '/Other/tiktok/workslist',
      name: 'WorksList',
      component: WorksList
    },
    {
      path: '/Other/xiaoe/orderlist',
      name: 'OrderList',
      component: OrderList
    },
    {
      path: '/MarketingAutomation',
      name: 'MarketingAutomation',
      component: MarketingAutomation
    }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import ( /* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
