<template>
  <!-- 小程序事件 -->
  <div class="wechatFriends">
    <div class="nodeTitle">小程序事件筛选</div>
    <el-select v-model="value" placeholder="请选择" clearable >
      <el-option v-for="item in eventList" :key="item" :label="item" :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
/* eslint-disable */
import {
  getMiniEventList,
} from "@/service/group";
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      value:'',
      eventList: [],
    };
  },
  mounted() {},
  created() {
    this.getMiniEventList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getMiniEventList() {
      getMiniEventList().then(res => {
        this.eventList = res;
      });
    },
    save() {
      console.log("save -> this.node", this.value);
      if (this.value === "" ) {
        Message.warning("请检查！配置项未填写完成！");
        return false;
      }
      return this.value;
    },
    init(val) {
      console.log("init -> val", val);
      this.value = val || ''
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
</style>
