<!-- 指示组件 -->
<template>
  <div class="event-condition">
    <div class="condition-title">
      <span>指标</span>
      <span>
        <i class="iconfont icon-gongshi"></i>
        自定义指标公式
      </span>
    </div>
    <div class="indicate">
      <div class="indicate-valule-list">
        <div class="values-item" v-for="(item, i) in indicateTotal" :key="item">
          <div class="values-selects">
            <div class="index">{{ String.fromCharCode(item) }}</div>
            <el-dropdown size="mini" trigger="click" @command="changeIndicate">
              <el-tag size="small">
                {{
                eventCodes[i]
                ? eventCodes[i].event_name
                ? eventCodes[i].event_name
                : "请选择"
                : "请选择"
                }}
              </el-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="
                    beforeHandleCommand(item, v.event_code, v.event_name, i)
                  " v-for="v in indicateData" :key="v.event_code">{{ v.event_name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span>的</span>
            <el-dropdown size="mini" trigger="click" placement="bottom-start" @command="changeIndicateRight">
              <el-tag size="small">
                {{
                eventCodes[i]
                ? eventCodes[i].countType
                ? eventCodes[i].countType
                : "请选择"
                : "请选择"
                }}
              </el-tag>
              <el-tag size="small" @click="removeTag(item)">
                <i class="el-icon-delete"></i>
              </el-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommandRight(item, '次数', i)" key="66">次数</el-dropdown-item>
                <el-dropdown-item :command="beforeHandleCommandRight(item, '人数', i)" key="77">人数</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="values-info">
            <p>
              指标重命名：{{ eventCodes[i] && eventCodes[i].event_name }}的{{
              eventCodes[i] && eventCodes[i].countType
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="indicate-select">
        <div class="icon-add">
          <i class="iconfont icon-xinjian"></i>
        </div>
        <el-tag size="small" @click="addIndicate">请选择</el-tag>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["eventData"],
  data () {
    return {
      indicateData: [],
      indicateTotal: [],
      defaultCode: 65,
      eventCodes: []
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this._initIndicateData();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
  methods: {
    _initIndicateData () {
      this.$http
        .post(
          "https://cdpengine.cn/scrm-platform/v1/DataAnalysis/eventList"
        )
        .then(data => {
          this.indicateData = data.data.data;
        });
    },
    //点击增加指标个数
    addIndicate () {
      if (this.indicateTotal.length === 0) {
        this.indicateTotal.push(this.defaultCode);
        return this.defaultCode++;
      }
      if (this.eventCodes.length === 0) return;
      if (this.eventCodes.length !== this.indicateTotal.length) return;
      if (this.eventCodes.every(item => item.countType && item.event_name)) {
        this.indicateTotal.push(this.defaultCode);
        return this.defaultCode++;
      }
    },
    beforeHandleCommand (code, eventCode, event_name, index) {
      return {
        code: code,
        eventCode: eventCode,
        index: index,
        event_name: event_name
      };
    },
    beforeHandleCommandRight (code, countType, index) {
      return {
        code: code,
        countType: countType,
        index: index
      };
    },
    changeIndicate (command) {
      const { code, eventCode, event_name, index } = command;
      const i = this.eventCodes.findIndex(item => item.code === code);
      if (i >= 0) {
        this.eventCodes[i].eventCode = eventCode;
        this.eventCodes[i].event_name = event_name;
        this.eventCodes.reverse().reverse();
      } else {
        command.countType = "人数";
        this.eventCodes.push(command);
      }
    },
    changeIndicateRight (command) {
      const { code, countType, index } = command;
      const i = this.eventCodes.findIndex(item => item.code === code);
      if (i >= 0) {
        this.eventCodes[i].countType = countType;
        this.eventCodes.reverse().reverse();
      } else {
        this.eventCodes.push(command);
      }
    },
    removeTag (item) {
      let index1 = this.indicateTotal.findIndex(v => v === item);
      // this.eventCodes = this.eventCodes.filter(v => v.code !== item)
      if (index1 !== -1) {
        this.indicateTotal.splice(index1, 1);
      }
      let index2 = this.eventCodes.findIndex(v => v.code === item);
      if (index2 !== -1) {
        this.eventCodes.splice(index2, 1);
      }
    }
  },
  watch: {
    eventCodes (newValue) {
      this.$emit("getEventCodesValue", this.eventCodes);
    },
    eventData: {
      immediate: true,
      deep: true,
      handler (val) {
        var res = [];
        this.indicateTotal = [];
        this.defaultCode = 65;
        setTimeout(() => {
          val.forEach(item => {
            var t = this.indicateData.filter(
              value => value.event_code === item.eventCode
            );
            res.push({
              countType: item.countType,
              event_name: t[0].event_name,
              eventCode: item.eventCode,
              code: this.defaultCode
            });
            this.indicateTotal.push(this.defaultCode);
            this.defaultCode++;
          });
          this.eventCodes = res;
        }, 500);
      }
    }
  }
};
</script>
<style scoped>
/* @import url(); 引入css类 */
@import url("../../index.css");
</style>