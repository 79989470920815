<template>
  <div class="has-scollbar customer-details">
    <el-row>
      <el-col :span="11">
        <el-row>
          <Block title="基本信息" class="base-info">
            <div class="base-info-contaier" v-if="userData.detail">
              <div class="info">
                <img :src="userData.detail.picUrl" alt="" />
                <div class="text">
                  <p>{{ userData.detail.name }}</p>
                  <span :style="{
                      color:
                        userData.detail.addType === '微信'
                          ? ' #67C23A'
                          : '#E6A23C',
                    }">@{{ userData.detail.addType }}</span>
                </div>
              </div>
              <div v-for="(item, index) in logList" class="wrap-box" :key="index">
                <el-divider content-position="center">{{item.dataDate}}</el-divider>
                <div class="line-box">
                  <el-timeline>
                    <el-timeline-item v-for="(activity, i) in item.list" :key="i+'-activity'" color="#0bbd87">
                      <div class="item-box">
                        <!-- 时间线左侧 -->
                        <div class="line-left">
                          <span class="eventTypeDate">{{ activity.eventTime }}</span>
                          <el-image class="img" v-if="activity.type ==='小程序'" :src="miniPro"></el-image>
                          <el-image class="img" v-if="activity.type ==='公众号'" :src="officialAccount"></el-image>
                          <el-image class="img" v-if="['企微','企业微信'].includes(activity.type)" :src="enterpriseWechat"></el-image>
                        </div>
                        <!-- 时间线右侧 -->
                        <div class="line-right">
                          {{activity.event}}
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </div>
          </Block>
        </el-row>
        <el-row v-if="userData.detail">
          <Block title="备注信息" class="remark-block">
            <p class="has-border" v-show="!showDescriptionEdit" @click="showEdit(2)">
              {{ description ? description : "没有更多信息啦" }}
            </p>
            <el-input v-show="showDescriptionEdit" v-model="description" type="textarea" :rows="8" ref="saveDescriptionInput" @keyup.enter.native="handleInputContentConfirm()" @blur="handleInputContentConfirm()"></el-input>
          </Block>
        </el-row>
      </el-col>
      <el-col :span="13">
        <el-row>
          <Block title="所在群聊" class="group-chat" v-if="GroupList">
            <div class="group-chat-list">
              <div class="group-chat-item" v-for="value in GroupList" :key="value.group_id">
                <el-tag> {{ value.group_name }}</el-tag>
              </div>
            </div>
          </Block>
        </el-row>
        <el-row>
          <Block title="所属客服" class="customer-service" v-if="userData.detail">
            <div class="customer-base-info">
              <div class="info-rows">
                <div class="hd">客服名称：</div>
                <div class="describe">
                  <i class="el-icon-user"></i>
                  {{ userData.detail.userName }}
                </div>
              </div>
              <div class="info-rows">
                <div class="hd">最后沟通时间：</div>
                <div class="describe">---</div>
              </div>
              <div class="info-rows">
                <div class="hd">来源：</div>
                <div class="describe">{{ userData.detail.addWay }}</div>
              </div>
              <div class="info-rows">
                <div class="hd">时间：</div>
                <div class="describe">{{ userData.detail.addTime }}</div>
              </div>
              <div class="info-rows">
                <div class="hd">性别：</div>
                <div class="describe">{{ userData.detail.gender }}</div>
              </div>
              <div class="info-rows">
                <div class="hd">公司：</div>
                <div class="describe">
                  <span class="has-border" v-show="!showCompanyEdit" @click="showEdit(1)">{{
                    remark_company || "暂无"
                  }}</span>
                  <el-input v-show="showCompanyEdit" v-model="remark_company" ref="saveCompanyInput" @keyup.enter.native="handleInputContentConfirm()" @blur="handleInputContentConfirm()"></el-input>
                </div>
              </div>
              <div class="info-rows">
                <div class="hd">电话：</div>
                <div class="describe">
                  <span class="has-border" v-show="!showMobilesEdit" @click="showEdit(3)">{{
                    remark_mobiles
                  }}</span>
                  <el-input v-show="showMobilesEdit" v-model="remark_mobiles" ref="saveMobilesInput" @keyup.enter.native="handleInputContentConfirm()" @blur="handleInputContentConfirm()"></el-input>
                </div>
              </div>
              <div class="info-rows">
                <div class="hd">连接账号：</div>
                <div class="describe">
                  <span class="eventTypeImage">
                    <el-image :src="enterpriseWechat"></el-image>
                    <span>企微</span>
                  </span>
                  <span v-show="miniproId" class="eventTypeImage">
                    <el-image :src="miniPro"></el-image>
                    <span>小程序</span>
                  </span>
                  <span v-show="accountId" class="eventTypeImage">
                    <el-image :src="officialAccount"></el-image>
                    <span>公众号</span>
                  </span>
                </div>
              </div>
            </div>
          </Block>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { queryCustomerDetails, getCustomerGroup, getCustomerActdetail, postCustomerRemark } from '@/service/customerManage'
import { getQuery } from '@/utils'
import Block from '@/components/Block'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data () {
    return {
      userData: {},
      GroupList: [],
      logList: [],
      officialAccount: require('@/assets/officialAccount.png'),
      enterpriseWechat: require('@/assets/enterpriseWechat.png'),
      tencentCloud: require('@/assets/tencentCloud.png'),
      miniPro: require('@/assets/minipro.png'),
      accountId: null,
      miniproId: null,
      remark_company: '',
      showCompanyEdit: false,
      description: '',
      showDescriptionEdit: false,
      remark_mobiles: '',
      showMobilesEdit: false
    }
  },
  computed: {

  },
  created () {
    this.runData()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    runData () {
      const { externalUserid } = getQuery()
      queryCustomerDetails(externalUserid).then(res => {
        this.userData = res
        this.remark_company = res.detail.remarkCorpname
        this.description = res.detail.comment
        this.remark_mobiles = res.detail.remarkMobiles
      })
      getCustomerGroup({ externalUserId: externalUserid }).then(res => {
        this.GroupList = res
      })
      getCustomerActdetail({ externalUserId: externalUserid }).then(res => {
        this.logList = res.logs
        this.accountId = res.accountId
        this.miniproId = res.miniproId
      })
    },
    postCustomerRemark () {
      const { userId, externalUserid } = getQuery()
      var data = {
        userid: userId,
        external_userid: externalUserid,
        description: this.description,
        remark_company: this.remark_company,
        remark_mobiles: this.remark_mobiles.split(';')
      }
      postCustomerRemark(data).then(res => {
        Message.success('编辑成功！')
        this.showCompanyEdit = false
        this.showDescriptionEdit = false
        this.showMobilesEdit = false
      })
    },
    showEdit (type) {
      switch (type) {
        case 1:
          this.showCompanyEdit = true;
          this.$nextTick(_ => {
            this.$refs.saveCompanyInput.$refs.input.focus();
          });
          break;
        case 2:
          this.showDescriptionEdit = true;
          this.$nextTick(_ => {
            this.$refs.saveDescriptionInput.$refs.input.focus();
          });
          break;
        case 3:
          this.showMobilesEdit = true;
          this.$nextTick(_ => {
            this.$refs.saveMobilesInput.$refs.input.focus();
          });
          break;
        default:
          break;
      }
    },
    handleInputContentConfirm () {
      this.postCustomerRemark()
    }
  },
  components: {
    Block
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
