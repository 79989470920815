import { get, post, remove } from '../Api/axios'
import {
  URL_QUERY_LABEL_LIST,
  URL_SYNCHRONIZE_LABEL,
  URL_ADD_LABEL,
  URL_UPDATE_LABEL,
  URL_DELETE_LABEL,
  URL_POST_MARKTAG
} from '@/Api/api.js'

export function getLableList () {
  return get(URL_QUERY_LABEL_LIST)
}

export function synchronizeLabel () {
  return post(URL_SYNCHRONIZE_LABEL, {
    tag_id: [],
    group_id: []
  })
}

export function addLabel (params) {
  return post(URL_ADD_LABEL, {
    ...params
  })
}

export function updateLabel (params) {
  return post(URL_UPDATE_LABEL, {
    ...params
  })
}
export function removeLabel (params) {
  return remove(URL_DELETE_LABEL, {
    ...params
  })
}

export function postMarktag (params) {
  return post(URL_POST_MARKTAG, {
    ...params
  })
}
