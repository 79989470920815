<template>
  <div class="tag-container">
    <div v-for="(item,index) in treeData" :key="index" class="tag-group">
      <div class="tag-title">{{item.groupName}}</div>
      <el-tag class="tag-items" v-for="(v,i) in item.tagList" :type="checked.includes(v.tagName) ? '' : 'info'" :key="i" @click="handleUpdate(v,checked.includes(v.tagName))">{{v.tagName}}</el-tag>
    </div>
  </div>
</template>

<script >
import { getLableList } from '@/service/label'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      treeData: [],
      checked: []
    }
  },
  computed: {
    tags () {
      return [...this.list]
    }
  },
  components: {},
  created () {
    this._initLableList()
  },
  methods: {
    _initLableList () {
      this.checked = [...this.tags]
      getLableList().then(res => {
        this.treeData = res
      })
    },
    handleUpdate (data, flag) {
      // flag true为已选择  false未选择
      if (flag) {
        const index = this.checked.findIndex(e => e === data.tagName)
        this.checked.splice(index, 1)
      } else {
        this.checked.push(data.tagName)
      }
      console.log(data, flag, this.checked);
    }
  }
}
</script>

<style lang="less" scoped>
.tag-container {
  padding: 0 20px 20px;
  .tag-group {
    margin: 10px 0;
    .tag-title {
      margin-bottom: 10px;
    }
    .tag-items {
      margin: 5px;
    }
  }
}
</style>
