import {
  get,
  upload,
  post
} from '../Api/axios'
import {
  URL_CODE_UPLOAD_PIC,
  URL_CODE_GENERATE_QRCODE,
  URL_CODE_TEMPLATE_ADD,
  URL_CODE_DOWNLOAD_PIC,
  URL_CODE_DELETE_PIC,
  URL_OFFICIAL_ACCOUNT_QRCODE_CALCULATE,
  URL_OFFICIAL_ACCOUNT_QRCODE_LIST,
  URL_OFFICIAL_ACCOUNT_QRCODE_CREATE,
  URL_OFFICIAL_ACCOUNT_QRCODE_DELETE
} from '@/Api/api.js'

export function postUpload(params = {}) {
  return upload(URL_CODE_UPLOAD_PIC, {
    ...params
  })
}

export function creatQRCode(params = {}) {
  return get(URL_CODE_GENERATE_QRCODE, {
    ...params
  })
}

export function getHistoricalDocuments(params = {}) {
  return get(URL_CODE_TEMPLATE_ADD, {
    ...params
  })
}

export function getDownloadPic(params = {}) {
  return get(URL_CODE_DOWNLOAD_PIC, {
    ...params
  })
}

// URL_CODE_DELETE_PIC
export function getDelPic(params = {}) {
  return get(URL_CODE_DELETE_PIC, {
    ...params
  })
}

// URL_OFFICIAL_ACCOUNT_QRCODE_CALCULATE
export function getQRCodeCalculate(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_QRCODE_CALCULATE, {
    ...params
  })
}

export function getQrcodeList(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_QRCODE_LIST, {
    ...params
  })
}

export function postQrcodeCreate(params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_QRCODE_CREATE, {
    ...params
  })
}

// URL_OFFICIAL_ACCOUNT_QRCODE_DELETE
export function getQRCodeDelete(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_QRCODE_DELETE, {
    ...params
  })
}
