<!-- 线性图 -->
<template>
  <div id="map">暂无数据...</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['mapData'],
  data () {
    /* eslint-disable */
    return {
      option: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        color: ['#6E9CFA', '#FE5377', '#95FFEC', '#F86F91', '#5C8BFF', '#FCBE64', '#8BE0F0', '#9B81FB', '#FD765D', '#4AB664', '#F38AC3', '#AC71FF', '#F4E56E'],
        series: []
      }
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    console.log(this.LineMap);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.flush()
  },
  methods: {
    refreshMap () {
      const myChart = echarts.init(document.getElementById('map'))
      myChart.setOption(this.option,true);
    },
    flush () {
      if (this.mapData.series) {
        console.log(this.mapData)
        this.option.series = this.mapData.series
        this.option.xAxis.data = this.mapData.xAxis
        this.option.legend.data = this.mapData.legend
        this.refreshMap()
      }

    }
  },
  watch: {
    mapData () {
      this.flush()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>