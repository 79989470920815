<template >
  <div class="official-account-label">
    <el-container>
      <el-header class="groupheader">公众号标签</el-header>
      <div class="replytop">
        <el-input
          placeholder="搜索标签名称或ID"
          prefix-icon="el-icon-search"
          v-model="tag"
          @focus="getPageList"
          :clearable="true"
          @clear="getPageList"
          @change="getPageList"
        >
        </el-input>
        <el-popover
          placement="bottom"
          title="标签名称"
          width="200"
          v-model="visible">
          <el-input v-model="tagname"></el-input>
          <div style="text-align: right; margin: 10px 0 0 0; ">
            <el-button size="mini" type="text" @click="cancel">取消</el-button>
            <el-button type="primary" size="mini" @click="submit">确定</el-button>
          </div>
          <el-button
            class="topBtn"
            type="primary"
            slot="reference"
              >
              <i class="el-icon-plus"></i>
              创建标签
          </el-button>
        </el-popover>

      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#F9F9F9' }"
      >
        <el-table-column prop="id" label="ID" width="180">
        </el-table-column>
        <el-table-column prop="name" label="标签名称" width="230">
        </el-table-column>
         <el-table-column prop="count" label="标记粉丝数">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="medium"
              @click="deleteItem(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-size="10"
        layout="prev, pager, next"
        background
        :total="total"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </el-container>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="320px"
      :before-close="handleClose">
      <p style="padding: 0 0 10px 0">标签名称</p>
      <el-input v-model="tagname" class='tagInput'></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="editpost">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTagsList, postTagsEdit, postTagsCreate, postTagsDel } from '@/service/fan'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tag: '',
      visible: false,
      tagname: '',
      dialogVisible: false,
      id: ''
    }
  },
  computed: {

  },
  created() {
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
     getTagsList({ pageNo: this.pageNo, pageSize: this.pageSize, tag: this.tag }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    addGroupWel() {
      this.$router.push('/Wechat/ParameterQRCode/addChannelQRcode')
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem(id) {
      postTagsDel({ tag: { id: id } }).then(res => {
          Message.success('删除成功')
          this.getPageList()
      })
    },
    editpost() {
      postTagsEdit({ tag: { id: this.id, name: this.tagname } }).then(res => {
        this.id = ''
        this.dialogVisible = false
        Message.success('编辑成功')
        this.getPageList()
      })
    },
    edit(row) {
      this.id = row.id
      this.tagname = row.name
      this.dialogVisible = true
    },
    submit() {
      postTagsCreate({ tag: { name: this.tagname } }).then(res => {
        this.cancel()
        Message.success('创建成功')
        this.getPageList()
      })
    },
    cancel() {
      this.visible = false
      this.tagname = ''
    },
    handleClose() {
      this.dialogVisible = false
      this.tagname = ''
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
