<!-- 登陆页面 -->
<template>
  <div class="login-wrapper">
    <div class="login-left">
      <span>集成全渠道客户数据的营销自动化平台</span>
      <span>马上体验功能强大，简单易上手的CDP引擎吧!</span>
      <span>
        CDP引擎集成了微信、公众号、小程序、app等全渠道用户数据，在引<br />流拉新、用户画像、数据洞察、人群分析、营销自动化等方面助力客户<br />实现营销效率提升
      </span>
      <img src="@/assets/back.png" alt="" />
      <div>
        如有任何问题，您可以联系在线客服，也可以通过服务热线
        <section>13163372660</section>
        联系我们
      </div>
    </div>
    <div class="login-right">
      <img src="@/assets/logo.png" alt="" />
      <el-form :label-position="labelPosition" :model="formData" :rules="formDataRules" ref="formDataRef" label-width="100px" class="demo-ruleForm">
        <div class="tabs-box">
          <div :class="activeTab==='1'?'active':''" @click="changeTab('1')" class="tab-item">账号登陆</div>
          <div :class="activeTab==='2'?'active':''" @click="changeTab('2')" class="tab-item">手机登陆</div>
        </div>
        <el-form-item v-if="activeTab==='1'" prop="user">
          <el-input placeholder="请输入用户名" v-model="formData.user">
          </el-input>
        </el-form-item>
        <el-form-item v-if="activeTab==='1'" prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="formData.password">
          </el-input>
        </el-form-item>
        <el-form-item class="phone_box" v-if="activeTab==='2'" prop="phone">
          <el-input type="text" placeholder="中国大陆（86）| 手机号" v-model="formData.phone">
          </el-input>
        </el-form-item>
        <el-form-item class="code_box" v-if="activeTab==='2'" prop="code">
          <el-input class="code" type="text" placeholder="验证码" v-model="formData.code">
          </el-input>
          <div class="get_code" @click="getCode">获取验证码</div>
        </el-form-item>
      </el-form>
      <span @click="loginBtn" class="login-btn">登陆</span>
    </div>
    <!-- <div class="login-contaier">
      <div class="title">登录</div>

    </div> -->
  </div>
</template>

<script>
import {
  getUserRoleList,
  postUserLogin,
  getCode
} from '@/service/login'

export default {
  data () {
    return {
      activeTab: '1',
      formData: {
        user: '',
        password: '',
        phone: '',
        code: ''
      },
      formDataRules: {
        user: [
          { required: true, message: '请输入用户名' }
        ],
        password: [
          { required: true, message: '请输入密码' }
        ],
        phone: [
          { required: true, message: '请输入手机号' }
        ],
        code: [
          { required: true, message: '请输入验证码' }
        ],
      },
      labelPosition: 'top'
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    loginBtn () {
      this.$refs.formDataRef.validate(async (valid) => {
        if (valid) {
          // const res = await this.$http.post(`http://8.134.11.223:8080/userprofile-platform/api/user/login?user=${this.formData.user}&password=${this.formData.password}`)
          let p = {}
          if (this.activeTab === '1') {
            p = { user: this.formData.user, password: this.formData.password }
          } else {
            p = { user: this.formData.phone, password: this.formData.code }
          }
          const res = await postUserLogin({ ...p })
          console.log('🚀 ~ file: index.vue ~ line 73 ~ this.$refs.formDataRef.validate ~  res', res)
          const { id, token, name } = res
          const params = JSON.parse(JSON.stringify(res))
          params.name = encodeURIComponent(params.name)
          delete params.token
          const cookie = token + ' ' + 'user=' + JSON.stringify(params)
          if (res.token !== null) {
            window.sessionStorage.setItem('name', name)
            window.sessionStorage.setItem('cookie', cookie)
            const roleData = await getUserRoleList({
              pageNum: 1,
              pageSize: 10,
              userId: id
            }
            )
            // const roleData = await this.$http.get('http://8.134.11.223:8080/userprofile-platform/api/user/role/list', {
            //   params: {
            //     pageNum: 1,
            //     pageSize: 10,
            //     userId: id
            //   }
            // })
            console.log(roleData)
            window.sessionStorage.setItem('roleId', roleData.list[0].roleId)
            this.$router.push({ name: 'base-layout', params: { name: name } })
          }
        }
      })
    },
    changeTab (val) {
      this.$refs['formDataRef'].clearValidate();
      this.activeTab = val
    },
    getCode () {
      const that = this
      this.$refs.formDataRef.validateField('phone', async (valid) => {
        if (!valid) {
          const res = await getCode({ phone: this.formData.phone })
          if (res === null) {
            that.$message.error(res.message)
          } else {
            this.$message.success('已发送验证码')
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.login {
  &-btn {
    width: 15vw;
    height: 3vw;
    background-color: #0086f9;
    // border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Regular;
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    cursor: pointer;
  }
  &-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .el-input__inner {
      width: 23vw;
      // height: 4vw;
      height: 36px;
      // background-color: #f8f3fb;
      // border-radius: 1vw;
    }
    img {
      width: 17vw;
      margin-bottom: 5vw;
    }
  }
  &-wrapper {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 7vw 11vw;
    background: rgb(246, 252, 255);
    display: flex;
    justify-content: space-between;
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vw;
      color: #000000;
      section {
        font-family: PingFangSC-Regular;
        font-size: 1vw;
        font-weight: normal;
        letter-spacing: 0vw;
        color: #0086fa;
      }
    }
    img {
      width: 35vw;
      margin: 2vw 0 0.5vw;
    }
    span:first-child {
      font-family: PingFangSC-Semibold;
      font-size: 3vw;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vw;
      color: #000000;
    }

    span:nth-child(2) {
      font-family: PingFangSC-Regular;
      font-size: 2vw;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vw;
      color: #000000;
      margin: 2vw 0 3vw;
    }

    span:nth-child(3) {
      font-family: PingFangSC-Light;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.5vw;
      letter-spacing: 0vw;
      color: #000000;
    }
  }
}
.login-wrapper {
  .tabs-box {
    width: 100%;
    display: flex;
    margin-bottom: 22px;
    .tab-item {
      width: 50%;
      text-align: center;
      padding-bottom: 8px;
      border-bottom: 2px solid #bfbfbf;
      cursor: pointer;
      &.active {
        color: #0086f9;
        border-bottom: 2px solid #0086f9;
      }
    }
  }
  .phone_box {
    width: 23vw;
    .el-input__inner {
      width: 100% !important;
    }
  }
  .code_box {
    .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .code {
      flex: 1;
      margin-right: 5px;
      .el-input__inner {
        width: 100%;
      }
    }
    .get_code {
      width: 35%;
      background-color: #e5faf3;
      border: 1px solid #0cafb8;
      color: #0cafb8;
      cursor: pointer;
      border-radius: 4px;
      text-align: center;
    }
  }
  .login-btn {
    border-radius: 4px;
    font-size: 16px;
    width: 160px;
    height: 38px;
  }
}
</style>
