<template>
  <div class="poster" ref="imageWrapper" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)" @click="handleClose">
    <div class="title">
      长按保存海报,发送好友或朋友圈
    </div>
    <div class="sub_title">
      好友通过海报关注公众号即可助力
    </div>
    <div class="poster_contnet" @click.stop>
      <img :src="poster" v-if="poster" alt="" width="100%" srcset="">
      <div class="box" ref="poster" v-else id="poster">
        <img :src="posterPic" width="100%" height="" class="bg" crossorigin="anonymous">
        <img :src="qrcodeUrl" width="100%" class="code" :style="{top:`${code.top}px`,left:`${code.left}px`,width:`${code.width}px`,height:`${code.height}px`}" crossorigin="anonymous">
        <div class="avatar" :style="{top:`${avatar.top}px`,left:`${avatar.left}px`,width:`${avatar.width}px`,height:`${avatar.height}px`}">
          <img :src="queryParams.headimgurl" alt="" srcset="" width="100%" style="border-radius: 50%;" crossorigin="anonymous">
          <div :style="`white-space: nowrap;transform: translateX(-50%);left: 20px; position: absolute; font-size: 10px;`">
            {{queryParams.nickname}}
          </div>
        </div>
      </div>
    </div>
    {{newImg}}
    <div class="close" @click="handleClose"><img src="@/assets/close.png" alt="" srcset=""></div>
    <div style="height:200px;"></div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import {
  getFissionActivityUserCreatePoster
} from '@/service/activity';
export default {
  data () {
    return {
      poster: '',
      queryParams: {
        openid: '',
        nickname: '',
        openidPic: '',
        headimgurl: ''
      },
      code: {
        top: 20,
        left: 30,
        height: 60,
        width: 60
      },
      avatar: {
        left: 100,
        top: 200,
        height: 40,
        width: 40
      },
      loading: false,
      newImg: '',
      posterPic: '',
      qrcodeUrl: ''
    }
  },
  methods: {
    init (data) {
      this.queryParams.openid = data.openid
      this.queryParams.nickname = data.nickname
      this.queryParams.openidPic = data.openidPic
      this.queryParams.headimgurl = data.headimgurl
      getFissionActivityUserCreatePoster(this.queryParams).then(res => {
        this.posterPic = res.posterPic
        this.qrcodeUrl = res.qrcodeUrl
        this.code = res.positionInfo.code
        this.avatar = res.positionInfo.avatar
        this.$nextTick(() => {
          this.toImage()
        })
      })
    },
    toImage () {
      this.loading = true
      this.$nextTick(() => {
        const content = document.getElementById("poster");

        html2canvas(content, {
          useCORS: true,
          // 截图区域的宽 可以用DOM宽高替换，具体详见：（二）处
          width: 750 / 3,
          // 截图区域的高
          height: 1280 / 3,
          // 以下4项必须设置
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x: 0,
          y: window.pageYOffset
        }).then(canvas => {
          const dataURL = canvas.toDataURL("image/png", 1).replace("image/png", "image/octet-stream");
          this.poster = dataURL;
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.poster {
  color: #fff;
  position: fixed;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: scroll;
  .header {
  }
  .title {
    font-size: 20px;
    padding: 5px 0;
  }
  .sub_title {
    padding: 0 0 15px 0;
  }
  .poster_contnet {
    // height: calc(100vh - 140px);
  }
  .close {
    height: 40px;
    padding: 10px;
  }
}
.box {
  width: 750px / 3;
  height: 1280px / 3;
  background-color: #000;
  margin: 0 auto;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
  }
  .code {
    position: absolute;
    cursor: pointer;
  }
  .avatar {
    position: absolute;
    cursor: pointer;
  }
}
</style>
