<template>
  <div>
    <el-upload v-if="!imageUrl"
      action="www.baidu.com"
      list-type="picture-card"
      :http-request='updateImg'
      :show-file-list='false'>
      <i class="el-icon-plus"></i>
    </el-upload>
    <div class="img-wrapper"
      v-if="imageUrl">
      <img :src="imageUrl"
        class="upload-picture"
        alt="">
      <div class="mask">
        <i class="el-icon-zoom-in"
          @click="handlePictureCardPreview"></i>
        <i class="el-icon-delete"
          @click="removeImg"></i>
      </div>
    </div>
    <el-dialog custom-class="el-dialog-s"
      append-to-body
      :visible.sync="dialogVisible">
      <img width="100%"
        :src="imageUrl"
        alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {

  },
  data () {
    return {
      dialogVisible: false,
      imageUrl: ''
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    handlePictureCardPreview () {
      this.dialogVisible = true
    },
    async updateImg (val) {
      this.imageUrl = await this.getBase64(val.file)
      this.$emit('handleImg', val.file)
    },
    removeImg () {
      this.imageUrl = ''
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }
  },
  components: {

  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
