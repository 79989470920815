<!-- 用户管理 -->
<template>
  <div class="rolesSystem">
    <el-row class="top">
      <el-col :span="4" class="newBtn">
        <el-button type="primary" icon="el-icon-plus" @click="addRoles"
          >新增</el-button
        >
      </el-col>
      <el-col :span="18" class="search"> </el-col>
    </el-row>
    <el-card>
      <el-row>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="Id" width="100"> </el-table-column>
          <el-table-column prop="memo" label="角色" width="100">
          </el-table-column>
          <el-table-column prop="name" label="用户名" width="180">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="100">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status === 0"
                type="success"
                size="small"
                >正常</el-button
              >
              <el-button
                v-if="scope.row.status === 1"
                type="warning"
                size="small"
                >失效</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="180">
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间" width="180">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="editRoles(scope.row)"
                  >编辑</el-button
                >

                <el-button
                  type="warning"
                  size="mini"
                  plain
                  @click="allotMenu(scope.row)"
                  >分配菜单</el-button
                >

                <el-button
                  type="warning"
                  size="mini"
                  plain
                  @click="getAuth(scope.row)"
                  >分配权限</el-button
                >

                <el-popconfirm
                  title="确定删除吗？"
                  @confirm="deleteUser(scope.row)"
                  class="confBtn"
                >
                  <el-button type="danger" size="mini" plain slot="reference"
                    >删除</el-button
                  >
                </el-popconfirm>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryUserList.pageNum"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="queryUserList.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <AddRoles
      :dialogShow="dialogVisible"
      @closeDialog="closeDialog"
      :rolesData="rolesData"
      :updateTable="_geInitDataList"
    />
    <AllotRoles
      :drawer="drawer"
      :treeProps="treeProps"
      @closeDrawer="closeDrawer"
      :currentTree="currentTree"
      :info="info"
    />
  </div>
</template>

<script>
import AddRoles from './component/AddRoles'
import AllotRoles from './component/AllotRoles'

import {
  getRoleList,
  postRoleDelete,
  getMenusList,
  getPermissionList,
  getRoleMenusList,
  getRolePermissionList
} from '@/service/roles'
export default {
  data() {
    return {
      tableData: [],
      queryUserList: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dialogVisible: false,
      rolesData: {},
      drawer: false,
      info: {},
      treeProps: [],
      treeCurrentData: {},
      currentTree: []
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this._geInitDataList()
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  methods: {
    handleSizeChange(newSize) {
      this.queryUserList.pageSize = newSize
      this._geInitDataList()
    },
    handleCurrentChange(NewPageNum) {
      this.queryUserList.pageNum = NewPageNum
      this._geInitDataList()
    },
    _geInitDataList() {
      getRoleList({ ...this.queryUserList }).then(data => {
        this.tableData = data.list
        this.total = data.total
      })
    },
    addRoles() {
      this.dialogVisible = true
      this.rolesData = {}
    },
    closeDialog(bool) {
      this.dialogVisible = bool
      this.rolesData = {}
    },
    // 编辑
    editRoles(row) {
      this.rolesData = row
      this.dialogVisible = true
    },
    deleteUser(row) {
      const { id } = row
      postRoleDelete({ id: id }).then(res => {
          this.$message.success('删除成功')
          this._geInitDataList()
      })
    },
    async allotMenu(row) {
      const { id } = row
      this.info = {
        title: '菜单列表',
        ...row
      }
      await this._getMenuListRole()
      await this._getCurrentTreeMenuData(id)
      this.drawer = true
    },
    closeDrawer(bool) {
      this.drawer = false
      this.currentTree = []
    },
    // 获取菜单列表
    async _getMenuListRole() {
      const data = await getMenusList()
      this.treeProps = data
    },
    async _getCurrentTreeMenuData(id) {
      const data = await getRoleMenusList({ roleId: id })
      this.currentTree = []
      this.filterTree(this.treeProps, data)
      console.log(this.currentTree)
    },
    // 过滤树节点
    filterTree(treeData, filterData) {
      treeData.map((item) => {
        if (item.children.length > 0) {
          return this.filterTree(item.children, filterData)
        }
        return filterData.map(value => {
          if (value.menusEntity.id === item.id) {
            this.currentTree.push(item.id)
          }
        })
      })
    },
    filterAtuhTree(treeData, filterData) {
      treeData.map((item) => {
        if (item.children.length > 0) {
          return this.filterAtuhTree(item.children, filterData)
        }
        return filterData.map(value => {
          if (value.permissionId === item.id) {
            this.currentTree.push(item.id)
          }
        })
      })
    },
    // 分配菜单
    async getAuth(row) {
      const { id } = row
      this.info = {
        title: '分配角色权限',
        ...row
      }
      await this._getAuthListData()
      await this._getCurrentAuthTree(id)
      this.drawer = true
    },
    // 获取权限列表
    async _getAuthListData() {
      const data = await getPermissionList()
      this.treeProps = data
    },
    async _getCurrentAuthTree(id) {
      const data = await getRolePermissionList({ roleId: id })
      this.currentTree = []
      this.filterAtuhTree(this.treeProps, data)
    }
  },
  components: {
    AddRoles,
    AllotRoles
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.top {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.search .el-input {
  width: 250px;
  float: right;
}
.el-table {
  padding-left: 20px;
}
.newBtn .el-button {
  float: left;
}
.pagination {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px 0px 10px;
}
.el-drawer__wrapper h2 {
  text-align: left !important;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
.el-drawer__wrapper h1 {
  text-align: left !important;
  padding-left: 20px;
  font-size: 25px;
}
.drawerConfirm {
  margin-top: 60px;
  padding-left: 20px;
}
.drawerConfirm el-button {
  width: 100px;
}
.el-col {
  text-align: left !important;
}
.confBtn {
  margin-left: 10px;
}
</style>
