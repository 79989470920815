<template>
  <div class="h5-success">
    <div class="warp">
      <div class="header">{{form.titleName}}</div>
      <div class="header-date">
        <span class="el-icon-date icon"></span>
        {{form.activeTime | formarDate}}
      </div>
      <div class="line"></div>
      <div class="pic">
        <img :src="form.wechatPic" alt="" srcset="" width="100%">
      </div>
      <div class="line"></div>

      <div class="footer">{{form.comment}}</div>
    </div>
  </div>
</template>

<script >
export default {
  name: '',
  filters: {
    formarDate (data) {
      const date = new Date(data)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const d = date.getDay() + 1
      const dlist = { 0: '七', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六' }
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' 星期' + dlist[d]
    }
  },
  data () {
    return {
      form: {}
    }
  },
  beforeRouteEnter (form, to, next) {
    document.title = form.meta.title
    next()
  },
  created () {
    this.form = this.$route.query
  },
  components: {},
  methods: {}
}
</script>

<style lang="less" scoped>
.h5-success {
  height: 100vh;
  width: 100vw;
  // height: 100%;
  // width: 100%;
  background-color: #568aed;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  .warp {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 28px;
      background: #568aed;
      left: -1px;
      bottom: 56px;
      border-radius: 0 28px 28px 0;
      z-index: 1;
      /* 左上、右上、右下、左下 */
    }

    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 28px;
      background: #568aed;
      right: -1px;
      bottom: 56px;
      border-radius: 28px 0 0 28px;
      z-index: 1;
      /* 左上、右上、右下、左下 */
    }
    .header {
      font-size: 24px;
      text-align: left;
      margin-bottom: 10px;
      padding: 20px;
    }
    .header-date {
      display: inline-block;
      background-color: #ecf0f1;
      color: #a9aaac;
      padding: 4px 15px;
      border-radius: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
      .icon {
        margin-right: 5px;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
    .pic {
      border: 1px solid #568aed;
      padding: 10px;
      width: 50%;
      margin: 30px auto;
      border-radius: 10px;
    }
    .footer {
      height: 34px;
      padding: 20px;
      font-size: 18px;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eef9ff;
      position: relative;
      border-radius: 10px;
    }
  }
}
</style>
