<template>
  <div class="preview-form">
    <el-dialog title="预览" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="80px" label-position="top">
        <div v-for="(item,index) in formList" :key="index">
          <el-form-item :label="item.type== 'textarea' ? '' :item.itemName" :rules="['upload','textarea'].includes(item.type)?[]:[{required:item.isMust}] ">
            <el-input style="width: 100%;" v-if="item.type== 'input'" :placeholder="item.placeholder" v-model="item.data[0]" upload-demo></el-input>
            <el-input-number style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'number'" upload-demo v-model="item.data[0]"></el-input-number>
            <el-select style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'select'" upload-demo v-model="item.data[0]">
              <el-option v-for="(i,index) in item.checkbox" :key="index" :value="i"></el-option>
            </el-select>
            <el-checkbox-group v-model="item.data" upload-demo v-if="item.type== 'checkbox'" style="display: flex;flex-direction: column;">
              <el-checkbox v-for="(i,index) in item.checkbox" :key="index" :label="i"></el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-model="item.data[0]" upload-demo v-if="item.type== 'radio'" style="display: flex;flex-direction: column;">
              <el-radio v-for="(i,index) in item.checkbox" :key="index" :label="i"></el-radio>
            </el-radio-group>
            <el-rate v-model="item.data[0]" upload-demo v-if="item.type== 'rate'"></el-rate>
            <el-date-picker style="width: 100%;" :placeholder="item.placeholder" v-model="item.data[0]" upload-demo v-if="item.type== 'date'" type="datetime">
            </el-date-picker>
            <div class="text" v-if="item.type== 'textarea'" :style="{'text-align':item.position,'word-break': 'break-all'}">{{item.data[0]}}</div>
            <div class="" v-if="item.type== 'upload'">
              <img :src="item.url" alt="" width="80px">
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script >
export default {
  name: "",
  data () {
    return {
      dialogVisible: false,
      formList: [],
      form: {}
    }
  },
  components: {},
  methods: {
    init (data) {
      this.dialogVisible = data.show
      this.formList = []
      data.formList.forEach(e => {
        this.formList.push(JSON.parse(JSON.stringify(e)))
      });
    },
    handleClose () {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.form = {}
          this.formList = []
          this.dialogVisible = false
        })
        .catch(_ => { });
    }
  }
}
</script>

<style lang="less">
.preview-form .el-dialog__body {
  max-height: 60vh;
  overflow: auto;
}
</style>
