<template>
  <div class="desktopGetUser-allInfos">
    <el-card class="header-info">
      <div class="left">
        <img class="head-img" :src="infoList.picUrl" />
        <img
          v-if="infoList.gender === '女'"
          class="sex-icon"
          src="../../assets/girl.png"
          alt=""
        />
        <img
          v-if="infoList.gender === '男'"
          class="sex-icon"
          src="../../assets/boy.png"
          alt=""
        />
      </div>
      <div class="right">
        <p class="name">{{ infoList.name || "-" }}</p>
        <p>客户ID：{{ infoList.userId || "-" }}</p>
      </div>
    </el-card>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="客户详情" name="detail">
        <el-card class="user-info">
          <p class="item-box">
            <span class="label">性别</span>
            <span class="val">{{ infoList.gender || "-" }}</span>
          </p>
          <p class="item-box">
            <span class="label">客户状态</span>
            <span class="val">{{
              infoList.isDel === "1" ? "删除" : "正常"
            }}</span>
          </p>
          <p class="item-box">
            <span class="label">添加时间</span>
            <span class="val">{{ infoList.addTime || "-" }}</span>
          </p>
          <p class="item-box">
            <span class="label">添加渠道</span>
            <span class="val">{{ infoList.addWay || "-" }}</span>
          </p>
          <div class="item-box">
            <span class="label">公司</span>
            <div class="val">
              <el-input
                size="mini"
                v-model="infoList.corpname"
                @blur="postCustomerRemark()"
              ></el-input>
            </div>
          </div>
          <div class="item-box">
            <span class="label">电话</span>
            <div class="val">
              <el-input
                size="mini"
                v-model="infoList.mobiles"
                @blur="postCustomerRemark()"
              ></el-input>
            </div>
          </div>
          <div class="item-box flex">
            <span class="label">连接账号</span>
            <div class="val">
              <p>
                公众号id：{{
                  (infoList.linkAccount && infoList.linkAccount.accountId) ||
                  "-"
                }}
              </p>
              <p>
                小程序id：{{
                  (infoList.linkAccount && infoList.linkAccount.miniproId) ||
                  "-"
                }}
              </p>
              <p>
                企微id：{{
                  (infoList.linkAccount && infoList.linkAccount.wechatId) || "-"
                }}
              </p>
            </div>
          </div>
        </el-card>
        <el-card class="group-info">
          <div class="title-box">所在群</div>
          <el-tag
            class="tag"
            v-for="(item, index) in infoList.groupInfo"
            :key="index"
            >{{ item }}</el-tag
          >
          <el-divider></el-divider>
          <div
            class="title-box"
            style="display: flex; justify-content: space-between"
          >
            企业标签<i class="el-icon-edit" @click="tagsDrawer = true"></i>
          </div>
          <el-tag
            class="tag"
            v-for="(item, index) in infoList.tag"
            :key="index + '-'"
            >{{ item }}</el-tag
          >
        </el-card>
        <el-card class="remark-info">
          <div class="title-box">备注</div>
          <el-input
            v-model="infoList.comment"
            type="textarea"
            :rows="8"
            ref="saveDescriptionInput"
            @keyup.enter.native="postCustomerRemark()"
            @blur="postCustomerRemark()"
          >
          </el-input>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="客户动态" name="dynamic">
        <dynamic ref="dynamic" :externalUserid="queryParams.externalUserid" />
      </el-tab-pane>
    </el-tabs>
    <el-drawer :visible.sync="tagsDrawer" direction="btt" :show-close="false">
      <template #title>
        <div class="" style="display: flex; justify-content: space-between">
          <el-button size="small" @click="tagsDrawer = false">取消</el-button>
          <el-button type="primary" size="small" @click="handleUpdate"
            >确认</el-button
          >
        </div>
      </template>
      <tags ref="tags" v-if="tagsDrawer" :list="infoList.tag" />
    </el-drawer>
  </div>
</template>

<script>
import {
  getEnterpriseWechatConfig,
  getTicket,
  getUserAllInfo
} from '@/service/enterprise';
import {
  postCustomerRemark,
  updateCustomerLabel
} from '@/service/customerManage';
import sha1 from 'sha1';
import { getRandomString } from '@/utils';
import Dynamic from './Dynamic.vue';
import tags from './tags.vue';

const wx = window.wx;
export default {
  components: { Dynamic, tags },
  data () {
    return {
      tagsDrawer: false,
      infoList: {},
      active: 'detail',
      queryParams: {
        externalUserId: ''
      },
      config: {},
      ticket: {}
    };
  },
  async created () {
    try {
      const res = await getEnterpriseWechatConfig();
      this.config = res;
      const params = await this.getConfig('company');
      this.getInfo(params);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    getInfo (data) {
      console.log(data);
      wx.config({
        bate: true,
        debug: false,
        appId: data.corpId,
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['agentConfig'] // 必填，传入需要使用的接口名称
      });
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ['agentConfig', 'getCurExternalContact'],
          success: () => {
            this.agent();
          },
          fail: () => {
            this.$message.error('api验证失败');
          }
        });
      });
      wx.error((err) => {
        this.$message.error('config信息验证失败', err);
      });
    },
    async agent () {
      const data = await this.getConfig('application');
      wx.agentConfig({
        corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: data.agentid, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact'], // 必填，传入需要使用的接口名称
        success: (res) => {
          wx.invoke('getCurExternalContact', {}, async (CurRes) => {
            if (CurRes.err_msg === 'getCurExternalContact:ok') {
              const externalUserid = CurRes.userId;
              this.queryParams.externalUserId = externalUserid;
              this.$refs.dynamic.getList(externalUserid);
              this.getDetails();
            } else {
              this.$message.error('获取外部联系人失败', JSON.stringify(CurRes));
            }
          });
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            this.$message.error('版本过低请升级');
          }
        }
      });
    },
    async getConfig (type) {
      const ticket = await getTicket();
      this.ticket = ticket;
      const timestamp = new Date().getTime();
      const nonceStr = getRandomString(18);
      const string1 = `jsapi_ticket=${this.ticket[type]
        }&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href.split('#')[0]
        }`;
      const signature = sha1(string1);
      return {
        corpId: this.config.corpId,
        ticket: this.ticket[type],
        timestamp,
        signature,
        nonceStr,
        agentid: this.config.selfappAgentid
      };
    },
    async getDetails () {
      const userAllInfo = await getUserAllInfo(this.queryParams);
      this.infoList = userAllInfo;
    },
    handleClick () { },
    postCustomerRemark () {
      const data = {
        userid: this.infoList.principalUserId || '',
        external_userid: this.queryParams.externalUserId || '',
        description: this.infoList.comment || '',
        remark_company: this.infoList.corpname || '',
        remark_mobiles: [this.infoList.mobiles] || ''
      };
      postCustomerRemark(data).then((res) => {
        this.$message.success('编辑成功！');
      });
    },
    handleUpdate () {
      // flag true为已选择  false未选择
      updateCustomerLabel({
        userId: this.infoList.principalUserId || '',
        externalUserid: this.queryParams.externalUserId || '',
        // nowStage: this.infoList.tag.join(',') || '',
        // changeStage: this.$refs.tags.checked.join(',') || ''
        changeStage: this.infoList.tag.join(',') || '',
        nowStage: this.$refs.tags.checked.join(',') || ''
      }).then((res) => {
        this.getDetails();
        this.tagsDrawer = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.desktopGetUser-allInfos {
  min-height: 100vh;
  padding: 20px;
  background-color: #f0f0f0;

  .title-box {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .has-border {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 5px 15px;
    line-height: 1.5;
  }
  .header-info {
    ::v-deep .el-card__body {
      display: flex;

      .left {
        position: relative;

        .head-img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
        }

        .sex-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 20px;
          padding: 2px;
          background-color: rgb(255, 255, 255);
          border-radius: 40%;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }
  .user-info {
    .item-box {
      // border: 1px solid red;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .label {
        color: #b4b7bf;
        width: 35%;
      }
      .val {
        font-size: 14px;
      }
    }
  }

  .flex {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start !important;
    .val {
      p {
        margin-bottom: 2px;
      }
    }
  }

  .group-info {
    .tag {
      margin-right: 10px;
    }
  }

  .remark {
    margin-top: 10px;
  }
}

::v-deep .el-drawer__open .el-drawer.btt {
  height: 60% !important;
}
::v-deep .el-drawer__header {
  padding: 10px 20px;
  margin: 0;
}
</style>
