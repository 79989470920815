<template>
  <div class="mini-customer-page-list-wrapper">
    <el-card class="block-wrapper user-list">
      <div slot="header" class="title displayFlex">
        <span>客户列表</span>
      </div>
      <slot></slot>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="客户昵称" width="210">
            <template slot-scope="scope">
              <div class="uesr-infos">
                <img :src="scope.row.avatarUrl" alt="" />
                <div class="text">
                  <div class="titles">
                    <p>{{ scope.row.nickName }}</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="openId" label="opendId" width="270">
          </el-table-column>
          <el-table-column prop="gender" label="性别"> </el-table-column>
          <el-table-column prop="dataTime" label="访问时间"> </el-table-column>
          <el-table-column prop="betweenDays" label="添加距今天数">
          </el-table-column>
          <el-table-column prop="externalUserid" label="是否微信好友">
            <template slot-scope="scope">
              <span v-if="scope.row.externalUserid">
                <el-button type="text" @click="copyIsWechat(scope.row.unionId)"
                  >是</el-button
                ></span
              >
              <span v-else>否</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getUserinfoList } from '@/service/minipro'
export default {
  props: {

  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    }
  },
  computed: {

  },
  created() {
    this.runList()
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    runList(params) {
      getUserinfoList({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    },
    // 复制
    copyIsWechat(wechat) {
      var oInput = document.createElement('input')
      oInput.value = wechat
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      // Message.success('已复制   ' + wechat)
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('p', null, '已复制'),
          h('p', null, wechat)
        ]),
        type: 'success'
      });
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
