<template>
  <!-- 短信模板 -->
  <div class="wechatFriends">
    <div class="nodeTitle">短信模板配置</div>
    <el-form>
      <el-form-item required label="模板名称">
        <el-select v-model="data.templateId" filterable placeholder="请选择" @change="handleChange">
          <el-option
            v-for="item in smsTemplateList"
            :key="item.templateId"
            :label="item.templateName"
            :value="item.templateId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="title" v-if="content">
      短信内容
    </div>
    <div class="msg_content" v-if="content">
      <p class="content" v-html="content"></p>
      <div class="msg_input">
        <div class="msg_item" v-for="(item,index) in msgList" :key="index">
          <div v-html="item.title"></div>
          <div>=</div>
          <div><el-input v-model="data.templateParam[index]"></el-input></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {  getSmsTemplateList  } from '@/service/sms'

import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      data:{
        templateId: "",
        templateParam:[]
      },
      msgList:[],
      curSms: {},
      content: "",
      smsTemplateList: [],
    };
  },
  mounted() {},
  created() {
    this.getTemplate();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getTemplate () {
      getSmsTemplateList({ pageno: 1, pagesize: 100 }).then(e => {
        this.smsTemplateList = e.list
      })
    },
    save() {
      console.log("save -> this.node", this.data);
      if (this.data.templateId === "" ) {
        Message.warning("请选择模板!");
        return false;
      }
      return this.data;
    },
    init(val) {
      console.log("init -> val", val);
      this.content = ''
      this.data = val || {
        templateId: "",
        data:{}
      }
      this.$nextTick(()=>{
        this.handleChange(this.data.templateId,'init')
      })
    },
    handleChange(data,type='change'){
      if(!data) return 
      console.log("change -> data", data);
      this.curSms = this.smsTemplateList.find(e => e.templateId == data)
      let content = this.curSms.templateContent
      this.msgList = []
      if(type == 'change') this.data.templateParam = []
      for (let index = 1; index ; index++) {
        let i = `{${index}}`
        if(content.includes(i)){
         content = content.replace(i,`<span style="color:#459ffc;">${i}</span>`)
      if(type == 'change') this.data.templateParam.push('')
         this.msgList.push({
          title:`<span style="color:#459ffc;">${i}</span>`
         })
        }else{
          break
        }
      }
      this.content = content
    }
  }
};
</script>

<style lang="less" scoped>
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}

.msg_content{
  border: 1px solid #efefef;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  .content{
    margin-bottom: 20px;
  }
  .msg_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
}

/deep/.el-form-item__content{
  width: 180px;
  display: inline-block;
  margin-left: 20px;
}
</style>
