<template>
  <!-- 微信信息 -->
  <div class="wechatMsg">
    <div class="nodeTitle">微信消息</div>
    <div class="title">
      <span></span>类型
    </div>
    <el-radio v-model="node.sendType" label="消息">消息</el-radio>
    <el-radio v-model="node.sendType" label="朋友圈">朋友圈</el-radio>
    <div class="title">
      <span></span>内容
    </div>
    <el-input type="textarea" :rows="5" v-model="node.msgDetail" placeholder="请输入内容"></el-input>
    <el-upload
      action="#"
      class="upload-box"
      ref="upload"
      list-type="picture-card"
      :auto-upload="true"
      :limit="1"
      :on-exceed="handleExceed"
      accept=".jpg, .jpeg, .png, .gif"
      :file-list="showFiles"
      :http-request="uploadPic"
      :before-upload="beforeAvatarUpload"
    >
      <el-button type="primary" class="uploadBtn">
        <i class="iconfont icon-yunshangchuan"></i>
        上传图片
      </el-button>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <div class="title">
      <span></span>执行员工
    </div>
    <el-select v-model="node.responsibleId" filterable placeholder="请选择">
      <el-option
        style="width:'200px'"
        v-for="item in userOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
/* eslint-disable */
import {
  getSOPGroupList,
  uploadFile,
  uploadAttachmentFile
} from "@/service/group";
import { Message } from "element-ui";
export default {
  props: ["editnode"],
  data() {
    return {
      node: {
        sendType: "消息",
        msgDetail: "",
        mediaId: "",
        responsibleId: "",
        msgType: "文字"
      },
      showFiles: [],
      disable: false,
      userOptions: [],
      upload: {
        file: null,
        type: "image",
        introduction: "素材介绍"
      }
    };
  },
  mounted() {},
  created() {
    this.getSOPGroupList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getSOPGroupList() {
      getSOPGroupList().then(res => {
        res &&
          res.map(item => {
            this.userOptions.push({ label: item.userName, value: item.userId });
          });
      });
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles();
      this.node.msgType = "文字";
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async uploadPic(val) {
      this.upload.file = val.file;
      this.disable = true;
      console.log(
        "🚀 ~ file: index.vue ~ line 358 ~ uploadPic ~ this.sendType",
        this.sendType
      );
      if (this.node.sendType === "消息") {
        uploadFile({ ...this.upload }).then(res => {
          Message.success("图片上传成功");
          this.node.mediaId = res;
          this.node.msgType = "图片";
          this.disable = false;
        });
      } else {
        uploadAttachmentFile({ media: val.file }).then(res => {
          Message.success("图片上传成功");
          this.node.mediaId = res;
          this.node.msgType = "图片";
          this.disable = false;
        });
      }
    },
    save() {
      console.log("save -> this.node", this.node);
      if (this.node.msgDetail === "" || this.node.responsibleId === "") {
        Message.warning("请检查！配置项未填写完成！");
        return false;
      }
      if (this.node.mediaId === "" && this.node.msgType === "图片") {
        Message.warning("请检查！配置项未填写完成！");
        return false;
      }
      return this.node;
    },
    init(val) {
      this.node = val || {
        sendType: "消息",
        msgDetail: "",
        mediaId: "",
        responsibleId: "",
        msgType: "文字"
      };
    }
  }
};
</script>

<style lang="less">
.wechatMsg {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .upload-box {
    display: flex;
    align-items: flex-start;
    margin: 8px;
    flex-direction: column;
    .el-upload-list__item {
      // margin-left: 20px;
    }

    .el-upload {
      background-color: #fff;
      width: auto;
      height: auto;
      line-height: 1;
      display: block;
      text-align: left;
      border: 0 !important;
      border-radius: 0;
      i {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .el-textarea {
    margin: 8px;
    width: 80%;
  }
  .el-select,
  .el-radio {
    margin-bottom: 15px;
  }
}
</style>
