<template>
  <!-- 表单填写 -->
  <div class="wechatFriends">
    <div class="nodeTitle">填写表单</div>
    <el-row>
      <el-col>
        <el-radio-group v-model="node.msgType">
          <el-radio label="实时消息"></el-radio>
          <el-radio label="定时任务"></el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <div class="title">
            <span></span>表单名称
          </div>
      <el-select v-model="node.formName" filterable placeholder="请选择">
        <el-option
          style="width:'200px'"
          v-for="item in formNameOptions"
          :key="item.id"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  queryCustomerForm
} from "@/service/customerForm";
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      node:{
        msgType:'实时消息',
        formName:''
      },
      formNameOptions: [],
    };
  },
  mounted() {},
  created() {
    this.queryCustomerForm();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    queryCustomerForm() {
      queryCustomerForm({pageNo: 1, pageSize: 10,formName:''}).then(res => {
        res && res.list.map(e=>{
          this.formNameOptions.push({label: e.formName,value:e.formName,id:e.formId})
        })
      });
    },
    save() {
      console.log("save -> this.node", this.node);
      if (this.node.formName === "" || this.node.msgType === "") {
        Message.warning("请检查！配置项未填写完成！");
        return false;
      }
      return this.node;
    },
    init(val) {
      console.log("init -> val", val);
      this.node = val || {
        msgType:'实时消息',
        formName:''
      }
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
</style>
