<template>
  <Block title="素材库" class="material-library-main">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane name="text">
        <span slot="label"><i class="iconfont icon-wenjian"></i>话术</span>
        <TextMaterial />
      </el-tab-pane>
      <el-tab-pane name="image">
        <span slot="label"><i class="iconfont icon-tupian"></i> 图片</span>
        <ImageMaterial />
      </el-tab-pane>
      <el-tab-pane name="file">
        <span slot="label"><i class="el-icon-folder"></i> 文件</span>
        <FileMaterial />
      </el-tab-pane>
    </el-tabs>
  </Block>
</template>

<script>
import Block from '@/components/Block'
import TextMaterial from './component/Script'
import ImageMaterial from './component/Image'
import FileMaterial from './component/File'
export default {
  data () {
    return {
      activeName: 'text'
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    handleClick (key) {
      console.log('%c 🥥 key: ', 'font-size:20px;background-color: #FCA650;color:#fff;', key)
    }
  },
  components: {
    Block,
    TextMaterial,
    ImageMaterial,
    FileMaterial
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
