<template >
  <div class="fan-list">
    <el-container>
      <el-header class="groupheader">粉丝列表</el-header>
      <el-tabs v-model="isSubscribe" @tab-click="handleClick">
        <el-tab-pane label="已关注" name="是"></el-tab-pane>
        <el-tab-pane label="黑名单" name="否"></el-tab-pane>
      </el-tabs>
      <div class="replytop">
        <el-input
          placeholder="搜索标签名称或ID"
          prefix-icon="el-icon-search"
          v-model="uid"
          @focus="getPageList"
          :clearable="true"
          @clear="getPageList"
          @change="getPageList"
        ></el-input>
        <el-popover
          placement="bottom"
          width="310"
          v-model="visible"
          class="shaixuanDropdown"
          popper-class="popperFrom"
        >
          <div style="text-align: right; margin: 0">
            <el-form
              label-position="left"
              label-width="110px"
              :model="formLabelAlign"
            >
              <el-form-item label="包含标签">
                <el-select
                  v-model="formLabelAlign.tag"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in tagList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="来源">
                <el-input v-model="formLabelAlign.subscribeScene"></el-input>
              </el-form-item>
              <el-form-item label="最近关注时间段">
                <el-date-picker
                  v-model="formLabelAlign.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="是否企业微信好友">
                <el-select v-model="formLabelAlign.isWechat">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button size="mini" type="text" @click="Cancel">取消</el-button>
            <el-button type="primary" size="mini" @click="Submit"
              >确定</el-button
            >
          </div>
          <el-button plain slot="reference">
            <i class="iconfont icon-shaixuan"></i>条件筛选
          </el-button>
        </el-popover>
        <span @click="getFanList" class="dataRe">
          <i class="el-icon-refresh-right"></i>
          更新数据
        </span>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#F9F9F9' }"
      >
        <el-table-column
          prop="openid"
          label="OpenID"
          width="280"
        ></el-table-column>
        <el-table-column
          prop="subscribeTime"
          label="关注时间"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="followDate"
          label="关注距今天数"
        ></el-table-column>
        <el-table-column prop="" label="标签">
          <template slot-scope="scope">
            <div v-if="scope.row.tagid">
              <el-popover
                placement="bottom"
                width="550"
                trigger="click"
                :ref="'popover-' + scope.row.openid"
              >
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      :label="item.id"
                      v-for="(item, index) in tagList"
                      :key="index"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div class="buttons">
                  <span @click="setTagEdit(scope.row.openid)">确定</span>
                  <span @click="cancel(scope.row.openid)">取消</span>
                </div>
                <div
                  slot="reference"
                  class="tagHas"
                  @click="openTagsChooseEdit(scope.row.tagid)"
                >
                  <span v-for="(item, index) in scope.row.tagid" :key="index">{{
                    item
                  }}</span>
                  <img src="@/assets/arrow.png" alt="" />
                </div>
              </el-popover>
            </div>
            <div v-else>
              <el-popover
                placement="bottom"
                width="550"
                trigger="click"
                :ref="'popover-' + scope.row.openid"
              >
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      :label="item.id"
                      v-for="(item, index) in tagList"
                      :key="index"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div class="buttons">
                  <span @click="setTag(scope.row.openid)">确定</span>
                  <span @click="cancel(scope.row.openid)">取消</span>
                </div>
                <div slot="reference" class="tagEmpty" @click="openTagsChoose">
                  <span>无标签</span>
                  <img src="@/assets/arrow.png" alt="" />
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subscribeScene"
          label="来源场景"
        ></el-table-column>
        <el-table-column prop="channelName" label="渠道名称"></el-table-column>
        <el-table-column prop="isWechat" label="是否企业好友">
          <template slot-scope="scope">
            <span v-if="scope.row.isWechat"
              ><el-button type="text" @click="copyIsWechat(scope.row.isWechat)"
                >是</el-button
              ></span
            >
            <span v-else>否</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-size="10"
        layout="prev, pager, next"
        background
        :total="total"
        :hide-on-single-page="true"
      ></el-pagination>
    </el-container>
  </div>
</template>

<script>
import { postUserList, getFanList, getTagsList, batchtagging, batchuntagging } from '@/service/fan'
import {
  Message
} from 'element-ui'
export default {
  data () {
    return {
      checkList: [],
      oldIds: [],
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      uid: '',
      isSubscribe: '是',
      visible: false,
      tagList: [],
      formLabelAlign: {
        subscribeScene: '',
        tag: '',
        isWechat: '',
        time: ''
      }
    }
  },
  computed: {
  },
  created () {
    this.getPageList()
    this.getTagsList()
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    openTagsChooseEdit (names) {
      const oldIds = []
      names.forEach(item => {
        oldIds.push(this.tagList.find(f => f.name === item).id)
      })
      this.oldIds = oldIds
      this.checkList.splice(0, this.checkList.length, ...oldIds)
      console.log(this.oldIds)
    },
    cancel (id) {
      console.log(this.$refs[`popover-${id}`])
      this.$refs[`popover-${id}`].doClose()
    },
    setTagEdit (id) {
      console.log(this.oldIds, this.checkList)
      let chooseList = [...this.checkList]
      this.oldIds.forEach(item => {
        const index = chooseList.findIndex(f => f === item)
        if (index === -1) {
          //取消勾选
          batchuntagging({
            openid_list: [id],
            tagid: item
          })
        }
      })
      chooseList = chooseList.filter(item => this.oldIds.indexOf(f => f === item))
      if (chooseList.length > 0) {
        chooseList.forEach((tagid, index) => {
          if (index !== this.checkList.length - 1) {
            batchtagging({
              openid_list: [id],
              tagid
            })
          } else {
            batchtagging({
              openid_list: [id],
              tagid
            }).then(res => {
              this.getPageList()
              this.cancel(id)
              Message.success('更新成功')
            })
          }

        })

      } else {
        this.getPageList()
        this.cancel(id)
        Message.success('更新成功')
      }

    },
    setTag (id) {
      if (this.checkList.length > 0) {
        this.checkList.forEach((tagid, index) => {
          if (index !== this.checkList.length - 1) {
            batchtagging({
              openid_list: [id],
              tagid
            })
          } else {
            batchtagging({
              openid_list: [id],
              tagid
            }).then(res => {
              this.getPageList()
              this.cancel(id)
              Message.success('更新成功')
            })
          }

        })

      } else {
        this.cancel(id)
      }
      console.log(id, this.checkList)

    },
    openTagsChoose () {
      this.checkList.splice(0, this.checkList.length)
    },
    getPageList () {
      // , isSubscribe: this.isSubscribe
      postUserList({ pageNo: this.pageNo, pageSize: this.pageSize, uid: this.uid, isSubscribe: this.isSubscribe === '是' ? 1 : 0 }).then(res => {
        this.tableData = res.list.map(item => {
          if (item.tagid) {
            item.tagid = item.tagid.split(',')
          }
          return item
        })
        this.total = res.num
      })
    },
    getFanList () {
      getFanList().then(res => {
        Message.success('更新成功')
      })
    },
    getTagsList () {
      getTagsList({ pageNo: 1, pageSize: 100, tag: '' }).then(res => {
        this.tagList = res.list
      })
    },
    handleClick (tab, event) {
      this.isSubscribe = tab.name
      this.getPageList()
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem (id) {
      // delMaterialList({ id: id }).then(res => {
      //     Message.success('删除成功')
      //     this.getPageList()
      // })
    },
    edit (id) {
    },
    Submit () {
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        uid: this.uid,
        subscribeScene: this.formLabelAlign.subscribeScene,
        tag: this.formLabelAlign.tag,
        isWechat: this.formLabelAlign.isWechat - 0,
        startTime: this.formLabelAlign.time && this.formLabelAlign.time[0] ? this.$moment(this.formLabelAlign.time[0]).format('YYYY-MM-DD') : '',
        endTime: this.formLabelAlign.time && this.formLabelAlign.time[1] ? this.$moment(this.formLabelAlign.time[1]).format('YYYY-MM-DD') : ''
      }
      postUserList(data).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    Cancel () {
      this.visible = false
      this.formLabelAlign = {
        subscribeScene: '',
        tag: '',
        isWechat: '',
        time: ''
      }
    },
    // 复制
    copyIsWechat (wechat) {
      console.log('ddddd=>', wechat)
      var oInput = document.createElement('input')
      oInput.value = wechat
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      // Message.success('已复制   ' + wechat)
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('p', null, '已复制'),
          h('p', null, wechat)
        ]),
        type: 'success'
      });
    }
  },
  components: {}
}
</script>

<style lang="less">
@import url("./index.less");
</style>
