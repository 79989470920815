<template>
  <div class="Xiao-List">
    <div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="昵称">
          <el-input v-model="formInline.nickname" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item label="添加渠道">
          <el-input v-model="formInline.from" placeholder="添加渠道"></el-input>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="是否微信好友">
          <el-select v-model="formInline.wechatFrined" placeholder="是否微信好友" clearable>
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="text" @click="getXiaoErtongBackendSyncUsreList">
            <i class="el-icon-refresh-right"></i>更新数据
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="List" style="width: 100%">
        <el-table-column label="昵称" width="200">
          <template slot-scope="scope">
            <div class="disflex">
              <el-avatar :src="scope.row.avatar"></el-avatar>
              <span style="padding-left:10px">{{ scope.row.userNickname }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="from" label="用户来源"></el-table-column>
        <el-table-column prop="userCreatedAt" label="添加时间"></el-table-column>
        <el-table-column prop="paySum" label="消费总额"></el-table-column>
        <el-table-column prop="punchCount" label="购买次数"></el-table-column>
        <el-table-column prop="bindPhone" label="绑定手机号"></el-table-column>
        <el-table-column prop="collectPhone" label="采集手机号"></el-table-column>
        <el-table-column prop="unionid" label="是否微信好友">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.unionid"
              type="text"
              @click="copyIsWechat(scope.row.unionid)"
            >是</el-button>
            <span v-else>否</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  postXiaoErtongBackendGetUsreList,
  getXiaoErtongBackendSyncUsreList
} from "@/service/xiaoetong";
import { Message } from "element-ui";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      List: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      date: "",
      formInline: {
        nickname: "",
        from: "",
        startTime: "",
        endTime: "",
        wechatFrined: ""
      }
    };
  },
  computed: {},
  created() {
    this.postXiaoErtongBackendGetUsreList();
  },
  mounted() {},
  watch: {},
  methods: {
    postXiaoErtongBackendGetUsreList() {
      var data = {
        ...this.formInline,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      postXiaoErtongBackendGetUsreList({ ...data }).then(res => {
        this.List = res.list;
        this.total = res.num;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.postXiaoErtongBackendGetUsreList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.postXiaoErtongBackendGetUsreList();
    },
    onSubmit() {
      if (this.date) {
        this.formInline.startTime = moment(this.date[0]).format("YYYY-MM-DD");
        this.formInline.endTime = moment(this.date[1]).format("YYYY-MM-DD");
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
      this.postXiaoErtongBackendGetUsreList();
    },
    // 复制
    copyIsWechat(wechat) {
      var oInput = document.createElement("input");
      oInput.value = wechat;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      // Message.success('已复制   ' + wechat)
      const h = this.$createElement;
      this.$message({
        message: h("p", null, [h("p", null, "已复制"), h("p", null, wechat)]),
        type: "success"
      });
    },
    // 更新数据
    getXiaoErtongBackendSyncUsreList() {
      getXiaoErtongBackendSyncUsreList().then(res => {
        Message.success("更新成功");
        this.postXiaoErtongBackendGetUsreList();
      });
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
