<template>
  <!-- 公众号模板 -->
  <div class="wechatFriends">
    <div class="nodeTitle">模板消息配置</div>
    <div class="chooseTemp">
      <el-button type="success" @click="chooseTemplate">选择模板</el-button>
    </div>
    <div class="messageContent" v-if="tempLateForm.template_id">
      <div class="messagePreview">
        <div>
          <span class="previewTitle">消息预览</span>
          <div class="meeting">
            会议通知
            <ul>
              <li v-for="(item, index) in tempLate.from" :key="index">
                <span v-show="item.lable !== ''" class="lableStyle">
                  {{ item.lable }}:
                </span>
                {{ tempLateForm.data[item.prop].value }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; width: 100%">
        <el-form-item v-for="(item, index) in tempLate.from" :key="index" :label="item.fromLabel" :prop="item.prop">
          <el-input v-model="tempLateForm.data[item.prop].value"></el-input>
        </el-form-item>
        <el-form-item label="点击跳转" prop="link">
          <el-radio-group v-model="link" @change="radioChange">
            <el-radio :label="1">关闭</el-radio>
            <el-radio :label="2">跳转链接</el-radio>
            <el-radio :label="3">跳转小程序</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <!-- 选择模板 -->
    <div>
      <el-dialog title="选择模板" :visible.sync="dialogTableVisible">
        <el-table :data="tableData">
          <el-table-column property="date" type="index" label="序号" width="150"></el-table-column>
          <el-table-column property="title" label="标题"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleChoose(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <el-button type="success" @click="getTemplatePrivateList">同步模板</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 填写链接 -->
    <div>
      <el-dialog title="填写跳转链接" :visible.sync="linkShow">
        跳转链接
        <el-input v-model="tempLateForm.url" autocomplete="off"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="
              linkShow = false;
              link = 1;
            ">取 消</el-button>
          <el-button type="primary" @click="
              linkShow = false;
              tempLateForm.miniprogram = { appid: '', path: '' };
            ">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 添加小程序 -->
    <div>
      <el-dialog title="填写小程序信息" :visible.sync="minProShow">
        <span>
          小程序appid
          <el-input v-model="tempLateForm.miniprogram.appid" autocomplete="off" style="margin-bottom: 10px"></el-input>
        </span>
        页面路径
        <el-input v-model="tempLateForm.miniprogram.path" autocomplete="off"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="
              minProShow = false;
              link = 1;
            ">取 消</el-button>
          <el-button type="primary" @click="
              minProShow = false;
              tempLateForm.url = '';
            ">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";

import { getTemplateList, getTemplatePrivateList } from "@/service/push";
export default {
  props: ["editnode"],
  data () {
    return {
      tableData: [],
      dialogTableVisible: false,
      tempLate: {
        from: [],
        title: "",
      },
      linkShow: false,
      minProShow: false,
      link: 1,
      tempLateForm: {
        template_id: "",
        url: "",
        miniprogram: {
          appid: "",
          path: "",
        },
        data: {},
      },
    };
  },
  mounted () { },
  created () { },
  watch: {
    editnode (val) {
      console.log("wewe-> val", val);
      this.init(val);
    },
  },
  methods: {
    async chooseTemplate (flag = true) {
      const res = await getTemplateList();
      this.tableData = res;
      if (flag) this.dialogTableVisible = true;
    },
    getTemplatePrivateList () {
      getTemplatePrivateList().then((res) => {
        Message.success("同步模板成功");
      });
    },
    handleChoose (row, type = "change") {
      var example = row.example.split("\r\n");
      var content = row.content.split("\n");
      this.tempLateForm.template_id = row.templateId;
      var from = [];
      content.map((item, index) => {
        var i = index + 1;
        var obj = {};
        if (index === 0) {
          obj = {
            lable: "",
            key: i,
            fromLabel: "字段" + i,
            value: example[index],
            prop: "first",
          };
        } else if (index === content.length - 1) {
          obj = {
            lable: "备注",
            key: i,
            fromLabel: "字段" + i,
            value: example[index],
            prop: "remark",
          };
        } else {
          var value = example[index];
          obj = {
            lable: item.split("：")[0],
            key: i,
            fromLabel: "字段" + i,
            value: value.substring(value.indexOf("：") + 1),
            prop: "keyword" + index,
          };
        }
        from.push(obj);
      });
      if (type == 'change') {
        var tempLateForm = {
          template_id: row.templateId,
          url: "",
          miniprogram: {
            appid: "",
            path: "",
          },
          data: {},
        };
      } else {
        var tempLateForm = this.tempLateForm
      }
      from.map((item, index) => {
        // var i = index + 1;
        if (index === 0) {
          tempLateForm.data.first = { value: item.value, color: '#173177' };
        } else if (index === from.length - 1) {
          tempLateForm.data.remark = { value: item.value, color: '#173177' };
        } else {
          tempLateForm.data["keyword" + index] = { value: item.value, color: '#173177' };
        }
      });
      this.tempLateForm = tempLateForm;
      this.tempLate = { title: row.title, from: from };
      this.dialogTableVisible = false;
    },
    radioChange (val) {
      console.log(this.tempLateForm);
      switch (val) {
        case 1:
          this.tempLateForm.url = "";
          this.tempLateForm.miniprogram = {
            appid: "",
            path: "",
          };
          break;
        case 2:
          this.linkShow = true;
          break;
        case 3:
          this.minProShow = true;
          break;
        default:
          break;
      }
    },
    save () {
      console.log("save -> this.node", this.tempLateForm);
      const tempLateForm = this.tempLateForm
      if (tempLateForm.template_id === "") {
        Message.warning("请添加选择模板！");
        return false;
      }
      return tempLateForm;
    },
    async init (val) {
      console.log("init -> val", val);
      this.tempLateForm = val || {
        template_id: "",
        url: "",
        miniprogram: {
          appid: "",
          path: "",
        },
        data: {},
      };
      if (this.tempLateForm.template_id) {
        if (!this.tempLateForm.url) {
          this.$set(this.tempLateForm, 'url', {
            appid: "",
            path: "",
          })
        }
        if (!this.tempLateForm.miniprogram?.appid) {
          this.$set(this.tempLateForm, 'miniprogram', '')
        }
        this.link = this.tempLateForm.url ? 2 : this.tempLateForm.miniprogram?.appid ? 3 : 1;
        await this.chooseTemplate(false);
        let row = this.tableData.find(
          (e) => e.templateId == this.tempLateForm.template_id
        );
        this.handleChoose(row, 'init');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tableBottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.messageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  .messagePreview {
    border: 2px solid #e9eef3;
    background: #f5f8ff;
    font-size: 14px;
    .previewTitle {
      display: flex;
      justify-content: center;
      padding: 10px;
    }
    .meeting {
      border: 2px solid #e9eef3;
      margin: 5px 15px 15px 15px;
      background: #fff;
      padding: 10px;
      ul {
        padding-top: 10px;
      }
      .lableStyle {
        color: #bdbdbd;
      }
    }
  }
}
/deep/.el-form-item {
  display: flex;
  justify-content: space-between;
  .el-form-item__label {
    min-width: 80px;
  }
  // width: 180px;
  // display: inline-block;
  // margin-left: 20px;
}
</style>
