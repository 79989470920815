<template>
  <div class="enterprise-settings">
    <el-dialog
      title="公众号设置"
      :visible.sync="dialogFormVisible"
      :before-close="back"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="AppId" prop="appId">
          <el-input v-model="ruleForm.appId"></el-input>
        </el-form-item>
        <el-form-item label="AppSecret" prop="appSecret">
          <el-input v-model="ruleForm.appSecret"></el-input>
        </el-form-item>
        <el-form-item label="GrantType" prop="grantType">
          <el-input v-model="ruleForm.grantType"></el-input>
        </el-form-item>
        <el-form-item> </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getOfficialAccountUpdateConfig, getOfficialAccountUpdateList } from '@/service/enterprise'
import {
  Message
} from 'element-ui'
export default {
  components: {

  },
  props: ['officialShow'],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        appId: '',
        appSecret: '',
        grantType: ''
      },
      rules: {
        appId: [
          { required: true, message: '请输入appIdID', trigger: 'blur' }
        ],
        appSecret: [
          { required: true, message: '请输入appSecret', trigger: 'blur' }
        ],
        grantType: [
          { required: true, message: '请输入grantType', trigger: 'blur' }
        ]
      }

    }
  },
  computed: {

  },
  created() {
    this.getOfficialAccountUpdateList()
  },
  mounted() {

  },
  watch: {
    officialShow(val) {
      this.dialogFormVisible = val
    }
  },
  methods: {
    getOfficialAccountUpdateList() {
      getOfficialAccountUpdateList().then(res => {
        this.ruleForm.appId = res.app_id
        this.ruleForm.appSecret = res.app_secret
        this.ruleForm.grantType = res.grant_type
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getOfficialAccountUpdateConfig({ ...this.ruleForm }).then(res => {
            this.dialogFormVisible = false
            this.$emit('visableOfficialShow', false)
            Message.success('添加成功')
          }).catch(function (error) {
            // 处理 getJSON 和 前一个回调函数运行时发生的错误
            console.log('发生错误！', error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit('visableOfficialShow', false);
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
