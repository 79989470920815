<template>
  <div class="Sop-detail">
    <div class="topInput">
      <i class="el-icon-arrow-left" style="width: 20px" @click="back"></i>
      <el-input v-model="data.sopName" placeholder="请输入计划名称"></el-input>
    </div>
    <el-row :gutter="20">
      <el-col :span="6" class="bgc borderR">
        <el-steps direction="vertical" :active="active">
          <el-step title="触发条件" icon="iconfont icon-icon-test">
            <span slot="description">
              <i class="el-icon-time"></i>
              <span>触发时间：</span>对受众用户进行触发
            </span>
          </el-step>
          <el-step title="受众用户" icon="iconfont icon-duorenxiezuo">
            <span slot="description">
              <div>
                <el-tag type="warning">{{ data.num }}人</el-tag>
              </div>
              <i class="el-icon-circle-check"></i>
              <span>用户行为：</span>满足1个条件
            </span>
          </el-step>
          <el-step title="触达配置" icon="iconfont icon-xinfeng">
            <span slot="description">
              <div class="detailTag" v-for="(t, i) in tags" :key="t" :label="t">
                <el-tag type="warning" v-show="i % 2 == 1">{{ t }}</el-tag>
                <el-tag v-show="i % 2 == 0">{{ t }}</el-tag>
              </div>
            </span>
          </el-step>
        </el-steps>
      </el-col>
      <el-col :span="18" class="bgc">
        <div class="grid-content bg-purple">
          <div v-show="active == 1">
            <div class="title"><span></span>活动描述</div>
            <el-input class="description" v-model="data.description"></el-input>
            <div class="title"><span></span>设置发送时间</div>
            <el-radio
              v-model="data.sendTimeType"
              label="当日"
              class="radioblock"
              @change="radioChange"
            >
              加入规则后
              <el-input
                class="date"
                v-model="hours"
                @change="setTime"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input
              >小时
              <el-input
                v-model="moment"
                class="date"
                @change="setTime"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input
              >分钟后提醒
            </el-radio>
            <el-radio
              v-model="data.sendTimeType"
              label="非当日"
              @change="radioChange"
            >
              加入规则后
              <el-input
                v-model="date"
                class="date"
                @change="setDate"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input
              >天后，
              <el-time-picker
                v-model="time"
                :picker-options="{
                  selectableRange: '00:00:00- 23:59:59',
                }"
                format="HH:mm:ss"
                placeholder="18:30:00"
                value-format="HH:mm:ss"
                @change="setDate"
              ></el-time-picker>
              提醒发送
            </el-radio>
          </div>
          <div v-show="active == 2">
            <div class="disflex">
              <div>
                <div class="title"><span></span>性别</div>
                <el-select
                  v-model="data.gender"
                  placeholder="请选择"
                  clearable
                  @change="getTagsUserNum"
                >
                  <el-option
                    v-for="item in genderOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div class="title"><span></span>添加渠道</div>
                <el-select
                  v-model="data.addChannel"
                  placeholder="请选择"
                  clearable
                  @change="getTagsUserNum"
                >
                  <el-option
                    v-for="item in channelList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="title"><span></span>排除标签</div>
                <el-select
                  v-model="excludeTags"
                  multiple
                  placeholder="请选择"
                  @change="chooseExcludeTag"
                >
                  <el-option-group
                    v-for="group in tagsOptions"
                    :key="group.groupId"
                    :label="group.groupName"
                  >
                    <el-option
                      v-for="item in group.tagList"
                      :key="item.tagId"
                      :label="item.tagName"
                      :value="item.tagName"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div>
                <div class="title"><span></span>添加时间</div>
                <el-date-picker
                  v-model="data.addTimeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="getTagsUserNum"
                >
                </el-date-picker>
                <div class="title"><span></span>选择标签</div>
                <el-select
                  v-model="tags"
                  clearable
                  multiple
                  placeholder="请选择"
                  @change="chooseTag"
                >
                  <el-option-group
                    v-for="group in tagsOptions"
                    :key="group.groupId"
                    :label="group.groupName"
                  >
                    <el-option
                      v-for="item in group.tagList"
                      :key="item.tagId"
                      :label="item.tagName"
                      :value="item.tagName"
                    ></el-option>
                  </el-option-group>
                </el-select>
                <div class="title"><span></span>添加人</div>
                <el-select
                  v-model="data.addId"
                  clearable
                  placeholder="请选择"
                  @change="getTagsUserNum"
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="users">
              <el-tag type="success" effect="dark">受众用户数</el-tag>
              <span class="num">{{ data.num }}</span>
            </div>
            <!-- <div class="title"><span></span>执行员工</div>
            <el-select
              v-model="data.responsibleId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in userOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select> -->
          </div>

          <div v-show="active == 3">
            <div class="title"><span></span>类型</div>
            <el-radio v-model="data.sendType" label="消息">消息</el-radio>
            <el-radio v-model="data.sendType" label="朋友圈">朋友圈</el-radio>
            <div class="title"><span></span>内容</div>
            <el-input
              type="textarea"
              :rows="5"
              v-model="data.msgDetail"
              placeholder="请输入内容"
            ></el-input>
            <el-upload
              action="#"
              class="upload-box"
              ref="upload"
              list-type="picture-card"
              :auto-upload="true"
              :limit="1"
              :on-exceed="handleExceed"
              accept=".jpg, .jpeg, .png, .gif"
              :file-list="showFiles"
              :http-request="uploadPic"
              :before-upload="beforeAvatarUpload"
            >
              <el-button type="primary" class="uploadBtn">
                <i class="iconfont icon-yunshangchuan"></i>
                上传图片
              </el-button>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="postionBottom">
          <div>
            <el-button
              @click="previous"
              type="primary"
              plain
              :disabled="active == 1"
              >上一步</el-button
            >
            <el-button @click="next" type="primary" :disabled="active == 3"
              >下一步</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="save" :disabled="disable"
              >发布</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getSOPPersonList,
  uploadFile,
  getTagsUserNum,
  getLabelList,
  postSopEdit,
  postSopCreate,
  uploadAttachmentFile,
} from "@/service/group";
import { queryAddWay } from "@/service/customerManage";
import { Message } from "element-ui";
export default {
  props: ["dataDetail"],
  data() {
    return {
      active: 1,
      data: {
        description: "",
        mediaId: "",
        msgDetail: "",
        msgType: "文字",
        responsibleId: "",
        sendTime: "",
        sendTimeType: "当日",
        sendType: "消息",
        sopName: "",
        tags: "",
        num: 0,
        gender: "",
        addChannel: "",
        addTimeRange: null,
        excludeTags: "",
        addId: "",
      },
      tags: [],
      date: "",
      moment: "",
      hours: "",
      time: new Date(2021, 9, 10, 9, 0),
      userOptions: [],
      tagsOptions: [],
      showFiles: [],
      disable: false,
      upload: {
        file: null,
        type: "image",
        introduction: "素材介绍",
      },
      genderOptions: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
      channelList: [],
      excludeTags: [],
    };
  },
  computed: {},
  created() {
    // console.log(this.$parent.dataDetail)
    this.getChannelList();
    this.getSOPPersonList();
    this.getLabelList();
  },
  mounted() {},
  watch: {
    dataDetail(val) {
      console.log(val);
      if (val && val.id) {
        this.data.sopName = val.sopName;
        this.data.id = val.id;
        this.data.description = val.description;
        this.data.mediaId = val.mediaId;
        this.data.msgDetail = val.msgDetail;
        this.data.msgType = val.msgType;
        this.data.responsibleId = val.responsibleId;
        this.data.sendTimeType = val.sendTimeType;
        this.data.sendType = val.sendType;
        this.data.tags = val.tags;
        this.data.num = val.num;
        this.data.sendTimeType = val.sendTimeType;
        this.data.sendTime = val.sendTime;
        this.data.gender = val.gender;
        this.data.addChannel = val.addChannel;
        if (this.data.addTimeRange) {
          this.data.addTimeRange = val.addTimeRange.split(";");
        }
        this.data.addId = val.addId;
        this.tags = val.tags.split(",");
        this.excludeTags = val.excludeTags && val.excludeTags.split(",");
        if (val.picUrl) {
          this.showFiles.push({
            name: val.picUrl,
            url: val.picUrl,
          });
        }
        if (val.sendTimeType === "当日") {
          this.moment = val.sendTime.split(",")[1];
          this.hours = val.sendTime.split(",")[0];
        } else {
          this.time = val.sendTime.split(",")[1];
          this.date = val.sendTime.split(",")[0];
        }
      }
    },
  },
  methods: {
    getSOPPersonList() {
      getSOPPersonList().then((res) => {
        res &&
          res.map((item) => {
            this.userOptions.push({ label: item.userName, value: item.userId });
          });
      });
    },
    getChannelList() {
      queryAddWay().then((res) => {
        this.channelList = res;
      });
    },
    getLabelList() {
      getLabelList().then((res) => {
        this.tagsOptions = res;
      });
    },
    chooseTag(val) {
      this.tags = val;
      this.data.tags = val.toString();
      this.getTagsUserNum();
    },
    chooseExcludeTag(val) {
      this.excludeTags = val;
      this.data.excludeTags = val.toString();
      this.getTagsUserNum();
    },
    getTagsUserNum() {
      var date = [];
      if (this.data.addTimeRange) {
        date.push(
          this.$moment(this.data.addTimeRange[0]).format("YYYY-MM-DD HH:mm:ss")
        );
        date.push(
          this.$moment(this.data.addTimeRange[1]).format("YYYY-MM-DD HH:mm:ss")
        );
      }
      var data = {
        tags: this.data.tags,
        gender: this.data.gender,
        addTimeRange: date.join(";"),
        timeFromNow: "",
        addChannel: this.data.addChannel,
        excludeTags: this.data.excludeTags,
        addId: this.data.addId,
      };
      if (
        data.tags === "" &&
        data.gender === "" &&
        data.addTimeRange === "" &&
        data.timeFromNow === "" &&
        data.addChannel === "" &&
        data.excludeTags === "" &&
        data.addId === ""
      ) {
        return false;
      } else {
        getTagsUserNum({ ...data }).then((res) => {
          this.data.num = res;
        });
      }
    },
    back() {
      this.tags = [];
      this.date = "";
      this.time = "";
      this.hours = "";
      this.moment = "";
      this.showFiles = [];
      this.disable = false;
      this.active = 1;
      this.excludeTags = [];
      this.upload = {
        file: null,
        type: "image",
        introduction: "素材介绍",
      };
      this.data = {
        description: "",
        mediaId: "",
        msgDetail: "",
        msgType: "文字",
        responsibleId: "",
        sendTime: "",
        sendTimeType: "当日",
        sendType: "消息",
        sopName: "",
        tags: "",
        num: 0,
      };
      this.$emit("visabledetails", false);
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    previous() {
      if (this.active-- < 0) this.active = 0;
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles();
      this.data.msgType = "文字";
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    async uploadPic(val) {
      this.upload.file = val.file;
      this.disable = true;
      console.log(
        "🚀 ~ file: index.vue ~ line 358 ~ uploadPic ~ this.sendType",
        this.sendType
      );
      if (this.data.sendType === "消息") {
        uploadFile({ ...this.upload }).then((res) => {
          Message.success("图片上传成功");
          this.data.mediaId = res;
          this.data.msgType = "图片";
          this.disable = false;
        });
      } else {
        uploadAttachmentFile({ media: val.file }).then((res) => {
          if (res) {
            Message.success("图片上传成功");
            this.data.mediaId = res;
            this.data.msgType = "图片";
            this.disable = false;
          } else {
            Message.warning("图片分辨率不能超过 1440 x 1080，请重新上传");
            this.showFiles = [];
          }
        });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    setTime() {
      this.data.sendTime = this.hours + "," + this.moment;
    },
    setDate() {
      this.data.sendTime = this.date + "," + this.time;
    },
    radioChange() {
      this.data.sendTime = "";
      this.date = "";
      this.time = "";
      this.hours = "";
      this.moment = "";
    },
    save() {
      console.log("data", this.data);
      if (this.data.sopName === "") {
        Message.warning("请检查！计划名称未填写！");
        return false;
      }
      if (this.data.description === "") {
        Message.warning("请检查！活动描述未填写！");
        return false;
      }
      if (
        this.data.sendTime === "" ||
        (this.data.sendTimeType === "当日" &&
          (this.hours === "" || this.moment === ""))
      ) {
        Message.warning("请检查！未设置发送时间！");
        return false;
      }
      // if (this.data.tags === '') {
      //   Message.warning('请选择添加标签！')
      //   return false
      // }
      if (this.data.responsibleId === "") {
        Message.warning("请选择执行员工！");
        return false;
      }
      if (this.data.msgDetail === "") {
        Message.warning("请填写消息内容！");
        return false;
      }
      if (this.data.mediaId === "" && this.data.msgType === "图片") {
        Message.warning("请检查！图片未上传成功！");
        return false;
      }
      var date = [];
      if (this.data.addTimeRange) {
        date.push(
          this.$moment(this.data.addTimeRange[0]).format("YYYY-MM-DD HH:mm:ss")
        );
        date.push(
          this.$moment(this.data.addTimeRange[1]).format("YYYY-MM-DD HH:mm:ss")
        );
        this.data.addTimeRange = date.join(";");
      }
      this.data.sopType = "目标客户";
      if (this.data.id) {
        postSopEdit({ ...this.data }).then((res) => {
          Message.success("编辑成功");
          this.back();
        });
      } else {
        postSopCreate({ ...this.data }).then((res) => {
          Message.success("保存成功");
          this.back();
        });
      }
    },
  },
  components: {},
};
</script>

<style lang="less">
@import url("./index.less");
</style>
