<!-- 添加/编辑用户 -->
<template>
  <div>
    <el-dialog
      title="提示"
      @open="openDialog"
      @close="closeDialog"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form
        ref="addUserFormRef"
        :model="addUserForm"
        :rules="addUserFormRules"
        label-width="80px"
      >
        <el-form-item label="账号" prop="user">
          <el-input
            placeholder="请输入账号"
            v-model="addUserForm.user"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="addUserForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="name">
          <el-input
            placeholder="请输入用户名"
            v-model="addUserForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" props="status">
          <el-select v-model="addUserForm.status" placeholder="请选择状态">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="postAddUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateUser,
  addUser
} from '@/service/roles'

export default {
  props: {
    dialogShow: {
      type: Boolean,
      defalut: false
    },
    userData: Object,
    updateTable: Function
  },
  data() {
    return {
      addUserForm: {
        user: '',
        password: '',
        name: '',
        status: '',
        id: ''
      },
      addUserFormRules: {
        user: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: ['change', 'blur'] }]
      },
      options: [
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '失效'
        }
      ]
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
      this.addUserForm = {}
      this.userData = {}
    },
    postAddUser() {
      this.$refs.addUserFormRef.validate(async (valid) => {
        if (valid) {
          if (this.userData.id) {
            // 编辑
            updateUser({ ...this.addUserForm }).then(data => {
              if (data) {
                this.$message.success('修改成功')
              } else {
                this.$message.error('修改失败')
              }
            })
          } else {
            addUser({ ...this.addUserForm }).then(data => {
              if (data) {
                this.$message.success('添加成功')
              } else {
                this.$message.error('添加失败')
              }
            })
          }
          setTimeout(() => {
            this.$emit('closeDialog', false)
            this.addUserForm = {}
            this.updateTable()
          }, 500)
        }
      })
    },
    openDialog() {
      console.log(this.userData)
      if (this.userData.id) {
        this.addUserForm = this.userData
      }
      console.log(this.addUserForm)
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
.el-select {
  float: left;
}
</style>
