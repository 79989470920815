<template>
  <!-- 条件判断 -->
  <div class="wechatFriends">
    <div class="nodeTitle">条件判断</div>
    <div class="radio">
      <el-radio v-model="value" label="hasPhone">是否有手机号</el-radio>
      <el-radio v-model="value" label="hasWechat">是否微信好友</el-radio>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { getAddressList } from "@/service/group";
// import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      value: "hasPhone",
      userOptions: [],
    };
  },
  mounted() {},
  created() {},
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    },
  },
  methods: {
    save() {
      console.log("save -> this.node", this.value);
      return this.value;
    },
    init(val) {
      console.log("init -> val", val);
      this.value = val || "hasPhone";
    },
  },
};
</script>

<style lang="less">
.radio{
  padding: 20px 0;
}
</style>
