<template >
  <div class="group-welcome-add">
    <el-container>
      <el-header class="groupheader">
        <span>新建群欢迎语</span>
        <span v-if="id" @click="goListPage" class="iconBtn"
          ><i class="iconfont icon-back"></i
        ></span>
      </el-header>
      <el-main>
          <el-form ref="form" :model="form" label-width="100px">
        <div class="welBox">
            <el-form-item label="欢迎语内容">
              <el-input
                type="textarea"
                :rows="5"
                v-model="form.text.content"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="其他类型">
              <el-radio-group v-model="form.type">
                <el-radio label="文本"></el-radio>
                <el-radio label="图片"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="图片" v-show="form.type === '图片'">
                <el-upload
                  action="#"
                  class="upload-box"
                  ref="upload"
                  list-type="picture-card"
                  :auto-upload="true"
                  :limit="1"
                  :on-exceed="handleExceed"
                  accept=".jpg,.jpeg,.png,.gif"
                  :file-list="showFiles"
                  :http-request="uploadPic"
                  :before-upload="beforeAvatarUpload"
                >
                  <el-button type="primary" class="uploadBtn">
                    <i class="iconfont icon-yunshangchuan"></i>
                    上传图片
                  </el-button>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
            </el-form-item>
             </div>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" class="submitBtn" :disabled="disable">立即提交</el-button>
            </el-form-item>
          </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { uploadFile, postGroupWelAdd, postGroupWelGet, postGroupWelEdit } from '@/service/group'
import { getQuery } from '@/utils'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      id: '',
      form: {
        image: {
          media_id: ''
        },
        text: {
          content: ''
        },
        type: '文本'
      },
      pic_url: '',
      showFiles: [],
      disable: false,
      upload: {
        file: null,
        type: 'image',
        introduction: '员工活码图片'
      }
    }
  },
  computed: {

  },
  created() {
    this.init()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    init() {
      const { id } = getQuery()
      if (id) {
        this.id = id
        postGroupWelGet({ template_id: id }).then(res => {
          this.form.image.media_id = res.media_id
          this.form.text.content = res.text.content
          this.form.type = res.type
          const fileArr = []
          const fileObj = {}
          fileObj.name = 'file'
          fileObj.url = res.image.pic_url
          fileArr.push(fileObj)
          this.showFiles = fileArr
        })
      }
    },
    onSubmit() {
      if (this.form.text.content === '') {
        Message.warning('请输入欢迎语')
        return false
      }
      if (this.form.type === '图片' && this.form.image.media_id === '') {
        Message.warning('请上传图片')
        return false
      }
      const form_ = JSON.parse(JSON.stringify(this.form))
      console.log('🚀 ~ file: index.vue ~ line 144 ~ onSubmit ~ form_ ', form_)
      if (this.form.type === '文本') { this.$delete(form_, 'image') }
      if (this.id) {
        postGroupWelEdit({ template_id: this.id, ...form_ }).then(res => {
            Message.success('编辑成功')
          setTimeout(() => {
            this.goListPage()
          }, 1000)
        })
      } else {
        postGroupWelAdd({ ...form_ }).then(res => {
          Message.success('保存成功')
          setTimeout(() => {
            this.goListPage()
          }, 1000)
        })
      }
    },
    goListPage() {
      this.$router.push('/group/welcome')
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    async uploadPic(val) {
      console.log('🚀 ~ file: index.vue ~ line 180 ~ uploadPic ~ val', val)
      this.disable = true
      this.upload.file = val.file
      uploadFile({ ...this.upload }).then((res) => {
         Message.success('图片上传成功')
         this.form.image.media_id = res
         this.disable = false
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
