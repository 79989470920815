import { get } from '../Api/axios'
import {
  URL_USER_LOGIN,
  URL_USER_ROLE_LIST,
  URL_USER_LOGOUT,
  URL_GENERAL_VIEW,
  URL_USER_CODE
} from '@/Api/api.js'

export function getUserRoleList (params = {}) {
  return get(URL_USER_ROLE_LIST, {
    ...params
  })
}

export function postUserLogin (params = {}) {
  return get(URL_USER_LOGIN, {
    ...params
  })
}

export function LogOut (params = {}) {
  return get(URL_USER_LOGOUT, {
    ...params
  })
}

export function getGeneralView (params = {}) {
  return get(URL_GENERAL_VIEW, {
    ...params
  })
}

export function getCode (params = {}) {
  return get(URL_USER_CODE, {
    ...params
  })
}
