<template>
  <div class="enterprise-page-list-wrapper">
    <el-form ref="form" :model="form" size="medium" label-width="40px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="姓名">
            <el-input  v-model="form.name" placeholder="姓名" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="14" class="btn">
          <el-button size="medium" type="primary" @click="handleQuery">
            查询
          </el-button>
          <el-button size="medium"  type="primary" class="synchronization" :loading="loading" @click="getEnterpriseDepartmentList">
            同步企业微信
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <template>
      <div class="user-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="avatar" label="成员头像" width="150">
            <template slot-scope="scope">
              <div class="uesr-infos">
                <el-image :src="scope.row.avatar" alt=""></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别" >
          </el-table-column>
          <el-table-column prop="user_name" label="成员姓名">
          </el-table-column>
          <el-table-column prop="department_name" label="部门"> </el-table-column>
          <el-table-column prop="group_num" label="群数量"> </el-table-column>
          <el-table-column prop="group_user_num" label="群客户数"> </el-table-column>
          <el-table-column prop="user_num" label="客户数"> </el-table-column>
          <el-table-column prop="status" label="状态">
          </el-table-column>
        </el-table>
      </div>
    </template>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 40]"
      :page-size="pageSize"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  postEnterpriseAddressList,
  getEnterpriseDepartmentList
} from '@/service/enterprise'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {
        name: ''
      },
      tableData: [],
      loading: false
    }
  },
  computed: {

  },
  created() {
    this.runList()
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    runList(params) {
      postEnterpriseAddressList({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    },
    handleQuery() {
      this.runList({
        ...this.form
      })
    },
    getEnterpriseDepartmentList() {
      this.loading = true
      getEnterpriseDepartmentList().then(res => {
          Message.success('同步成功')
          this.loading = false
          this.runList({ ...this.form })
      })
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
