import { get } from '../Api/axios'
import {
    URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_LIST,
    URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_DELETE
} from '@/Api/api.js'

export function getMaterialList(params = {}) {
    return get(URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_LIST, {
      ...params
    })
}

export function delMaterialList(params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_DELETE, {
    ...params
  })
}
