import { get, post } from '../Api/axios'
import {
  URL_AUTOMATION_FOLDER_LIST,
  URL_AUTOMATION_LIST,
  URL_AUTOMATION_CREATE,
  URL_AUTOMATION_DELETE
} from '@/Api/api.js'

export function getAutomationFolderList(params = {}) {
  return get(URL_AUTOMATION_FOLDER_LIST, {
    ...params
  })
}

export function postAutomationList(params = {}) {
  return post(URL_AUTOMATION_LIST, {
    ...params
  })
}

export function postAutomationCreate(params = {}) {
  return post(URL_AUTOMATION_CREATE, {
    ...params
  })
}

export function getAutomationDel(params = {}) {
  return get(URL_AUTOMATION_DELETE, {
    ...params
  })
}
