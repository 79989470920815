<template>
  <div>
    <div class="group-drainage-page-list-wrapper" v-show="!isShowAdd">
      <el-form ref="form" :model="form" size="medium" label-width="80px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活码名称">
              <el-input v-model="form.codeName" placeholder="请输入活码名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="创建人">
              <el-input v-model="form.creator" placeholder="请输入创建人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建日期">
              <el-date-picker v-model="form.createTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="btn">
            <el-button size="medium" type="success" @click="handleQuery">查询</el-button>
            <el-button size="medium" @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
        <div class="btnRow">
          <el-button size="medium" type="primary" @click="addDrainage">新建群活码</el-button>
          <!-- <div>
          <el-button size="medium" type="primary" @click="addDrainage" :disabled="multipleSelection.length === 0">批量下载</el-button>
          <el-button size="medium" type="primary" @click="addDrainage" :disabled="multipleSelection.length === 0">批量删除</el-button>
        </div> -->
        </div>
      </el-form>
      <template>
        <div class="user-list">
          <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="codeName" label="活动名称"></el-table-column>
            <el-table-column prop="codeDescribe" label="活动描述"></el-table-column>
            <el-table-column prop="date" label="活码样式" width="150">
              <template slot-scope="scope">
                <div class="uesr-infos">
                  <el-image :src="scope.row.codeUrl" alt=""></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="groupLists" label="实际群名称">
              <template slot-scope="scope" v-if="scope.row.groupLists">
                <el-tag v-for="v in scope.row.groupLists" :key="v.group_id" size="mini">{{ v.group_name }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="已扫码入群人数" width="120"> </el-table-column>
            <el-table-column prop="userName" label="创建人"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="150">
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="medium" @click="goDetails(scope.row)">编辑</el-button>
                <el-button type="text" size="medium" @click="downLoadQrcode(scope.row.codeUrl)">下载</el-button>
                <el-button type="text" size="medium" @click="postDelContactWay(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <AddDrainage v-show="isShowAdd" @visabledetails="visabledetails" :dataDetail="dataDetail"></AddDrainage>
  </div>
</template>

<script>
import {
  getGroupDrainageList,
  delGroupJoin
} from '@/service/drainage'
import qs from 'qs'
import AddDrainage from '@/views/GroupDrainage/addGroupDrainage'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {
        creator: '',
        codeName: '',
        createTime: ''
      },
      useUserList: [],
      tableData: [],
      labelList: [],
      addTypeList: ['单人', '多人'],
      dialogVisible: false,
      defaultChecked: [],
      currentRow: {},
      updateCursor: '',
      isShowAdd: false,
      multipleSelection: [],
      dataDetail: {},
      downLoadUrl: 'https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    this.runList()
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    runList(params) {
      getGroupDrainageList({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    },
    handleQuery() {
      this.runList({
        ...this.form,
        startTime: this.form.createTime && this.form.createTime[0] ? this.$moment(this.form.createTime[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        endTime: this.form.createTime && this.form.createTime[0] ? this.$moment(this.form.createTime[1]).format('YYYY-MM-DD HH:mm:ss') : ''
      })
    },
    handleReset() {
      this.runList()
      this.form = {}
    },
    // 下载
    downLoadQrcode(url) {
      if (url) {
        debugger
        window.open(
          this.downLoadUrl + qs.stringify({ urlString: url }), '_self'
        )
      } else {
        Message.error('下载失败')
      }
    },
    addDrainage() {
      this.isShowAdd = true
    },
    goDetails(item) {
      this.isShowAdd = true
      this.dataDetail = item
    },
    settingLabel(row) {
      const { tag } = row
      this.currentRow = row
      this.defaultChecked = tag.split(',')
      this.labelRun()
      this.dialogVisible = true
    },
    filterTree(result) {
      return result.map(item => {
        if (item.tagList) {
          return {
            id: item.groupName,
            label: item.groupName,
            children: this.filterTree(item.tagList)
          }
        }
        return {
          id: item.tagName,
          label: item.tagName
        }
      })
    },
    // 删除记录
    postDelContactWay(val) {
      delGroupJoin({ config_id: val.configId }).then(res => {
        Message.success('删除成功')
        this.runList()
      })
    },
    visabledetails(visable) {
      this.isShowAdd = visable
      if (!visable) {
        this.dataDetail = {}
        this.runList()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  },
  components: {
    AddDrainage
  }
}
</script>

<style lang="less">
  @import url("./index.less");
</style>
