<template>
  <Block title="群列表">
    <el-row>
      <el-col :span="6">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="群名称">
            <el-input
              v-model="form.groupname"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="10" style="marginleft: 10px">
        <el-button type="primary" @click="searchGroupName">查询</el-button>
        <el-button type="warning" @click="synchronize">同步数据</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="groupName" label="群名"> </el-table-column>
      <el-table-column prop="owner" label="群主"> </el-table-column>
      <el-table-column prop="tags" label="标签">
        <template slot-scope="scope">
          <template v-if="scope.row.tags">
            <el-tag
              style="marginright: 5px"
              v-for="value in scope.row.tags.split(',')"
              :key="value"
              >{{ value }}</el-tag
            >
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column prop="num" label="群人数"> </el-table-column>
      <el-table-column
        label="群成员邀请人员进群统计"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="getGroupDetailes(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="标签设置" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="getGroupTags(scope.row)"
            >群标签</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" :runList="_initGroupList"></Pagination>
    <el-dialog title="查看群邀请详情" :visible.sync="dialogVisible" width="50%">
      <el-table :data="groupDetailsTableData" stripe style="width: 100%">
        <el-table-column>
          <template slot="header">
            <p class="invite-people">邀请人：{{ invite }}</p>
          </template>
          <template slot-scope="scope">
            <div class="avatar-name">
              <img :src="scope.row.picUrl" alt="" />
              <p>{{ scope.row.userName }}</p>
            </div>
          </template>
        </el-table-column>
         <el-table-column>
          <template slot-scope="scope">
            <p>{{ scope.row.gender }}</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <p>{{ scope.row.joinTime }}</p>
          </template>
        </el-table-column>
         <el-table-column>
          <template slot-scope="scope">
            <p>{{ scope.row.joinScene }}</p>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="选择标签" :visible.sync="dialogTagsVisible" width="50%">
      <el-tree
        :data="treeData"
        show-checkbox
        class="label-tree"
        node-key="id"
        ref="tree"
        default-expand-all
        :default-checked-keys="defaultChecked"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTagsVisible = false">取 消</el-button>
        <el-button type="primary" @click="configLabel">确 定</el-button>
      </span>
    </el-dialog>
  </Block>
</template>

<script>
import Block from '@/components/Block'
import Pagination from '@/components/Pagination'
import { getQueryGroupPageList, getSynchronizeGroup, getGroupDetailes, getGroupLabelList, getGroupLabelTags } from '@/service/group'
export default {
  data() {
    return {
      total: 0,
      form: {
        groupname: ''
      },
      tableData: [],
      groupDetailsTableData: [],
      dialogVisible: false,
      invite: '',
      treeData: [],
      defaultChecked: [],
      dialogTagsVisible: false,
      groupId: null
    }
  },
  computed: {

  },
  created() {
    this._initGroupList(1, 10)
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    _initGroupList(pageNo, pageSize, groupname) {
      getQueryGroupPageList({
        pageNo,
        pageSize,
        groupname
      }).then(res => {
        this.total = res.count
        this.tableData = res.list
        this.defaultChecked = []
        this.groupId = null
      })
    },
    filterTree(result) {
      return result.map(item => {
        if (item.tags) {
          return {
            id: item.groupName,
            label: item.groupName,
            children: this.filterTree(item.tags)
          }
        }
        return {
          id: item.tagName,
          label: item.tagName
        }
      })
    },
    _initLableList() {
      getGroupLabelList().then(res => {
        this.treeData = this.filterTree(res)
      })
    },
    getGroupTags(row) {
      const { tags, groupId } = row
      this._initLableList()
      this.defaultChecked = tags ? tags.split(',') : []
      this.dialogTagsVisible = true
      this.groupId = groupId
    },
    configLabel() {
      getGroupLabelTags({
        group_id: this.groupId,
        tagids: this.$refs.tree.getCheckedNodes(true).map(v => v.label).join(',')
      }).then((res) => {
        this._initGroupList(1, 10)
        this.dialogTagsVisible = false
      })
    },
    searchGroupName() {
      this._initGroupList(1, 10, this.form.groupname)
    },
    synchronize() {
      getSynchronizeGroup()
    },
    getGroupDetailes(row) {
      const { groupId, owner } = row
      this.invite = owner
      this.dialogVisible = true
      getGroupDetailes(groupId).then(res => {
        console.log(res)
        this.groupDetailsTableData = res
      })
    }
  },
  components: {
    Block,
    Pagination
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
