<template>
  <div
    ref="node"
    :style="nodeContainerStyle"
    @click="clickNode"
    @mouseup="changeNodeSite"
    :class="nodeContainerClass"
  >
    <div class="ef-node-left-ico flow-node-drag">
      <i :class="nodeIcoClass"></i>
    </div>
    <!-- 节点名称 -->
    <div class="ef-node-text" :show-overflow-tooltip="true">
      <span>{{ node.name }}</span>
      <span>节点ID:{{ node.id }}</span>
    </div>
  </div>
</template>

<script>
   /* eslint-disable */
export default {
  props: {
    node: Object,
    activeElement: Object
  },
  data() {
    return {}
  },
  computed: {
    nodeContainerClass() {
      return {
        'ef-node-container': true,
        'ef-node-active': this.activeElement.type == 'node' ? this.activeElement.nodeId === this.node.id : false
      }
    },
    // 节点容器样式
    nodeContainerStyle() {
      let bg = "#fff"
      switch (this.node.type) {
        case 'havoir':
          bg = '#b2d2ea'
          break;
        case 'attribute':
          bg = '#fac3a6'
          break;
        case 'blacklist':
          bg = '#ffe295'
          break;
        case 'wait':
          bg = '#ddeed5'
          break;
        case 'message':
        case 'push':
        case 'outbound':
        case 'position':
        case 'popup':
          bg = '#d4d4d4'
          break;
        default:
          break;
      }
      return {
        top: this.node.top,
        left: this.node.left,
        background: bg
      }
    },
    nodeIcoClass() {
      var nodeIcoClass = {}
      nodeIcoClass['el-icon-connection'] = true
      // 添加该class可以推拽连线出来，viewOnly 可以控制节点是否运行编辑
      nodeIcoClass['flow-node-drag'] = this.node.viewOnly ? false : true
      return nodeIcoClass
    }
  },
  methods: {
    // 点击节点
    clickNode() {
      this.$emit('clickNode', this.node.id)
    },
    // 鼠标移动后抬起
    changeNodeSite() {
      // 避免抖动
      if (this.node.left == this.$refs.node.style.left && this.node.top == this.$refs.node.style.top) {
        return;
      }
      this.$emit('changeNodeSite', {
        nodeId: this.node.id,
        left: this.$refs.node.style.left,
        top: this.$refs.node.style.top,
      })
    }
  }
}
</script>
