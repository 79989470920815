<!-- 线性图 -->
  <template>
  <el-table :data="tableData"
    style="width: 100%">
    <el-table-column prop="dataList[0].dataList"
      label="日期"
      width="180">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: ['mapData'],
  data () {
    /* eslint-disable */
    return {
      tableData: [],
      key: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
  },
  watch: {
    mapData () {
      if (this.mapData) {
        let allDate = []
        let sex = []
        this.mapData.all.forEach(item => {
          this.key = [...this.key, ...Object.keys(item)]
        })
        let date = this.mapData.dataList
        let dateC = this.mapData.all[0][this.key[0]]
        let dataList = this.mapData.all
        date.map(item => {
          let flag = dateC.map(v => {
            return item
          })
          return flag
        }).forEach(item => {
          item.forEach(v => allDate.push(v))
        })
        date.forEach(item => {
          dateC.forEach((v, i) => {
            sex.push(v.legend)
          })
        })
        let tableTitle = this.key.map(v => [])
        date.forEach((item, index) => {
          dataList.forEach((v, i) => {
            v[this.key[i]].forEach(g => {
              tableTitle[i].push(g.value[index])
            })
          })
        })
        console.log(allDate);
        console.log(sex);
        console.log(tableTitle);
        let content = []
        let keys = this.key
        if (tableTitle.length === 1) {
          allDate.map((item, i) => {
            content.push({
              date: item,
              sex: sex[i],
              [keys[0]]: tableTitle[0][i]
            })
          })
        }
        if (tableTitle.length === 2) {
          allDate.map((item, i) => {
            content.push({
              date: item,
              sex: sex[i],
              [keys[0]]: tableTitle[0][i],
              [keys[1]]: tableTitle[1][i],
            })
          })
        }
        if (tableTitle.length === 3) {
          allDate.map((item, i) => {
            content.push({
              date: item,
              sex: sex[i],
              [keys[0]]: tableTitle[0][i],
              [keys[1]]: tableTitle[1][i],
              [keys[2]]: tableTitle[2][i],
            })
          })
        }
        if (tableTitle.length === 4) {
          allDate.map((item, i) => {
            content.push({
              date: item,
              sex: sex[i],
              [keys[0]]: tableTitle[0][i],
              [keys[1]]: tableTitle[1][i],
              [keys[2]]: tableTitle[2][i],
              [keys[3]]: tableTitle[3][i],
            })
          })
        }
        console.log(content);
      }
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>