import { get, post, upload } from '../Api/axios'
import {
  URL_OFFICIAL_SOP_MASSSENDALL_LIST,
  URL_OFFICIAL_SOP_MASSSENDALL_DELETE,
  URL_OFFICIAL_SOP_MEDIA_UPLOADNEWS,
  URL_OFFICIAL_SOP_MEDIA_UPLOAD_TEMP,
  URL_OFFICIAL_SOP_COVERUSERNUMS,
  URL_OFFICIAL_SOP_MASSSENDALL,
  URL_OFFICIAL_SOP_MASS_PREVIEW,
  URL_OFFICIAL_SOP_CHANNEL_LIST
} from '@/Api/api.js'

export function getMassSendList(params = {}) {
  return get(URL_OFFICIAL_SOP_MASSSENDALL_LIST, {
    ...params
  })
}

export function postMassSendDelte(params = {}) {
  return post(URL_OFFICIAL_SOP_MASSSENDALL_DELETE, {
    ...params
  })
}

export function postCoveruserNums(params = {}) {
  return post(URL_OFFICIAL_SOP_COVERUSERNUMS, {
    ...params
  })
}

export function uploadNews(params = {}) {
  return post(URL_OFFICIAL_SOP_MEDIA_UPLOADNEWS, {
    ...params
  })
}

export function uploadTemp(params = {}) {
  return upload(URL_OFFICIAL_SOP_MEDIA_UPLOAD_TEMP, {
    ...params
  })
}

export function postMassSendAll(params = {}) {
  return post(URL_OFFICIAL_SOP_MASSSENDALL, {
    ...params
  })
}

export function postMassPreview(params = {}) {
  return post(URL_OFFICIAL_SOP_MASS_PREVIEW, {
    ...params
  })
}

export function getChannelList(params = {}) {
  return get(URL_OFFICIAL_SOP_CHANNEL_LIST, {
    ...params
  })
}
