<template>
  <div class="personas">
    <div class="selectRow">
      <div>
        <el-select v-model="selectValue" placeholder="请选择" :clearable="false" @change="typeChange">
          <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-date-picker
          v-model="enddate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择日期"
          :clearable="false"
          @change="dateChange"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div>
        <el-select v-model="dateType" placeholder="请选择" :clearable="false" @change="dateTypeChange">
          <el-option
            v-for="item in selectdateTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>&nbsp;&nbsp; {{begindate}} 至 {{enddate}}</div>
    </div>
    <el-row :gutter="24">
      <el-col :span="12" v-for="(item, index) in data" :key="index">
        <div class="echatBox">
          <DoughnutChart :source="item"></DoughnutChart>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="echatBox">
          <MapChart :source="mapData" :key="Math.random()*100"></MapChart>
        </div>
      </el-col>
      <el-col :span="12">
      <div class="echatBox">
        <CityChart :source="cityData" :key="Math.random()"></CityChart>
      </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getUserportrait } from "@/service/minipro";
import moment from "moment";
// import { Message } from "element-ui";
import DoughnutChart from "./DoughnutChart";
import MapChart from "./MapChart";
import CityChart from "./CityChart";
export default {
  data() {
    return {
      selectValue: 1,
      selectOption: [
        {
          value: 1,
          label: "活跃用户数"
        },
        {
          value: 2,
          label: "新增用户数"
        }
      ],
      enddate: moment(new Date())
        .add(-1, "d")
        .format("YYYY-MM-DD"),
      begindate: moment(new Date())
        .add(-1, "d")
        .format("YYYY-MM-DD"),
      dateType: 1,
      selectdateTypeOption: [
        {
          value: 1,
          label: "近1天"
        },
        {
          value: 2,
          label: "近7天"
        },
        {
          value: 3,
          label: "近30天"
        }
      ],
      data: [],
      mapData: { title: "省级分布", id: "map", label: "省份", data: [] },
      cityData: {
        title: "地级分布",
        id: "city",
        label: "城市",
        data: [],
        province: []
      },
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) < new Date(v);
        }
      }
    };
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {},
  watch: {},
  methods: {
    init() {
      const obj = {
        begin_date: moment(this.begindate).format("YYYYMMDD"),
        end_date: moment(this.enddate).format("YYYYMMDD")
      };
      getUserportrait({ ...obj }).then(res => {
        if (res) {
          this.data = [];
          var datalist = [];
          this.mapData.data = [];
          if (this.selectValue === 1) {
            datalist = res.visit_uv;
            this.mapData.data = res.visit_uv.province;
            this.cityData.data = res.visit_uv.city;
            this.cityData.province = res.visit_uv.province;
          } else {
            datalist = res.visit_uv_new;
            this.mapData.data = res.visit_uv_new.province;
            this.cityData.data = res.visit_uv_new.city;
            this.cityData.province = res.visit_uv_new.province;
          }
          // 活跃用户数据
          for (var i in datalist) {
            switch (i) {
              case "ages":
                this.data.push({
                  title: "年龄分布",
                  id: i,
                  label: "年龄",
                  data: datalist[i]
                });
                break;
              case "platforms":
                this.data.push({
                  title: "终端分布",
                  id: i,
                  label: "终端",
                  data: datalist[i]
                });
                break;
              case "genders":
                this.data.push({
                  title: "性别分布",
                  id: i,
                  label: "性别",
                  data: datalist[i]
                });
                break;
              case "devices":
                this.data.push({
                  title: "机型分布",
                  id: i,
                  label: "机型",
                  data: datalist[i]
                });
                break;
              default:
                break;
            }
          }
        }
      });
    },
    dateChange(date) {
      this.enddate = moment(date).format("YYYY-MM-DD");
      this.dateTypeChange();
    },
    dateTypeChange() {
      switch (this.dateType) {
        case 1:
          this.begindate = this.enddate;
          break;
        case 2:
          this.begindate = moment(this.enddate)
            .add(-6, "d")
            .format("YYYY-MM-DD");
          break;
        case 3:
          this.begindate = moment(this.enddate)
            .add(-29, "d")
            .format("YYYY-MM-DD");
          break;
        default:
          break;
      }
      this.init();
    },
    typeChange() {
      this.init();
    }
  },
  components: {
    DoughnutChart,
    MapChart,
    CityChart
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
