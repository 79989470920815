<template>
  <div class="customer-page-list-wrapper">
    <Block title="客户属性筛选">
      <el-form ref="form" :model="form" size="medium" label-width="80px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="搜索客户">
              <el-input v-model="form.name" style="width: 250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="企业标签">
              <el-input
                style="width: 250px"
                v-model="form.enterprise"
                @focus="showDialog"
                :title="form.enterprise"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="添加时间">
              <el-date-picker
                v-model="form.addStartTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="添加渠道">
              <el-select
                v-model="form.addWay"
                placeholder="请选择"
                style="width: 250px"
              >
                <el-option
                  v-for="item in addWay"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="性别">
              <el-select
                v-model="form.gender"
                style="width: 250px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in gender"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="添加人">
              <el-select
                v-model="form.userId"
                style="width: 250px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in addUserList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="padding-left: 80px">
            <el-button size="medium" type="primary" @click="handleQuery"
              >查询</el-button
            >
            <el-button size="medium" @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </Block>
    <el-card class="block-wrapper user-list">
      <div slot="header" class="title displayFlex">
        <span>客户列表</span>
        <el-button
          type="primary"
          plain
          @click="addTag"
          :disabled="multipleSelection.length === 0"
          >批量打标签</el-button
        >
      </div>
      <slot></slot>
      <div class="user-total">
        <h2>共{{ total }}个客户</h2>
        <span @click="refreshData">
          <i class="el-icon-refresh-right"></i>
          更新数据
        </span>
      </div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="date" label="全部客户" width="210">
            <template slot-scope="scope">
              <div class="uesr-infos">
                <img :src="scope.row.picUrl" alt="" />

                <div class="text">
                  <div class="titles">
                    <p>{{ scope.row.name }}</p>
                    <span
                      :style="{
                        color:
                          scope.row.addType === '微信' ? ' #67C23A' : '#E6A23C',
                      }"
                      >@{{ scope.row.addType }}</span
                    >
                  </div>
                  <!-- <el-tag type="warning" size="mini">正常</el-tag> -->
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="所属客户" width="180">
          </el-table-column>
          <el-table-column prop="tag" label="标签">
            <template slot-scope="scope">
              <el-tag
                v-for="v in scope.row.tag.split(',')"
                :key="v"
                size="mini"
                >{{ v }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别"> </el-table-column>
          <el-table-column prop="delTime" label="客户状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="!scope.row.delTime">正常</el-tag>
              <el-tag type="info" v-else>已删除</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="添加时间"> </el-table-column>
          <el-table-column prop="addWay" label="添加渠道"> </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="goDetails(scope.row.userId, scope.row.externalUserid)"
                >详情</el-button
              >
              <el-button
                type="text"
                size="medium"
                @click="settingLabel(scope.row)"
                >标签设置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="选择标签" :visible.sync="dialogVisible" width="50%">
      <el-tree
        :data="treeData"
        show-checkbox
        class="label-tree"
        node-key="id"
        ref="tree"
        default-expand-all
        :default-checked-keys="defaultChecked"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="configLabel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 企业标签 -->
    <el-dialog title="选择企业标签" :visible.sync="dialogChooseVisible">
      <div>
        <el-radio v-model="relationship" label="or"
          >以下标签满足其中之一</el-radio
        >
        <el-radio v-model="relationship" label="and">以下标签同时满足</el-radio>
        <div
          v-for="(item, index) in treeData"
          :key="index"
          style="margin-top: 15px"
        >
          <span>{{ item.groupName }}:</span>
          <div
            style="margin-top: 5px; margin-bottom: 10px"
            class="tagSelectDialog"
          >
            <el-checkbox-group v-model="checkboxGroup" size="medium">
              <el-checkbox-button
                v-for="tag in item.tagList"
                :label="tag.tagName"
                :key="tag.tagId"
                >{{ tag.tagName }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSelect">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量打标签 -->
    <el-dialog title="批量打标签" :visible.sync="dialogAddVisible">
      <div>
        <div
          v-for="(item, index) in treeData"
          :key="index"
          style="margin-top: 15px"
        >
          <span>{{ item.groupName }}:</span>
          <div
            style="margin-top: 5px; margin-bottom: 10px"
            class="tagSelectDialog"
          >
            <el-input
              class="input-new-tag"
              v-if="item.inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm(item)"
              @blur="handleInputConfirm(item)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag addbtn"
              size="small"
              @click="showInput(item)"
              >+ 添加</el-button
            >
            <el-checkbox-group v-model="checkboxGroup" size="medium">
              <el-checkbox-button
                v-for="tag in item.tagList"
                :label="tag.tagName"
                :key="tag.tagId"
                >{{ tag.tagName }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleAddCancel">取 消</el-button>
        <el-button type="primary" @click="handleAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Block from '@/components/Block'
import {
  queryCustomerManagePageList,
  queryAddWay,
  queryAddUserList,
  updateCustomerLabel,
  refreshDataList
} from '@/service/customerManage'
import { getLableList, postMarktag, addLabel } from '@/service/label'
import { unid } from '@/utils'
import { Message } from 'element-ui'
export default {
  props: {

  },
  data () {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {
        name: '',
        addStartTime: [],
        enterprise: '',
        addWay: '',
        gender: '',
        userId: ''
      },
      gender: ['男', '女'],
      addWay: {},
      addUserList: [],
      tableData: [],
      dialogVisible: false,
      treeData: [],
      defaultChecked: [],
      currentRow: {},
      updateCursor: '',
      dialogChooseVisible: false,
      relationship: 'or',
      checkboxGroup: [],
      dialogAddVisible: false,
      inputVisible: false,
      inputValue: '',
      multipleSelection: []
    }
  },
  computed: {

  },
  created () {
    this.runList()
    this.addWayRun()
    this.addUserRun()
  },
  mounted () {

  },
  watch: {
  },
  methods: {
    runList (params) {
      queryCustomerManagePageList({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    addWayRun () {
      queryAddWay().then(res => {
        this.addWay = res
      })
    },
    addUserRun () {
      queryAddUserList().then(res => {
        this.addUserList = res
      })
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.runList({
        ...this.form,
        addStartTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        addEndTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        relationship: this.form.enterprise ? this.relationship : null
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.runList({
        ...this.form,
        addStartTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        addEndTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        relationship: this.form.enterprise ? this.relationship : null
      })
    },
    handleQuery () {
      this.runList({
        ...this.form,
        addStartTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        addEndTime: this.form.addStartTime[0] ? this.$moment(this.form.addStartTime[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        relationship: this.form.enterprise ? this.relationship : null
      })
    },
    handleReset () {
      this.runList()
      this.form = {
        name: '',
        addStartTime: [],
        enterprise: '',
        addWay: '',
        gender: '',
        userId: ''
      }
    },
    goDetails (userId, externalUserid) {
      this.$router.push(`/customer/userDetails?userId=${userId}&externalUserid=${externalUserid}`)
    },
    settingLabel (row) {
      const { tag } = row
      this.currentRow = row
      this.defaultChecked = tag.split(',')
      this.labelRun()
      this.dialogVisible = true
    },
    filterTree (result) {
      return result.map(item => {
        if (item.tagList) {
          return {
            id: item.groupName,
            label: item.groupName,
            children: this.filterTree(item.tagList)
          }
        }
        return {
          id: item.tagName,
          label: item.tagName
        }
      })
    },
    labelRun () {
      getLableList().then(res => (this.treeData = this.filterTree(res)))
    },
    configLabel () {
      updateCustomerLabel({
        userId: this.currentRow.userId,
        externalUserid: this.currentRow.externalUserid,
        changeStage: this.currentRow.tag,
        nowStage: this.$refs.tree.getCheckedNodes(true).map(v => v.label).join(',')
      }).then((res) => {
        this.runList()
        this.dialogVisible = false
      })
    },
    refreshData () {
      refreshDataList({
        cursor: this.updateCursor,
        limit: 100
      }).then((res) => {
        this.updateCursor = res
        this.runList()
        this.$message.success('刷新成功')
      })
    },
    showDialog () {
      getLableList().then(res => (this.treeData = res))
      this.checkboxGroup = []
      this.dialogChooseVisible = true
    },
    handleCancel () {
      this.dialogChooseVisible = false
      this.checkboxGroup = []
    },
    handleSelect () {
      this.form.enterprise = this.checkboxGroup.join(',')
      this.dialogChooseVisible = false
    },
    addTag () {
      getLableList().then(res => (this.treeData = res))
      this.dialogAddVisible = true
    },
    showInput (item) {
      this.treeData = this.treeData.map(v => {
        if (v.groupId === item.groupId) {
          v.inputVisible = true
          return v
        }
        v.inputVisible = false
        return v
      })
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    async handleInputConfirm (item) {
      var inputValue = this.inputValue
      if (inputValue) {
        await addLabel({
          group_id: item.groupId,
          group_name: item.groupName,
          tag: [{ name: this.inputValue, id: unid }]
        })
        await getLableList().then(res => (this.treeData = res))
      }
      this.treeData = this.treeData.map(v => {
        v.inputVisible = false
        return v
      })
      this.inputValue = ''
    },
    handleAddCancel () {
      this.dialogAddVisible = false
      this.inputValue = ''
      this.checkboxGroup = []
    },
    handleAdd () {
      var userIds = []
      this.multipleSelection.map(item => {
        userIds.push(item.externalUserid)
      })
      console.log('🚀 ~ file: index.vue ~ line 514 ~ handleAdd ~ this.multipleSelection', this.multipleSelection)
      var data = { newLabel: this.checkboxGroup.join(','), userIds: userIds }
      postMarktag(data).then(res => {
        Message.success('批量添加成功')
        this.runList()
        this.handleAddCancel()
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    }
  },
  components: {
    Block
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
