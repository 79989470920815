<template>
  <!-- 微信群 -->
  <div class="wechatFriends">
    <div class="nodeTitle">公众号事件筛选</div>
    <el-form-item>
      <el-select v-model="value" placeholder="请选择公众号事件">
        <el-option v-for="item in userOptions" :key="item.eventCode" :label="item.eventName" :value="item.eventCode"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      value: "",
      userOptions: [],
    };
  },
  mounted() {},
  created() {
    this.getList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    },
  },
  methods: {
    async getList() {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/automation/account/event/list"
      );
      if (data.code === 200) {
        this.userOptions = data.data
      }
    },
    save() {
      console.log("save -> this.node", this.value);
      if (this.value === "") {
        Message.warning("请选择公众号事件");
        return false;
      }
      return this.value;
    },
    init(val) {
      console.log("init -> val", val);
      this.value = val || "";
    },
  },
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
</style>
