<template>
  <div style="overflow:auto;height: 100%;">
    <div class="ef-node-form">
      <!-- <div class="ef-node-form-header" v-show="type === 'node'">节点编辑</div>
      <div class="ef-node-form-header" v-show="type === 'line'">删除连接线</div>-->
      <div class="ef-node-form-body">
        <el-form
          :model="node"
          ref="dataForm"
          label-width="80px"
          label-position="top"
          v-show="type === 'node'"
        >
          <!-- 开始节点条件  start-->
          <span v-show="this.node.type === 'start'">
            <StartFrom ref="startFrom" :editnode="this.node.startNode"></StartFrom>
          </span>
          <!-- 开始节点条件  end-->
          <!-- 条件选择条件  start-->
          <span v-show="this.node.type === 'judge'">
            <JudgeNode ref="JudgeNode" :editnode="this.node.judgeNode"></JudgeNode>
          </span>
          <!-- 条件选择条件  end-->
          <!-- 微信好友节点条件  start-->
          <span v-show="this.node.type === 'wechat'">
            <WechatFriends ref="wechatFriends" :editnode="this.node.wechatPersonSelect"></WechatFriends>
          </span>
          <!-- 微信好友节点条件  end-->
          <!-- 等待节点条件  start-->
          <span v-show="this.node.type === 'wait'">
            <div class="nodeTitle">延迟等待</div>
            <el-row class="disflexRow" style="padding-bottom:15px">
              <el-col :span="11">
                <el-form-item>
                  <el-input-number v-model="node.timeDelay" controls-position="right"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="2" class="scol">秒</el-col>
            </el-row>
          </span>
          <!-- 等待节点条件  end-->
          <!-- 公众号事件节点条件  start-->
          <span v-show="this.node.type === 'officialEvent'">
            <OfficialEvent ref="OfficialEvent" :editnode="this.node.accountEventNode"></OfficialEvent>
          </span>
          <!-- 公众号事件节点条件  end-->
          <!-- 公众号事件节点条件  start-->
          <span v-show="this.node.type === 'wechatEvent'">
            <div class="nodeTitle">微信事件筛选</div>
            <el-form-item>
              <el-select v-model="node.wechatEventNode" placeholder="请选择微信事件">
                <el-option label="添加客户" value="add_external_contact"></el-option>
                <el-option label="编辑客户" value="edit_external_contact"></el-option>
                <el-option label="删除客户" value="del_external_contact"></el-option>
                <el-option label="删除根据成员" value="del_follow_user"></el-option>
                <el-option label="客户接替失败" value="transfer_fail"></el-option>
              </el-select>
            </el-form-item>
          </span>
          <!-- 公众号事件节点条件  end-->
          <!-- 小程序事件节点条件  start-->
          <span v-show="this.node.type === 'miniproEvent'">
            <Minipro ref="Minipro" :editnode="this.node.miniproEventNode"></Minipro>
          </span>
          <!-- 小程序事件节点条件  end-->
          <!-- 微信群节点条件  start-->
          <span v-show="this.node.type === 'wechatGroup'">
            <WechatGroup ref="WechatGroup" :editnode="this.node.wechatGroupSelect"></WechatGroup>
          </span>
          <!-- 微信群节点条件  end-->
          <!-- 表单填写节点条件  start-->
          <span v-show="this.node.type === 'formEvent'">
            <FormEvent ref="FormEvent" :editnode="this.node.formEventNode"></FormEvent>
          </span>
          <!-- 表单填写节点条件  end-->
          <!-- 公众号粉丝节点条件  start-->
          <span v-show="this.node.type === 'officialFans'">
            <OfficialFans ref="OfficialFans" :editnode="this.node.accountPersonSelect"></OfficialFans>
          </span>
          <!-- 公众号粉丝节点条件  end-->
          <!-- 微信消息节点条件  start-->
          <span v-show="this.node.type === 'wechatMsg'">
            <WechatMsg ref="WechatMsg" :editnode="this.node.wechatPersonNode"></WechatMsg>
          </span>
          <!-- 微信消息节点条件  end-->
          <!-- 公众号消息节点条件  start-->
          <span v-show="this.node.type === 'officialMsg'">
            <OfficialMsg ref="OfficialMsg" :editnode="this.node.accountMessageNode"></OfficialMsg>
          </span>
          <!-- 公众号消息节点条件  end-->
          <!-- 公众号群消息节点条件  start-->
          <span v-show="this.node.type === 'accountGroup'">
            <AccountGroup ref="AccountGroup" :editnode="this.node.wechatGroupNode"></AccountGroup>
          </span>
          <!-- 公众号群消息节点条件  end-->
          <!-- 服务通知配置节点条件  start-->
          <span v-show="this.node.type === 'miniproMessage'">
            <MiniproMessage ref="MiniproMessage" :editnode="this.node.miniproMessageNode"></MiniproMessage>
          </span>
          <!-- 服务通知配置节点条件  end-->
          <!-- 公众号模板节点条件  start-->
          <span v-show="this.node.type === 'accountFormwork'">
            <AccountFormwork ref="AccountFormwork" :editnode="this.node.accountFormworkNode"></AccountFormwork>
          </span>
          <!-- 公众号模板节点条件  end-->
          <!-- 短消消息节点条件  start-->
          <span v-show="this.node.type === 'shortMessage'">
            <ShortMessage ref="ShortMessage" :editnode="this.node.shortMessageNode"></ShortMessage>
          </span>
          <!-- 短消消息节点条件  end-->
        </el-form>
        <!-- float: right -->
        <el-button
          v-show="type === 'node'"
          type="primary"
          style="margin-left: 50px"
          @click="save"
        >保存节点信息</el-button>
        <slot name="delete"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { cloneDeep } from "lodash";
import "./jsplumb";
import StartFrom from "./start_node_form";
import WechatFriends from "./wechatFriends_node_form";
import WechatMsg from "./wechatMsg_node_form";
import OfficialMsg from "./officialMsg_node_form";
import Minipro from "./minipro_node_form.vue";
import WechatGroup from "./wechat_group_node_form.vue";
import FormEvent from "./form_event_node_form.vue";
import OfficialFans from "./official_fans_node_form.vue";
import AccountGroup from "./wechatGroup_node_form.vue";
import MiniproMessage from "./miniproMessage_node_form.vue";
import AccountFormwork from "./accountFormwork_node_form.vue";
import ShortMessage from "./shortMessage_node_form.vue";
import OfficialEvent from "./officialEvent_node_form.vue";
import JudgeNode from "./judgeNode_node_form.vue";
import moment from "moment";
import { Message } from "element-ui";
export default {
  data() {
    return {
      visible: true,
      // node 或 line
      type: "node",
      node: {
        msgType: "定时任务",
        timeDelay: 0,
        accountEventNode: "",
        wechatEventNode: "",
      },
      line: {},
      data: {}
    };
  },
  mounted() {},
  methods: {
    /**
     * 表单修改，这里可以根据传入的ID进行业务信息获取
     * @param data
     * @param id
     */
    nodeInit(data, id) {
      console.log("nodeInit -> data", data);
      this.type = "node";
      this.data = data;
      data.nodeList.filter(node => {
        if (node.id === id) {
          // node.timeDelay = parseInt(node.timeDelayNode);
          let props = ''
            switch (node.nodeType) {
            case "startNode":
              props = "startNode";
              break;
            case "timeDelayNode":
              props = "timeDelay";
              break;
            case "wechatPersonSelect":
              props = "wechatPersonSelect";
              break;
            case "accountEventNode":
              props = "accountEventNode";
              break;
            case "wechatPersonNode":
              props = "wechatPersonNode";
              break;
            case "accountMessageNode":
              props = "accountMessageNode";
              break;
            case "wechatEventNode":
              props = "wechatEventNode";
              break;
            case "miniproEventNode":
              props = "miniproEventNode";
              break;
            case "wechatGroupSelect":
              props = "wechatGroupSelect";
              break;
            case "formEventNode":
              props = "formEventNode";
              break;
            case "accountPersonSelect":
              props = "accountPersonSelect";
              break;
            case "wechatGroupNode":
              props = "wechatGroupNode";
              break;
            case "miniproMessageNode":
              props = "miniproMessageNode";
              break;
            case "accountFormworkNode":
              props = "accountFormworkNode";
              break;
            case "shortMessageNode":
              props = "shortMessageNode";
              break;
            case "accountEventNode":
              props = "accountEventNode";
              break;
            case "judgeNode":
              props = "judgeNode";
              break;
            default:
              break;
          }
          if(node.data){
            this.$set(node,props,node.data)
          }
          this.node = cloneDeep(node);
        }
      });
    },
    lineInit(line) {
      this.type = "line";
      this.line = line;
    },
    // 修改连线
    saveLine() {
      this.$emit("setLineLabel", this.line.from, this.line.to, this.line.label);
    },
    save() {
      this.data.nodeList.filter(node => {
        if (node.id === this.node.id) {
          node.name = this.node.name;
          node.left = this.node.left;
          node.top = this.node.top;
          node.ico = this.node.ico;
          node.timeDelay = this.node.timeDelay;
          node.accountEventNode = this.node.accountEventNode;
          node.wechatEventNode = this.node.wechatEventNode;
          // 等待节点
          if (node.type === "wait") {
            if (node.timeDelay !== 0) {
              node.data = node.timeDelay
              Message.success("节点信息保存成功");
            } else {
              Message.warning("请输入等待时间");
            }
          }
          // 公众号事件节点
          if (node.type === "officialEvent") {
            node.data = this.$refs.OfficialEvent.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
           
          }
          // 微信事件节点
          if (node.type === "wechatEvent") {
            if (!node.wechatEventNode) {
              Message.warning("请选择微信事件");
            } else {
              node.data = node.wechatEventNode
              Message.success("节点信息保存成功");
            }
          }
          // 开始节点数据处理
          if (node.type === "start") {
            let startNode = { msgType: this.$refs.startFrom.node.msgType };
            if (this.$refs.startFrom.node.msgType === "定时任务") {
              if (this.$refs.startFrom.typeTime === "一次性任务") {
                startNode.regularTime =
                  moment(this.$refs.startFrom.node.date).format("YYYY-MM-DD") +
                  " " +
                  this.$refs.startFrom.node.time;
              } else {
                let dateList = [];
                dateList.push(
                  moment(this.$refs.startFrom.node.date[0]).format(
                    "YYYY-MM-DD"
                  ),
                  moment(this.$refs.startFrom.node.date[1]).format(
                    "YYYY-MM-DD"
                  ),
                  this.$refs.startFrom.node.time
                );
                startNode.rangeTime = dateList.join(";");
              }
            }
            node.data = startNode;
            node.startNode = startNode;
            Message.success("节点信息保存成功");
          }
          // 微信好友
          if (node.type === "wechat") {
            let data = this.$refs.wechatFriends.data;
            if (this.$refs.wechatFriends.data.addTime) {
              let addTimeRangeList = [];
              addTimeRangeList.push(
                moment(this.$refs.wechatFriends.data.addTime[0]).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                moment(this.$refs.wechatFriends.data.addTime[1]).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              );
              data.addTimeRange = addTimeRangeList.join(";");
            }
            node.data = data;
            Message.success("节点信息保存成功");
          }
          // 条件选择
          if (node.type === "judge") {
            node.data = this.$refs.JudgeNode.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          // 微信消息
          if (node.type === "wechatMsg") {
            node.data = this.$refs.WechatMsg.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          // 小程序事件
          if (node.type === "miniproEvent") {
            node.data = this.$refs.Minipro.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          // 微信群
          if (node.type === "wechatGroup") {
            node.data = this.$refs.WechatGroup.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          // 表单填写
          if (node.type === "formEvent") {
            node.data = this.$refs.FormEvent.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          // 公众号粉丝
          if (node.type === "officialFans") {
            node.data = this.$refs.OfficialFans.save();
            if (node.data) {
              Message.success("节点信息保存成功");
            }
          }
          
          // 公众号消息
          if (node.type === "officialMsg") {
            let data = this.$refs.OfficialMsg.save();
            node.data = data;
            if (data) {
              this.$refs.OfficialMsg.closeTab();
              Message.success("节点信息保存成功");
            }
          }

          // 公众号群消息
          if (node.type === "accountGroup") {
            let data = this.$refs.AccountGroup.save();
            node.data = data;
            if (data) {
              Message.success("节点信息保存成功");
            }
          }
          // 消息通知消息
          if (node.type === "miniproMessage") {
            let data = this.$refs.MiniproMessage.save();
            node.data = data;
            if (data) {
              Message.success("节点信息保存成功");
            }
          }
          // 公众号模板
          if (node.type === "accountFormwork") {
            let data = this.$refs.AccountFormwork.save();
            node.data = data;
            if (data) {
              Message.success("节点信息保存成功");
            }
          }
          // 短信
          if (node.type === "shortMessage") {
            let data = this.$refs.ShortMessage.save();
            node.data = data;
            if (data) {
              Message.success("节点信息保存成功");
            }
          }
          
          this.$emit("repaintEverything");
        }
      });
      window.localStorage.setItem(
        "nodeList",
        JSON.stringify(this.data.nodeList)
      );
    },
    // 删除激活的元素
    deleteElement() {
      if (this.activeElement.type === "node") {
        this.deleteNode(this.activeElement.nodeId);
      } else if (this.activeElement.type === "line") {
        this.$confirm("确定删除所点击的线吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            var conn = this.jsPlumb.getConnections({
              source: this.activeElement.sourceId,
              target: this.activeElement.targetId
            })[0];
            this.jsPlumb.deleteConnection(conn);
          })
          .catch(() => {});
      }
    }
  },
  components: {
    StartFrom,
    WechatFriends,
    WechatMsg,
    OfficialMsg,
    Minipro,
    WechatGroup,
    FormEvent,
    OfficialFans,
    AccountGroup,
    MiniproMessage,
    AccountFormwork,
    ShortMessage,
    OfficialEvent,
    JudgeNode
  }
};
</script>

<style lang="less" scoped>
.disflexRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.scol {
  margin-bottom: 11px;
  padding-left: 10px;
}
.el-node-form-tag {
  position: absolute;
  top: 50%;
  margin-left: -15px;
  height: 40px;
  width: 15px;
  background-color: #fbfbfb;
  border: 1px solid rgb(220, 227, 232);
  border-right: none;
  z-index: 0;
}
.group-analysis {
  text-align: left;
  padding: 12px 0;
  .user-prop-title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .add-all {
    background-color: #00bf8a;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    margin-right: 3px;
    cursor: pointer;
  }
  .delete-all {
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #000;
  }
  .add_rows {
    font-size: 14px;
    cursor: pointer;
  }
  .date_picker {
    &.el-input__inner {
      width: auto;
    }
  }

  .index {
    width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.input_num {
  margin-right: 5px;
}
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left !important;
}
.leveltwo-option-header {
  font-size: 12px !important;
  padding: 5px 0 !important;
  background-color: #f5f7fa;
  color: #888;
}
.relationship {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #38d474;
  .relationship-bar {
    height: 100%;
    width: 2px;
    background-color: rgba(53, 219, 171, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
  .relationship-text {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    background-color: #d6efe8;
    cursor: pointer;
  }
}
.option-search {
  .el-input__inner {
    border: 0;
    border-bottom: 1px solid #888;
  }
}
.closeBtn {
  position: absolute;
  left: 90%;
  top: 35%;
  bottom: 0;
}
.el-select-dropdown__list {
  padding: 10px;
}
.ef-node-form {
  .el-form-item {
    margin-bottom: 11px !important;
  }
}
.dateStyle {
  width: 200px !important;
}
.el-input-number {
  width: auto !important;
}
</style>
