<template>
  <div class="customer-stage-notset">
    <el-dialog
      title="未设置客户"
      :visible.sync="visible"
      width="60%"
      @close="hanldeClose"
    >
      <div v-show="step == 1">
        <el-table
          :data="tableData"
          ref="multipleTable"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            property="name"
            label="未设置客户"
            width="170"
          ></el-table-column>
          <el-table-column
            property="user_name"
            label="所属员工"
          ></el-table-column>
          <el-table-column prop="tag" label="标签" width="200">
            <template slot-scope="scope">
              <el-tag
                v-for="v in scope.row.tag.split(',')"
                :key="v"
                size="mini"
                >{{ v }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            property="add_time"
            label="添加时间"
            width="170"
          ></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          background
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
      <div v-show="step == 2">
        <span class="selectTitle">选择客户状态</span>
        <el-select v-model="value" clearable placeholder="请选择">
          <el-option
            v-for="item in stagList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <div class="listTitle">已选择{{ selectData.length }}客户</div>
        <div class="listBox">
          <li v-for="item in selectData" :key="item.external_userid">
            <img :src="item.pic_url" />
            <span>{{ item.name }}</span>
          </li>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-show="step == 1">
        <el-button @click="hanldeClose">取 消</el-button>
        <el-button type="primary" @click="setCustomerStatus"
          >设置客户状态</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-show="step == 2">
        <el-button @click="back">上一步</el-button>
        <el-button type="primary" @click="doneCustomerStatus">完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCustomerStageNotset, updateCustomerStage } from '@/service/customerManage'
import {
  Message
} from 'element-ui'
export default {
  name: 'notset',
  props: ['dialogTableVisible'],
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      visible: false,
      step: 1,
      stagList: ['初步沟通', '新加客户', '意向客户', '即将成交', '已付款'],
      selectData: [],
      value: null
    }
  },

  computed: {

  },
  created() {
    this.runList()
  },
  mounted() {

  },
  watch: {
    dialogTableVisible(newValue, oldValue) {
      this.visible = newValue
    }
  },
  methods: {
    // 列表模块
    runList() {
      getCustomerStageNotset({
        pageno: this.pageNo, pagesize: this.pageSize
      }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.selectData = val
    },
    // 设置客户状态
    setCustomerStatus() {
      if (this.selectData.length === 0) {
        Message.warning('请至少选择一个客户')
        return
      }
      this.step = 2
    },
    hanldeClose(type) {
      this.step = 1
      this.selectData = []
      this.$refs.multipleTable.clearSelection()
      this.value = null
      this.$emit('close-dialogStatus', !!type)
    },
    back() {
      this.step = 1
    },
    doneCustomerStatus() {
      if (this.value === null) {
        Message.warning('请选择客户状态')
        return
      }
      this.selectData.map(item => {
        var obj = {
          type: '1',
          userId: null,
          externalUserid: null,
          tag: null,
          nowStage: '',
          changeStage: ''
        }
        obj.userId = item.user_id
        obj.tag = item.tag.split(',').filter(v => v !== item.now_stage).join(',')
        obj.externalUserid = item.external_userid
        obj.changeStage = this.value
        obj.nowStage = item.now_stage
        obj.type = '1'
        updateCustomerStage(obj).then(res => {

        })
      })
      Message.success('设置完成')
      this.hanldeClose(true)
    }

  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
