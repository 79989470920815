<template>
  <div class="New-Sop-List">
    <div v-show="!detailsShow">
      <div class="topBox">
        <el-button type="primary" @click="goDetails(true, null)"
          >创建规则</el-button
        >
        <el-input
          placeholder="输入要搜索的名称"
          v-model="introduction"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="
              getGroupSopList(introduction, pageNo, pageSize, this.sopType)
            "
          ></el-button>
        </el-input>
      </div>
      <!-- :class="item.state == 0 ? '' : 'disable'" -->
      <el-card v-for="(item, index) in tableData" :key="index">
        <div class="box">
          <div class="mainlist">
            <p>
              添加好友第
              <span class="textColor">&nbsp;{{ item.timeFromNow }}&nbsp;</span>
              天 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="textColor">{{ item.everydayTime }}</span
              >&nbsp;&nbsp; 发送
            </p>
            <p class="msgDetail">
              执行员工: <span class="textColor">{{ item.responsibleName }}</span>
            </p>
            <p>
              推送内容: <span class="textColor">{{ item.msgDetail }}</span>
            </p>
          </div>
          <div class="btnlist">
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="delListItem(item.id)"
              >删除</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="goDetails(true, item)"
              >编辑</el-button
            >
          </div>
        </div>
      </el-card>
      <el-empty :image-size="200" v-show="tableData.length == 0"></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <Details
      v-show="detailsShow"
      @visabledetails="visabledetails"
      :dataDetail="dataDetail"
    ></Details>
  </div>
</template>

<script>
import {
  getPersonalListAndDetails,
  removeSop
} from '@/service/group'
import { Message } from 'element-ui'
import Details from '@/views/Sop/NewDetail'
export default {
  props: ['sopType'],
  data() {
    return {
      introduction: null,
      pageNo: 1,
      pageSize: 5,
      tableData: [],
      detailsShow: false,
      dataDetail: {},
      total: 0
    }
  },
  computed: {},
  created() {
    this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
  },
  mounted() { },
  watch: {
    sopType(val) {
      this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, val)
    }
  },
  methods: {
    getGroupSopList(introduction, pageNo, pageSize, sopType) {
      getPersonalListAndDetails({ introduction, pageNo, pageSize, sopType }).then(
        res => {
          this.tableData = res.info
          this.total = res.num
          this.tableData &&
            this.tableData.map(item => {
              var t_ = ''
              t_ = item.timeDesc.split('日')
              item.time = t_[1] && t_[1].substring(0, t_[1].length - 2)
              item.date = this.DateDiffer(t_[0])
            })
        }
      )
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getGroupSopList(this.introduction, 1, val, this.sopType)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getGroupSopList(this.introduction, val, this.pageSize, this.sopType)
    },
    DateDiffer(end) {
      // date1结束时间
      let date1 = new Date(end)
      // date2当前时间
      let date2 = new Date()
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
      const diff = date1.getTime() - date2.getTime() // 目标时间减去当前时间
      const diffDate = diff / (24 * 60 * 60 * 1000) // 计算当前时间与结束时间之间相差天数
      return diffDate
    },
    goDetails(vis, item) {
      this.detailsShow = vis
      this.dataDetail = item
    },
    visabledetails(visable) {
      this.detailsShow = visable
      if (!visable) {
        this.dataDetail = {}
        this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
      }
    },
    delListItem(id) {
      removeSop(id).then(res => {
        this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
        Message.success('删除成功')
      })
    }
  },
  components: {
    Details
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
