<template>
  <div class="flow-menu" ref="tool">
    <div v-for="menu in menuList" :key="menu.id">
      <span class="ef-node-pmenu" @click="menu.open = !menu.open"
        ><i
          :class="{
            'el-icon-caret-bottom': menu.open,
            'el-icon-caret-right': !menu.open,
          }"
        ></i
        >&nbsp;{{ menu.name }}</span
      >
      <ul v-show="menu.open" class="ef-node-menu-ul">
        <draggable
          @end="end"
          @start="move"
          v-model="menu.children"
          :options="draggableOptions"
        >
          <li
            v-for="subMenu in menu.children"
            class="ef-node-menu-li"
            :key="subMenu.id"
            :type="subMenu.type"
            :style="subMenu.style"
          >
            <i :class="subMenu.ico"></i> {{ subMenu.name }}
          </li>
        </draggable>
      </ul>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
/* eslint-disable */
var mousePosition = {
  left: -1,
  top: -1
}

export default {
  data() {
    return {
      activeNames: '1',
      // draggable配置参数参考 https://www.cnblogs.com/weixin186/p/10108679.html
      draggableOptions: {
        preventOnFilter: false,
        sort: false,
        disabled: false,
        ghostClass: 'tt',
        // 不使用H5原生的配置
        forceFallback: true,
        // 拖拽的时候样式
        // fallbackClass: 'flow-node-draggable'
      },
      // 默认打开的左侧菜单的id
      defaultOpeneds: ['1', '2'],
      menuList: [
        {
          id: '1',
          type: 'group',
          name: '用户判断',
          ico: 'el-icon-connection',
          open: true,
          children: [
            {
              id: '11',
              type: 'havoir',
              name: '行为判断',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:'#b2d2ea'
              }
            }, {
              id: '12',
              type: 'attribute',
              name: '属性判断',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:'#fac3a6'
              }
            }
          ]
        },
        {
          id: '2',
          type: 'group',
          name: '运营工具',
        //   ico: 'el-icon-connection',
          open: true,
          children: [
            {
              id: '21',
              type: 'blacklist',
              name: '黑名单过滤',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:"#ffe295"
              }
            }, {
              id: '22',
              type: 'wait',
              name: '等待',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:"#ddeed5"
              }
            }
          ]
        },
        {
          id: '3',
          type: 'group',
          name: '投放渠道',
          ico: 'el-icon-connection',
          open: true,
          children: [
            {
              id: '31',
              type: 'outbound',
              name: '外呼',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:"#d4d4d4"
              }
            }, {
              id: '32',
              type: 'push',
              name: 'PUSH',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
              style: {
                  background:"#d4d4d4"
              }
            },
            {
              id: '33',
              type: 'position',
              name: '运营位',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
               style: {
                  background:"#d4d4d4"
              }
            }, {
              id: '34',
              type: 'popup',
              name: '弹窗',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
                style: {
                  background:"#d4d4d4"
              }
            }, {
              id: '35',
              type: 'message',
              name: '短信',
            //   ico: 'el-icon-connection',
              // 自定义覆盖样式
               style: {
                  background:"#d4d4d4"
              }
            }
          ]
        }
      ],
      nodeMenu: {}
    }
  },
  components: {
    draggable
  },
  created() {
    /**
     * 以下是为了解决在火狐浏览器上推拽时弹出tab页到搜索问题
     * @param event
     */
    if (this.isFirefox()) {
      document.body.ondrop = function (event) {
        // 解决火狐浏览器无法获取鼠标拖拽结束的坐标问题
        mousePosition.left = event.layerX
        mousePosition.top = event.clientY - 50
        event.preventDefault();
        event.stopPropagation();
      }
    }
  },
  methods: {
    // 根据类型获取左侧菜单对象
    getMenuByType(type) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].type === type) {
            return children[j]
          }
        }
      }
    },
    // 拖拽开始时触发
    move(evt, a, b, c) {
      var type = evt.item.attributes.type.nodeValue
      this.nodeMenu = this.getMenuByType(type)
    },
    // 拖拽结束时触发
    end(evt, e) {
      this.$emit('addNode', evt, this.nodeMenu, mousePosition)
    },
    // 是否是火狐浏览器
    isFirefox() {
      var userAgent = navigator.userAgent
      if (userAgent.indexOf("Firefox") > -1) {
        return true
      }
      return false
    }
  }
}
</script>
