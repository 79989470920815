<template>
  <div class="add-sutff-code">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="类型">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">单人</el-radio>
          <el-radio :label="2">多人</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="使用员工" prop="sutff">
        <el-button type="primary" plain @click="dialogSuffVisible = true">
          <i class="el-icon-plus el-icon--right"></i> 添加
        </el-button>
        <div class="formTag" v-if="sutffName!==null">
        <el-tag size="small">{{sutffName}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="添加设置">
        <el-checkbox
          v-model="form.skip_verify"
          label="客户添加时无需经过确认自动成为好友"
          name="type"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="扫码标签" prop="tags">
        <el-button type="primary" plain @click="dialogTagsVisible = true">
          <i class="el-icon-plus el-icon--right"></i> 添加标签
        </el-button>
        <div class="formTag">
        <el-tag size="small" v-for="tag in form.tags" :key="tag">{{tag}}</el-tag>
        </div>
        <span class="tips"
          >根据使用场景做标签记录，扫码添加的客户，可自动打上标签</span
        >
      </el-form-item>
      <el-form-item label="活动场景" prop="state">
        <el-input
          v-model="form.state"
          maxlength="30"
          show-word-limit
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="欢迎语" class="welcome" prop="welcome">
        <div class="welcomeBox">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.conclusions.text.content"
            maxlength="220"
            show-word-limit
          >
          </el-input>
        </div>
        <el-upload
          action="#"
          ref="upload"
          list-type="picture-card"
          :auto-upload="true"
          :limit="1"
          :on-exceed="handleExceed"
          accept=".jpg,.jpeg,.png,.gif"
          :http-request="uploadPic"
          :before-upload="beforeAvatarUpload"
        >
          <el-button type="primary" class="uploadBtn">
            <i class="el-icon-plus"></i>
            上传图片
          </el-button>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <span class="tips"
          >设置个性化欢迎语，扫描该员工活码添加的客户，将自动推送该欢迎语</span
        >
      </el-form-item>
      <el-form-item class="bottomBtn">
        <el-button type="primary" @click="onSubmit" :disabled='picloading'>保存</el-button>
        <el-button @click="goListPage">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 添加使用人员弹窗 -->
    <el-dialog
      title="选择使用员工"
      :visible.sync="dialogSuffVisible"
      width="50%"
    >
      <el-checkbox-group v-model="form.user" @change="changeType">
        <el-checkbox
          v-for="(item, index) in useUserList"
          :key="item.userId + index"
          :label="item.userId"
        >
          {{ item.userName }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSutffLabel">取 消</el-button>
        <el-button type="primary" @click="getSutffName"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加标签弹窗 @change="changeType" -->
    <el-dialog
      title="选择使用员工"
      :visible.sync="dialogTagsVisible"
      width="50%"
      class="tagDialog"
    >
      <div v-for="(item, index) in tagsList" :key="index">
        <div>{{ item.groupName }}</div>
        <el-checkbox-group v-model="form.tags">
          <el-checkbox
            v-for="i in item.tagList"
            :key="i.tagId"
            :label="i.tagName"
          >
            {{ i.tagName }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTagsLabel">取 消</el-button>
        <el-button type="primary" @click="dialogTagsVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 微信预览样式 -->
    <div v-if="form.conclusions.text.content !== '' || imageUrl !== ''">
      <div class="wxchatBorderRightMid">
        <div style="margin-left: 30px; margin-top: 15px">
          <div align="center" style="padding: 15px 0">
            <el-tag type="info" size="mini">凌晨 2:20</el-tag>
          </div>
          <el-row v-if="form.conclusions.text.content !== ''">
            <el-col :span="2">
              <el-avatar
                shape="square"
                :src="squareUrl"
                style="width: 35px; height: 35px"
              ></el-avatar>
            </el-col>
            <el-col :span="20">
              <div class="chatPop1">
                <span style="line-height: 23px">{{
                  form.conclusions.text.content
                }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="imageUrl !== ''">
            <el-col :span="2">
              <el-avatar
                shape="square"
                :src="squareUrl"
                style="width: 35px; height: 35px"
              ></el-avatar>
            </el-col>
            <el-col :span="20">
              <div class="chatPop1">
                <span style="line-height: 23px"
                  ><img style="width: 100%" :src="imageUrl"
                /></span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUseUserList,
  uploadFile,
  getLabelList,
  postAddContactWay
} from '@/service/drainage'
// import qs from 'qs'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      form: {
        type: 1,
        scene: 2,
        skip_verify: true,
        state: '',
        user: [],
        is_temp: false,
        conclusions: {
          image: {
            media_id: ''
          },
          text: {
            content: ''
          }
        },
        tags: []
      },
      useUserList: [],
      tagsList: [],
      disabled: false,
      squareUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      imageUrl: '',
      upload: {
        file: null,
        type: 'image',
        introduction: '员工活码图片'
      },
      dialogSuffVisible: false,
      dialogTagsVisible: false,
      sutffName: null,
      picloading: false
    }
  },
  computed: {

  },
  created() {
    this.getUseUserList()
    this.getLabelList()
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    // 获取使用人员
    getUseUserList() {
      getUseUserList().then(res => {
        this.useUserList = res
      })
    },
    // 获取标签列表
    getLabelList() {
      getLabelList().then(res => {
        this.tagsList = res
      })
    },
    onSubmit() {
      if (this.form.user.length === 0) {
        Message.warning('请选择使用员工')
        return false
      }
      if (this.form.tags.length === 0) {
        Message.warning('请添加标签')
        return false
      }
      if (this.form.state === '') {
        Message.warning('请输入活动场景')
        return false
      }
      if (this.form.conclusions.text.content === '') {
        Message.warning('请输入欢迎语')
        return false
      }
      postAddContactWay({ ...this.form }).then(res => {
        Message.success('保存成功')
        setTimeout(() => {
             this.goListPage()
        }, 1000)
      })
    },
    goListPage() {
      this.$router.push('/drainage/activitycode')
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles()
      this.imageUrl = ''
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    async uploadPic(val) {
      this.imageUrl = await this.getBase64(val.file)
      this.upload.file = val.file
      this.picloading = true
      uploadFile({ ...this.upload }).then((res) => {
        this.form.conclusions.image.media_id = res
        this.picloading = false
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    // 添加使用人员
    closeSutffLabel() {
      this.dialogSuffVisible = false
      this.form.user = []
    },
    changeType(val) {
      if (this.form.user.length > 1) {
        this.form.user.splice(0, 1)
      }
    },
    // 添加标签
    closeTagsLabel() {
      this.dialogTagsVisible = false
      this.form.tags = []
    },
    getSutffName() {
      this.useUserList.map(item => {
            if (item.userId === this.form.user[0]) {
                     this.sutffName = item.userName
            }
      })
      this.dialogSuffVisible = false
    }
  },
  components: {

  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
