import { get, post } from '../Api/axios'
import {
  URL_OFFICIAL_ACCOUNT_TAGSGET,
  URL_OFFICIAL_ACCOUNT_TAGSCREATE,
  URL_OFFICIAL_ACCOUNT_TAGSEDIT,
  URL_OFFICIAL_ACCOUNT_TAGESDEL,
  URL_OFFICIAL_ACCOUNT_USERLIST,
  URL_CUSTOMER_ACCOUNT_GETUSERLIST,
  URL_CUSTOMER_ACCOUNT_BATCHTAG,
  URL_CUSTOMER_ACCOUNT_BATCHUNTAG
} from '@/Api/api.js'

export function getTagsList (params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_TAGSGET, {
    ...params
  })
}

export function batchuntagging (params = {}) {
  return post(URL_CUSTOMER_ACCOUNT_BATCHUNTAG, {
    ...params
  })
}
export function batchtagging (params = {}) {
  return post(URL_CUSTOMER_ACCOUNT_BATCHTAG, {
    ...params
  })
}
export function postTagsCreate (params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_TAGSCREATE, {
    ...params
  })
}

export function postTagsEdit (params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_TAGSEDIT, {
    ...params
  })
}

export function postTagsDel (params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_TAGESDEL, {
    ...params
  })
}

export function postUserList (params = {}) {
  return post(URL_OFFICIAL_ACCOUNT_USERLIST, {
    ...params
  })
}

// URL_CUSTOMER_ACCOUNT_GETUSERLIST
export function getFanList (params = {}) {
  return get(URL_CUSTOMER_ACCOUNT_GETUSERLIST, {
    ...params
  })
}
