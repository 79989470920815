<template>
  <div class="form">
    <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="表单名称" prop="formName">
        <el-input v-model="ruleForm.formName"></el-input>
      </el-form-item>
      <el-form-item label="表单描述" prop="formIntroduction">
        <el-input v-model="ruleForm.formIntroduction"></el-input>
      </el-form-item>
      <el-form-item label="H5页面名称" prop="h5FormName">
        <el-input v-model="ruleForm.h5FormName"></el-input>
      </el-form-item>
      <!-- <el-form-item label="自动打标签" prop="isLabel">
        <el-input v-model="ruleForm.isLabel"></el-input>
      </el-form-item> -->
      <el-form-item label="失效时间">
        <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="ruleForm.untilDate" style="width: 100%;"></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script >
export default {
  props: {
    form: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      ruleForm: {
        formName: '', untilDate: '', formIntroduction: '', h5FormName: '' //, isLabel: ''
      },
      rules: {
        formName: [
          { required: true, message: '请输入表单名称', trigger: 'blur' }
        ],
        formIntroduction: [
          { required: true, message: '请输入表单描述', trigger: 'blur' }
        ],
        h5FormName: [
          { required: true, message: '请输入H5页面名称', trigger: 'blur' }
        ]
      }
    };
  },
  watch: {
    form: {
      handler (val) {
        this.ruleForm.untilDate = val.untilDate || ''
        this.ruleForm.formName = val.formName || ''
        this.ruleForm.formIntroduction = val.formIntroduction || ''
        this.ruleForm.h5FormName = val.h5FormName || ''
        // this.ruleForm.isLabel = val.isLabel || ''
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submitForm () {
      let data = null
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          data = this.ruleForm
        }
      });
      return data
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  height: 100%;
  overflow: auto;
}
</style>
