<template>
  <div class="smstask">
    <el-button type="primary"
      class="smstask__text"
      @click="addSmsTemplate(null,'add')">创建群发任务</el-button>
    <el-table :data="tableData"
      :default-sort="{prop: 'date', order: 'descending'}">
      <el-table-column prop="id" width="70px"
        label="任务ID">
      </el-table-column>
      <el-table-column prop="templateName" width="160px"
        label="模板名称">
      </el-table-column>
      <el-table-column prop="templateContent"
        label="模板内容">
      </el-table-column>
      <el-table-column prop="smsType" width="120px"
        label="短信类型">
      </el-table-column>
      <el-table-column prop="missionName" width="120px"
        label="任务名称">
      </el-table-column>
      <el-table-column prop="sendTime" width="160px"
        label="发送时间">
      </el-table-column>
      <el-table-column prop="num"  width="120px"
        label="发送用户数">
      </el-table-column>
      <el-table-column label="状态" width="140px">
        <template slot-scope="scope">
          <span style="margin-left: 10px;"
            v-if="scope.row.status==='0'">未发送</span>
          <span style="margin-left: 10px;color:#67C23A"
            v-else>发送完毕<i class="el-icon-success" /></span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 40, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
      class="smstask__pagination">
    </el-pagination>
    <Pop :dialogFormVisible="dialogFormVisible"
      @closeSmsTemplate="closeSmsTemplate"
      @getTextPageList="getTextPageList"
      :editList="editList" />
  </div>
</template>

<script>
import { getSmsTaskist, delSmsTemplate } from '@/service/sms'
import Pop from './components/addSmsTask.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pop
  },
  data () {
    return {
      name: '',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dialogFormVisible: false,
      editList: {},
      currentText: {
      }
    }
  },
  computed: {

  },
  created () {
    this.getTextPageList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    addSmsTemplate (row = {}, type) {
      if (type === 'add') {
        this.editList = {}
      } else {
        this.editList = row
      }
      this.dialogFormVisible = true
    },
    closeSmsTemplate () {
      this.dialogFormVisible = false
    },
    getTextPageList () {
      getSmsTaskist({ pageno: this.pageNo, pagesize: this.pageSize }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getTextPageList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getTextPageList()
    },
    handleRemove (id) {
      const obj = {
        template_id: id
      }
      delSmsTemplate(obj).then(() => {
        this.getTextPageList()
        this.$message.success('删除成功')
      })
    }
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
