<template >
  <div class="channel-code">
    <el-container>
      <el-header class="groupheader">渠道二维码</el-header>
      <div class="topMain">
        <div class="top">
          <span>实时概况</span>
          <span>
            数据更新于
            <span class="date">{{ updateDate }}</span>
            <i class="el-icon-refresh" @click="getQRCodeCalculate"></i>
          </span>
        </div>
        <div class="center">
          <div class="box">
            <span>今日扫码人次</span>
            <span>{{ data.todayAllNum }}</span>
            <span>昨日：{{ data.yesterdayAllNum }}</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="box">
            <span>今日扫码人数</span>
            <span>{{ data.todayUserNum }}</span>
            <span>昨日：{{ data.yesterdayUserNum }}</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="box">
            <span>今日扫码新用户</span>
            <span>{{ data.todayNewScan }}</span>
            <span>昨日：{{ data.yesterdayNewScan }}</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="box">
            <span>今日扫码老用户</span>
            <span>{{ data.todayOldScan }}</span>
            <span>昨日：{{ data.yesterdayOldScan }}</span>
          </div>
        </div>
      </div>
      <div class="replytop">
        <el-input
          placeholder="搜索渠道名称"
          prefix-icon="el-icon-search"
          v-model="name"
          @focus="getPageList"
          :clearable="true"
          @clear="getPageList"
          @change="getPageList"
        >
        </el-input>
        <el-button class="topBtn" type="primary" @click="addGroupWel()">
          <i class="el-icon-plus"></i>
          新建二维码
        </el-button>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#F9F9F9' }"
      >
        <el-table-column prop="channel_name" label="渠道名称" align="center">
        </el-table-column>
        <el-table-column prop="channel_code" label="渠道code"  align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="160" align="center">
        </el-table-column>
        <el-table-column prop="util_time" label="失效时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="scan_num" label="扫码人数" width="100" align="center">
        </el-table-column>
        <el-table-column
          prop="new_subscribe_num"
          label="新增关注人数"
          width="110"
        >
        </el-table-column>
        <el-table-column prop="url_useful" label="二维码" width="180" align="center">
          <template slot-scope="scope">
            <div class="uesr-infos">
              <el-image :src="scope.row.url_useful" alt="" @click="handleDownloadCode(scope.row)"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="deleteItem(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
       <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-size="10"
        layout="prev, pager, next"
        background
        :total="total"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </el-container>
  </div>
</template>

<script>
import { getQRCodeCalculate, getQrcodeList, getQRCodeDelete } from '@/service/code'
import moment from 'moment'
import qs from 'qs'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      name: '',
      data: {
        todayAllNum: 0,
        todayUserNum: 0,
        todayNewScan: 0,
        todayOldScan: 0,
        yesterdayAllNum: 0,
        yesterdayUserNum: 0,
        yesterdayNewScan: 0,
        yesterdayOldScan: 0
      },
      updateDate: moment().format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {

  },
  created() {
    this.getQRCodeCalculate()
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
      getQrcodeList({ pageNo: this.pageNo, pageSize: this.pageSize, name: this.name }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    getQRCodeCalculate() {
      getQRCodeCalculate().then(res => {
        this.data = res
        this.updateDate = moment().format('YYYY-MM-DD HH:mm:ss')
      })
    },
    addGroupWel() {
      this.$router.push('/Wechat/ParameterQRCode/addChannelQRcode')
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem(id) {
      getQRCodeDelete({ id: id }).then(res => {
          Message.success('删除成功')
          this.getPageList()
      })
    },
    handleDownloadCode(data) {
      const params = qs.stringify({ urlString: data.url_useful })
      window.location.href = 'https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?' + params
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
