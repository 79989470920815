import {
  get, post
} from '../Api/axios'
import {
  URL_OFFICIAL_CUSTOMER_FORM,
  URL_OFFICIAL_CUSTOMER_FORM_DELETE,
  URL_OFFICIAL_CUSTOMER_FORM_CHANNEL,
  URL_OFFICIAL_CUSTOMER_FORM_SAVE_DETAIL,
  URL_OFFICIAL_CUSTOMER_FORM_DEL_DETAIL,
  URL_OFFICIAL_CUSTOMER_FORM_CREATE,
  URL_OFFICIAL_CUSTOMER_FORM_SEARCH,
  URL_OFFICIAL_CUSTOMER_FORM_UPDATE,
  URL_OFFICIAL_CUSTOMER_FORM_SAVE,
  URL_OFFICIAL_CUSTOMER_FORM_GET_OPENID,
  URL_OFFICIAL_CUSTOMER_FORM_GET_DATA,
  URL_OFFICIAL_CUSTOMER_FORM_SYNC_TAG
} from '@/Api/api.js'

export function queryCustomerForm ({
  pageNo,
  pageSize,
  ...params
}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM, {
    pageNo,
    pageSize,
    ...params
  })
}
export function delCustomerForm (formId) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_DELETE, {
    formId
  })
}
export function queryChannel (formId) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_CHANNEL, {
    formId
  })
}
export function addChannel (params = {}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_SAVE_DETAIL, {
    ...params
  })
}
export function delChannel (params = {}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_DEL_DETAIL, {
    ...params
  })
}
export function formCreate (data = {}) {
  return post(URL_OFFICIAL_CUSTOMER_FORM_CREATE, {
    ...data
  })
}
export function formSearch (params = {}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_SEARCH, {
    ...params
  })
}
export function formUpdate (data = {}) {
  return post(URL_OFFICIAL_CUSTOMER_FORM_UPDATE, {
    ...data
  })
}
export function formSave (data = {}) {
  return post(URL_OFFICIAL_CUSTOMER_FORM_SAVE, {
    ...data
  })
}
export function getOpenid (params = {}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_GET_OPENID, {
    ...params
  })
}
export function queryData ({
  pageNo,
  pageSize,
  ...params
}) {
  return get(URL_OFFICIAL_CUSTOMER_FORM_GET_DATA, {
    pageNo,
    pageSize,
    ...params
  })
}
export function syncTag () {
  return get(URL_OFFICIAL_CUSTOMER_FORM_SYNC_TAG)
}
