<template>
  <div class="Marketing-Automation">
    <el-row :gutter="20" v-if="!showAdd">
      <el-col :span="6">
        <div class="grid-content bg">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="任务状态" name="first">
              <ul class="taskStatus">
                <li
                  v-for="(item,index) in taskStatus"
                  :key="index"
                  @click="taskStatusChange(item.value)"
                >
                  <span :style="{background:item.color}"></span>
                  <span>{{item.title}}</span>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="任务文件夹" name="second">
              <ul class="taskStatus">
                <li v-for="(item,index) in folderList" :key="index" @click="folderChange(item)">
                  <span>
                    <i class="el-icon-folder-opened"></i>
                  </span>
                  <span>{{item}}</span>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="grid-content bg">
          <div class="replytop">
            <el-input
              placeholder="输入要搜索的名称"
              prefix-icon="el-icon-search"
              v-model="name"
              @focus="postAutomationList"
              :clearable="true"
              @clear="postAutomationList"
              @change="postAutomationList"
            ></el-input>
            <el-button class="topBtn" type="primary" @click="add()">新建任务</el-button>
          </div>
          <el-table :data="tableData" :header-cell-style="{ background: '#F9F9F9' }">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="missionName" label="任务名称/创建时间" width="200">
              <template slot-scope="scope">
                <div>
                  <div class="missionNameTable">
                    <span :style="{background: scope.row.status === '1'?'#B7472A':'#3EB134'}"></span>
                    <span>{{scope.row.missionName}}</span>
                  </div>
                  <div>
                    <span>{{scope.row.createTime}}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="executeTime" label="执行时间"></el-table-column>
            <el-table-column prop="classify" label="分类"></el-table-column>
            <el-table-column prop="covernum" label="覆盖人数"></el-table-column>
            <el-table-column label="操作" width="130">
              <template slot-scope="scope">
                <i class="el-icon-edit-outline autoicon" @click="add(scope.row)"></i>
                <i class="el-icon-delete autoicon" @click="deleteItem(scope.row.id)"></i>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-size="pageSize"
            layout="prev, pager, next"
            background
            :total="total"
            :hide-on-single-page="true"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>
    <Automation
      v-else
      :editData="editData"
      :folderList="folderList"
      @visableAutomation="visableAutomation"
    ></Automation>
  </div>
</template>
<script>
import {
  getAutomationFolderList,
  postAutomationList,
  getAutomationDel
} from "@/service/auto";
import Automation from "./Automation/index";
import { Message } from "element-ui";
export default {
  data() {
    return {
      activeName: "first",
      pageNo: 1,
      pageSize: 8,
      taskStatus: [
        {
          title: "所有任务",
          value: null,
          color: "#0E3961"
        },
        {
          title: "运行中的任务",
          value: 0,
          color: "#3EB134"
        },
        {
          title: "已结束的任务",
          value: 1,
          color: "#B7472A"
        }
      ],
      folderList: [],
      tableData: [],
      name: "",
      folder: null,
      status: null,
      total: 0,
      showAdd: false,
      editData: null
    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getAutomationFolderList();
    this.postAutomationList();
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    taskStatusChange(value) {
      this.status = value;
      this.folder = null
      this.postAutomationList();
    },
    folderChange(value) {
      this.folder = value;
      this.status = null;
      this.postAutomationList();
    },
    postAutomationList() {
      postAutomationList({
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        name: this.name,
        folder: this.folder,
        status: this.status
      }).then(res => {
        this.tableData = res.list;
        this.total = res.num;
      });
    },
    getAutomationFolderList() {
      getAutomationFolderList().then(res => {
        this.folderList = res;
      });
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.postAutomationList();
    },
    add(val) {
      console.log("add -> val", val);
      this.showAdd = true;
      if (val) {
        this.editData = val;
      } else {
        this.editData = null;
      }
    },
    deleteItem(row) {
      getAutomationDel({ id: row }).then(res => {
        Message.success("删除成功");
        this.postAutomationList();
      });
    },
    visableAutomation(val) {
      console.log("visableAutomation -> val", val);
      this.showAdd = val;
      this.pageNo = 1;
      this.postAutomationList();
    }
  },
  components: {
    Automation
  }
};
</script>

<style  lang="less">
@import url("./index.less");
</style>
