<template>
  <div class="authority-management">
    <div class="topbotton">
      <el-button type="primary" @click="show(0)">添加父节点</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9F9' }"
      row-key="id"
      border
      default-expand-all
      :row-style="{ height: '20px' }"
      :cell-style="{ padding: '0px' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="label" label="名称" align="left">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            v-show="scope.row.parentId === 0"
            @click="show(scope.row.id)"
            >添加子节点
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="delPermissionDelete(scope.row.id)"
            >删除节点</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加节点弹窗 -->
    <el-dialog
      :title="this.addNodeForm.parentId ? '添加子节点' : '添加父节点'"
      @close="closeDialog()"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form
        ref="addNodeFormRef"
        :model="addNodeForm"
        :rules="addNodeFormRules"
        label-width="80px"
      >
        <el-form-item label="节点名称" prop="name">
          <el-input
            placeholder="请输入名称"
            v-model="addNodeForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Url" prop="url">
          <el-input
            placeholder="请输入Url"
            v-model="addNodeForm.url"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="addpostPermission()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPermissionList, postPermissionAdd, postPermissionDelete } from '@/service/roles'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      addNodeForm: {
        parentId: 0,
        name: '',
        url: ''
      },
      addNodeFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入url', trigger: 'blur' }]
      },
      dialogShow: false
    }
  },
  computed: {

  },
  created() {
    this.getList()
  },
  mounted() {
  },
  watch: {

  },
  methods: {
    getList() {
      getPermissionList().then(res => {
        this.tableData = res
      })
    },
    show(parentId) {
      this.addNodeForm.parentId = parentId
      this.dialogShow = true
    },
    closeDialog() {
      this.dialogShow = false
      this.addNodeForm = {
        parentId: 0,
        name: '',
        url: ''
      }
    },
    addpostPermission() {
      postPermissionAdd({ ...this.addNodeForm }).then(res => {
        Message.success('添加成功')
        this.getList()
        this.closeDialog()
      })
    },
    delPermissionDelete(id) {
      postPermissionDelete({ id: id }).then(res => {
        Message.success('删除成功')
        this.getList()
        console.log('🚀 ~ file: index.vue ~ line 67 ~ postPermissionDelete ~ res', res)
      })
    }
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
