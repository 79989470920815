<!-- 列表 -->
<template>
  <div class="add-scheduled-task">
    <div class="addCenter">
      <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
        <el-step title="1.设置活动信息"></el-step>
        <el-step title="2.目标用户"></el-step>
        <el-step title="3.推送内容"></el-step>
      </el-steps>

      <el-form ref="form" :model="form" class="addFrom">
        <div v-if="active === 0" class="w680">
          <el-form-item label="活动名称" label-width="80px">
            <el-input v-model="form.name" class="inputW300"></el-input>
          </el-form-item>
          <el-form-item label="推送规则" label-width="80px">
            <el-radio-group v-model="form.rule" size="medium" @change="radioGroupChange">
              <el-radio border label="定时发送"></el-radio>
              <el-radio border label="立即发送"></el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="fontCss" v-show="form.rule === '定时发送'">
            消息将于
            <el-date-picker v-model="dating" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions"></el-date-picker>
            <el-time-picker arrow-control v-model="timing" type="datetime" format="HH:mm" value-format="HH:mm" placeholder="任意时间点" :clearable="false"></el-time-picker>准时发送
          </div>
        </div>
        <div v-if="active === 1">
          <div class="ml20">
            <el-row :gutter="20">
              <el-col :span="20">
                <!-- 用户行为满足 start -->
                <el-row>
                  <el-card shadow="never">
                    <el-form>
                      <el-row type="flex" justify="space-between" class="user-prop-title">
                        <!-- <div><i class="el-icon-minus delete-all"/>用户行为满足</div> -->
                        <div style="font-weight: bold">用户行为满足</div>
                        <div class="add_rows" @click="handleAddPropsRow('actRules', 'actDetails')">
                          <i class="el-icon-plus add-all" />
                          <span>添加</span>
                        </div>
                      </el-row>
                      <el-row :gutter="20" type="flex">
                        <el-col :span="1" style="width: 40px" v-if="actRules.actDetails.length > 1">
                          <div class="relationship">
                            <div class="relationship-bar"></div>
                            <div class="relationship-text" @click="handleTurnOn('actRules')">
                              {{ actRules.relationship | relationship }}
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="actRules.actDetails.length > 1 ? 23 : 24">
                          <el-row v-for="(item, index) in actRules.actDetails" :key="index">
                            <el-col :span="21">
                              <!-- 第一行 -->
                              <el-row :gutter="20" style="margin: 10px 0">
                                <el-col :span="6" style="display: flex; width: 320px">
                                  <div v-if="actRules.actDetails.length > 1" class="index" style="
                                      margin-right: 5px;
                                      background: #00bf8a;
                                    ">
                                    {{ String.fromCharCode(65 + index) }}
                                  </div>
                                  <el-date-picker v-model="item.date" type="daterange" range-separator="至" size="small" class="date_picker" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="false" @change="
                                      (e) => handleChangeActDetailsDate(e, item)
                                    "></el-date-picker>
                                </el-col>
                                <el-col :span="2" style="width: 120px">
                                  <el-select v-model="item.actType" placeholder="请选择" size="small" @change="clearGroupNum">
                                    <el-option v-for="item in actTypeOption" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                  </el-select>
                                </el-col>
                                <el-col :span="5">
                                  <el-select v-model="item.eventCode" placeholder="请选择" size="small" @change="clearGroupNum">
                                    <el-row class="option-search">
                                      <el-input v-model="eventListFilter" prefix-icon="el-icon-search" clearable size="small" />
                                    </el-row>
                                    <el-option v-for="item in eventListOptionFilter" :key="item" :label="item" :value="item"></el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <!-- 第二行 -->
                              <el-row :gutter="20" type="flex" style="margin: 0">
                                <el-col :span="2" style="display: flex; width: 120px">
                                  <div style="
                                      margin-right: 5px;
                                      padding-left: 20px;
                                    " v-if="actRules.actDetails.length > 1"></div>
                                  <el-input size="small" value="总次数" readonly />
                                </el-col>
                                <el-col :span="1" style="width: 65px">
                                  <el-input size="small" value="≥" readonly />
                                </el-col>
                                <el-col :span="4" style="
                                    display: flex;
                                    align-items: center;
                                    width: 155px;
                                  ">
                                  <el-input-number v-model="item.num" :precision="0" :controls="false" size="small" class="input_num" @change="clearGroupNum" />
                                  <span>次</span>
                                </el-col>
                              </el-row>
                            </el-col>
                            <el-col :span="3" :style="`text-align:right;padding-right:${
                                actRules.actDetails.length > 1 ? '5' : '0'
                              }px;margin-top:10px;`">
                              <span @click="
                                  handleDeletePropsRow(
                                    index,
                                    'actRules',
                                    'actDetails'
                                  )
                                " style="cursor: pointer">
                                <i class="el-tag__close el-icon-close"></i>
                              </span>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-card>
                </el-row>
              </el-col>
              <!-- 用户行为满足 end -->
              <!-- 评估 -->
              <el-col :span="4">
                <el-form-item prop="groupNum" :rules="[
                    {
                      validator: (rules, value, callback) =>
                        validateGroupNum(rules, value, callback),
                      trigger: 'change',
                    },
                  ]">
                  <el-input :value="form.groupNum" type="text" readonly />
                </el-form-item>
                <el-button type="primary" size="small" v-loading="calculateBtn" @click="handleCalculate">评估</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="active === 2">
          <el-select v-model="form.templateId" placeholder="请选择推送模板" @change="tempLateSelect">
            <el-option v-for="item in templateList" :value="item.priTmplId" :key="item.priTmplId" :label="item.title"></el-option>
          </el-select>
          <div v-show="form.templateId" class="disflex">
            <div class="previewBox">
              <div class="topBox">
                <el-image :src="miniPro"></el-image>小程序名称
              </div>
              <div class="centerBox">
                <p>{{ templateTitle }}</p>
                <ul>
                  <li v-for="item in templateContent" :key="item.key">
                    <span>{{ item.label }}</span>
                    <span>{{ item.value }}</span>
                  </li>
                </ul>
              </div>
              <div class="bottomBox">
                查看详情<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div>
              <div class="inputBox">
                <span class="label">模板ID</span>
                <span class="value">{{ form.templateId }}</span>
              </div>
              <div class="inputBox">
                <span class="label">模板名称</span>
                <span class="value">{{ templateTitle }}</span>
              </div>
              <div class="inputBox">
                <span class="label">跳转页面</span>
                <span class="value">
                  <el-input v-model="page"> </el-input>
                </span>
              </div>
              <div class="inputListBox">
                <span class="label">详细内容</span>
                <span class="value">
                  <div v-for="item in templateContent" :key="item.key" class="inputBox">
                    <span class="label">{{ item.label }}</span>
                    <el-input v-model="item.value"> </el-input>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-form>

      <div class="bntList">
        <el-button v-if="active !== 0" round @click="Previous">上一步</el-button>
        <el-button v-if="active !== 2" type="primary" round @click="next">下一步</el-button>
        <el-button v-if="active === 2" type="primary" round @click="next">完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  /* eslint-disable */
  props: ["editData"],
  filters: {
    relationship (val) {
      if (val) {
        return val == "and" ? "且" : "或";
      }
    }
  },
  data () {
    let validateGroupNum = (rules, value, callback) => {
      if (!this.form.groupNum && this.form.groupNum != "0") {
        return callback(new Error("请进行评估"));
      }
      return callback();
    };
    return {
      active: 0,
      validateGroupNum,
      form: {
        name: "",
        rule: "定时发送",
        actRules: {},
        groupNum: undefined,
        templateId: null
      },
      dating: new Date(),
      timing: moment("23:59:00").format('HH:mm:ss'),
      datingStart: new Date(),
      datingEnd: new Date(),
      actRules: {
        relationship: "and",
        actDetails: []
      },
      levelTwoFilter: "",
      levelOneOption: [],
      conditionOption: [
        {
          text: "等于",
          value: "="
        },
        {
          text: "不等于",
          value: "!="
        },
        {
          text: "大于等于",
          value: ">="
        },
        {
          text: "小于等于",
          value: "<="
        },
        {
          text: "包含",
          value: "like"
        },
        {
          text: "不包含",
          value: "not like"
        }
      ],
      actTypeOption: [
        {
          text: "做过",
          value: "="
        },
        {
          text: "没做过",
          value: "!="
        }
      ],
      relationship: "且",
      eventListFilter: "",
      num: 0,
      groupId: null,
      templateList: [],
      missionId: null,
      status: 0,
      calculateBtn: false,
      miniPro: require('@/assets/minipro.png'),
      templateContent: [],
      templateTitle: '',
      page: '',
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) > new Date(v);
        }
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getEventList();
    this.getFilterList();
    this.getTemplateList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.init();
  },
  computed: {
    eventListOptionFilter () {
      if (this.eventListFilter) {
        let arr = this.eventListOption.filter(e =>
          e.includes(this.eventListFilter)
        );
        if (arr.length) {
          return arr;
        }
        return this.eventListOption;
      }
      return this.eventListOption;
    }
  },
  methods: {
    init () {
      if (this.editData) {
        this.form = {
          name: this.editData.missionName,
          rule: this.editData.sendType === 0 ? '立即发送' : '定时发送',
          groupNum: this.editData.userNum,
          templateId: this.editData.template_id
        };
        this.id = this.editData.id
        if (this.editData.sendType === 1) {
          this.dating = this.editData.sendTime.split(' ')[0];
          this.timing = this.editData.sendTime.split(' ')[1]
        }
        this.actRules = this.editData.missionRules
          ? this.editData.missionRules
          : {
            relationship: "and",
            actDetails: []
          };
        if (this.actRules.actDetails && this.actRules.actDetails.length > 0) {
          this.actRules.actDetails.forEach(e => {
            this.$set(e, "date", [e.startDate, e.endDate]);
          });
        }
        this.page = this.editData.page
      } else {
        this.status = 0;
        this.form = {
          name: "",
          rule: "定时发送",
          actRules: {},
          groupNum: undefined
        };
        this.templateContent = []
        this.templateTitle = '',
          this.dating = new Date(),
          this.timing = "23:59",
          this.actRules = {
            relationship: "and",
            actDetails: []
          }
      }
    },
    radioGroupChange (value) {
      // if (value === "周期性推送") {
      //   this.sendType = "";
      // }
    },
    async getFilterList (callback) {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/property/list"
      );
      if (data.flag) {
        this.levelOneOption = data.data;
        this.levelOne = [...this.levelOneOption];
        if (callback) callback();
      }
    },
    format () {
      let da = new Date();
      let year = da.getFullYear();
      let month =
        da.getMonth() + 1 < 10 ? "0" + (da.getMonth() + 1) : da.getMonth() + 1;
      let date = da.getDate() < 10 ? "0" + da.getDate() : da.getDate();
      return [year, month, date].join("-");
    },
    handleDeletePropsRow (i, prop, prop2) {
      this[prop][prop2].splice(i, 1);
      if (this[prop][prop2].length <= 1) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
    handleAddPropsRow (prop, prop2) {
      let obj = {};
      if (prop == "actRules") {
        obj = {
          date: [this.format(), this.format()],
          startDate: this.format(),
          endDate: this.format(),
          actType: "=",
          eventCode: this.eventListOption[0],
          num: "0"
        };
      } else {
        obj = {
          value: this.levelOneOption[0].propertyList[0]["enumValue"][0],
          key: this.levelOneOption[0].propertyList[0].leveltTwoCode,
          condition: "=",
          enumValue: this.levelOneOption[0].propertyList[0]["enumValue"]
        };
      }
      this[prop][prop2].push(obj);
      if (this[prop][prop2].length > 1 && !this[prop].relationship) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
    async handleCalculate () {
      let params = { actRules: this.actRules }
      if (
        !this.actRules.actDetails.length
      ) {
        this.$message.warning("请填写用户行为满足");
        return;
      }
      this.calculateBtn = true;
      const { data: res } = await this.$http.post(
        "https://cdpengine.cn/scrm-platform/mini/subscribe/event/num/calculate",
        params
      );
      if (res.flag) {
        this.form.groupNum = res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.calculateBtn = false;
    },
    async getTemplateList (callback) {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/mini/subscribe/gettemplate"
      );
      if (data.code === 200) {
        this.templateList = data.data.data;
        if (this.editData) {
          this.templateContent = []
          this.templateTitle = ''
          var selectTemp = this.templateList.filter(i => i.priTmplId === this.editData.template_id)[0]
          this.templateTitle = selectTemp.title
          var contentStr = ''
          contentStr = selectTemp.example
          var content = contentStr.split("\n");
          var keyArr = selectTemp.content.split("\n")
          var datas = JSON.parse(this.editData.datas)
          content.map((item, index) => {
            if (item !== '') {
              var key = this.setKey(keyArr[index].split(':')[1])
              var obj = { label: item.split(":")[0], value: datas[key].value, key: key }
              this.templateContent.push(obj)
            }
          })
        }
      }
    },
    tempLateSelect (value) {
      this.templateContent = []
      this.templateTitle = ''
      var contentStr = ''
      var selectTemp = this.templateList.filter(i => i.priTmplId === value)[0]
      contentStr = selectTemp.example
      this.templateTitle = selectTemp.title
      var content = contentStr.split("\n");
      var keyArr = selectTemp.content.split("\n")
      content.map((item, index) => {
        if (item !== '') {
          var obj = { label: item.split(":")[0], value: item.split(":")[1], key: this.setKey(keyArr[index].split(':')[1]) }
          this.templateContent.push(obj)
        }
      })
    },
    setKey (str) {
      var a1 = /\{{(.+?)\}}/g;
      let a2 = str.match(a1).toString()    //因为输出的是数组，所以得转化成字符串
      var a3 = a2.replace("{{", "");         //用空字符替换掉括号
      a3 = a3.replace("}}", "");
      a3 = a3.replace(".DATA", "");
      return a3
    },
    Previous () {
      this.active--;
    },
    next () {
      switch (this.active) {
        case 0:
          if (this.form.name === "") {
            this.$message.warning("请填写活动名称");
          } else {
            this.active++;
          }
          break;
        case 1:
          if (this.form.groupNum != undefined) {
            this.active++;
          } else {
            this.$message.warning("请先评估用户行为");
          }
          break;
        case 2:
          if (this.templateContent.filter(i => i.value === '').length > 0) {
            this.$message.warning("请完整填写详细内容");
          } else {
            this.overAdd();
          }
          break;
        default:
          break;
      }
    },
    async getEventList () {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/mini/subscribe/event/list"
      );
      console.log("🚀 ~ file: index.vue ~ line 599 ~ getEventList ~ data", data)
      if (data.code == 200) {
        this.eventListOption = data.data;
      }
    },
    handleChangeActDetailsDate (val, item) {
      item.startDate = val[0];
      item.endDate = val[1];
      this.clearGroupNum();
    },
    clearGroupNum () {
      this.form.groupNum = "";
      this.calculateBtn = true;
      this.calculateBtn = false;
    },
    goBack () {
      this.$emit("goBack", false);
    },
    overAdd () {
      let data = {};
      if (this.id) {
        data.id = this.id;
      }
      data.missionName = this.form.name;
      data.missionRules = this.actRules
      data.userNum = this.form.groupNum;
      data.template_id = this.form.templateId
      data.page = this.page
      if (this.form.rule === "定时发送") {
        data.sendType = 1;
        data.sendTime = moment(this.dating).format("YYYY-MM-DD") + ' ' + this.timing;
      } else {
        data.sendType = 0;
      }
      data.data = {}
      this.templateContent.map(item => {
        data.data[item.key] = { value: item.value }
      })
      this.$http
        .post(
          "https://cdpengine.cn/scrm-platform/mini/subscribe/subscribe/send/create",
          data
        )
        .then(res => {
          if (res.data.flag) {
            this.$message.success(res.data.message);
            this.active = 0;
            this.$emit("goBack", false);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleTurnOn (prop) {
      let data = this[prop];
      if (data.relationship == "and") {
        data.relationship = "or";
      } else {
        data.relationship = "and";
      }
    },
  },
  watch: {
    editData () {
      this.init();
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>