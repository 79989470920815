<template>
  <section class="label-list-container">
    <Block title="企业客户标签">
      <div class="label-main">
        <div class="handle-add">
          <el-button icon="el-icon-plus" type="primary" @click="addLableGroupp"
            >添加标签组</el-button
          >
          <el-button
            icon="el-icon-refresh-right"
            type="primary"
            @click="synchronizeWebChat"
            >同步企业标签
          </el-button>
        </div>
        <div class="label-list">
          <div
            class="label-item"
            v-for="result in labelList"
            :key="result.groupId"
          >
            <div class="titles">
              {{ result.groupName }}
            </div>
            <div class="label-son">
              <el-input
                class="input-new-tag"
                v-if="result.visible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm(result)"
                @blur="handleInputConfirm(result)"
              >
              </el-input>
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput(result)"
                >+ 添加
              </el-button>
              <el-tag
                :key="tag.tagId"
                v-for="tag in result.tags"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, result)"
              >
                {{ tag.tagName }}
              </el-tag>
            </div>
            <div class="handle">
              <a @click="editLableGroup(result)">
                <i class="el-icon-edit-outline"></i>
                编辑标签名
              </a>
              <a @click="removeGroup(result.groupId)">
                <i class="el-icon-delete"></i>
                删除标签组
              </a>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        :title="`${editStatus ? '编辑' : '新增'}标签组`"
        :visible.sync="dialogVisible"
        width="40%"
      >
        <el-form
          ref="dynamicValidateForm"
          :model="dynamicValidateForm"
          label-width="100px"
        >
          <el-form-item
            label="标签组名称"
            :rules="{
              required: true,
              message: '标签组名称不能为空',
              trigger: 'blur',
            }"
          >
            <el-input v-model="dynamicValidateForm.group_name"></el-input>
          </el-form-item>
          <template v-if="!editStatus">
            <el-form-item
              v-for="(domain, index) in dynamicValidateForm.tag"
              label="标签名称"
              :key="domain.key"
              :prop="'tag.' + index + '.name'"
              :rules="{
                required: true,
                message: '标签名称不能为空',
                trigger: 'blur',
              }"
            >
              <div class="label-name-input">
                <el-input v-model="domain.name"></el-input>
                <i
                  class="el-icon-circle-plus-outline"
                  @click.prevent="addDomain()"
                ></i>
                <i
                  v-if="index > 0"
                  class="el-icon-remove-outline"
                  @click.prevent="removeDomain(domain)"
                ></i>
              </div>
            </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onFinish('dynamicValidateForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </Block>
  </section>
</template>

<script>
import Block from '@/components/Block'
import { synchronizeLabel } from '@/service/label'
import { getGroupLabelList, addGroupLabel, removeGroupLabel, updateGroupLabel } from '@/service/group'
import { unid } from '@/utils'
export default {
  props: {

  },
  data() {
    return {
      labelList: [],
      inputValue: '',
      dialogVisible: false,
      dynamicValidateForm: {
        tag: [{
          name: ''
        }],
        group_name: ''
      },
      editStatus: false
    }
  },
  computed: {

  },
  created() {
    this._initLableList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    _initLableList() {
      getGroupLabelList().then(res => {
        this.labelList = res.map(v => {
          v.visible = false
          return v
        })
      })
    },
    synchronizeWebChat() {
      synchronizeLabel()
    },
    handleClose(tag, result) {
      removeGroupLabel({
        tagId: tag.id,
        groupId: result.groupId,
        type: 0
      }).then(() => this._initLableList())
    },

    showInput(result) {
      this.labelList = this.labelList.map(v => {
        if (v.groupId === result.groupId) {
          v.visible = true
          return v
        }
        v.visible = false
        return v
      })
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    async handleInputConfirm(result) {
      const inputValue = this.inputValue
      if (inputValue) {
        await addGroupLabel({
          group_id: result.groupId,
          group_name: result.groupName,
          order: 0,
          tag: [{ name: this.inputValue, id: unid }]
        })
        await this._initLableList()
      }
      this.labelList = this.labelList.map(v => {
        v.visible = false
        return v
      })
      this.inputValue = ''
    },
    onFinish(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.editStatus) {
            await updateGroupLabel({
              group_id: this.dynamicValidateForm.id,
              groupname: this.dynamicValidateForm.group_name
            })
          } else {
            await addGroupLabel({
              group_id: unid,
              ...this.dynamicValidateForm,
              order: 1
            })
          }
          this._initLableList()
          this.$refs[formName].resetFields()
          this.dynamicValidateForm = {
            tag: [{
              name: ''
            }],
            group_name: ''
          }
          this.dialogVisible = false
        }
      })
    },

    removeDomain(item) {
      var index = this.dynamicValidateForm.tag.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.tag.splice(index, 1)
      }
    },
    addDomain() {
      this.dynamicValidateForm.tag.push({
        value: '',
        key: Date.now()
      })
    },
    addLableGroupp() {
      this.dialogVisible = true
      this.editStatus = false
      this.$refs.dynamicValidateForm.resetFields()
      this.dynamicValidateForm = {
        tag: [{
          name: ''
        }],
        group_name: ''
      }
    },
    editLableGroup(res) {
      this.dialogVisible = true
      this.editStatus = true
      this.dynamicValidateForm.group_name = res.groupName
      this.dynamicValidateForm.id = res.groupId
    },
    removeGroup(tag) {
      removeGroupLabel({
        groupId: tag,
        tagId: '',
        type: 1
      }).then(() => this._initLableList())
    }
  },
  components: {
    Block
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
