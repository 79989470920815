<template>
  <div>
    <LineChart></LineChart>
    <TableChart></TableChart>
  </div>
</template>

<script>
import LineChart from './lineChart'
import TableChart from './tableChart'
export default {
  data() {
    return {};
  },
  computed: {},
  created() { },
  mounted() {
  },
  watch: {},
  methods: {
  },
  components: {
    LineChart,
    TableChart
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
