<template>
  <div>
    <Block title="趋势图">
      <div class="nav-list">
        <el-button v-for="item in navData"
          :key="item.value"
          @click="current = item.value"
          :type="current === item.value ? 'primary':'none'">{{item.text}}</el-button>
      </div>
      <div class="select-date">
        <p>日期筛选：</p>
        <el-date-picker v-model="selectDate"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div id="line"></div>
      <div class="select-model-button">
        <el-button :type="model.date ? 'primary':'none'"
          @click="switchModal('date')">按日期查看</el-button>
        <el-button :type="model.lord ? 'primary':'none'"
          @click="switchModal('lord')">按群主查看</el-button>
      </div>
      <el-table :data="tableData"
        style="width: 100%">
        <el-table-column prop="owner"
          label="名称"
          v-if="model.lord">
        </el-table-column>

        <el-table-column prop="stat_time"
          label="日期"
          v-if="model.date">
        </el-table-column>

        <el-table-column prop="new_chat_cnt"
          label="新增客户群数量">
        </el-table-column>
        <el-table-column prop="member_total"
          label="截至当天客户群总人数">
        </el-table-column>
        <el-table-column prop="msg_total"
          label="截至当天客户群消息总数">
        </el-table-column>
        <el-table-column prop="new_member_cnt"
          label="客户群新增群人数">
        </el-table-column>
        <el-table-column prop="chat_total"
          label="截至当天客户群总数量">
        </el-table-column>
        <el-table-column prop="member_has_msg"
          label="截至当天有发过消息的群成员数">
        </el-table-column>
        <el-table-column prop="chat_has_msg"
          label="截至当天有发过消息的客户群数量">
        </el-table-column>
      </el-table>
    </Block>
    <Block title="群客户量TOP10">
      <div id="bar"></div>
    </Block>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
import Block from '@/components/Block'
import { groupDataStatisticsPolymerization, groupDataStatisticsDayPolymerization } from '@/service/group'
export default {
  data () {
    return {
      current: 'new_chat_cnt',
      navData: [
        { value: 'new_chat_cnt', text: '新增客户群数量' },
        { value: 'member_total', text: '截至当天客户群总人数' },
        { value: 'msg_total', text: '截至当天客户群消息总数' },
        { value: 'new_member_cnt', text: '客户群新增群人数' },
        { value: 'chat_total', text: '截至当天客户群总数量' },
        { value: 'member_has_msg', text: '截至当天有发过消息的群成员数' },
        { value: 'chat_has_msg', text: '截至当天有发过消息的客户群数量' }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: (picker) => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
            this.getQueryDayStatistics(start.getTime(), end.getTime())
          }
        }, {
          text: '最近一个月',
          onClick: (picker) => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
            this.getQueryDayStatistics(start.getTime(), end.getTime())
          }
        }],
        onPick: ({ maxDate, minDate }) => {
          if (maxDate && minDate) {
            const end = new Date(maxDate)
            const start = new Date(minDate)
            if ((end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24 > 30) {
              return this.$message.warning('日期最大长度为30天')
            }
            console.log()
            this.selectDate = [start, end]
            this.getQueryDayStatistics(start.getTime(), end.getTime())
          }
        },
        disabledDate: (v) => {
          var nowDate = new Date()
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) < new Date(v)
        }
      },
      selectDate: [],
      lineOption: {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },

        series: [{
          data: [],
          type: 'line',
          smooth: true
        }]
      },
      model: {
        date: true,
        lord: false
      },
      tableData: [],
      barOption: {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },

        series: [{
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }]
      },
      result: [],
      resultAllDate: []
    }
  },
  computed: {

  },
  created () {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    end.setTime(end.getTime() - 3600 * 1000 * 24)
    this.selectDate = [start, end]
    this.getQueryDayStatistics(start.getTime(), end.getTime())
  },
  mounted () {

  },
  watch: {
    current () {
      this.result = []
      this.resultAllDate.forEach(item => {
        this.result.push({
          stat_time: moment(item.stat_time * 1000).format('YYYY-MM-DD'),
          value: item.data[`${this.current}`]
        })
      })
      this.createLine()
      this.createBar()
    }
  },
  methods: {
    getQueryStatistics () {
      groupDataStatisticsPolymerization().then(res => {
        this.tableData = res.map(v => ({ owner: v.owner, ...v.data }))
      })
    },
    getQueryDayStatistics (start, end) {
      groupDataStatisticsDayPolymerization(parseInt(start / 1000), parseInt(end / 1000)).then(res => {
        this.resultAllDate = res
        this.tableData = res.map(v => ({ stat_time: moment(v.stat_time * 1000).format('YYYY-MM-DD'), ...v.data }))
        this.result = []

        this.resultAllDate.forEach(item => {
          this.result.push({
            stat_time: moment(item.stat_time * 1000).format('YYYY-MM-DD'),
            value: item.data[`${this.current}`]
          })
        })
        this.createLine()
        this.createBar()
      })
    },
    createLine () {
      var chartDom = document.getElementById('line')
      this.lineOption.xAxis.data = this.result.map(v => v.stat_time)
      this.lineOption.series[0].data = this.result.map(v => v.value)
      var myChart = echarts.init(chartDom)
      myChart.setOption(this.lineOption, true)
    },
    createBar () {
      var chartDom = document.getElementById('bar')
      this.barOption.xAxis.data = this.result.map(v => v.stat_time)
      this.barOption.series[0].data = this.result.map(v => v.value)
      var myChart = echarts.init(chartDom)
      myChart.setOption(this.barOption, true)
    },
    switchModal (key) {
      if (key === 'date') {
        this.model.date = true
        this.model.lord = false
        this.getQueryDayStatistics(this.selectDate[0].getTime(), this.selectDate[1].getTime())
      } else {
        this.getQueryStatistics()
        this.model.date = false
        this.model.lord = true
      }
    }
  },
  components: {
    Block
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
