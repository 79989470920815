<template>
  <div class="smstemplate">
    <div class="smstemplate__top">
    <el-button type="primary" class="smstemplate__text" @click="addSmsTemplate(null,'add')">创建正文模板</el-button>
     <el-button type="primary" class="smstemplate__text"  @click="refresh()"> <i class="el-icon-refresh-right iconStyle"/>同步模板状态</el-button>
    </div>
    <el-table :data="tableData" :default-sort = "{prop: 'date', order: 'descending'}">
      <el-table-column prop="templateId" width="110px"
        label="ID" >
      </el-table-column>
      <el-table-column prop="smsTypes"  width="110px"
        label="模板类型">
      </el-table-column>
      <el-table-column prop="templateName" width="150px"
        label="模板名称">
      </el-table-column>
       <el-table-column prop="templateContent"
        label="内容">
      </el-table-column>
       <el-table-column label="状态/原因" width="130px">
       <template slot-scope="scope">
        <el-popover trigger="hover" placement="top" v-if="scope.row.status==='-1'">
          <p>原因: {{ scope.row.reviewReply }}</p>
          <div slot="reference" class="name-wrapper">
             <span style="margin-left: 10px;color:#f56c6c" >未通过<i class="el-icon-warning"/></span>
          </div>
        </el-popover>
        <div v-else>
        <span style="margin-left: 10px;" v-if="scope.row.status==='1'">待审核</span>
        <span style="margin-left: 10px;color:#67C23A" v-else>已通过<i class="el-icon-success"/></span>
        </div>
      </template>
      </el-table-column>
      <el-table-column prop="createTime"
        label="申请时间" width="170px">
      </el-table-column>
      <el-table-column prop="action"
        label="操作" width="120px">
        <template slot-scope="scope">
          <div class="action">
            <a @click="addSmsTemplate(scope.row,'edit')">修改</a>
            <el-popconfirm title="确定要删除当前短信模板嘛？"
              @confirm="handleRemove(scope.row.templateId)">
              <a slot="reference">删除</a>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 40, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
      class="smstemplate__pagination">
    </el-pagination>
    <Pop :dialogFormVisible="dialogFormVisible" @closeSmsTemplate="closeSmsTemplate" @getTextPageList="getTextPageList" :editList="editList"/>
  </div>
</template>

<script>
import { getSmsTemplateList, delSmsTemplate, refreshSmsTemplate } from '@/service/sms'
import Pop from './components/addSmsTemplate.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pop
  },
  data () {
    return {
      name: '',
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dialogFormVisible: false,
      editList: {},
      currentText: {
      }
    }
  },
  computed: {

  },
  created () {
    this.getTextPageList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    refresh() {
     refreshSmsTemplate().then(e => {
       this.getTextPageList()
       this.$message.success('更新成功')
     })
    },
    addSmsTemplate(row = {}, type) {
     if (type === 'add') {
         this.editList = {}
     } else {
this.editList = row
    }
     this.dialogFormVisible = true
    },
    closeSmsTemplate() {
     this.dialogFormVisible = false
    },
    getTextPageList () {
      getSmsTemplateList({ pageno: this.pageNo, pagesize: this.pageSize }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getTextPageList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getTextPageList()
    },
    handleRemove (id) {
        const obj = {
            template_id: id
        }
      delSmsTemplate(obj).then(() => {
        this.getTextPageList()
        this.$message.success('删除成功')
      })
    }
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
