<template>
  <div>
    <div v-for="(item, index) in logList" class="wrap-box" :key="index">
      <el-divider content-position="center">{{item.dataDate}}</el-divider>
      <div class="line-box">
        <el-timeline>
          <el-timeline-item v-for="(activity, i) in item.list" :key="i+'-activity'" color="#0bbd87">
            <div class="item-box">
              <!-- 时间线左侧 -->
              <div class="line-left">
                <span class="eventTypeDate">{{ activity.eventTime }}</span>
                <el-image class="img" v-if="activity.type ==='小程序'" :src="miniPro"></el-image>
                <el-image class="img" v-if="activity.type ==='公众号'" :src="officialAccount"></el-image>
                <el-image class="img" v-if="['企微','企业微信'].includes(activity.type)" :src="enterpriseWechat"></el-image>
              </div>
              <!-- 时间线右侧 -->
              <div class="line-right">
                {{activity.event}}
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomerActdetail } from '@/service/customerManage'
export default {
  data () {
    return {
      logList: [],
      officialAccount: require('@/assets/officialAccount.png'),
      enterpriseWechat: require('@/assets/enterpriseWechat.png'),
      miniPro: require('@/assets/minipro.png')
    }
  },
  props: {
    externalUserid: {
      type: String,
      default: ''
    }
  },
  methods: {
    getList (id) {
      const externalUserId = id || this.externalUserid
      getCustomerActdetail({ externalUserId }).then(res => {
        this.logList = res.logs
      })
    }
  },
  watch: {
    externalUserid: {
      handler (val) {
        if (val) {
          this.getList()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.line-box {
  position: relative;
  left: 55%;
  transform: translateX(-50px);
  width: 100%;
}
.line-left {
  position: absolute;
  left: -35%;
  transform: translateX(-50px);
  font-size: 12px;
}
.line-right {
  position: absolute;
  left: 30%;
  transform: translateX(-50px);
  font-size: 12px;
}
.img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 5px;
}
</style>
