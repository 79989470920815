import {
  get,
  post
} from '../Api/axios'

import {
  URL_ROLE_LIST,
  URL_ROLE_DELETE,
  URL_MENUS_LIST_MENU,
  URL_PERMISSION_LIST_MENU,
  URL_ROLE_UPDATE,
  URL_ROLE_ADD,
  URL_MENUS_UPDATE,
  URL_PERMISSION_UPDATE,
  URL_USER_DELETE,
  URL_USER_LIST,
  URL_ROLE_LIST_ALL,
  URL_USER_ROLE_UPDATE,
  URL_USER_ROLE_ADD,
  URL_PERMISSION_ADD,
  URL_PERMISSION_DELETE,
  URL_USER_UPDATE,
  URL_USER_ADD,
  URL_ROLE_MENUS_LIST,
  URL_ROLE_PERMISSION_LIST,
  URL_USER_ROLE_LIST
} from '@/Api/api.js'

export function postRoleDelete(params = {}) {
  return get(URL_ROLE_DELETE, {
    ...params
  })
}

export function getRoleList(params = {}) {
  return get(URL_ROLE_LIST, {
    ...params
  })
}

export function getMenusList(params = {}) {
  return get(URL_MENUS_LIST_MENU, {
    ...params
  })
}

export function getPermissionList(params = {}) {
  return get(URL_PERMISSION_LIST_MENU, {
    ...params
  })
}

export function postRoleUpdate(params = {}) {
  return get(URL_ROLE_UPDATE, {
    ...params
  })
}

export function postRoleUAdd(params = {}) {
  return get(URL_ROLE_ADD, {
    ...params
  })
}

export function postMenusUpdate(params = {}) {
  return post(URL_MENUS_UPDATE, {
    ...params
  })
}

export function postPermissionUpdate(params = {}) {
  return post(URL_PERMISSION_UPDATE, {
    ...params
  })
}

export function delUser(params = {}) {
  return get(URL_USER_DELETE, {
    ...params
  })
}

export function getUserList(params = {}) {
  return get(URL_USER_LIST, {
    ...params
  })
}

export function getRoleListAll(params = {}) {
  return get(URL_ROLE_LIST_ALL, {
    ...params
  })
}

export function updateUserRole(params = {}) {
  return get(URL_USER_ROLE_UPDATE, {
    ...params
  })
}

export function addUserRole(params = {}) {
  return get(URL_USER_ROLE_ADD, {
    ...params
  })
}

// URL_PERMISSION_ADD
export function postPermissionAdd(params = {}) {
  return get(URL_PERMISSION_ADD, {
    ...params
  })
}

// URL_PERMISSION_DELETE
export function postPermissionDelete(params = {}) {
  return get(URL_PERMISSION_DELETE, {
    ...params
  })
}

// URL_USER_UPDATE
export function updateUser(params = {}) {
  return get(URL_USER_UPDATE, {
    ...params
  })
}

// URL_USER_ADD
export function addUser(params = {}) {
  return get(URL_USER_ADD, {
    ...params
  })
}

// URL_ROLE_MENUS_LIST
export function getRoleMenusList(params = {}) {
  return get(URL_ROLE_MENUS_LIST, {
    ...params
  })
}

// URL_ROLE_PERMISSION_LIST
export function getRolePermissionList(params = {}) {
  return get(URL_ROLE_PERMISSION_LIST, {
    ...params
  })
}

// URL_USER_ROLE_LIST
export function getUserRoleList(params = {}) {
  return get(URL_USER_ROLE_LIST, {
    ...params
  })
}
