<template>
  <div class="material-img">
    <Search :type="2" v-bind:reset="getImagePageList" />
    <div class="img-material-list">
      <div class="items" v-for="item in tableData" :key="item.id">
        <img :src="item.picUrl" alt="">
        <div class="base-info">
          <div class="create-time">
            <span>创建时间：</span>
            <span>{{item.createTime}}</span>
          </div>
          <div class="introduction">
            <span>描述：</span>
            <span>{{item.introduction}}</span>
          </div>
        </div>
        <div class="mask">
          <el-button type="primary" size="medium" @click="downLoad(item.picUrl)">下载</el-button>
          <el-button type="danger" size="medium" @click="removeImg(item.id)">删除</el-button>
        </div>
      </div>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { getMaterialLibraryPageList, removeMaterial, downLoadMaterial } from '@/service/materialLibrary'
import Search from '../Search'
export default {
  props: {

  },
  data () {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {

  },
  created () {
    this.getImagePageList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    getImagePageList (introduction) {
      getMaterialLibraryPageList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        mediaType: '图片',
        introduction
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getImagePageList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getImagePageList()
    },
    removeImg (id) {
      removeMaterial(id).then(() => {
        this.getImagePageList()
        this.$message.success('删除成功')
      })
    },
    downLoad (url) {
      window.open(`https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?urlString=${url}`)
    }
  },
  components: {
    Search
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
