<template>
  <div>
    <el-card>
      <!--搜索与添加区域-->
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="16">
          <el-button type="primary" @click="handleAddTag()" style="float: left">添加</el-button>
        </el-col>
        <el-col :span="8">
          <el-input placeholder="请输入标签名称" v-model="queryInfo.tagname" clearable @clear="getTagList">
            <el-button slot="append" icon="el-icon-search" @click="getTagList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="tagList" border stripe>
        <!--        <el-table-column type="index">-->
        <!--        </el-table-column>-->
        <el-table-column prop="tag_id" label="标签id"></el-table-column>
        <el-table-column prop="tag_name" label="标签名称"></el-table-column>
        <el-table-column prop="level_1_name" label="一级大类"></el-table-column>
        <el-table-column prop="level_2_name" label="二级大类"></el-table-column>
        <el-table-column prop="user_type" label="id类型"></el-table-column>
        <el-table-column prop="tag_type" label="标签类型"></el-table-column>
        <el-table-column prop="develop_type" label="开发方式"></el-table-column>
        <el-table-column prop="exclusion" label="是否互斥"></el-table-column>
        <el-table-column prop="is_online" label="是否应用"></el-table-column>
        <el-table-column prop="tag_desc" label="标签介绍"></el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleAddTag(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeTagById(scope.row.tag_id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNo" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <el-dialog title="添加标签" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-row :gutter="20">
          <el-col :span="12" v-if="addForm.tag_id">
            <el-form-item label="标签id" prop="tag_id">
              <el-input v-model="addForm.tag_id" readonly disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签名称" prop="tag_name">
              <el-input v-model="addForm.tag_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="一级大类" prop="level_1_name">
              <el-input v-model="addForm.level_1_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二级大类" prop="level_2_name">
              <el-input v-model="addForm.level_2_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="id类型" prop="user_type">
              <el-input v-model="addForm.user_type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签类型" prop="user_type">
              <el-select v-model="addForm.tag_type" placeholder="请选择">
                <el-option label="分类" value="1"> </el-option>
                <el-option label="统计" value="2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开发方式" prop="develop_type">
              <el-select v-model="addForm.develop_type" placeholder="请选择">
                <el-option label="算法" value="1"> </el-option>
                <el-option label="非算法" value="2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否互斥" prop="exclusion">
              <el-select v-model="addForm.exclusion" placeholder="请选择">
                <el-option label="互斥" value="1"> </el-option>
                <el-option label="非互斥" value="2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否应用" prop="is_online">
              <el-select v-model="addForm.is_online" placeholder="请选择">
                <el-option label="使用中" value="1"> </el-option>
                <el-option label="已停用" value="2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标签介绍" prop="tag_desc">
              <el-input v-model="addForm.tag_desc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        tagname: '',
        pageNo: 1,
        pageSize: 10
      },
      tagList: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        tag_id: '',
        tag_name: '',
        level_1_name: '',
        level_2_name: '',
        user_type: '',
        tag_type: '',
        develop_type: '',
        exclusion: '',
        is_online: '',
        tag_desc: ''
      },
      addFormRules: {},
      editDialogVisible: false,
      editForm: {
      },
      editFormRules: {
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getTagList()
  },
  methods: {
    async getTagList () {
      console.log(this.queryInfo.tagname)
      if (!this.queryInfo.tagname) {
        const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/UserTagList', this.queryInfo)
        this.tagList = res.data.list
        this.total = res.data.totalCount
      } else {
        const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/searchUserTag', {}, {
          params: {
            tagname: this.queryInfo.tagname
          }
        })
        this.tagList = res.data.list
        this.total = res.data.totalCount
      }
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getTagList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getTagList()
    },
    addDialogClosed () {
      this.addForm = {
        tag_id: '',
        tag_name: '',
        level_1_name: '',
        level_2_name: '',
        user_type: '',
        tag_type: '',
        develop_type: '',
        exclusion: '',
        is_online: '',
        tag_desc: ''
      }
    },
    handleAddTag (tagInfo = null) {
      if (!tagInfo) {
        this.addDialogVisible = true
      } else {
        this.addForm = tagInfo
        this.addDialogVisible = true
      }
    },
    addTag () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return null
        if (this.addForm.tag_type !== '1' && this.addForm.tag_type !== '2') this.addForm.tag_type = this.addForm.tag_type === '分类' ? '1' : '2'
        if (this.addForm.develop_type !== '1' && this.addForm.develop_type !== '2') this.addForm.develop_type = this.addForm.develop_type === '算法' ? '1' : '2'
        if (this.addForm.exclusion !== '1' && this.addForm.exclusion !== '2') this.addForm.exclusion = this.addForm.exclusion === '互斥' ? '1' : '2'
        if (this.addForm.is_online !== '1' && this.addForm.is_online !== '2') this.addForm.is_online = this.addForm.is_online === '使用中' ? '1' : '2'
        if (!this.addForm.tag_id) {
          const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/addUserTag', this.addForm)
          console.log('res', res)
        } else {
          const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/updateUserTag', this.addForm)
          console.log('res', res)
        }
        this.$message.success('添加用户成功！')
        this.addDialogVisible = false
        this.getTagList()
      })
    },
    async removeTagById (id) {
      const confirmResult = await this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      // 如果确认删除返回值是 'confirm'
      // 取消了删除，则返回值为字符串 cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/updateUserTag', {}, {
        params: {
          tag_id: id
        }
      })
      console.log('res', res)
      this.$message.success('删除标签成功！')
      this.getTagList()
    }
  }
}
</script>

<style scoped>
.el-card {
  padding: 5px;
}
.el-select {
  width: 100%;
}
</style>
