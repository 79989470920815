<template>
  <!-- 公众号粉丝 -->
  <div class="wechatFriends">
    <div class="nodeTitle">粉丝圈选 </div>
    <div class="row">
      <span>全部用户</span>
      <div style="width:50px;margin-left:20px;">
         <el-checkbox v-model="node.is_to_all"></el-checkbox>
      </div>
    </div>
    <div class="row">
      <span>选择标签</span>
      <div style="width:180px;margin-left:20px;">
       <el-select v-model="node.tag_id" placeholder="请选择">
          <el-option
            v-for="item in tagList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getTagsList } from '@/service/fan'
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      node:{
        is_to_all:false,
        tag_id:''
      },
      tagList: [],
    };
  },
  mounted() {},
  created() {
    this.getTagsList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getTagsList() {
      getTagsList({ pageNo: 1, pageSize: 100,tag:'' }).then(res => {
        this.tagList = res.list
      })
    },
    save() {
      console.log("save -> this.node", this.node);
      if (this.node.is_to_all === "") {
        Message.warning("请检查！配置项未填写完成！");
        return false;
      }
      return this.node;
    },
    init(val) {
      console.log("init -> val", val);
      this.node = val || {
        is_to_all:false,
        tag_id:''
      }
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
.row{
  display: flex;
  align-items: center;
  padding: 5px 0;
  span{
  }
}
</style>
