<template>
  <div>
    <div class="bread-nav"
      @click="$router.go(-1)">
      <i class="el-icon-arrow-right
"></i> 返回规则列表
    </div>
    <Block title="规则详情">
      <dl class="rule-base-info">
        <td>
          <dd>创建者:</dd>
          <dd>{{result.creater}}</dd>
        </td>
        <td>
          <dd>创建时间:</dd>
          <dd>{{result.createTime}}</dd>
        </td>
        <td>
          <dd>任务名称:</dd>
          <dd>{{result.missionName}}</dd>
        </td>
        <td>
          <dd>执行员工:</dd>
          <dd>{{result.executeName}}</dd>
        </td>
        <td>
          <dd>推送内容:</dd>
          <dd>{{result.content}}</dd>
        </td>
        <td>
          <dd>是否已执行:</dd>
          <dd>{{result.pushInfo}}</dd>
        </td>
      </dl>
    </Block>
  </div>
</template>

<script>
import Block from '@/components/Block'
import { getGropRuleDetails } from '@/service/group'
import { getQuery } from '@/utils'
export default {
  data () {
    return {
      result: {}
    }
  },
  created () {
    this._init_getGropRuleDetails()
  },
  methods: {
    _init_getGropRuleDetails () {
      const { id } = getQuery()
      getGropRuleDetails(id).then(res => {
        this.result = res
        console.log(res)
      })
    }
  },
  components: {
    Block
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
