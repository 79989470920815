<template>
  <el-card class="block-wrapper">
    <div slot="header"
      class="title">
      <span>{{title}}</span>
    </div>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {

    }
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
