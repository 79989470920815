import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {
  Message
} from 'element-ui' // 导入 elementUI
import 'element-ui/lib/theme-chalk/index.css' // 导入 css
import * as echarts from 'echarts'
import './global.less'
import moment from 'moment' // 导入文件
import http from '@/Api/config';
import '@/components/ef/index.css'
import '@/components/auto/index.css'
Vue.use(ElementUI, {
  size: 'medium'
})
Vue.prototype.$moment = moment // 赋值使用 就是调用一下

moment.locale('zh-cn') // 这里是进行了汉化s
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.prototype.$moment = moment;
Vue.use(ElementUI)
Vue.prototype.$http = http;
Vue.prototype.$echarts = echarts;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
