<template>
  <Block title="客户阶段"
    class="customer-stage-main">
    <div class="header">
      <div class="list-toglle">
        <div>
          <i class="iconfont icon-liebiaolist46"></i>
        </div>
        <div>
          <i class="iconfont icon-hengxiangtongbi"></i>
        </div>
      </div>
      <el-input placeholder="请输入内容"
        v-model="input3"
        class="input-with-select">
        <el-button slot="append"
          icon="el-icon-search"></el-button>c
      </el-input>
      <div class="total">
        <p>共{{total}}个客户</p>
      </div>
    </div>
    <div class="content-main">
      <div class="dragelist">
        <div class="items"
          v-for="(item,index) in userList"
          :key="item.count">
          <div class="titles">
            <p>{{item.stage}}</p>
            <span>{{item.count}}新客户</span>
          </div>
          <draggable v-model="userList[`${index}`].users"
            animation="300"
            group="site"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            :move="onMove"
            :stage="userList[`${index}`].stage">
            <transition-group type="transition"
              tag="div"
              class="list-group">
              <div class="user"
                v-for="value in userList[`${index}`].users"
                :key="value.externalUserid">
                <div class="tp">
                  <img :src="value.picUrl"
                    alt="">
                  <div class="names">
                    <p>{{value.name}}</p>
                    <span>{{value.addType}}</span>
                  </div>
                </div>
                <div class="follow-up">上次跟进</div>
                <div class="customer">
                  <span>客服：</span>
                  <p>{{value.userId}}</p>
                </div>
                <div class="tags">
                  <el-tag size="mini"
                    v-for="tag in value.tag.split(',')"
                    :key="tag">
                    {{tag}}
                  </el-tag>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/Block'
import { getCustomerStage, updateCustomerStage } from '@/service/customerManage'
import draggable from 'vuedraggable'
export default {
  data () {
    return {
      userList: [],
      total: 0,
      flag: null
    }
  },
  computed: {

  },
  created () {
    this.initDataList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    initDataList () {
      getCustomerStage().then(res => {
        this.userList = res.list
        this.total = res.total
      })
    },
    onMove ({ relatedContext, draggedContext }) {
      clearTimeout(this.flag)
      this.flag = null
      this.flag = setTimeout(() => {
        // 你所更改的
        const relatedElement = relatedContext.component.$attrs.stage
        console.log('%c 🥖 relatedElement: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', relatedElement)
        // 本身
        const draggedElement = draggedContext.element
        console.log('%c 🍈 draggedElement: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', draggedElement)
        updateCustomerStage({
          userId: draggedElement.userId,
          externalUserid: draggedElement.externalUserid,
          tag: draggedElement.tag.split(',').filter(v => v !== draggedElement.stage).join(','),
          nowStage: draggedElement.stage,
          changeStage: relatedElement
        }).then(() => {
          this.initDataList()
        })
      }, 500)
    }
  },
  components: {
    Block,
    draggable
  }
}
</script>

<style  lang="less">
@import url("./index.less");

.chosenClass {
  border: 1px dashed #3498db;
  opacity: 1 !important;
}

.dragClass {
  border: none;
}
</style>
