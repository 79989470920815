<template>
  <div
    ref="node"
    :style="nodeContainerStyle"
    @click="clickNode"
    @mouseup="changeNodeSite"
    :class="nodeContainerClass"
  >
    <el-image :src="nodeContainerImg" fit="contain"></el-image>
    <div class="flow-node-drag auto-node-drge-hover">
      <span :class="nodeIcoClass">{{node.name}}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    node: Object,
    activeElement: Object
  },
  data() {
    return {};
  },
  computed: {
    nodeContainerClass() {
      return {
        "ef-node-container": true,
        "auto-node": true,
        "ef-node-active":
          this.activeElement.type == "node"
            ? this.activeElement.nodeId === this.node.id
            : false
      };
    },
    // 节点容器样式
    nodeContainerStyle() {
      return {
        top: this.node.top,
        left: this.node.left,
        background: "#fff"
      };
    },
    nodeContainerImg() {
      // let img = "";
      // switch (this.node.type) {
      //   case "start":
      //     img = require("@/assets/process/start.png");
      //     // name = "开始";
      //     break;
      //   case "wait":
      //     img = require("@/assets/process/wait.png");
      //     break;
      //   case "wechat":
      //     img = require("@/assets/process/wechatFridens.png");
      //     break;
      //   case "officialEvent":
      //     img = require("@/assets/process/officialEvent.png");
      //     break;
      //   case "wechatMsg":
      //     img = require("@/assets/process/wechatMsg.png");
      //     break;
      //   case "officialMsg":
      //     img = require("@/assets/process/officialMsg.png");
      //     break;
      //   default:
      //     break;
      // }
      return require(`@/assets/process/${this.node.type}.png`);
    },
    nodeIcoClass() {
      var nodeIcoClass = {};
      // nodeIcoClass["el-icon-connection"] = true;
      // 添加该class可以推拽连线出来，viewOnly 可以控制节点是否运行编辑
      nodeIcoClass["flow-node-drag"] = this.node.viewOnly ? false : true;
      return nodeIcoClass;
    }
  },
  methods: {
    // 点击节点
    clickNode() {
      this.$emit("clickNode", this.node.id);
    },
    // 鼠标移动后抬起
    changeNodeSite() {
      // 避免抖动
      if (
        this.node.left == this.$refs.node.style.left &&
        this.node.top == this.$refs.node.style.top
      ) {
        return;
      }
      this.$emit("changeNodeSite", {
        nodeId: this.node.id,
        left: this.$refs.node.style.left,
        top: this.$refs.node.style.top
      });
    }
  }
};
</script>
