<template>
  <div>
    <div class="search-form">
      <el-input placeholder="请输入内容" v-model="introduction" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
      </el-input>
      <el-button type="primary" class="add-btn" icon="el-icon-plus" @click="addMaterial('textForm')">新增</el-button>
    </div>
    <el-dialog :title="isAdd ? '新增内容' : '编辑内容'" :visible.sync="dialogVisible" width="30%">
      <el-form ref="textForm" :model="textForm" v-if="type === 1" label-width="40px">
        <el-form-item label="文本">
          <el-input v-model="textForm.content"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="textForm.introduction"></el-input>
        </el-form-item>
      </el-form>

      <el-form ref="imgForm" :model="imgForm" label-width="40px" v-if="type === 2">
        <el-form-item label="文案">
          <el-input v-model="imgForm.introduction"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <UploadPicture @handleImg="handleImg"></UploadPicture>
        </el-form-item>
      </el-form>

      <el-form ref="fileForm" :model="fileForm" label-width="40px" v-if="type === 3">
        <el-form-item label="文案">
          <el-input v-model="fileForm.introduction"></el-input>
        </el-form-item>
        <el-form-item label="文件">
          <UploadFile @handleFile="handleFile" :resetlist="resetlist"></UploadFile>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onFinish()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTextMaterialLibrary,
  updateMaterial,
  uploadImg,
  uploadDoc
} from "@/service/materialLibrary";
import UploadPicture from "@/components/UploadPicture";
import UploadFile from "@/components/UploadFile";

export default {
  props: {
    type: Number,
    reset: Function,
    currentText: Object
  },
  data () {
    return {
      introduction: "",
      dialogVisible: false,
      textForm: {
        content: "",
        introduction: ""
      },
      imgForm: {
        introduction: "",
        file: {}
      },
      fileForm: {
        introduction: "",
        file: {}
      },
      isAdd: true,
      resetlist: false
    };
  },
  computed: {},
  created () { },
  mounted () { },
  watch: {
    currentText () {
      console.log(this.currentText);
      this.textForm = {
        content: this.currentText.content,
        introduction: this.currentText.introduction
      };
      this.dialogVisible = true;
      this.isAdd = false;
      this.resetlist = false;
    }
  },
  methods: {
    addMaterial () {
      this.textForm = {};
      this.fileForm = {};
      this.dialogVisible = true;
      this.isAdd = true;
    },
    onFinish () {
      if (this.type === 1) {
        if (this.isAdd) {
          addTextMaterialLibrary(this.textForm).then(() => {
            this.reset();
            this.$refs.textForm.resetFields();
          });
          this.dialogVisible = false;
        } else {
          updateMaterial({
            ...this.textForm,
            id: this.currentText.id
          }).then(() => {
            this.reset();
            this.$refs.textForm.resetFields();
            this.$message.success("修改成功");
          });
          this.dialogVisible = false;
        }
      }
      if (this.type === 2) {
        uploadImg(this.imgForm).then(() => {
          this.reset();
          this.$refs.imgForm.resetFields();
        });
        this.dialogVisible = false;
      }

      if (this.type === 3) {
        uploadDoc({ ...this.fileForm }).then(() => {
          this.reset();
          this.$refs.fileForm.resetFields();
          this.resetlist = Math.ceil(Math.random() * 100);
        });
        this.dialogVisible = false;
      }
    },
    handleImg (file) {
      this.imgForm.file = file;
    },
    handleFile (file) {
      this.fileForm.file = file;
    },
    handleSearch () {
      this.reset(this.introduction);
    }
  },
  components: {
    UploadPicture,
    UploadFile
  }
};
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
