<template>
  <!-- 微信群 -->
  <div class="wechatFriends">
    <div class="nodeTitle">微信群圈选</div>
    <div class="title">
          <span></span>添加执行员工
        </div>
     <el-select v-model="value" filterable placeholder="请选择">
      <el-option
        style="width:'200px'"
        v-for="item in userOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
/* eslint-disable */
import {
  getAddressList,
} from "@/service/group";
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      value:'',
      userOptions: [],
    };
  },
  mounted() {},
  created() {
    this.getAddressList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getAddressList() {
      getAddressList({pageNo: 1, pageSize: 100}).then(res => {
        res &&
          res.list.map(item => {
            let index = this.userOptions.findIndex(e => e.value == item.user_id)
            if(index === -1) this.userOptions.push({ label: item.user_name, value: item.user_id });
          });
      });
    },
    save() {
      console.log("save -> this.node", this.value);
      if (this.value === "" ) {
        Message.warning("请添加执行员工！");
        return false;
      }
      return this.value;
    },
    init(val) {
      console.log("init -> val", val);
      this.value = val || ''
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
</style>
