<template>
  <div class="add-template">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="pd20">
      <el-breadcrumb-item>我的消息</el-breadcrumb-item>
      <el-breadcrumb-item>新增消息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="chooseTemp" v-show="tempLate.from.length === 0">
      <i class="iconfont icon-jurassic_add-template"></i>
      <el-button type="success" @click="chooseTemplate">选择模板</el-button>
    </div>
    <div v-show="tempLate.from.length > 0" class="editTempLate">
      <el-form :model="tempLateForm" :rules="tempLateRules" ref="tempLateForm" label-width="100px" class="demo-ruleForm">
        <div class="pd20">
          <span class="title">消息模板</span>
          <el-form-item label="群发名称" prop="missionName">
            <el-input v-model="tempLateForm.missionName" placeholder="仅后台查看，不会发送给粉丝" :disabled="dataDetail.isSend == '1'"></el-input>
          </el-form-item>
        </div>
        <div class="pd20">
          <span class="title">消息内容</span>
          <div class="messageContent">
            <div class="messagePreview">
              <div>
                <span class="previewTitle">消息预览</span>
                <div class="meeting">
                  会议通知
                  <ul>
                    <li v-for="(item, index) in tempLate.from" :key="index">
                      <span v-show="item.lable !== ''" class="lableStyle">{{ item.lable }}:</span>
                      {{ tempLateForm.data[item.prop] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style="width: 75%">
              <el-form-item v-for="(item, index) in tempLate.from" :key="index" :label="item.fromLabel" :prop="item.prop">
                <el-input v-model="tempLateForm.data[item.prop]" :disabled="dataDetail.isSend == '1'"></el-input>
              </el-form-item>
              <div>
                <el-form-item label="点击跳转" prop="link">
                  <el-radio-group v-model="tempLateForm.link" @change="radioChange" :disabled="dataDetail.isSend == '1'">
                    <el-radio :label="1">关闭</el-radio>
                    <el-radio :label="2" @click="showModel(1)">跳转链接</el-radio>
                    <el-radio :label="3" @click="showModel(2)">跳转小程序</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="btnbox">
          <el-form-item>
            <el-button type="primary" @click="showSubmitDiaog">保存</el-button>
            <el-button @click="onCancel">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 选择模板 -->
    <div>
      <el-dialog title="选择模板" :visible.sync="dialogTableVisible">
        <el-table :data="tableData">
          <el-table-column property="date" type="index" label="序号" width="150"></el-table-column>
          <el-table-column property="title" label="标题"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleChoose(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <el-button type="success" @click="getTemplatePrivateList">同步模板</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 填写链接 -->
    <div>
      <el-dialog title="填写跳转链接" :visible.sync="linkShow">
        跳转链接
        <el-input v-model="tempLateForm.url" autocomplete="off"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="linkShow = false">取 消</el-button>
          <el-button type="primary" @click="linkShow = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 添加小程序 -->
    <div>
      <el-dialog title="填写小程序信息" :visible.sync="minProShow">
        <span>
          小程序appid
          <el-input v-model="tempLateForm.miniprogram.appid" autocomplete="off" style="margin-bottom: 10px"></el-input>
        </span>
        页面路径
        <el-input v-model="tempLateForm.miniprogram.pagepath" autocomplete="off"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="minProShow = false">取 消</el-button>
          <el-button type="primary" @click="minProShow = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 发送设置 -->
    <div>
      <el-dialog title="发送设置" :visible.sync="sendShow">
        <div>
          <span class="titleCss">推送设置</span>
          <span class="settingcontent">
            推送对象
            <el-radio-group v-model="tags" :disabled="dataDetail.isSend == '1'" @change="tempLateForm.tags=''">
              <el-radio label="全部">全部</el-radio>
              <el-radio label="按标签选择">按标签选择</el-radio>
            </el-radio-group>
            <el-select v-model="tempLateForm.tags" placeholder="请选择" :filterable="false" size="small" v-show="tags !== '全部'">
              <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </span>

          <span class="titleCss">推送时间</span>
          <span class="settingcontent">
            发送时间
            <el-radio-group v-model="tempLateForm.sendType" :disabled="dataDetail.isSend == '1'">
              <el-radio :label="0">现在</el-radio>
              <el-radio :label="1">定时</el-radio>
            </el-radio-group>
            <span v-show="tempLateForm.sendType === 1">
              <el-date-picker :disabled="dataDetail.isSend == '1'" v-model="tempLateForm.sendTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="expireTimeOption" :clearable="false"></el-date-picker>
            </span>
          </span>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit('tempLateForm')" :disabled="dataDetail.isSend == '1'">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getTemplateList,
  getTemplatePrivateList,
  postTemplateCreate,

} from '@/service/push';
import { getTagsList } from '@/service/fan';
import { Message } from 'element-ui';
import { formatDate } from '@/utils/index'
export default {
  props: ['dataDetail'],
  data () {
    return {
      dialogTableVisible: false,
      tableData: [],
      tempLate: {
        from: [],
        title: ''
      },
      tags: '',
      tagList: [],
      templateId: null,
      tempLateForm: {
        missionName: '',
        type: 0,
        link: 1,
        url: '',
        tags: '',
        sendTime: formatDate(new Date()),
        sendType: 0,
        miniprogram: {
          appid: '',
          pagepath: ''
        },
        data: {}
      },
      tempLateRules: {
        missionName: [
          { required: true, message: '请输入群发名称', trigger: 'blur' }
        ],
        data: {}
      },
      linkShow: false,
      minProShow: false,
      sendShow: false,
      id: null,
      expireTimeOption: {
        disabledDate (date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      }
    };
  },
  computed: {},
  created () {
    this.getTagsList();
  },
  mounted () { },
  watch: {
    dataDetail (val) {
      console.log({ val });
      if (val && val.missionName) {
        this.templateId = val.templateId;
        this.id = val.id
        this.tags = val.tags === '全部' ? '全部' : '按标签选择';
        var tempLateForm = {
          missionName: val.missionName,
          type: 1,
          link: 1,
          url: val.url,
          tags: val.tags,
          sendTime: formatDate(new Date()),
          sendType: val.sendType,
          miniprogram: val.miniprogram
            ? val.miniprogram
            : {
              appid: '',
              pagepath: ''
            },
          data: {}
        };
        if (val.url) {
          tempLateForm.link = 2;
        } else if (val.miniprogram) {
          tempLateForm.link = 3;
        }
        var tempLate = {
          from: [],
          title: ''
        };
        tempLate.title = val.missionName;
        var tempLateRules = {
          missionName: [
            { required: true, message: '请输入群发名称', trigger: 'blur' }
          ],
          data: {}
        };
        if (val.data) {
          var keys = Object.keys(val.data);
          var from = [];
          var content = val.content.split('\n');
          keys.map((item, index) => {
            tempLateForm.data[item] = val.data[item].value;
            var i = index + 1;
            var label = '';
            if (index === 0) {
              label = '';
            } else if (index === keys.length - 1) {
              label = '备注';
            } else {
              label = content[index].split('：')[0];
            }
            var obj = {
              fromLabel: '字段' + i,
              key: i,
              lable: label,
              prop: item,
              value: val.data[item].value
            };
            from.push(obj);
            tempLateRules.data[item] = [
              { required: true, message: '该字段必填', trigger: 'blur' }
            ];
          });
          tempLate.from = from;
        }
        this.tempLateForm = tempLateForm;
        this.tempLate = tempLate;
        this.tempLateRules = tempLateRules;
        console.log(
          'this=>=>=>',
          this.tempLateForm,
          this.tempLate,
          this.tempLateRules
        );
      }
    }
  },
  methods: {
    getTagsList () {
      getTagsList({ pageNo: 1, pageSize: 100, tag: '' }).then(res => {
        this.tagList = res.list;
      });
    },
    chooseTemplate () {
      getTemplateList().then(res => {
        this.tableData = res;
        this.dialogTableVisible = true;
      });
    },
    handleChoose (row) {
      var example = row.example.split('\r\n');
      var content = row.content.split('\n');
      this.templateId = row.templateId;
      var from = [];
      content.map((item, index) => {
        var i = index + 1;
        var obj = {};
        if (index === 0) {
          obj = {
            lable: '',
            key: i,
            fromLabel: '字段' + i,
            value: example[index],
            prop: 'first'
          };
        } else if (index === content.length - 1) {
          obj = {
            lable: '备注',
            key: i,
            fromLabel: '字段' + i,
            value: example[index],
            prop: 'remark'
          };
        } else {
          var value = example[index];
          obj = {
            lable: item.split('：')[0],
            key: i,
            fromLabel: '字段' + i,
            value: value.substring(value.indexOf('：') + 1),
            prop: 'keyword' + index
          };
        }
        from.push(obj);
      });
      // 添加必填控制
      var tempLateRules = {
        missionName: [
          { required: true, message: '请输入群发名称', trigger: 'blur' }
        ],
        data: {}
      };
      var tempLateForm = {
        missionName: row.title,
        type: 0,
        link: 1,
        url: '',
        tags: '',
        sendTime: formatDate(new Date()),
        sendType: 0,
        miniprogram: {
          appid: '',
          pagepath: ''
        },
        data: {}
      };
      from.map((item, index) => {
        // var i = index + 1;
        if (index === 0) {
          tempLateRules.data.first = [
            { required: true, message: '该字段必填', trigger: 'blur' }
          ];
          tempLateForm.data.first = item.value;
        } else if (index === from.length - 1) {
          tempLateRules.data.remark = [
            { required: true, message: '该字段必填', trigger: 'blur' }
          ];
          tempLateForm.data.remark = item.value;
        } else {
          tempLateRules.data['keyword' + index] = [
            { required: true, message: '该字段必填', trigger: 'blur' }
          ];
          tempLateForm.data['keyword' + index] = item.value;
        }
      });
      this.tempLateRules = tempLateRules;
      this.tempLateForm = tempLateForm;
      this.tempLate = { title: row.title, from: from };
      this.dialogTableVisible = false;
    },
    getTemplatePrivateList () {
      getTemplatePrivateList().then(res => {
        Message.success('同步模板成功');
      });
    },
    radioChange (val) {
      this.tempLateForm.link = val;
      switch (val) {
        case 1:
          this.tempLateForm.url = '';
          this.tempLateForm.miniprogram = {
            appid: '',
            pagepath: ''
          };
          break;
        case 2:
          this.linkShow = true;
          break;
        case 3:
          this.minProShow = true;
          break;
        default:
          break;
      }
    },
    showSubmitDiaog () {
      this.sendShow = true;
    },
    onSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.tempLateForm.link === 2 && this.tempLateForm.url === '') {
            Message.warning('请填写跳转链接！');
          } else if (
            this.tempLateForm.link === 3 &&
            (this.tempLateForm.miniprogram.appid === '' ||
              this.tempLateForm.miniprogram.pagepath === '')
          ) {
            Message.warning('请填写小程序信息！');
          } else if (this.tempLateForm.tags == '' && this.tags !== '全部') {
            Message.warning('请选择推送标签！');
          } else {
            var data = {
              type: this.tempLateForm.type,
              missionName: this.tempLateForm.missionName,
              sendType: this.tempLateForm.sendType,
              template_id: this.templateId,
              data: {},
              id: this.id
            };
            if (this.tags !== '全部') {
              data.tags = this.tempLateForm.tags;
            } else {
              data.tags = this.tags;
            }
            if (this.tempLateForm.sendType === 0) {
              data.sendTime = null;
            } else {
              data.sendTime = this.tempLateForm.sendTime;
            }
            if (this.tempLateForm.link === 2) {
              data.url = this.tempLateForm.url;
            } else if (this.tempLateForm.link === 3) {
              data.miniprogram = this.tempLateForm.miniprogram;
            }
            var keys = Object.keys(this.tempLateForm.data);
            keys.map((item, index) => {
              data.data[item] = {
                value: this.tempLateForm.data[item],
                color: '#173177'
              };
            });
            console.log('data', data);
            postTemplateCreate({ ...data }).then(res => {
              Message.success('成功');
              this.onCancel();
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onCancel () {
      this.tempLate = {
        from: [],
        title: ''
      };
      this.tags = '';
      this.tagList = [];
      this.templateId = null;
      this.tempLateForm = {
        missionName: '',
        type: 0,
        link: 1,
        url: '',
        tags: '',
        sendTime: formatDate(new Date()),
        sendType: 0,
        miniprogram: {
          appid: '',
          pagepath: ''
        },
        data: {}
      };
      this.tempLateRules = {
        missionName: [
          { required: true, message: '请输入群发名称', trigger: 'blur' }
        ]
      };
      this.sendShow = false;
      this.$emit('callBack', false);
    },
    showModel (val) {
      console.log('🚀 ~ file: index.vue ~ line 544 ~ showModel ~ val', val)
      switch (val) {
        case 1:
          this.linkShow = true;
          break;
        case 2:
          this.minProShow = true;
          break;
        default:
          break;
      }
    }
  },

  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
