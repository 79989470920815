<template>
  <div class="code-concent">
    <el-radio-group v-model="active" style="margin-bottom: 20px;">
      <el-radio-button :label="1">文本</el-radio-button>
      <el-radio-button :label="2">文件</el-radio-button>
      <el-radio-button :label="3">图片</el-radio-button>
      <el-radio-button :label="4">视频</el-radio-button>
    </el-radio-group>
    <TextHtml v-show="active === 1"></TextHtml>
    <File v-show="active === 2"></File>
    <Pic v-show="active === 3"></Pic>
    <Video v-show="active === 4"></Video>
  </div>
</template>

<script>
import File from './File'
import TextHtml from './Text'
import Pic from './Pic'
import Video from './Video'
export default {
  props: {

  },
  data() {
    return {
      active: 1
    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  watch: {

  },
  methods: {

  },
  components: {
    File,
    TextHtml,
    Video,
    Pic
  }
}
</script>

<style lang="less" >
@import url("./index.less");
</style>
