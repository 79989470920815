<template>
  <div class="wrap">
    <div class="input-list">
      <div class="input-item" draggable="true" v-for="(item,index) in inputList" :key="index" @dragstart="dragStart($event,'add',item)" @dragend="dragEnd($event,'add')">
        <div>
          <img :src="require(`@/assets/form/${item.type}.png`)" alt="" srcset="" width="24px" style="margin-right:10px;">
        </div>
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="input-content" @dragover.prevent="dragEnter($event,null)">
      <div class="empty" v-if="!formList.length">

      </div>
      <el-form ref="form" v-else :model="form" label-width="80px" label-position="top" disabled>
        <div v-for="(item,index) in formList" :key="index" draggable="true" @dragstart="dragStart($event,'form',item,index)" @dragend="dragEnd($event,'form',item)">
          <div style="height:20px;" @dragover.stop="dragEnter($event,item,index+'-1')"></div>
          <div @click="curIndex=index" :class="curIndex===index?'active':''" class="primary" @dragover.stop="dragEnter($event,item,index)">
            <div class="tools"><i class="el-icon-delete" @click.stop="deleteItem(index)"></i></div>
            <el-form-item :label="item.type== 'textarea' ? '' :item.itemName" :rules="['upload','textarea'].includes(item.type)?[]:[{required:item.isMust}]">
              <el-input style="width: 100%;" readonly v-if="item.type== 'input'" :placeholder="item.placeholder" v-model="item.data[0]"></el-input>
              <el-input-number style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'number'" readonly v-model="item.data[0]"></el-input-number>
              <el-select style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'select'" readonly v-model="item.data[0]">
                <el-option v-for="(i,index) in item.checkbox" :key="index" :value="i" readonly></el-option>
              </el-select>
              <el-checkbox-group v-model="item.data" readonly v-if="item.type== 'checkbox'" style="display: flex;flex-direction: column;">
                <el-checkbox v-for="(i,index) in item.checkbox" :key="index" :label="i" readonly></el-checkbox>
              </el-checkbox-group>
              <el-radio-group v-model="item.data[0]" readonly v-if="item.type== 'radio'" style="display: flex;flex-direction: column;">
                <el-radio v-for="(i,index) in item.checkbox" :key="index" :label="i" readonly></el-radio>
              </el-radio-group>
              <el-rate v-model="item.data[0]" readonly v-if="item.type== 'rate'"></el-rate>
              <el-date-picker style="width: 100%;" :placeholder="item.placeholder" v-model="item.data[0]" readonly v-if="item.type== 'date'" type="datetime">
              </el-date-picker>
              <div class="text" v-if="item.type== 'textarea'" :style="{'text-align':item.position,'word-break': 'break-all'}">{{item.data[0]}}</div>
              <div class="" v-if="item.type== 'upload'">
                <img :src="item.url" alt="" width="80px">
              </div>
            </el-form-item>
          </div>
        </div>
        <div style="height:20px;"></div>
      </el-form>
    </div>
    <div class="input-config">
      <div class="header">
        组件属性
      </div>
      <div class="config-content" v-if="curIndex || curIndex === 0">
        <div class="row-item" v-if="formList[curIndex].type==='textarea'">
          <span class="label">描述内容</span>
          <div class="val">
            <el-input type="text" v-model="formList[curIndex].data[0]" placeholder="请输入描述内容"></el-input>
          </div>
        </div>
        <div class="row-item" v-else>
          <span class="label">标题名称</span>
          <div class="val">
            <el-input type="text" v-model="formList[curIndex].itemName" placeholder="请输入标题名称"></el-input>
          </div>
        </div>
        <div class="row-item" v-if="!['rate','checkbox','radio','textarea','upload'].includes(formList[curIndex].type)">
          <span class="label">占位提示</span>
          <div class="val">
            <el-input type="text" v-model="formList[curIndex].placeholder" placeholder="请输入单行文本"></el-input>
          </div>
        </div>
        <div class="row-item" v-if="!['upload','textarea'].includes(formList[curIndex].type)">
          <span class="label">是否必填</span>
          <div class="val">
            <el-switch :active-value="1" :inactive-value="0" v-model="formList[curIndex].isMust" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </div>
        </div>
        <div class="row-item" v-if="['select','radio','checkbox'].includes(formList[curIndex].type)">
          <el-divider content-position="center">选项</el-divider>
          <div class="option-list" v-for="(item,index) in formList[curIndex].checkbox" :key="index">
            <span class="el-icon-s-operation"></span>
            <el-input type="text" v-model="formList[curIndex].checkbox[index]" placeholder="选项名"></el-input>
            <span class="el-icon-remove-outline del-icon" @click="delOption(index)"></span>
          </div>
          <div class="add-option" @click="addOption()">
            <span class="el-icon-circle-plus-outline add-icon"></span>
            <span>添加选项</span>
          </div>
        </div>
        <div class="row-item" v-if="formList[curIndex].type==='upload'">
          <span class="label">上传图片</span>
          <div class="val">
            <upload @change="handleFile" />
          </div>
        </div>
        <div class="row-item" v-if="formList[curIndex].type==='textarea'">
          <span class="label">文字位置</span>
          <div class="val">
            <div class="align-item" :class="formList[curIndex].position == 'left' ? 'active' : ''" @click="formList[curIndex].position = 'left'"><img class="align-icon" src="@/assets/form/left-align.png"></div>
            <div class="align-item" :class="formList[curIndex].position == 'center' ? 'active' : ''" @click="formList[curIndex].position = 'center'"><img class="align-icon" src="@/assets/form/center-align.png"></div>
            <div class="align-item" :class="formList[curIndex].position == 'right' ? 'active' : ''" @click="formList[curIndex].position = 'right'"><img class="align-icon" src="@/assets/form/right-align.png"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Upload from '../components/Upload.vue'
export default {
  props: {
    formData: {
      type: Array,
      default: () => []
    }
  },
  components: { Upload },
  data () {
    return {
      inputList: [
        {
          type: 'input',
          name: '单行文本'
        },
        {
          type: 'number',
          name: '数字'
        },
        {
          type: 'select',
          name: '下拉选择'
        },
        {
          type: 'radio',
          name: '单选框组'
        },
        {
          type: 'checkbox',
          name: '多选框组'
        },
        {
          type: 'date',
          name: '日期时间'
        },
        {
          type: 'rate',
          name: '评分'
        },
        {
          type: 'upload',
          name: '图片选择'
        },
        {
          type: 'textarea',
          name: '描述'
        }
      ],
      formList: [],
      form: {},
      type: '',
      overIndex: null,
      curIndex: null,
      dragItem: {},
      fileList: []
    }
  },
  watch: {
    formData: {
      handler (val) {
        this.formList = val
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    addOption () {
      this.formList[this.curIndex].checkbox.push('选项')
    },
    delOption (index) {
      this.formList[this.curIndex].checkbox.splice(index, 1)
    },
    deleteItem (index) {
      this.curIndex = null
      this.$nextTick(() => {
        if (this.formList.length) {
          this.formList.splice(index, 1)
        } else {
          this.formList = []
        }
        this.$forceUpdate()
      })
    },
    handleFile (url) {
      this.formList[this.curIndex].url = url
    },
    // 交换位置
    term (from, to) {
      if (to > -1 && !isNaN(this.overIndex)) {
        const term = this.formList[to]
        this.formList[to] = this.formList[from]
        this.formList[from] = term
        this.fromIndex = to
        this.$forceUpdate()
      }
    },
    dragEnter (e, data = null, index = -1) {
      // 当overindex不为-1时则排序
      this.overIndex = index
      if (this.type !== 'add') {
        // 表单中的列表排序
        this.term(this.fromIndex, this.overIndex)
      }
    },
    dragStart (e, type, data, index) {
      this.dragItem = data
      this.type = type
      if (type !== 'add') {
        this.fromIndex = index
      } else {
        this.fromIndex = 0
      }
    },
    dragEnd (e, type) {
      if (type === 'add') {
        let data = ['']
        if (this.dragItem.type === 'checkbox') data = []
        if (this.dragItem.type === 'rate') data = [0]
        if (this.dragItem.type === 'textarea') data = ['描述']
        const obj = { ...this.dragItem, data, isMust: 1, placeholder: '请输入' + this.dragItem.name, position: 'left', itemName: this.dragItem.name, id: '', url: '', checkbox: ['选项一', '选项二'] }

        if (this.overIndex === -1) {
          this.formList.push(obj)
          this.dragItem = {}
          this.type = ''
          return;
        }

        if (this.overIndex === 0 || this.overIndex === '0-1') {
          this.formList.unshift(obj)
          return;
        }

        if (!isNaN(this.overIndex) && this.overIndex > -1) {
          this.formList.splice(this.overIndex, 0, obj)
          return;
        }

        if (isNaN(this.overIndex)) {
          const index = this.overIndex.split('-')[0]
          this.formList.splice(index, 0, obj)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  height: 100%;
  display: flex;

  .input-list {
    width: 200px;
    padding: 0 10px;
    height: 100%;
    overflow: auto;
    .input-item {
      font-size: 14px;
      padding: 10px 5px;
      margin-bottom: 5px;
      border: 1px solid #eee;
      border-radius: 4px;
      display: flex;
      align-content: center;
      cursor: pointer;
      > span {
        line-height: 24px;
      }
    }
  }
  .input-content {
    flex: 1;
    background: #ececec;
    padding: 0 20px;
    overflow: auto;
    .empty {
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/form/empty.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
    .primary {
      padding: 8px 16px;
      background-color: #ecf8ff;
      border-radius: 4px;
      border-left: 5px solid #50bfff;
      position: relative;
      &.active {
        // border: 1px dashed #000;
        border-left: 5px solid rgb(80, 191, 255);
        box-shadow: 0 2px 12px 0 rgba(80, 191, 255, 0.4);
      }
      .tools {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-50%, -50%);
        border: 1px solid;
        color: red;
        font-size: 12px;
        width: 15px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        cursor: pointer;
      }
    }
  }
  .input-config {
    width: 300px;
  }
}
</style>
