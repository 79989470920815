<template>
  <!-- 微信好友 -->
  <div class="wechatFriends">
    <div class="nodeTitle">微信好友筛选</div>
    <div>
      <div>
        <div class="title">
          <span></span>性别
        </div>
        <el-select v-model="data.gender" placeholder="请选择" clearable @change="getTagsUserNum">
          <el-option
            v-for="item in genderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="title">
          <span></span>添加渠道
        </div>
        <el-select v-model="data.addChannel" placeholder="请选择" clearable @change="getTagsUserNum">
          <el-option v-for="item in channelList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <div class="title">
          <span></span>排除标签
        </div>
        <el-select v-model="excludeTags" multiple placeholder="请选择" @change="chooseExcludeTag">
          <el-option-group
            v-for="group in tagsOptions"
            :key="group.groupId"
            :label="group.groupName"
          >
            <el-option
              v-for="item in group.tagList"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagName"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <div>
        <div class="title">
          <span></span>添加距今天数
        </div>
        <el-input-number v-model="data.timeFromNow" :min="1" label="天数" @change="getTagsUserNum"></el-input-number>&nbsp;天
        <div class="title">
          <span></span>选择标签
        </div>
        <el-select v-model="tags" clearable multiple placeholder="请选择" @change="chooseTag">
          <el-option-group
            v-for="group in tagsOptions"
            :key="group.groupId"
            :label="group.groupName"
          >
            <el-option
              v-for="item in group.tagList"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagName"
            ></el-option>
          </el-option-group>
        </el-select>
        <div class="title">
          <span></span>添加人
        </div>
        <el-select v-model="data.addId" clearable placeholder="请选择" @change="getTagsUserNum">
          <el-option
            v-for="item in userOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="title">
          <span></span>添加时间
        </div>
        <el-date-picker
          v-model="data.addTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getTagsUserNum"
        ></el-date-picker>
      </div>
    </div>

    <div class="users">
      <el-tag type="success" effect="dark">受众用户数</el-tag>
      <span class="num">{{ data.num }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  getSOPPersonList,
  getTagsUserNum,
  getLabelList
} from "@/service/group";
import { queryAddWay } from "@/service/customerManage";
export default {
  props: ["editnode"],
  data() {
    return {
      data: {
        tags: "",
        num: 0,
        gender: "",
        addChannel: "",
        addTime: null,
        excludeTags: "",
        addId: "",
        timeFromNow: 0
      },
      tags: [],
      genderOptions: [
        {
          value: "男",
          label: "男"
        },
        {
          value: "女",
          label: "女"
        }
      ],
      channelList: [],
      excludeTags: [],
      userOptions: [],
      tagsOptions: []
    };
  },
  mounted() {},
  created() {
    this.getChannelList();
    this.getSOPPersonList();
    this.getLabelList();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    getSOPPersonList() {
      getSOPPersonList().then(res => {
        res &&
          res.map(item => {
            this.userOptions.push({ label: item.userName, value: item.userId });
          });
      });
    },
    getChannelList() {
      queryAddWay().then(res => {
        this.channelList = res;
      });
    },
    getLabelList() {
      getLabelList().then(res => {
        this.tagsOptions = res;
      });
    },
    chooseTag(val) {
      this.tags = val;
      this.data.tags = val.toString();
      this.getTagsUserNum();
    },
    chooseExcludeTag(val) {
      this.excludeTags = val;
      this.data.excludeTags = val.toString();
      this.getTagsUserNum();
    },
    getTagsUserNum() {
      var date = [];
      if (this.data.addTime) {
        date.push(
          this.$moment(this.data.addTime[0]).format("YYYY-MM-DD HH:mm:ss")
        );
        date.push(
          this.$moment(this.data.addTime[1]).format("YYYY-MM-DD HH:mm:ss")
        );
      }
      var data = {
        tags: this.data.tags,
        gender: this.data.gender,
        addTimeRange: date.join(";"),
        timeFromNow: "",
        addChannel: this.data.addChannel,
        excludeTags: this.data.excludeTags,
        addId: this.data.addId
      };
      if (
        data.tags === "" &&
        data.gender === "" &&
        data.addTime === "" &&
        data.timeFromNow === "" &&
        data.addChannel === "" &&
        data.excludeTags === "" &&
        data.addId === ""
      ) {
        return false;
      } else {
        getTagsUserNum({ ...data }).then(res => {
          this.data.num = res;
        });
      }
    },
    init(val) {
      console.log("init -> val", val);
      this.data = val || {
        tags: "",
        num: 0,
        gender: "",
        addChannel: "",
        addTime: null,
        excludeTags: "",
        addId: "",
        timeFromNow: 0
      };
      if (this.data.tags !== "") {
        this.tags = val.tags.split(",");
      }
      if (this.data.excludeTags !== "") {
        this.excludeTags = val.excludeTags.split(",");
      }
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      width: 5px;
      background: #eee;
      height: 18px;
      margin: 8px;
    }
  }
  .el-range-editor--medium.el-input__inner {
    height: 36px;
    width: 95%;
    margin-bottom: 10px;
  }
  .users {
    padding: 15px;

    .num {
      padding: 0 10px;
      font-weight: bold;
    }
  }
}
</style>
