<!-- 表格 -->
<template>
  <div>
    <el-table v-if="!target"
      :data="tableData"
      stripe
      style="width: 945px">
      <el-table-column prop="datadate"
        label="日期">
      </el-table-column>
      <el-table-column v-for="(item,i) in title"
        :key="item"
        :prop="'value'+(i+1)"
        :label="item">
      </el-table-column>
    </el-table>
    <el-table v-else
      :data="tableData"
      stripe
      style="width: 945px">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="child-table">
            <el-table :show-header='false'
              :data="props.row.childrens"
              style="width: 100%">
              <el-table-column prop="datadate">
              </el-table-column>
              <el-table-column v-for="(item,i) in title"
                :key="item"
                :prop="'value'+i">
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="type"
        label="">
      </el-table-column>
      <el-table-column v-for="(item,i) in title"
        :key="item"
        :prop="'value'+i"
        :label="item">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    mapData: Array,
    target: String
  },
  data () {
    /* eslint-disable */
    return {
      tableData: [],
      title: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

    this.filterData()
  },
  methods: {
    filterData () {
      console.log(this.mapData)
      console.log(this.target)
      this.title = this.mapData.transform.legend
      if (!this.target) {
        let tableData = []
        this.mapData.transformTendency.forEach(item => {
          let temp = {}
          temp.datadate = item.datadate
          item.overview.forEach((v) => {
            temp['value' + v.step] = v.users
          })
          tableData.push(temp)
        })
        this.tableData = tableData
      } else {
        let tableData = this.mapData.transform.numUser.map(item => {
          let temp = {}
          temp.type = item.type
          item.num.forEach((v, i) => {
            temp['value' + i] = v
          })
          return temp
        })
        this.tableData = tableData.map(item => {
          item.childrens = []
          this.mapData.transformTendency.forEach(v => {
            if (item.type === v.legend) {
              let temp = {}
              temp.datadate = v.datadate
              v.overview.forEach((m, i) => {
                temp['value' + i] = m.users
              })
              item.childrens.push(temp)
            }
          })
          return item
        })


        console.log(this.tableData)
      }
    }
  },
  watch: {
    mapData () {
      this.filterData()
    }
  }
}
</script>
<style scoped>
.child-table tbody tr td .cell {
  padding-left: 30px !important;
  color: red;
}
</style>