<!-- 线性图 -->
<template>
  <div id="map">暂无数据...</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['mapData'],
  data() {
    /* eslint-disable */
    return {
      option: {
        color: ['#6E9CFA', '#FE5377', '#95FFEC', '#F86F91', '#5C8BFF', '#FCBE64', '#8BE0F0', '#9B81FB', '#FD765D', '#4AB664', '#F38AC3', '#AC71FF', '#F4E56E'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        title: [],
        series: [{}, {}, {}]

      }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // this.refreshMap()
    this.flush()
  },
  methods: {
    refreshMap() {
      const myChart = echarts.init(document.getElementById('map'))
      myChart.setOption(this.option);
    },
    flush() {
      if (this.mapData) {
        let key = []
        let legend = new Set()
        let legends = []
        let isEvent = []
        this.mapData.all.forEach(item => {
          key = [...key, ...Object.keys(item)]
        })
        this.mapData.all.forEach((item, i) => {
          item[key[i]].forEach(v => {
            legend.add(v.legend)
          })
        })
        legends = Array.from(legend)
        console.log(legends, key);
        if (legends.length > 1) {
          this.option.title = []
          key.forEach((item, i) => {
            this.option.title.push({
              subtext: item,
              top: '75%',
              textAlign: 'center'
            })
          })
          this.option.title.forEach((item, i) => {
            if (i === 0) this.option.title[i].left = '16.67%'
            if (i === 1) this.option.title[i].left = "50%"
            if (i === 2) this.option.title[i].left = '83.33%'
          })
          this.mapData.all.forEach((item, i) => {
            let flag = []
            item[key[i]].forEach(v => {
              flag.push({
                ['name']: v.legend,
                value: v.total
              })
            })
            isEvent.push({
              type: 'pie',
              radius: '25%',
              radius: ['20%', '40%'],
              center: ['50%', '50%'],
              data: flag,
              label: {
                position: 'outer',
                formatter: '{b} {d}%',
                bleedMargin: 5,
                labelLine: {
                  length: 5
                }
              },
              top: 0,
              bottom: 0
            })
          })
          isEvent.forEach((item, i) => {
            if (i === 0) {
              isEvent[0].left = '0'
              isEvent[0].right = '66.6667%'
            }
            if (i === 1) {
              isEvent[1].left = '33.3333%'
              isEvent[1].right = '33.3333%'

            }
            if (i === 2) {
              isEvent[2].left = '66.6667%'
              isEvent[2].right = '0'
            }
          })
          console.log(isEvent);
          this.option.series = isEvent
        } else {
          this.option.title = []
          this.mapData.all.forEach((item, i) => {
            item[key[i]].forEach(v => {
              isEvent.push({
                ['name']: key[i],
                value: v.total
              })
            })
          })
          this.option.series = [
            {
              name: '所有用户',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: isEvent
            }
          ]
        }

      }
      this.refreshMap()
    }
  },
  watch: {
    mapData() {
      this.flush()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>