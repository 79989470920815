<!-- 列表 -->
<template>
  <div>
    <div class="mini-message-task" v-if="!showAdd">
      <div class="main-top">
        <el-input
          placeholder="输入搜索关键词"
          prefix-icon="el-icon-search"
          v-model="keywords"
          class="keywordsInput"
          @change="search"
        ></el-input>
        <el-button type="success" @click="ShowAdd">创建任务</el-button>
      </div>
      <el-card
        class="box-card"
        v-for="item in tableData"
        :key="item.missionId"
        v-show="tableData.length > 0"
      >
        <div slot="header" class="clearfix">
          <span>{{ item.missionName }}</span>
        </div>
        <div>
          <el-table :data="changeMap(item)" style="width: 100%">
            <el-table-column
              prop="createTime"
              label="创建时间"
            ></el-table-column>
            <el-table-column prop="sendTime" label="执行时间"></el-table-column>
            <el-table-column prop="creator" label="创建人"></el-table-column>
            <el-table-column
              prop="templateName"
              label="模板名称"
            ></el-table-column>
            <el-table-column
              prop="userNum"
              label="覆盖用户量"
            ></el-table-column>
            <el-table-column label="设置" width="180">
              <template slot-scope="scope">
                <el-button @click="editDate(scope.row)" type="text" size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="deleteData(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageno"
        :page-sizes="[10, 20, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <el-empty :image-size="200" v-show="tableData.length === 0"></el-empty>
    </div>
    <AddPush v-if="showAdd" v-on:goBack="Back" :editData="editData"></AddPush>
  </div>
</template>

<script>
/* eslint-disable */
import {
  Message
} from 'element-ui'
import AddPush from "./component/AddPush";
import { getSubscribeSendList, getSubscribeSendDelete } from "@/service/minipro";
export default {
  data() {
    return {
      tableData: [],
      keywords: "",
      showAdd: false,
      pageno: 1,
      pagesize: 10,
      showLineChart: false,
      mapData: null,
      editData: null,
      total: 0
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getInitList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() { },
  methods: {
    getInitList() {
      var data = { name: this.keywords, pageNo: this.pageno, pageSize: this.pagesize }
      getSubscribeSendList(data).then(res => {
        this.tableData = res.list;
        this.total = res.num
      })
    },
    editDate(row) {
      this.editData = row
      this.showAdd = true
    },
    deleteData(row) {
      var data = { id: row.id }
      getSubscribeSendDelete(data).then(res => {
        Message.success('删除成功')
        this.getInitList();
      })
    },
    ShowAdd() {
      this.editData = null;
      this.showAdd = true;
    },
    Back() {
      this.showAdd = false;
      this.getInitList();
    },
    changeMap(item) {
      let arr = [];
      arr.push(item);
      return arr;
    },
    search() {
      this.getInitList();
    },
    handleCurrentChange(pageNo) {
      this.pageno = pageNo
      this.getInitList()
    },
    handleSizeChange(pageSize) {
      this.pagesize = pageSize
      this.getInitList()
    }
  },
  components: {
    AddPush
  }
};
</script>

<style lang='less'>
@import url("./index.less");
</style>