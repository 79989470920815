<template>
  <div class="sms-package">
    <div class="topBbox">
      <span class="tips">套餐包状态</span>
      <el-radio-group v-model="type" @change="radioChange">
        <el-radio-button label="全部">全部</el-radio-button>
        <el-radio-button label="使用中">使用中</el-radio-button>
        <el-radio-button label="已用完">已用完</el-radio-button>
        <el-radio-button label="已过期">已过期</el-radio-button>
      </el-radio-group>
    </div>
    <div class="tableList">
      <el-table :data="packageList" style="width: 100%">
        <el-table-column prop="packageId" label="套餐包编号"> </el-table-column>
        <el-table-column prop="packageType" label="套餐包名称">
        </el-table-column>
        <el-table-column prop="packageAmount" label="短信显示总量（条）">
        </el-table-column>
        <el-table-column prop="currentUsage" label="使用量（条）">
        </el-table-column>
        <el-table-column prop="packageExpiredTime" label="有效截止时间">
        </el-table-column>
        <el-table-column prop="packageCreateTime" label="创建时间">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-show="scope.row.state === '使用中'" class="acitve"
              >有效</span
            >
            <span v-show="scope.row.state === '已过期'">已过期</span>
            <span v-show="scope.row.state === '已用完'">已用完</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <span class="text">共{{ total }}条</span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        background
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getMessagePackage } from '@/service/sms'
export default {
  components: {
  },
  data() {
    return {
      packageList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      type: '全部'
    }
  },
  computed: {

  },
  created() {
    this.getMessagePackage()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getMessagePackage() {
      var data = { pageNo: this.pageNo, pageSize: this.pageSize, type: this.type === '全部' ? '' : this.type }
      getMessagePackage({ ...data }).then(res => {
        this.packageList = res.list
        this.total = res.num
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getMessagePackage()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getMessagePackage()
    },
    radioChange(value) {
     this.type = value
     this.pageNo = 1
     this.pageSize = 10
     this.getMessagePackage()
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
