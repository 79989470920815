<template>
  <div class="addWordChat">
    <div class="addContent">
      <div class="addTop">
        <div class="back">
          <el-button @click="goBack" size="mini">
            <i class="el-icon-d-arrow-left"></i>返回
          </el-button>
        </div>
        <div class="title">{{rule_id?'修改规则':'新建规则'}}</div>
      </div>
      <div class="addCenter">
        <div class="addCenterBox">
          <p>成员发送的消息包含以下内容时将进行拦截</p>
          <div class="addBox">
            <div class="boxTitle">敏感词</div>
            <div>
              <el-tag
                :key="tag"
                v-for="tag in dynamicTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
              >{{ tag }}</el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              ></el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加敏感词</el-button>
            </div>
          </div>
          <div class="addBox">
            <div class="boxTitle">其他</div>
            <div class="pdl-10">
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="1">手机号码</el-checkbox>
                <el-checkbox :label="2">邮箱地址</el-checkbox>
                <el-checkbox :label="3">红包</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="addCenterBox">
          <p>使用范围</p>
          <div class="addBox">
            <div>
              <span v-show="scopeOfUse.length > 0">
                <el-button
                  class="button-new-tag"
                  size="small"
                  v-for="item in scopeOfUse"
                  :key="item.id"
                >
                  <i v-if="item.children && item.children.length > 0" class="el-icon-folder-opened"></i>
                  <i v-else class="el-icon-user"></i>
                  {{ item.label }}
                </el-button>
              </span>
              <el-link
                type="primary"
                @click="dialogVisible = true"
                class="pdl-10"
                :underline="false"
              >{{ scopeOfUse.length > 0 ? "修改" : "添加" }}</el-link>
            </div>
          </div>
        </div>
        <div class="addCenterBox">
          <p>拦截方式</p>
          <div class="addBox">
            <div class="pdl-10">
              <el-radio v-model="intercept_type" :label="1">警告并拦截发送</el-radio>
              <el-radio v-model="intercept_type" :label="2">仅发警告</el-radio>
            </div>
          </div>
        </div>
        <div class="addCenterBox">
          <p>规则名称</p>
          <div class="addBox">
            <div class="pdl-10">
              <div v-show="!showruleNameEdit" @click="showRuleNameInput">{{ rule_name }}</div>
              <el-input
                v-show="showruleNameEdit"
                v-model="rule_name"
                ref="saveruleNameInput"
                @keyup.enter.native="handleInputRuleNameConfirm()"
                @blur="handleInputRuleNameConfirm()"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="addBottom">
        <el-button type="primary" @click="saveRlue">保存并生效</el-button>
      </div>
    </div>
    <!-- 使用范围弹窗 -->
    <el-dialog
      title="使用范围"
      :visible.sync="dialogVisible"
      :before-close="handledialogClose"
      class="dialogTreeBox"
      width="600px"
    >
      <div class="dialogTree">
        <div class="treeBox">
          <el-input placeholder="搜索成员或部门" v-model="filterText"></el-input>
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <i v-if="data.children && data.children.length > 0" class="el-icon-folder-opened"></i>
                <i v-else class="el-icon-user"></i>
                {{ data.label }}
              </span>
            </span>
          </el-tree>
        </div>
        <div class="viewBox">
          <p>已选择的部门或成员</p>
          <div v-for="item in selectTreeNode" :key="item.id" class="viewList">
            <span>
              <i v-if="item.children && item.children.length > 0" class="el-icon-folder-opened"></i>
              <i v-else class="el-icon-user"></i>
              {{ item.label }}
            </span>
            <span class="closebtn">
              <i class="el-icon-close" @click="delSelectTreeNode(item.id)"></i>
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";
import {
  postAddInterceptRule,
  postUpdateInterceptRule,
  getDepartmentInfo
} from "@/service/enterprise";
export default {
  props: ["editData"],
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      checkList: [1, 2, 3],
      intercept_type: 1,
      rule_name: "规则名称",
      showruleNameEdit: false,
      dialogVisible: false,
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      selectTreeNode: [],
      scopeOfUse: [],
      rule_id: null
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getDepartmentInfo();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    editData() {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.editData) {
        this.rule_name = this.editData.ruleName;
        this.rule_id = this.editData.ruleId;
        this.dynamicTags = this.editData.wordList;
        this.intercept_type =
          this.editData.interceptType === "仅发警告" ? 2 : 1;
        this.setScopeOfUse(this.editData.departList,this.editData.userList)
        if (
          this.editData.semanticsList &&
          this.editData.semanticsList.length > 0
        ) {
          var semanticsList = [];
          this.editData.semanticsList.map(item => {
            switch (item) {
              case "手机号":
                semanticsList.push(1);
                break;
              case "邮箱地":
                semanticsList.push(2);
                break;
              case "红包":
                semanticsList.push(3);
                break;
              default:
                break;
            }
          });
          this.checkList = semanticsList;
        }
      } else {
        this.rule_name = "规则名称";
        this.rule_id = null;
        this.dynamicTags = [];
        this.intercept_type = 1;
        this.checkList = [1, 2, 3];
        this.selectTreeNode = [];
        this.scopeOfUse = [];
      }
    },
    getDepartmentInfo() {
      getDepartmentInfo().then(res => {
        this.setTreeData(res);
      });
    },
    setScopeOfUse(departList,userList){
      var result = []
      departList&&departList.map(item=>{
         result.push(this.treeData.filter(i=>i.departmentName === item)[0])
      })
      var childrenList = []
      this.treeData.map(item=>{
        childrenList.concat(item.children)
      })
      userList&&userList.map(item=>{
         childrenList.push(childrenList.filter(i=>i.userName === item)[0])
      })
       this.scopeOfUse = result
       this.selectTreeNode = result
    },
    setTreeData(arr, parentId) {
      if (parentId) {
        arr.map((item, index) => {
          item.id = parentId + "-" + index;
          item.label = item.userName;
        });
      } else {
        arr.map((item, index) => {
          item.id = item.departmentId;
          item.label = item.departmentName;
          if (item.children.length > 0) {
            this.setTreeData(item.children, item.departmentId);
          }
        });
      }
      this.treeData = arr;
    },
    goBack() {
      this.$emit("goBack", false);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showRuleNameInput() {
      this.showruleNameEdit = true;
      this.$nextTick(_ => {
        this.$refs.saveruleNameInput.$refs.input.focus();
      });
    },
    handleInputRuleNameConfirm() {
      this.showruleNameEdit = false;
    },
    handledialogClose() {
      this.dialogVisible = false;
      this.filterText = "";
    },
    handleConfirm() {
      this.scopeOfUse = this.selectTreeNode;
      console.log("handleConfirm -> this.scopeOfUse", this.scopeOfUse)
      this.handledialogClose();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data, node, element) {
      if (this.selectTreeNode.filter(i => i.id == data.id).length === 0) {
        this.selectTreeNode.push(data);
      }
    },
    delSelectTreeNode(id) {
      this.selectTreeNode = this.selectTreeNode.filter(i => i.id != id);
    },
    saveRlue() {
      var user_list = []
      var department_list = []
      this.scopeOfUse.map(item=>{
        if(item.children){
          department_list.push(item.departmentId)
        }else{
         user_list.push(item.userId)
        }
      })
      var data = {
        rule_name: this.rule_name,
        word_list: this.dynamicTags,
        semantics_list: this.checkList,
        intercept_type: this.intercept_type,
        applicable_range: {
          user_list: user_list,
          department_list: department_list
        }
      };
      if (this.rule_id) {
        data.rule_id = this.rule_id
        // 修改
        postUpdateInterceptRule(data).then(res => {
          Message.success("修改成功");
          this.goBack();
        });
      } else {
        // 新增
        postAddInterceptRule(data).then(res => {
          Message.success("添加成功");
          this.goBack();
        });
      }
    }
  },
  components: {}
};
</script>

<style lang='less'>
@import url("./index.less");
</style>