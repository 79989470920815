import { get, post } from '../Api/axios'
import {
  URL_OFFICIAL_SOP_TEMPLATE_MISSION_LIST,
  URL_OFFICIAL_SOP_TEMPLATE_LIST,
  URL_OFFICIAL_SOP_TEMPLATE_PRIVATE_LIST,
  URL_OFFICIAL_SOP_TEMPLATE_MISSION_CREATE,
  URL_OFFICIAL_SOP_TEMPLATE_MISSION_DELETE,
  URL_OFFICIAL_SOP_TEMPLATE_LOG_LIST
} from '@/Api/api.js'

export function getTemplateMissionList(params = {}) {
  return get(URL_OFFICIAL_SOP_TEMPLATE_MISSION_LIST, {
    ...params
  })
}

export function getTemplatePrivateList(params = {}) {
  return get(URL_OFFICIAL_SOP_TEMPLATE_PRIVATE_LIST, {
    ...params
  })
}

export function postTemplateCreate(params = {}) {
  return post(URL_OFFICIAL_SOP_TEMPLATE_MISSION_CREATE, {
    ...params
  })
}

export function getTemplateList(params = {}) {
  return get(URL_OFFICIAL_SOP_TEMPLATE_LIST, {
    ...params
  })
}

export function delTemplate(params = {}) {
  return get(URL_OFFICIAL_SOP_TEMPLATE_MISSION_DELETE, {
    ...params
  })
}

export function getLogList(params = {}) {
  return get(URL_OFFICIAL_SOP_TEMPLATE_LOG_LIST, {
    ...params
  })
}
