<template>
  <div class="MiniAccess">
    <div class="selectRow">
      <div>
        <el-select
          v-model="selectValue"
          placeholder="请选择"
          :clearable="false"
          @change="typeChange"
        >
          <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="pd10">
        <el-select
          v-model="dateType"
          placeholder="请选择"
          :clearable="false"
          @change="dateTypeChange"
        >
          <el-option
            v-for="item in selectdateTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false"
          @change="dateChange"
          :disabled="dateType === 1"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
    </div>
    <div id="lineChart"></div>
  </div>
</template>

<script>
import { getDailyVisitTrend } from "@/service/minipro";
import * as echarts from "echarts";
import moment from "moment";
export default {
  data() {
    return {
      selectValue: 1,
      selectOption: [
        { label: "打开次数", value: 1 },
        { label: "访问次数", value: 2 },
        { label: "访问人数", value: 3 },
        { label: "新用户数", value: 4 },
        { label: "人均停留时长", value: 5 },
        { label: "平均访问深度", value: 6 }
      ],
      date: [
        moment(new Date())
          .add(-7, "d")
          .format("YYYY-MM-DD"),
        moment(new Date())
          .add(-1, "d")
          .format("YYYY-MM-DD")
      ],
      dateType: 1,
      selectdateTypeOption: [
        {
          value: 1,
          label: "近7天"
        },
        {
          value: 2,
          label: "自定义"
        }
      ],
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) < new Date(v);
        }
      },
      option: {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#3CA1FF"
            },
            areaStyle: {
              color: "#E8F2FF"
            }
          }
        ]
      }
    };
  },
  computed: {},
  created() { },
  mounted() {
    var date = this.getBetweenDate(this.date[0], this.date[1]);
    this.init(date);
  },
  watch: {},
  methods: {
    init(dateList) {
      const data = [];
      dateList.map(item => {
        var obj = {
          begin_date: moment(item).format("YYYYMMDD"),
          end_date: moment(item).format("YYYYMMDD")
        };
        getDailyVisitTrend(obj).then(res => {
          var list = res.list[0];
          switch (this.selectValue) {
            case 1: // 1.session_cnt 打开次数
              data.push(list.session_cnt ? list.session_cnt : 0);
              break;
            case 2: // 2.visit_pv 访问次数
              data.push(list.visit_pv ? list.visit_pv : 0);
              break;
            case 3: // 2.visit_uv 访问人数
              data.push(list.visit_uv ? list.visit_uv : 0);
              break;
            case 4: // 4.新用户数
              data.push(list.visit_uv_new ? list.visit_uv_new : 0);
              break;
            case 5: // 5.stay_time_uv 人均停留时长
              data.push(list.stay_time_uv ? list.stay_time_uv : 0);
              break;
            case 6: // 6.visit_depth 平均访问深度
              data.push(list.visit_depth ? list.visit_depth : 0);
              break;
            default:
              break;
          }
          if (data.length === dateList.length) {
            this.setChart(data, dateList);
          }
        });
      });
    },
    setChart(data, dateList) {
      this.option.xAxis.data = dateList;
      this.option.series[0].data = data;
      var chartDom = document.getElementById("lineChart");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
    getBetweenDate(startTime, endTime) {
      // 校验时间格式
      if (typeof startTime === "string") {
        const reg = /^\d{4}-(0?[1-9]|1[0-2])-((0?[1-9])|((1|2)[0-9])|30|31)$/g;
        var msg = { message: `开始时间：${startTime}时间格式错误` };
        if (!reg.test(startTime)) throw msg;
      }
      if (typeof endTime === "string") {
        const reg = /^\d{4}-(0?[1-9]|1[0-2])-((0?[1-9])|((1|2)[0-9])|30|31)$/g;
        var msg_ = { message: `结束时间：${endTime}时间格式错误` };
        if (!reg.test(endTime)) throw msg_;
      }
      var start = new Date(startTime);
      var end = new Date(endTime);
      const resultTime = [];
      // 当 开始时间小于结束时间的时候进入循环
      while (start <= end) {
        var getDay = start.getDate();
        // 月份需要加 1
        var getMonth = start.getMonth() + 1;
        var getYear = start.getFullYear();
        /**
         * 拼接时间格式
         * (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) 自动给 小于10的时间前面补0
         */
        var setTime =
          `${getYear}-` +
          (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) +
          "-" +
          (getDay >= 10 ? `${getDay}` : `0${getDay}`);

        resultTime.push(setTime);
        /**
         * 重新设置开始时间
         * 使用 setFullYear() 方法会自动将时间累加，返回的是时间戳格式
         * 使用 new Date() 将时间重新设置为标准时间
         * getMonth - 1 将月份时间重新还原
         */
        start = new Date(start.setFullYear(getYear, getMonth - 1, getDay + 1));
      }
      console.log("getBetweenDate -> resultTime", resultTime);
      return resultTime;
    },
    dateTypeChange() {
      if (this.dateType === 1) {
        this.date = [
          moment(new Date())
            .add(-7, "d")
            .format("YYYY-MM-DD"),
          moment(new Date())
            .add(-1, "d")
            .format("YYYY-MM-DD")
        ];
        var date = this.getBetweenDate(this.date[0], this.date[1]);
        this.init(date);
      }
    },
    typeChange() {
      var date = this.getBetweenDate(this.date[0], this.date[1]);
      this.init(date);
    },
    dateChange() {
      var date = this.getBetweenDate(this.date[0], this.date[1]);
      this.init(date);
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
