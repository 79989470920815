<template>
  <div class="h5-form">
    <div class="header">
      <div class="userInfo">
        <div class="avatar">
          <el-avatar :size="65" :src="userInfo.headimgurl"></el-avatar>
        </div>
        <div class="info">
          <div class="name">{{ userInfo.nickname }}</div>
          <div class="rank">排名：{{ userInfo.rank }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="card">
        <div class="title">
          <div class="text">邀请进度</div>
          <div class="right blue" @click="receiveAward">领奖</div>
        </div>
        <div class="process">
          <div class="line">
            <div class="inner" :style="{'width': percent+'%'}"></div>
          </div>
          <div class="box">
            <span v-if="info.remain == 0">已完成</span>
            <span class="status" v-else>未完成</span>
            <img src="../../../assets/box.png">
          </div>
        </div>
        <div class="process_text">已邀请<span class="blue">{{info.friendsHelpNum}}人</span>,还差<span class="blue">{{info.remain}}人</span>完成任务</div>
        <div class="activity_btn">
          <div class="btn" v-if="!info.isEnd" @click="handleJoin">立即参加活动</div>
          <div class="btn_end" v-else>活动已结束</div>
        </div>
      </div>
      <div class="card">
        <div class="tabs">
          <div class="tabs_item" v-for="(item,index) in tabs" :key="index" :class="[`${tabsIndex == index ? 'active' : ''}`]" @click="tabsIndex = index">{{item.title}}</div>
        </div>
        <div class="info">
          <div class="introduction" v-show="tabsIndex == 0">
            <div class="intro_item" v-html="info.introduction" style="white-space:pre-line;"></div>
            <div class="intro_item" v-html="info.endTime" style="white-space:pre-line;"></div>
          </div>
          <div class="rank" v-show="tabsIndex == 1">
            <el-table :data="info.popularityRank" :header-cell-style="{ background: '#F9F9F9' }">
              <el-table-column class-name="rank-box" type="index" width="50" label="排名" align="center">
                <template v-slot="{$index}">
                  <img class="rank-icon" v-if="$index===0" src="../../../assets/rank1.svg" alt="" width="100%">
                  <img class="rank-icon" v-else-if="$index===1" src="../../../assets/rank2.svg" alt="" width="100%">
                  <img class="rank-icon" v-else-if="$index===2" src="../../../assets/rank3.svg" alt="" width="100%">
                  <span v-else>{{$index+1}}</span>
                </template>
              </el-table-column>
              <el-table-column label="用户信息" width="150" align="left">
                <template slot-scope="{row}">
                  <div class="rankUser">
                    <img :src="row.openidPicUrl" class="avatar">
                    <span>{{row.nickname}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="邀请" align="center">
                <template slot-scope="{row}">
                  <span class="blue">{{row.num}}人</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="friendsHelp" v-show="tabsIndex == 2">
            <el-table :data="info.friendsHelp" empty-text="还没有好友为你助力哦~" :header-cell-style="{ background: '#F9F9F9' }">
              <el-table-column label="邀请好友" width="150" align="left">
                <template slot-scope="{row}">
                  <div class="rankUser">
                    <span>{{row.openid}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center">
                <template slot-scope="{row}">
                  <span class="blue">{{row.subscribeTime}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <poster v-show="visible" @close="visible = false" ref="poster" />
    <!-- <div class="isEnd" v-if="info.isEnd">
      活动已结束
    </div> -->
  </div>
</template>

<script>
import {
  getFissionActivityUserSearch
  //  getFissionActivityUserCreatePoster
} from '@/service/activity';
import { getOpenid } from '@/service/customerForm';
import { getOfficialAccountUpdateList } from '@/service/enterprise';
import poster from './poster.vue'
export default {
  name: '',
  components: { poster },
  data () {
    return {
      userInfo: {
        headimgurl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        nickname: '',
        rank: ''
      },
      info: {},
      tabs: [{ title: '活动详情' }, { title: '人气排行' }, { title: '助力好友' }],
      tabsIndex: 0,
      url: '',
      secret: '',
      appid: '',
      openid: '',
      redirect_uri: '',
      status: 1, // 1 正常 0 草稿 2 结束
      visible: false
    }
  },
  created () {
    this.getCode()
    // this.init()
  },
  computed: {
    percent () {
      return (this.info.friendsHelpNum / (this.info.friendsHelpNum + this.info.remain)) * 100 || 0
    }
  },
  methods: {
    async init () {
      const res = await getFissionActivityUserSearch({ openid: this.openid })
      this.info = res
      this.userInfo.rank = res.rank
      if (this.info.isEnd) {
        this.$message.error('活动已结束')
      }
    },
    async getCode () {
      const code = this.getUrlParam('code')
      const res = await getOfficialAccountUpdateList()
      if (code === 'null' || code === null || code === '') {
        this.secret = res.app_secret
        this.appid = res.app_id
        this.redirect_uri = res.redirect_uri_fission
        const APPID = res.app_id
        const scope = 'snsapi_userinfo'; // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
        const url = 'https://open.weixin.qq.com' + '/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(this.redirect_uri) + '+%0A&response_type=code&scope=' + scope + '&state=#wechat_redirect';
        this.url = url
        window.location.href = url
      } else {
        this.getOpenId(code) // 把code传给后台获取用户信息
      }
    },
    async getOpenId (code) {
      getOpenid({ code }).then(data => {
        // if (data.errcode) {
        //   this.getCode(true)
        // } else {
        this.openid = data.openid
        this.userInfo = data
        this.init()
        // }
      }).catch(() => {
        console.log(1111);
      })
    },
    getUrlParam (key) {
      const hash = location.hash
      if (hash) {
        return this.getHashParam(key)
      } else {
        return this.getHistoryParam(key)
      }
    },
    getHashParam (key) {
      const url = location.href
      // 获取 hash 值，不包含 '#' 号
      const hash = url.substring(url.indexOf('#') + 1)
      // 查找 '?' 号所在的索引
      const searchIndex = hash.indexOf('?')
      // '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
      const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ''
      // 把搜索参数字符串提过URLSearchParams转成对象形式
      const usp = new URLSearchParams(search)
      // 通过URLSearchParams自带的get方法，查询键所对应的值
      return usp.get(key)
    },
    // 获取url中的参数
    getHistoryParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); // 构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); // 匹配目标参数
      if (r != null) {
        return unescape(r[2]);
      }
      return null; // 返回参数值
    },
    handleJoin () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.poster.init({ headimgurl: this.userInfo.headimgurl, visible: true, nickname: this.userInfo.nickname, openidPic: this.userInfo.headimgurl, openid: this.openid })
      })
    },
    receiveAward () {
      if (this.info.remain == 0) {
        this.$alert(this.info.countersign, '', {
          // cancelButtonText: '取消',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          // type: 'warning',
          customClass: 'h5_message_box',
          center: true
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@h_height: 250px;

.h5-form {
  background-color: #f5f5f5;
  min-height: 100vh;
  .header {
    height: @h_height;
    background-color: #2d7ffb;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .userInfo {
      display: flex;
      align-items: center;
      height: 60px;
      .avatar {
        margin-right: 20px;
      }
      .info {
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        div {
          height: 30px;
        }
      }
    }
  }
  .content {
    padding: 0 20px;
    position: relative;
    z-index: 1;
    top: calc(@h_height / 2);
    .card {
      background: #fff;
      padding: 20px;
      font-size: 14px;
      border-radius: 5px;
      margin-bottom: 20px;
      :nth-last-child(1) {
        margin-bottom: 0;
      }

      .title {
        display: flex;
        justify-content: space-between;
        .text {
          font-weight: 600;
        }
        .right {
          font-size: 12px;
        }
      }

      .process {
        height: 100px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 0 0;
        .line {
          width: 100%;
          height: 15px;
          border-radius: 20px;
          background-color: #e8e8e8;
          .inner {
            height: 100%;
            background: #3f8bfe;
            border-radius: 20px;
          }
        }
        .box {
          position: absolute;
          right: 0;
          top: 10px;
          display: flex;
          flex-direction: column;
          .status {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 18px;
            color: #a1a1a1;
            text-align: center;
          }
        }
      }

      .process_text {
        text-align: center;
        margin-bottom: 10px;
      }

      .activity_btn {
        .btn {
          width: 60%;
          text-align: center;
          margin: 0 auto;
          // background: #2d7ffb;
          background: linear-gradient(#3f8bfe 0%, #1d77ff 100%);
          color: #fff;
          padding: 12px 20px;
          border-radius: 25px;
        }
        .btn_end {
          width: 60%;
          text-align: center;
          margin: 0 auto;
          background: #e1e1e1;
          color: #000;
          padding: 12px 20px;
          border-radius: 25px;
        }
      }

      .tabs {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        .active {
          color: #1d77ff;
          height: 38px;
          border-bottom: 2px solid;
        }
      }
      .info {
        padding: 20px 0;
        .intro_item {
          margin-bottom: 20px;
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
        .rankUser {
          display: flex;
          align-items: center;
        }
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}

.blue {
  color: #2d7ffb;
}

/deep/ .el-table__empty-text {
  width: 100%;
}
/deep/ .el-table .rank-box .cell {
  display: flex;
}
.rank-icon {
  width: 30px;
  height: 30px;
}

.isEnd {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: #a1a1a1ec;
  z-index: 9;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 100vh;
}
</style>

<style>
.el-message-box.h5_message_box {
  width: auto;
}
</style>