import {
  get,
  post
} from '../Api/axios'
import {
  URL_XIAOETONG_BACKEND_CONFIG_INFO,
  URL_XIAOETONG_BACKEND_EDIT_CONFIG_INFO,
  URL_XIAOETONG_BACKEND_GET_USER_LIST,
  URL_XIAOETONG_BACKEND_SYNC_USER_LIST,
  URL_XIAOETONG_BACKEND_GET_ORDER_LIST
} from '@/Api/api.js'

export function getXiaoErtongBackendGetConfig (params = {}) {
  return get(URL_XIAOETONG_BACKEND_CONFIG_INFO, {
    ...params
  })
}

export function postXiaoErtongBackendUpdateConfig (params = {}) {
  return post(URL_XIAOETONG_BACKEND_EDIT_CONFIG_INFO, {
    ...params
  })
}

export function postXiaoErtongBackendGetUsreList (params = {}) {
  return post(URL_XIAOETONG_BACKEND_GET_USER_LIST, {
    ...params
  })
}

export function getXiaoErtongBackendSyncUsreList (params = {}) {
  return get(URL_XIAOETONG_BACKEND_SYNC_USER_LIST, {
    ...params
  })
}

export function postXiaoErtongBackendGetOrderList (params = {}) {
  return post(URL_XIAOETONG_BACKEND_GET_ORDER_LIST, {
    ...params
  })
}
