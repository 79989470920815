import { get, post } from '../Api/axios'
import {
  URL_FISSION_ACTIVITY_LIST,
  URL_FISSION_ACTIVITY_CREATE,
  URL_FISSION_ACTIVITY_DELETE,
  URL_FISSION_ACTIVITY_USER_CREATE_POSTER,
  URL_FISSION_ACTIVITY_USER_SEARCH
} from '@/Api/api.js'

export function getFissionActivityList (params = {}) {
  return post(URL_FISSION_ACTIVITY_LIST, {
    ...params
  })
}

export function getFissionActivityCreate (params = {}) {
  return post(URL_FISSION_ACTIVITY_CREATE, {
    ...params
  })
}

export function getFissionActivityDelete (params = {}) {
  return get(URL_FISSION_ACTIVITY_DELETE, {
    ...params
  })
}

export function getFissionActivityUserCreatePoster (params = {}) {
  return get(URL_FISSION_ACTIVITY_USER_CREATE_POSTER, {
    ...params
  })
}

export function getFissionActivityUserSearch (params = {}) {
  return get(URL_FISSION_ACTIVITY_USER_SEARCH, {
    ...params
  })
}
