<template>
  <Block title="群SOP">
    <div class="header-top">
      <div class="create-rule">
        <el-button type="primary"
          @click="$router.push('/personalSop/create/rule')">创建规则</el-button>
      </div>
      <div class="search">
        <el-input placeholder="输入要搜索的名称"
          v-model="input3"
          class="input-with-select">
          <el-button slot="append"
            icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData"
      style="width: 100%">
      <el-table-column prop="sopName"
        label="名称">
      </el-table-column>
      <el-table-column prop="creatorName"
        label="创建人">
      </el-table-column>
      <el-table-column prop="createTime"
        label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
            :style="{marginRight:'10px'}"
            @click="$router.push(`/personalSop/rule/details?id=${scope.row.id}`)">详情</el-button>
          <el-popconfirm title="确定要删除当前群规则嘛？"
            @confirm="removeRule(scope.row.id)">
            <el-button type="text"
              slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total"
      runList="getGroupSopList"></Pagination>
  </Block>
</template>

<script>
import Block from '@/components/Block'
import Pagination from '@/components/Pagination'
import { getPersonalSopPageList, removePersonalSOPRule } from '@/service/group'
export default {
  props: {

  },
  data () {
    return {
      total: 0,
      tableData: []
    }
  },
  computed: {

  },
  created () {
    this.getGroupSopList(1, 10)
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    getGroupSopList (pageNo, pageSize, ...props) {
      getPersonalSopPageList({ pageNo, pageSize, ...props }).then(res => {
        this.tableData = res.info
        this.total = res.num
      })
    },
    removeRule (id) {
      removePersonalSOPRule(id).then(() => {
        this.$message.success('删除成功')
        this.getGroupSopList(1, 10)
      })
    }
  },
  components: {
    Block,
    Pagination
  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
