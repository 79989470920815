<template>
  <div class="addSmsTemplate">
    <el-dialog title="创建正文模板"
      :visible.sync="dialogFormVisible"
      @close="closeDia()">
      <el-form :model="form"
        :rules="rules"
        ref="ruleForm">
        <el-form-item label="模板名称"
          prop="templateName">
          <el-input v-model="form.templateName"></el-input>
        </el-form-item>
        <el-form-item label="特殊资源"
          prop="smsType">
          <el-radio v-model="form.smsType"
            label="0">普通短信</el-radio>
          <el-radio v-model="form.smsType"
            label="1">营销短信</el-radio>
        </el-form-item>
        <el-form-item label="短信内容"
          prop="templateContent"
          class="addSmsTemplate__content">
          <div class="addSmsTemplate__short-href">生成短链接</div>
          <el-input type="textarea"
            v-model="form.templateContent"
            :rows='5'
            resize="none"
            maxlength="490"
            show-word-limit
            placeholder="请填写短信内容"></el-input>
          <div style="line-height:15px;margin-top:20px">当前模板预计发送条数为<span style="color:red">1</span>条短信</div>
          <div style="color:red;line-height:15px">(实际发送时，签名和模板变量会影响计费条数，请特别关注)</div>
          <div style="line-height:20px;margin-top:10px">1.短信长度(签名+正文)不超过70字时，按照1条短信计费；超过70字即为长短信时，按67字/条分隔成多条计费。例如，短信为150字，则按照67字/67字/16字分隔成3条计费</div>
          <div style="line-height:20px;">2.营销类短信，请在短信内容后面增加“回T退订”</div>
          <div style="line-height:20px;margin-bottom:30px">3.短信模板内容不能含有【】符号</div>
        </el-form-item>
        <el-form-item label="申请说明">
          <el-input type="textarea"
            v-model="form.remark"
            :rows='5'
            resize="none"
            maxlength="300"
            show-word-limit
            placeholder="请描述您的业务使用场景"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="closeDia()">取 消</el-button>
        <el-button type="primary"
          @click="addSmstemplate('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSmsTemplate, editSmsTemplate } from '@/service/sms'

export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    editList: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      form: {
        smsType: '0',
        templateName: '',
        templateContent: '',
        remark: '',
        templateId: ''
      },
      rules: {
        templateName: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        smsType: [
          { required: true, message: '请选择特殊资源', trigger: 'blur' }
        ],
        templateContent: [
          { required: true, message: '请输入短信内容', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    editList (val) {
      this.form.smsType = (val.smsType === '普通短信' || val.smsType === undefined) ? '0' : '1'
      this.form.templateName = val.templateName || ''
      this.form.templateContent = val.templateContent || ''
      this.form.remark = val.remark || ''
      this.form.templateId = val.templateId || ''
    }
  },
  methods: {
    closeDia () {
      this.$emit('closeSmsTemplate')
    },
    addSmstemplate (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editList && Object.keys(this.editList).length > 0) {
            editSmsTemplate(this.form).then(e => {
              this.$message.success('修改成功')
              this.$emit('getTextPageList')
            })
          } else {
            addSmsTemplate(this.form).then(e => {
              this.$message.success('新增成功')
              this.$emit('getTextPageList')
              this.form.smsType = '0'
              this.form.templateName = ''
              this.form.templateContent = ''
              this.form.remark = ''
            })
          }
          this.closeDia()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped>
.el-form-item {
  display: flex;
}
/deep/ .el-form-item__content {
  flex: 1;
}
/deep/ .el-dialog {
  width: 600px;
}
.addSmsTemplate__short-href {
  border: 1px solid #dcdfe6;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #409eff;
  margin-bottom: 10px;
}
/deep/ .addSmsTemplate__content .el-form-item__error {
  top: 38.5% !important;
}
</style>
