<template>
  <div class="">
    <el-card>
      <div style="display: flex">
        <div class="box-card">
          <div class="title-name">
            <span>公众号菜单</span>
          </div>
          <div class="foot-btn">
            <!--            <el-popover-->

            <!--              v-model="showBtn1"-->
            <!--              placement="top"-->
            <!--              trigger="manual"-->
            <!--              width="100"-->
            <!--            >-->
            <div class="tips-box">
              <div class="tips" v-if="showBtn1">
                <div v-for="(it, idx) in btnList[0] && btnList[0].sub_button" :key="idx" class="my-popover" @click="
                    chooseMsg(btnList[0] && btnList[0], 0, 'editSub', idx)
                  ">
                  {{ it.name || "" }}
                </div>
                <div class="my-popover san" @click="chooseMsg(btnList[0] && btnList[0], 0, 'addSub')">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <div v-if="btnList[0]" slot="reference" class="btn" @click="chooseMsg(btnList[0] && btnList[0], 0, 'editList')">
                {{ btnList[0].name || "" }}
              </div>
              <div v-else slot="reference" class="btn" @click="chooseMsg(btnList[0] && btnList[0], 0, 'addList')">
                <i class="el-icon-plus"></i>
              </div>
            </div>
            <div class="tips-box">
              <div class="tips" v-if="showBtn2">
                <div v-for="(it, idx) in btnList[1] && btnList[1].sub_button" :key="idx" class="my-popover" @click="
                    chooseMsg(btnList[1] && btnList[1], 1, 'editSub', idx)
                  ">
                  {{ it.name || "" }}
                </div>
                <div class="my-popover san" @click="chooseMsg(btnList[1] && btnList[1], 1, 'addSub')">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <div v-if="btnList[1]" slot="reference" class="btn" @click="chooseMsg(btnList[1] && btnList[1], 1, 'editList')">
                {{ btnList[1].name || "" }}
              </div>
              <div v-else slot="reference" class="btn" @click="chooseMsg(btnList[1] && btnList[1], 1, 'addList')">
                <i class="el-icon-plus"></i>
              </div>
            </div>
            <div class="tips-box">
              <div class="tips" v-if="showBtn3">
                <div v-for="(it, idx) in btnList[2] && btnList[2].sub_button" :key="idx" class="my-popover" @click="
                    chooseMsg(btnList[2] && btnList[2], 2, 'editSub', idx)
                  ">
                  {{ it.name || "" }}
                </div>
                <div class="my-popover san" @click="chooseMsg(btnList[2] && btnList[2], 2, 'addSub')">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <div v-if="btnList[2]" slot="reference" class="btn" @click="chooseMsg(btnList[2] && btnList[2], 2, 'editList')">
                {{ btnList[2].name || "" }}
              </div>
              <div v-else slot="reference" class="btn" @click="chooseMsg(btnList[2] && btnList[2], 2, 'addList')">
                <i class="el-icon-plus"></i>
              </div>
            </div>

            <!--            </el-popover>-->
            <!--            <el-popover-->

            <!--              v-model="showBtn2"-->
            <!--              placement="top"-->
            <!--              trigger="manual"-->
            <!--              width="100"-->
            <!--            >-->
            <!--            </el-popover>-->
            <!--            <el-popover-->

            <!--              v-model="showBtn3"-->
            <!--              placement="top"-->
            <!--              trigger="manual"-->
            <!--              width="100"-->
            <!--            >-->
            <!--            </el-popover>-->
          </div>
        </div>
        <el-card v-if="isEdit" class="box-card2">
          <div slot="header" class="header">
            <span>{{ form.name || "" }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="deleteMenu()">删除菜单</el-button>
          </div>
          <div>
            <el-form :model="form" label-position="right" size="small">
              <el-form-item label="菜单名称">
                <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
              </el-form-item>
              <el-form-item label="菜单内容">
                <el-select v-model="form.type" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="delete">
              <div class="tit">{{ getTit(form.type) }}</div>
              <div v-if="form.type == 'view'" class="input-box" style="display: flex; align-items: center">
                <div class="label" style="margin-right: 10px">页面地址</div>
                <el-input v-model="form.url"></el-input>
              </div>
              <div v-if="form.type == 'click'" class="input-box" style="display: flex; align-items: center">
                <div class="label" style="margin-right: 10px">菜单KEY值</div>
                <el-input v-model="form.key"></el-input>
              </div>
              <div v-if="form.type == 'miniprogram'">
                <div class="input-box" style="display: flex; align-items: center">
                  <div class="label" style="margin-right: 10px">
                    小程序appld
                  </div>
                  <el-input v-model="form.appid" placeholder="小程序的appld(仅认证公众号可配置)"></el-input>
                </div>
                <div class="input-box" style="display: flex; align-items: center">
                  <div class="label" style="margin-right: 10px">小程序路径</div>
                  <el-input v-model="form.pagepath" placeholder="小程序的页面路径pages/,index/index"></el-input>
                </div>
                <div class="input-box" style="display: flex; align-items: center">
                  <div class="label" style="margin-right: 10px">备用网页</div>
                  <el-input v-model="form.url" placeholder=""></el-input>
                </div>
                <span class="tit">旧版微信客户端无法支持小程序，用户点击菜单时将会打开
                  备用网页</span>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <el-button class="upload-btn" type="success" @click="createMenuList">
        <i class="el-icon-upload"></i>
        发布
      </el-button>
    </el-card>
  </div>
</template>

<script>
import { createMenuList, getMenuList } from '@/service/reply';
import { Message } from 'element-ui';
// import qs from 'qs'
// import {
//   Message
// } from 'element-ui'
export default {
  components: {},
  data () {
    return {
      btnList: [],
      isShow: false,
      form: {},
      options: [
        {
          label: '跳转网页（view）',
          value: 'view'
        },
        {
          label: '自定义点击事件(click)',
          value: 'click'
        },
        {
          label: '打开指定小程序(miniprogram)',
          value: 'miniprogram'
        }
      ],
      isEdit: false,
      listCur: 0,
      smallIdx: 0,
      type: ''
    }
  },
  computed: {
    showBtn1: {
      get () {
        return this.btnList[0] && this.isShow
      },
      set () {
      }
    },
    showBtn2: {
      get () {
        return this.btnList[1] && this.isShow
      },
      set () {
      }
    },
    showBtn3: {
      get () {
        console.log(this.btnList[2], '1')
        console.log(this.isShow, '2')
        return this.btnList[2] && this.isShow
      },
      set () {
      }
    }
  },
  mounted () {
    this.getMenu()
  },
  methods: {
    deleteMenu () {
      if (this.type === 'addSub' || this.type === 'addList') {
        this.resetAll()
        this.getMenu()
        return
      }
      this.$confirm('确定删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.type === 'editList') {
          this.btnList.splice(this.listCur, 1)
          this.isEdit = false
          this.type = 'deleteList'
          this.$forceUpdate()
        }
        if (this.type === 'editSub') {
          this.btnList[this.listCur].sub_button.splice(this.smallIdx, 1)
          this.isEdit = false
          this.type = 'deleteSub'
          this.$forceUpdate()
        }
        console.log(this.btnList)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    resetAll () {
      this.form = {}
      this.type = ''
      this.btnList = []
      this.smallIdx = 0
      this.listCur = 0
      this.isShow = false
      this.isEdit = false
    },
    getTit (type) {
      if (type === 'view') {
        return '订阅者点击该子菜单会跳到以下链接'
      }
      if (type === 'click') {
        return '用于消息接口推送，不超过128字节'
      }
      if (type === 'miniprogram') {
        return '订阅者点击该子菜单会跳到以下小程序'
      }
    },
    createMenuList () {
      if (this.type === 'editList' || this.type === 'addList') {
        this.btnList[this.listCur] = this.form
      }
      // if (this.type === 'addSub') {
      //   if (this.btnList[this.listCur].sub_button && this.btnList[this.listCur].sub_button.length > 0) {
      //     this.btnList[this.listCur].sub_button.push(this.form)
      //   } else {
      //     this.btnList[this.listCur].sub_button = []
      //     this.btnList[this.listCur].sub_button.push(this.form)
      //   }
      // }
      if (this.type === 'editSub') {
        this.btnList[this.listCur].sub_button[this.smallIdx] = this.form
      }
      console.log(this.btnList)
      // return
      createMenuList({
        button: this.btnList
      }).then(res => {
        // if (res.flag) {
        Message.success('成功')
        this.resetAll()
        this.getMenu()
        // } else {
        //   Message.error(res.message)
        // }
      })
    },
    getMenu () {
      getMenuList().then(res => {
        console.log(res, 'res')
        this.btnList = res.button;
        this.$nextTick(() => {
          this.isShow = true;
        })
      })
    },
    chooseMsg (row, idx, type, smallIdx) {
      console.log(row)
      if (type && type === 'editList') {
        this.form = JSON.parse(JSON.stringify(row))
        this.btnList[idx] = this.form
      }
      if (type && type === 'addList') {
        this.form = {
          name: '子菜单名称'
        }
        this.btnList[idx] = this.form
      }
      if (type && type === 'addSub') {
        if (row.sub_button && row.sub_button.length >= 5) {
          Message.info('最多添加5个子菜单')
          return
        }
        this.form = {
          name: '子菜单名称'
        }
        if (this.btnList[idx].sub_button && this.btnList[idx].sub_button.length > 0) {
          this.btnList[idx].sub_button.push(this.form)
          console.log(this.btnList, '1')
        } else {
          this.btnList[idx].sub_button = [this.form]
          console.log(this.btnList, '2')
        }
        this.$forceUpdate()
      }
      if (type && type === 'editSub') {
        this.form = row.sub_button[smallIdx]
      }
      this.type = type
      this.listCur = idx
      this.smallIdx = smallIdx
      this.isEdit = true;
      console.table(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
