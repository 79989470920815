<!-- 列表 -->
<template>
  <div class="SensitiveWordChat">
    <div class="content" v-show="!showAddWords">
      <div>聊天敏感词</div>
      <div class="tips">
        配置敏感词规则后，将下载到成员的企业微信，当成员发送给客户的消息包含敏感词时，将予以提示或发送失败
      </div>
      <div>
        <el-button type="primary" @click="addWordsShow(true)"
          >新建规则</el-button
        >
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="ruleName" label="规则名称"> </el-table-column>
          <el-table-column label="敏感词个数">
            <template slot-scope="scope">
              <p>{{ scope.row.wordList.length }}</p>
            </template>
          </el-table-column>
          <el-table-column label="使用范围">
            <template slot-scope="scope">
              <el-tag v-for="(v,index) in scope.row.userList" :key="index+'user'" effect="plain" size="small">
                <i class="el-icon-user"></i>{{ v }}
              </el-tag>
               <el-tag v-for="(v,index) in scope.row.departList" :key="index+'deparment'" effect="plain" size="small">
                <i class="el-icon-folder-opened"></i>{{ v }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="editDetailes(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="delRoe(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <AddWords v-show="showAddWords" v-on:goBack="addWordsShow" :editData="editData"></AddWords>
  </div>
</template>

<script>
/* eslint-disable */
import {
  Message
} from 'element-ui'
import { getContactInterceptList, getDelInterceptRule } from "@/service/enterprise";
import AddWords from './addWords'
export default {
  data() {
    return {
      tableData: [],
      showAddWords: false,
      editData: null
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getContactInterceptList()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() { },
  methods: {
    getContactInterceptList() {
      getContactInterceptList().then(res => {
        this.tableData = res.list
      })
    },
    editDetailes(row) {
      this.editData = row
      this.showAddWords = true
    },
    delRoe(row) {
      getDelInterceptRule({ ruleid: row.ruleId }).then(res => {
        Message.success('删除成功')
        this.getContactInterceptList()
      })
    },
    addWordsShow(val) {
      this.showAddWords = val
      if (!val) {
        this.getContactInterceptList()
        this.editData = null
      }
    }
  },
  components: {
    AddWords
  }
};
</script>

<style lang='less'>
@import url("./index.less");
</style>