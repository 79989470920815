<!-- 分配菜单，分配权限 -->
<template>
  <div>
    <el-drawer
      title="我是标题"
      @open="openDrawer"
      @close="closeDrawer"
      :visible.sync="drawer"
      :with-header="false"
    >
      <h1>{{ this.info.title }}</h1>
      <h2>{{ this.info.name }}</h2>
      <el-tree
        :data="treeProps"
        :props="defaultProps"
        ref="tree"
        show-checkbox
        node-key="id"
        highlight-current
        :default-checked-keys="defalutCheckKeys"
      >
      </el-tree>
      <el-row class="drawer_btn">
        <el-col :span="8">
          <el-button type="primary" @click="addMenuRoles">更新</el-button>
        </el-col>
        <el-col :span="16"> </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import {
  postMenusUpdate,
  postPermissionUpdate
} from '@/service/roles'
export default {
  props: {
    drawer: {
      type: Boolean,
      defalut: false
    },
    info: {},
    treeProps: {
      type: Array
    },
    currentTree: {
      type: Array
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      defalutCheckKeys: []
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  methods: {
    openDrawer() {
      this.defalutCheckKeys = this.currentTree
      // console.log(this.currentTree)
      // console.log(this.$refs.tree.setCheckedKeys(this.currentTree))
    },
    closeDrawer() {
      this.$emit('closeDrawer', false)
      // this.currentTree = []
      this.$nextTick(function () {
        // Dom更新完毕
        this.$refs.tree.setCheckedKeys([])
      })
    },
    addMenuRoles() {
      if (this.info.title === '菜单列表') {
        const checkTreeData = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
        postMenusUpdate({ roleId: this.info.id, menusId: checkTreeData }).then(data => {
          if (data === 'success') {
            this.$message.success('分配成功')
            this.closeDrawer()
          }
        })
      }
      if (this.info.title === '分配角色权限') {
        const checkTreeData = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
        postPermissionUpdate({
          permissionIds: checkTreeData,
          roleId: this.info.id
        }).then(data => {
          if (data === 'success') {
             this.$message.success('分配成功')
            this.closeDrawer()
          }
        })
      }
    }
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.el-drawer h1 {
  font-size: 25px;
}
.el-drawer h2 {
  font-size: 18px;
}
.el-drawer h1,
.el-drawer h2 {
  text-align: left;
  padding-left: 20px;
}
.drawer_btn {
  margin-top: 30px;
}
.drawer_btn .el-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
}
h1{
  padding: 20px;
}
h2{
  padding: 10px 0;
}
</style>
