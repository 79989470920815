import {
  get,
  post,
  upload
} from '../Api/axios'
import {
  URL_MATERIAL_LIBRARY_PAGE_LIST,
  URL_ADD_TEXT_MATERIAL,
  URL_ADD_IMAGE_MATERIAL,
  URL_UPDATE_MATERIAL,
  URL_DELETE_MATERIAL,
  URL_ADD_FILE_MATERIAL,
  URL_MEDIA_UPLOAD_DOC,
  URL_MEDIA_SPEECHCRAFT_LIST,
  URL_MEDIA_SPEECHCRAFT_INSERT,
  URL_MEDIA_SPEECHCRAFT_DELETE,
  URL_MEDIA_SPEECHCRAFT_UPDATE,
  URL_DOWNLOAD_MATERIAL
} from '@/Api/api.js'

export function getMaterialLibraryPageList (params = {}) {
  return post(URL_MATERIAL_LIBRARY_PAGE_LIST, {
    ...params
  })
}

export function addTextMaterialLibrary (params = {}) {
  return get(URL_ADD_TEXT_MATERIAL, {
    ...params
  })
}

export function addImageMaterialLibrary () {
  return post(URL_ADD_IMAGE_MATERIAL, {

  })
}

export function updateMaterial (params = {}) {
  return get(URL_UPDATE_MATERIAL, {
    ...params
  })
}

export function removeMaterial (id) {
  return get(URL_DELETE_MATERIAL, {
    id
  })
}
export function downLoadMaterial (urlString) {
  return get(URL_DOWNLOAD_MATERIAL, {
    urlString
  })
}

export function uploadImg (params = {}) {
  return upload(URL_ADD_IMAGE_MATERIAL, {
    ...params
  })
}

export function uploadFile (params = {}) {
  return upload(URL_ADD_FILE_MATERIAL, {
    ...params
  })
}

export function uploadDoc (params = {}) {
  return upload(URL_MEDIA_UPLOAD_DOC, {
    ...params
  })
}

export function getSpeechcraftList (params = {}) {
  return get(URL_MEDIA_SPEECHCRAFT_LIST, {
    ...params
  })
}

export function postSpeechcraftInsert (params = {}) {
  return post(URL_MEDIA_SPEECHCRAFT_INSERT, {
    ...params
  })
}

export function getSpeechcraftDel (params = {}) {
  return get(URL_MEDIA_SPEECHCRAFT_DELETE, {
    ...params
  })
}

export function postSpeechcraftUpdate (params = {}) {
  return post(URL_MEDIA_SPEECHCRAFT_UPDATE, {
    ...params
  })
}
