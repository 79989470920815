<template>
  <div class="customer-form">
    <FormList v-if="active===1" @row="e=> row = e"></FormList>
    <FormCreate v-if="active===2" :id="row.formId"></FormCreate>
  </div>
</template>

<script>
import FormList from './form-list/index.vue';
import FormCreate from './form-create/index.vue';
export default {
  components: { FormList, FormCreate },
  data () {
    return {
      active: 1, // 1是列表页 2是表单创建页
      row: {}
    }
  },
  methods: {
    changeActive (num) {
      this.active = num
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
