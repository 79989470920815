<template>
  <div class="drainage-details-wrapper">
    <div class="imgBox">
      <el-image :src="detailsData.qrCode"></el-image>
      <li>
        <a @click="downLoadQrcode(detailsData.qrCode)">下载二维码</a>
        <a @click="copyUrl(detailsData.qrCode)">复制链接</a>
      </li>
    </div>
    <div>
      <el-descriptions :column="1">
        <el-descriptions-item label="使用成员">
          <el-tag size="small">{{detailsData.stuff}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="活动场景">{{detailsData.state}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{
         detailsData.addType
        }}</el-descriptions-item>
        <el-descriptions-item label="设置">
          {{detailsData.skipVerify?"客户添加时无需经过确认自动成为好友":"无"}}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{detailsData.createTime}}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <el-descriptions :column="1">
        <el-descriptions-item label="扫码标签">
          <el-tag size="small" v-for="tag in detailsData.labelCode&&detailsData.labelCode.split(',')" :key="tag">{{tag}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="欢迎语"> {{detailsData.content}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import Details from '@/views/Drainage/details.js'
import {
  Message
} from 'element-ui'
// import Vue from 'vue'

export default {
  props: {

  },
  data() {
    return {
      detailsData: {},
      // data: new Vue({ data: {} }),
      downLoadUrl: 'https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    Details.$on('detailsData', this.changeData)
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    // 详情数据
    changeData(val) {
      this.detailsData = val
    },
    // 下载
    downLoadQrcode(url) {
      if (url) {
        window.open(
          this.downLoadUrl + qs.stringify({ urlString: url }), '_self'
        )
      } else {
        Message.error('下载失败')
      }
    },
    // 复制链接
    copyUrl(url) {
      console.log('ddddd=>', url)
      var oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      Message.success('复制成功')
    }
  },
  components: {

  }
}
</script>

<style lang="less">
  @import url("./index.less");
</style>
