<!-- 列表 -->
<template>
  <div class="add-send-task">
    <div class="addCenter">
      <el-steps
        :active="active"
        finish-status="success"
        simple
        style="margin-top: 20px"
      >
        <el-step title="1.设置推送信息"></el-step>
        <el-step title="2.目标用户"></el-step>
      </el-steps>

      <el-form ref="form" :model="form" class="addFrom">
        <div v-if="active === 0" class="w680">
          <el-form-item label="群发任务名称" label-width="100px">
            <el-input v-model="form.ruleName" class="inputW300"></el-input>
          </el-form-item>
          <el-form-item label="推送规则" label-width="100px">
            <el-radio-group v-model="form.rule" size="medium">
              <el-radio border label="定时发送"></el-radio>
              <el-radio border label="立即发送"></el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="fontCss" v-show="form.rule === '定时发送'">
            消息将于
            <el-date-picker
              v-model="dating"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
            <el-time-picker
              arrow-control
              v-model="timing"
              type="datetime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="任意时间点"
              :clearable="false"
            ></el-time-picker
            >准时发送
          </div>
          <el-form-item label="推送内容" label-width="100px">
            <el-tabs @tab-click="handleClick" v-model="type" class="pushTabs">
              <el-tab-pane name="text">
                <span slot="label" class="tabAdd">
                  <i class="el-icon-edit"></i> 文字/超链接
                </span>
                <el-input
                  type="textarea"
                  :rows="8"
                  placeholder="请填写内容"
                  v-model="form.replyInfo"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane name="mpnews" class="newstabpane">
                <span slot="label" class="tabAdd">
                  <i class="el-icon-tickets"></i>回复图文消息
                </span>
                <el-popover
                  placement="right-start"
                  width="400"
                  trigger="manual"
                  v-model="visible"
                >
                  <el-form
                    :label-position="labelPosition"
                    label-width="80px"
                    :model="form"
                    class="replyFrom"
                  >
                    <el-form-item label="封面介绍">
                      <el-input v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="封面图片">
                      <el-upload
                        action="#"
                        class="upload-box"
                        ref="upload"
                        :auto-upload="true"
                        :limit="1"
                        :on-exceed="handleExceed"
                        accept=".jpg, .jpeg, .png, .gif"
                        :http-request="uploadPic"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        list-type="picture"
                        :before-upload="beforeAvatarUpload"
                      >
                        <el-button type="primary" class="uploadBtn">
                          <i class="iconfont icon-yunshangchuan"></i>
                          上传图片
                        </el-button>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="简介">
                      <el-input type="text" v-model="form.digest"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="摘要（选填，该摘要只在发送图文信息为单条时显示）"
                    >
                      <el-input
                        type="textarea"
                        :rows="5"
                        v-model="form.replyInfo"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="跳转地址">
                      <el-input v-model="form.url">
                        <template slot="append">跳转地址</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="作者">
                      <el-input v-model="form.author"></el-input>
                    </el-form-item>
                    <el-form-item label="是否打开评论">
                      <el-radio-group
                        v-model="form.need_open_comment"
                        size="medium"
                      >
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否粉丝可评论">
                      <el-radio-group
                        v-model="form.only_fans_can_comment"
                        size="medium"
                      >
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                  <div class="news" slot="reference">
                    <img :src="src" />
                    <div class="newsTitle">
                      <span>{{ form.title }}</span>
                    </div>
                  </div>
                </el-popover>
              </el-tab-pane>
              <el-tab-pane name="image">
                <span slot="label" class="tabAdd">
                  <i class="el-icon-picture"></i>回复图片
                </span>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  ref="uploadPic"
                  :limit="1"
                  :on-exceed="handleExceed"
                  :before-upload="beforeAvatarUpload"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemovePic"
                  :http-request="uploadRePic"
                  :file-list="fileList"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
              </el-tab-pane>
            </el-tabs>
            <!-- <el-button
              class="topBtn"
              type="primary"
              @click="save"
              :disabled="uploading"
              >保存</el-button
            >-->
          </el-form-item>
        </div>
        <div v-if="active === 1">
          <div>
            <div class="disflexPush">
              <div>
                <div class="fromItemflex">
                  <span class="fromLabel">全部用户</span>
                  <el-checkbox v-model="form.is_to_all"></el-checkbox>
                </div>
                <!-- <div class="fromItemflex">
                  <span class="fromLabel">渠道名称</span>
                  <el-select clearable v-model="form.addChannel" placeholder="请选择" size="small">
                    <el-option
                      v-for="item in channelList"
                      :key="item.channel_code"
                      :label="item.channel_name"
                      :value="item.channel_code"
                    ></el-option>
                  </el-select>
                </div>
                <div class="fromItemflex">
                  <span class="fromLabel">是否企业微信好友</span>
                  <el-select clearable v-model="form.wechatFriend" placeholder="请选择" size="small">
                    <el-option label="是" value="是"></el-option>
                    <el-option label="否" value="否"></el-option>
                  </el-select>
                </div> -->
              </div>
              <div class="mgl-200">
                <div class="fromItemflex">
                  <span class="fromLabel">选择标签</span>
                  <el-select
                    v-model="tags"
                    clearable
                    placeholder="请选择"
                    size="small"
                    :disabled="form.is_to_all"
                  >
                    <el-option
                      v-for="item in tagList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <!-- <div class="fromItemflex">
                  <span class="fromLabel">添加距今天数</span>
                  <el-input-number
                    v-model="form.dayFromNow"
                    label="天数"
                    :disabled="
                      form.minuteFromNow || form.minuteFromNow === 0
                        ? true
                        : false
                    "
                  ></el-input-number>&nbsp; 天
                </div>
                <div class="fromItemflex">
                  <span class="fromLabel">添加距今分钟数</span>
                  <el-input-number
                    v-model="form.minuteFromNow"
                    :disabled="
                      form.dayFromNow || form.dayFromNow === 0 ? true : false
                    "
                    label="天数"
                  ></el-input-number>&nbsp; 分
                </div> -->
              </div>
            </div>
          </div>

          <div class="previewbox">
            <div>
              <el-input :value="form.groupNum" type="text" readonly />
              <el-button
                style="margin-bottom: 20px; margin-left: 10px"
                type="primary"
                size="small"
                v-loading="calculateLoading"
                @click="handleCalculate"
                >评估</el-button
              >
            </div>
            <div>
              <el-input
                v-model="form.touser"
                type="text"
                placeholder="请输入预览人公众号openid"
              />
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="small"
                v-loading="previewLoading"
                @click="handlePreview"
                >预览</el-button
              >
            </div>
          </div>
        </div>
      </el-form>

      <div class="bntList">
        <el-button v-if="active === 1" type="primary" round @click="next"
          >保存</el-button
        >
        <el-button v-if="active !== 0" round @click="goBack">取消</el-button>
        <el-button
          v-if="active !== 1"
          type="primary"
          v-loading="nextLoading"
          round
          @click="next"
          >下一步</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  uploadTemp,
  postCoveruserNums,
  postMassSendAll,
  postMassPreview,
  uploadNews,
  getChannelList
} from '@/service/sendtogroup';
import { getTagsList } from '@/service/fan';
import { uploadImg } from '@/service/materialLibrary';
import { Message } from 'element-ui';
export default {
  /* eslint-disable */
  props: ["editData", "sopType"],
  data () {
    return {
      active: 0,
      form: {
        ruleName: "",
        rule: "立即发送",
        replyInfo: "",
        author: "",
        need_open_comment: 0,
        only_fans_can_comment: 0,
        title: "标题",
        is_to_all: false,
        groupNum: null,
        touser: "",
        url: "",
        digest: "",
        dayFromNow: undefined,
        minuteFromNow: undefined,
        wechatFriend: null,
        addChannel: null
      },
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) > new Date(v);
        }
      },
      type: "text",
      src: require("@/assets/error.jpg"),
      dating: new Date(),
      timing: "23:59",
      datingStart: new Date(),
      datingEnd: new Date(),
      num: 0,
      visible: false,
      labelPosition: "top",
      upload: {
        media: null,
        type: "image"
      },
      dialogImageUrl: "",
      dialogVisible: false,
      meidaId: "",
      uploading: false,
      fileList: [],
      tagList: [],
      tags: null,
      calculateLoading: false,
      previewLoading: false,
      meidaIdNews: "",
      nextLoading: false,
      channelList: [],
      imageUrl: ""
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.init();
    this.getTagsList();
    this.getChannelList();
  },
  computed: {},
  methods: {
    init () {
      if (this.editData) {
        console.log(
          "🚀 ~ file: index.vue ~ line 384 ~ init ~ this.editData",
          this.editData
        );
        this.type = this.editData.msgtype;
        var mpnewsObj = {};
        if (this.editData.mpnewsObj) {
          mpnewsObj = this.editData.mpnewsObj;
        }
        this.form = {
          ruleName: this.editData.ruleName,
          rule: this.editData.sendType === 0 ? "立即发送" : "定时发送",
          groupNum: this.editData.num,
          is_to_all: this.editData.filter._to_all,
          touser: "",
          url: mpnewsObj.content_source_url,
          digest: mpnewsObj.digest,
          title: mpnewsObj.title,
          author: mpnewsObj.author,
          need_open_comment: mpnewsObj.need_open_comment
            ? mpnewsObj.need_open_comment
            : 0,
          only_fans_can_comment: mpnewsObj.only_fans_can_comment
            ? mpnewsObj.only_fans_can_comment
            : 0,
          replyInfo: mpnewsObj.content,
          dayFromNow: this.editData.filter.dayFromNow
            ? this.editData.filter.dayFromNow
            : undefined,
          minuteFromNow: this.editData.filter.minuteFromNow
            ? this.editData.filter.minuteFromNow
            : undefined,
          wechatFriend: this.editData.filter.wechatFriend,
          addChannel: this.editData.filter.addChannel
        };
        this.imageUrl = mpnewsObj.thumb_media_id;
        if (this.type === "mpnews") {
          this.meidaIdNews = this.editData.mpnews.media_id;
          this.fileList = mpnewsObj.thumb_media_id
            ? [{ name: "", url: mpnewsObj.thumb_media_id }]
            : [];
        } else {
          this.fileList = this.editData.imageObj
            ? [{ name: "", url: this.editData.imageObj }]
            : [];
        }
        if (this.type === "image") {
          this.meidaId = this.editData.images.media_ids[0];
        }
        switch (this.type) {
          case "text":
            this.form.replyInfo = this.editData.text.content;
            break;
          case "mpnews":
            this.visible = true;
            break;
          default:
            break;
        }
        this.tags = this.editData.filter.tag_id
          ? this.editData.filter.tag_id + ""
          : null;
        this.id = this.editData.id;
        if (this.editData.sendType === 1) {
          this.dating = this.editData.sendTime.split(" ")[0];
          this.timing = this.editData.sendTime.split(" ")[1];
        }
      } else {
        this.form = {
          ruleName: "",
          rule: "立即发送",
          replyInfo: "",
          author: "",
          need_open_comment: 1,
          only_fans_can_comment: 1,
          title: "标题",
          is_to_all: false,
          groupNum: null,
          touser: "",
          url: "",
          digest: "",
          dayFromNow: undefined,
          minuteFromNow: undefined,
          wechatFriend: null,
          addChannel: null
        };
        this.type = "text";
        this.dating = new Date();
        this.timing = "23:59";
        this.tags = null;
      }
    },
    getTagsList () {
      getTagsList({ pageNo: 1, pageSize: 100, tag: "" }).then(res => {
        this.tagList = res.list;
      });
    },
    getChannelList () {
      getChannelList().then(res => {
        this.channelList = res;
      });
    },
    Previous () {
      this.active--;
    },
    next () {
      switch (this.active) {
        case 0:
          if (this.form.ruleName === "") {
            Message.warning("请填写群发任务名称");
          } else if (this.type === "text" && this.form.replyInfo === "") {
            Message.warning("请填写文字或者超链接");
          } else if (this.type === "mpnews" && this.form.title === "") {
            Message.warning("请输入标题");
          } else if (this.type === "mpnews" && this.meidaIdNews === "") {
            Message.warning("请上传图片");
          } else if (this.type === "mpnews" && this.form.url === "") {
            Message.warning("请输入跳转链接");
          } else if (this.type === "mpnews" && this.form.author === "") {
            Message.warning("请输入作者");
          } else if (this.type === "image" && this.meidaId === "") {
            Message.warning("请上传图片");
          } else {
            if (this.type === "mpnews") {
              this.setMpnews();
            } else {
              this.active++;
            }
          }
          break;
        case 1:
          if (this.form.groupNum === null) {
            Message.warning("请先评估标签");
          } else {
            this.overAdd();
          }
          break;
        default:
          break;
      }
    },
    setMpnews () {
      this.nextLoading = true;
      var data = { articles: [] };
      var list = {
        thumb_media_id: this.meidaId,
        author: this.form.author,
        title: this.form.title,
        content_source_url: this.form.url,
        content: this.form.replyInfo,
        digest: this.form.digest,
        show_cover_pic: 1,
        need_open_comment: this.form.need_open_comment,
        only_fans_can_comment: this.form.only_fans_can_comment
      };
      data.articles.push(list);
      uploadNews(data).then(res => {
        console.log("setMpnews -> data", data)
        this.meidaIdNews = res;
        this.nextLoading = false;
        this.active++;
      });
    },
    handleClick (tab, event) {
      this.type = tab.name;
      if (tab.name === "mpnews") {
        this.visible = true;
      } else {
        this.visible = false;
      }
      this.fileList = [];
      this.meidaId = "";
      this.meidaIdNews = "";
    },
    handleRemovePic (file, fileList) {
      this.$refs.uploadPic.clearFiles();
      this.meidaId = "";
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadRePic (val) {
      this.upload.media = val.file;
      this.uploading = true;
      this.handleChange(val);
      uploadTemp({ ...this.upload }).then(res => {
        this.meidaId = res;
        this.uploading = false;
      });
    },
    handleRemove (file) {
      this.$refs.upload.clearFiles();
      this.src = require("@/assets/error.jpg");
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 上传文件超出个数
    handleExceed (files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async uploadPic (val) {
      this.uploading = true;
      this.src = await this.getBase64(val.file);
      this.upload.media = val.file;
      this.handleChange(val);
      uploadTemp({ ...this.upload }).then(res => {
        this.meidaId = res;
        this.meidaIdNews = res
        this.uploading = false;
      });
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    goBack () {
      this.$emit("goBack", false);
    },
    overAdd () {
      let data = {};
      if (this.id) {
        data.id = this.id;
      }
      data.ruleName = this.form.ruleName;
      if (this.form.rule === "定时发送") {
        data.sendType = 1;
        data.sendTime =
          moment(this.dating).format("YYYY-MM-DD") + " " + this.timing;
      } else {
        data.sendType = 0;
      }
      if (this.form.is_to_all) {
        data.filter = {
          is_to_all: this.form.is_to_all,
          dayFromNow: this.form.dayFromNow,
          minuteFromNow: this.form.minuteFromNow,
          addChannel: this.form.addChannel,
          wechatFriend: this.form.wechatFriend
        };
      } else {
        data.filter = {
          is_to_all: this.form.is_to_all,
          tag_id: this.tags,
          dayFromNow: this.form.dayFromNow,
          minuteFromNow: this.form.minuteFromNow,
          addChannel: this.form.addChannel,
          wechatFriend: this.form.wechatFriend
        };
      }
      data.num = this.form.groupNum;
      data.msgtype = this.type;
      switch (this.type) {
        case "text":
          data.text = {
            content: this.form.replyInfo
          };
          break;
        case "image":
          var mediaid = [];
          mediaid.push(this.meidaId);
          data.images = {
            media_ids: mediaid
          };
          data.imageObj = this.imageUrl;
          break;
        case "mpnews":
          data.mpnews = {
            media_id: this.meidaIdNews
          };
          data.send_ignore_reprint = 0;
          data.mpnewsObj = {
            thumb_media_id: this.imageUrl,
            author: this.form.author,
            title: this.form.title,
            content_source_url: this.form.url,
            content: this.form.replyInfo,
            digest: this.form.digest,
            show_cover_pic: 1,
            need_open_comment: this.form.need_open_comment,
            only_fans_can_comment: this.form.only_fans_can_comment
          };
          break;
        default:
          break;
      }
      postMassSendAll(data).then(res => {
        Message.success("成功");
        this.active = 0;
        this.$emit("goBack", false);
      });
    },
    handleCalculate () {
      this.calculateLoading = true;
      var data = null;
      if (this.form.is_to_all) {
        data = {
          filter: {
            is_to_all: this.form.is_to_all,
            dayFromNow: this.form.dayFromNow,
            minuteFromNow: this.form.minuteFromNow,
            addChannel: this.form.addChannel,
            wechatFriend: this.form.wechatFriend
          }
        };
      } else {
        if (this.tags) {
          data = {
            filter: {
              is_to_all: this.form.is_to_all,
              tag_id: this.tags,
              dayFromNow: this.form.dayFromNow,
              minuteFromNow: this.form.minuteFromNow,
              addChannel: this.form.addChannel,
              wechatFriend: this.form.wechatFriend
            }
          };
        } else {
          Message.warning("请选择标签");
          this.calculateLoading = false;
          return false;
        }
      }
      postCoveruserNums(data).then(res => {
        this.form.groupNum = res;
        this.calculateLoading = false;
      });
    },
    handlePreview () {
      if (this.form.touser === "") {
        Message.warning("请输入openid");
        return false;
      }
      this.previewLoading = true;
      var data = { touser: this.form.touser, msgtype: this.type };
      switch (this.type) {
        case "text":
          data.text = {
            content: this.form.replyInfo
          };
          break;
        case "image":
          data.image = {
            media_id: this.meidaId
          };
          break;
        case "mpnews":
          data.mpnews = {
            media_id: this.meidaIdNews
          };
          break;
        default:
          break;
      }
      postMassPreview(data).then(res => {
        this.previewLoading = false;
        Message.success("预览发送成功");
      });
    },
    async handleChange (file, fileList) {
      console.log("handleChange -> file", file);
      const res = await uploadImg({
        file: file.file,
        introduction: "公众号群发消息"
      });
      this.imageUrl = res;
    }
  },
  watch: {
    editData () {
      this.init();
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>