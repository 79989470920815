<template>
  <div class="add-channelcode">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="渠道名称" prop="channel_name">
        <el-input v-model="ruleForm.channel_name"></el-input>
      </el-form-item>
      <el-form-item label="场景值code" prop="scene_str">
        <el-input v-model="ruleForm.scene_str"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="ruleForm.action_name">
          <el-radio label="临时"></el-radio>
          <el-radio label="永久"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="过期时间" prop="day">
        <el-input-number
          v-model="ruleForm.day"
          class="expire"
        ></el-input-number>
        &nbsp;&nbsp;天
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { postQrcodeCreate } from '@/service/code'
import {
  Message
} from 'element-ui'
export default {
  components: {

  },
  data() {
    return {
      ruleForm: {
        channel_name: '',
        action_name: '临时',
        day: '',
        scene_str: ''
      },
      rules: {
        channel_name: [
          { required: true, message: '请输入渠道名称', trigger: 'blur' }
        ],
        scene_str: [
          { required: true, message: '请输入场景值code', trigger: 'blur' }
        ],
        day: [
          { required: true, message: '请输入过期天数', trigger: 'blur' }
        ]
      }

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  watch: {

  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            channel_name: this.ruleForm.channel_name,
            action_name: this.ruleForm.action_name === '临时' ? 'QR_STR_SCENE' : 'QR_LIMIT_STR_SCENE',
            expire_seconds: this.ruleForm.day * 24 * 60 * 60,
            action_info: {
              scene: {
                scene_str: this.ruleForm.scene_str
              }
            }
          }
          postQrcodeCreate({ ...data }).then(res => {
            Message.success('添加成功')
            this.$router.push('/Wechat/ParameterQRCode/channelQRCode')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$router.push('/Wechat/ParameterQRCode/channelQRCode')
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
