<!-- 线性图 -->
  <template>
  <el-table :data="tableData"
    style="width: 100%">
    <el-table-column v-for="(item) in key"
      :key="item"
      :prop="item"
      sortable
      :label="item==='all'?'总用户数':item"
      width="180">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: ['mapData'],
  data () {
    /* eslint-disable */
    return {
      tableData: [],
      key: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.filterData()
  },
  methods: {
    filterData () {
      if (this.mapData && this.mapData.list.length > 0) {
        if (this.mapData.list && this.mapData.list.length === 1) {
          const data = [{}]
          this.key = ['all']
          this.mapData.legend.forEach((value, i) => {
            this.key.push(value)
            console.log(value)
            data[0][`${value}`] = this.mapData.list[0].value[i]
          })
          data[0].all = this.mapData.list[0].total
          this.tableData = data
        } else {
          this.key = ['title', 'all', ...this.mapData.legend]
          const data = []
          this.mapData.list.forEach((item) => {
            const flagData = {}
            flagData.title = item.legend
            flagData.all = item.total
            this.mapData.legend.forEach((value, i) => {
              flagData[`${value}`] = item.value[i]
            })
            data.push(flagData)
          })
          this.tableData = data
          console.log(data)
        }

      }
    }
  },
  watch: {
    mapData () {
      this.filterData()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>