<template>
  <el-dialog title="数据" :visible.sync="visible">
    <el-button class="btn" type='primary' @click="downLoad">下载Excel</el-button>
    <el-button class="btn" type='primary' @click="handleSyncTag">同步打标签</el-button>
    <el-table :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" :data="tableData" style="width: 100%">
      <el-table-column show-overflow-tooltip :label="item" v-for="(item,index) in schame" :key="index" :prop="item" :schame="schame">
      </el-table-column>
    </el-table>
    <el-pagination class="page" @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>
import { queryData, syncTag } from '@/service/customerForm'
export default {
  data () {
    return {
      formId: '',
      visible: false,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      schame: [] // 表头字段
    }
  },
  methods: {
    openDialog (row) {
      this.formId = row.formId
      this.getList();
      this.visible = true
    },
    getList () {
      queryData({
        pageNo: this.pageNo, pageSize: this.pageSize, formId: this.formId
      }).then(res => {
        this.tableData = []
        res.details.forEach(item => {
          const itemObj = {}
          item.forEach((i) => {
            itemObj[i.itemName] = i.data
          })
          this.tableData.push(itemObj)
        })
        this.schame = res.schame
        this.total = res.total
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getList()
    },
    downLoad () {
      const url = 'https://cdpengine.cn/scrm-platform/customize/form/saved/form/download?formId=' + this.formId;
      window.open(url, '_self');
    },
    handleSyncTag () {
      syncTag().then(res => {
        this.$message.success('操作成功')
        this.getList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.del {
  cursor: pointer;
  font-size: 20px;
}
.btn {
  margin-bottom: 10px;
}
.page {
  margin-top: 10px;
}
</style>
