import moment from 'moment'
export function getQuery () {
  const search = `?${window.location.href.split('?')[1]}`
  if (search) {
    return search.substr(1).split('&')
      .reduce((acc, currentValue) => {
        const [key, value] = currentValue.split('=')
        acc[key] = value
        return acc
      }, {})
  }
  return {}
}

// 生成唯一编号
export const unid = `${moment().format('x')}`

const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789'
/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
export function getRandomString (len) {
  const min = 0
  const max = _charStr.length - 1
  let strs = ''
  // 判断是否指定长度，否则默认长度为15
  len = len || 15
  // 循环生成字符串
  for (var i = 0, index; i < len; i++) {
    index = RandomIndex(min, max, i);
    strs += _charStr[index]
  }
  return strs
}

/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前获取位置
 */
export function RandomIndex (min, max, i) {
  let index = Math.floor(Math.random() * (max - min + 1) + min)
  const numStart = _charStr.length - 10;
  // 如果字符串第一位是数字，则递归重新获取
  if (i === 0 && index >= numStart) {
    index = RandomIndex(min, max, i);
  }
  // 返回最终索引值
  return index;
}

export function formatDate (date) {
  var date = new Date(date);
  var YY = date.getFullYear() + '-';
  var MM =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  var hh =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':';
  var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + ' ' + hh + mm + ss;
}
