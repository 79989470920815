<!-- 列表 -->
<template>
  <div class="InheritJob">
    <div class="content" v-show="!recordShow">
      <div>在职继承</div>
      <div class="tips">
        <span class="spantext">当成员或客户/客户群有变更时,企业可将在职成员的客户和客户群分配给其他成员继续提供服务。</span>
        <span>
          <el-link type="primary" :underline="false" @click="recordShow=true">分配记录</el-link>
        </span>
      </div>
      <div class="pdt-10">
        <div class="addCenterBox">
          <p>需要转接的客户/客户群</p>
          <div class="addBox">
            <div class="pdl-10">
              <div
                v-if="
                  selectCustomer.length === 0 &&
                  selectCustomerGroup.length === 0
                "
              >
                <i class="el-icon-plus iconPlus"></i>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="dialogChooseCustomVisible = true"
                >去选择</el-link>
              </div>
              <div v-else class="resultBox">
                <div>
                  <span v-if="selectCustomer.length > 0">
                    {{ selectCustomer[0].name }}
                    <span
                      v-show="selectCustomer.length > 1"
                    >等{{ selectCustomer.length }}位客户</span>
                  </span>
                  <span v-show="selectCustomer.length > 0 && selectCustomerGroup.length > 0">、</span>
                  <span v-if="selectCustomerGroup.length > 0">
                    {{ selectCustomerGroup[0].group_name }}
                    <span
                      v-show="selectCustomerGroup.length > 1"
                    >等{{ selectCustomerGroup.length }}个客户群</span>
                  </span>
                </div>
                <div>
                  <el-link type="primary" :underline="false" @click="resetChoose">重新选择</el-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="addCenterBox">
          <p>将客户/客户群转接给</p>
          <div class="addBox">
            <div class="pdl-10">
              <div v-if="selectReplaceTreeNode.length === 0">
                <i class="el-icon-plus iconPlus"></i>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="dialogReplaceMemberVisible = true"
                >选择接替成员</el-link>
              </div>
              <div v-else class="resultBox">
                <div>
                  <span v-if="selectReplaceTreeNode.length > 0" class="transferBox">
                    <span>
                      <img
                        :src="selectReplaceTreeNode[0]&&selectReplaceTreeNode[0].picUrl"
                        :alt="selectReplaceTreeNode[0]&&selectReplaceTreeNode[0].userName"
                        class="transfer_img"
                      />
                      <i class="el-icon-error" @click="selectReplaceTreeNode=[]"></i>
                    </span>
                    <span>{{ selectReplaceTreeNode[0].userName }}</span>
                  </span>
                </div>
                <div>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="dialogReplaceMemberVisible = true"
                  >重新选择</el-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="addBottom">
        <el-button
          type="primary"
          @click="opendialogComfirm"
          :disabled="
            selectReplaceTreeNode.length === 0 ||
            (selectCustomer.length === 0 && selectCustomerGroup.length === 0)
          "
        >确认转接</el-button>
      </div>
    </div>

    <RecordAllocation v-show="recordShow" v-on:goBack="recordAllocatShow"></RecordAllocation>
    <!-- 选择需要转接的客户/客户群 -->
    <el-dialog
      title="选择需要转接的客户/客户群"
      :visible.sync="dialogChooseCustomVisible"
      class="dialogTreeBox"
      width="600px"
    >
      <div class="dialogChooseCustom">
        <div class="chooseBox" @click="chooseType(1)">
          <el-image :src="userIcon"></el-image>
          <span class="boldTitle">按成员选择</span>
          <span>选择有工作变更的成员的客户/客户群</span>
        </div>
        <div class="chooseBox" @click="chooseType(2)">
          <el-image :src="searchIcon"></el-image>
          <span class="boldTitle">选择指定客户/客户群</span>
          <span>直接选择有变更的客户/客户群</span>
        </div>
      </div>
    </el-dialog>
    <!-- 选择成员 -->
    <el-dialog title="选择成员" :visible.sync="dialogMemberVisible" class="dialogTreeBox" width="600px">
      <div class="dialogTree">
        <div class="treeBox">
          <el-input placeholder="搜索成员" v-model="filterText"></el-input>
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span
                :class="
                  data.userId &&
                  selectReplaceTreeNode.length > 0 &&
                  data.userId === selectReplaceTreeNode[0].userId
                    ? 'customDisable'
                    : ''
                "
              >
                <i v-if="data.children && data.children.length > 0" class="el-icon-folder-opened"></i>
                <i v-else class="el-icon-user"></i>
                {{ data.label }}
              </span>
            </span>
          </el-tree>
        </div>
        <div class="viewBox">
          <p>已选择的成员</p>
          <div v-for="item in selectTreeNode" :key="item.id" class="viewList">
            <span>
              <i v-if="item.children && item.children.length > 0" class="el-icon-folder-opened"></i>
              <i v-else class="el-icon-user"></i>
              {{ item.label }}
            </span>
            <span class="closebtn">
              <i class="el-icon-close" @click="delSelectTreeNode(item.id)"></i>
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(2)">取 消</el-button>
        <el-button
          type="primary"
          @click="handleMemberConfirm"
          :disabled="selectTreeNode.length === 0"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 指定客户 -->
    <el-dialog
      title="指定客户/客户群"
      :visible.sync="dialogCustomerVisible"
      class="dialogTreeBox"
      width="600px"
    >
      <div class="dialogCustomer">
        <div class="customerTop">
          <span>
            选择
            {{ selectTreeNode[0] && selectTreeNode[0].userName }}
            需要转接的客户/客户群
          </span>
          <span>
            <el-link type="primary" :underline="false" @click="resetCustomer">重选成员</el-link>
          </span>
        </div>
        <div class="pdt-10">
          <div class="addCenterBox">
            <p>需要转接的客户</p>
            <div class="addBox">
              <div class="pdl-10">
                <div v-if="selectCustomer.length === 0">
                  <i class="el-icon-plus iconPlus"></i>
                  <el-link type="primary" :underline="false" @click="getCustomerList">选择客户</el-link>
                </div>
                <div v-else class="resultBox">
                  <div>
                    {{ selectCustomer[0].name }}
                    <span
                      v-show="selectCustomer.length > 1"
                    >等{{ selectCustomer.length }}位客户</span>
                  </div>
                  <div>
                    <i class="el-icon-arrow-right" @click="getCustomerList"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="addCenterBox">
            <p>需要转接的客户群</p>
            <div class="addBox">
              <div class="pdl-10">
                <div v-if="selectCustomerGroup.length === 0">
                  <i class="el-icon-plus iconPlus"></i>
                  <el-link type="primary" :underline="false" @click="getCustomerGroupList">选择客户群</el-link>
                </div>
                <div v-else class="resultBox">
                  <div>
                    {{ selectCustomerGroup[0].group_name }}
                    <span
                      v-show="selectCustomerGroup.length > 1"
                    >等{{ selectCustomerGroup.length }}个客户群</span>
                  </div>
                  <div>
                    <i class="el-icon-arrow-right" @click="getCustomerGroupList"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(3)">取 消</el-button>
        <el-button
          type="primary"
          @click="handledialogClose()"
          :disabled="
            selectCustomer.length === 0 && selectCustomerGroup.length === 0
          "
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择客户 -->
    <el-dialog
      title="选择客户"
      :visible.sync="dialogCustomerChooseVisible"
      class="dialogTreeBox"
      width="700px"
    >
      <div class="dialogCustomer">
        <div class="customerTop">
          <span>
            选择
            {{ selectTreeNode[0] && selectTreeNode[0].userName }}
            需要转接的客户
          </span>
          <span>
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :current-page="pageNo"
              :total="totalCustomer"
            ></el-pagination>
          </span>
        </div>
        <div>
          <el-table
            :loading="tableLoading"
            :data="tableCustomerData"
            style="width: 100%"
            size="mini"
            :row-key="getRowKeys"
            @selection-change="handleSelectionChange"
            ref="CustomerTable"
          >
            <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column prop="date" label="客户">
              <template slot-scope="scope">
                <div class="uesr-infos">
                  <div class="text">
                    <div class="titles">
                      <p>{{ scope.row.name }}</p>
                      <span
                        :style="{
                          color:
                            scope.row.addType === '微信'
                              ? ' #67C23A'
                              : '#E6A23C',
                        }"
                      >@{{ scope.row.addType }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="标签">
              <template slot-scope="scope">
                <el-tag v-for="v in scope.row.tag.split(',')" :key="v" size="mini">{{ v }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="添加人"></el-table-column>
            <el-table-column prop="addTime" label="添加时间"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer dialog-customer-footer">
        <span>共 {{ totalCustomer }} 位可选客户</span>
        <span>
          <el-button @click="handledialogClose(4)">取 消</el-button>
          <el-button type="primary" @click="dialogCustomerChooseVisible = false">
            确 定
            <span v-show="selectCustomer.length > 0">({{ selectCustomer.length }})</span>
          </el-button>
        </span>
      </span>
    </el-dialog>
    <!-- 选择客户群 -->
    <el-dialog
      title="选择客户群"
      :visible.sync="dialogCustomerGroupChooseVisible"
      class="dialogTreeBox"
      width="700px"
    >
      <div class="dialogCustomer">
        <div class="customerTop">
          <span>
            选择
            {{ selectTreeNode[0] && selectTreeNode[0].userName }}
            需要转接的客户群
          </span>
          <span>
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentGroupChange"
              :current-page="pageGroupNo"
              :total="totalCustomer"
            ></el-pagination>
          </span>
        </div>
        <div>
          <el-table
            :loading="tableLoading"
            :data="tableCustomerData"
            style="width: 100%"
            size="mini"
            :row-key="getRowGroupKeys"
            @selection-change="handleSelectionGroupChange"
            ref="CustomerGroupTable"
          >
            <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column prop="group_name" label="客户群"></el-table-column>
            <el-table-column prop="num" label="群人数"></el-table-column>
            <el-table-column prop="create_time" label="创建时间"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer dialog-customer-footer">
        <span>共 {{ totalCustomer }} 位可选客户群</span>
        <span>
          <el-button @click="handledialogClose(5)">取 消</el-button>
          <el-button type="primary" @click="dialogCustomerGroupChooseVisible = false">
            确 定
            <span v-show="selectCustomerGroup.length > 0">({{ selectCustomerGroup.length }})</span>
          </el-button>
        </span>
      </span>
    </el-dialog>
    <!-- 选择接替成员 -->
    <el-dialog
      title="转接给其他成员"
      :visible.sync="dialogReplaceMemberVisible"
      class="dialogTreeBox"
      width="600px"
    >
      <div class="dialogTree">
        <div class="treeBox">
          <el-input placeholder="搜索成员" v-model="filterText"></el-input>
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            @node-click="handleReplaceNodeClick"
            :expand-on-click-node="false"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span
                :class="
                  data.userId &&
                  selectTreeNode.length > 0 &&
                  data.userId === selectTreeNode[0].userId
                    ? 'customDisable'
                    : ''
                "
              >
                <i v-if="data.children && data.children.length > 0" class="el-icon-folder-opened"></i>
                <i v-else class="el-icon-user"></i>
                {{ data.label }}
              </span>
            </span>
          </el-tree>
        </div>
        <div class="viewBox">
          <p>已选择的成员</p>
          <div v-for="item in selectReplaceTreeNode" :key="item.id" class="viewList">
            <span>
              <i v-if="item.children && item.children.length > 0" class="el-icon-folder-opened"></i>
              <i v-else class="el-icon-user"></i>
              {{ item.label }}
            </span>
            <span class="closebtn">
              <i class="el-icon-close" @click="delSelectReplaceTreeNode(item.id)"></i>
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(6)">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogReplaceMemberVisible = false"
          :disabled="selectReplaceTreeNode.length === 0"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 确认转接弹窗 -->
    <el-dialog
      title="确认转接"
      :visible.sync="dialogComfirmVisible"
      class="dialogComfirmBox"
      width="600px"
    >
      <div class="dialogComfirm">
        <div v-if="selectCustomer.length===0" class="onlyCustomerGroup">
          <i class="el-icon-info"></i>
          <span>
            将转接
            <span v-if="selectCustomerGroup.length > 0">{{ selectCustomerGroup.length }}个客户群</span>给
            <span v-if="selectReplaceTreeNode.length > 0">{{ selectReplaceTreeNode[0].userName }}</span>
          </span>
        </div>
        <div v-else>
          <div class="comfirmObj">
            将转接
            <span v-if="selectCustomer.length > 0">{{ selectCustomer.length }}位客户</span>
            <span v-show="selectCustomer.length > 0 && selectCustomerGroup.length > 0">、</span>
            <span v-if="selectCustomerGroup.length > 0">{{ selectCustomerGroup.length }}个客户群</span>
            给
            <span
              v-if="selectReplaceTreeNode.length > 0"
            >{{ selectReplaceTreeNode[0].userName }}</span>
          </div>
          <div class="comfirmTips">仅客户单聊将收到以下提示</div>
          <div class="comfirmContent">
            <div class="customer_transfer_confirm_dialog">
              <img
                :src="selectTreeNode[0]&&selectTreeNode[0].picUrl"
                :alt="selectTreeNode[0]&&selectTreeNode[0].userName"
                class="customer_transfer_confirm_dialog_avatar"
              />
              <div class="customer_transfer_confirm_dialog_cnt">
                <p class="customer_transfer_confirm_util_scroll word_default">
                  <span v-if="inputVisible">{{transfer_success_msg}}</span>
                  <el-input
                    class="input-new-tag"
                    v-else
                    type="textarea"
                    autosize
                    v-model="transfer_success_msg"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                    resize="none"
                  ></el-input>
                </p>
                <span class="customer_transfer_confirm_dialog_cnt_icon">
                  <i v-if="inputVisible" class="el-icon-edit-outline" @click="inputVisible=false"></i>
                  <i v-else class="el-icon-error" @click="transfer_success_msg=''"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(7)">取 消</el-button>
        <el-button type="primary" @click="confirmInheriteJob">确认转接</el-button>
      </span>
    </el-dialog>
    <!-- 查找指定客户  选择结果弹窗 -->
    <el-dialog
      title="指定客户/客户群"
      :visible.sync="dialogCustomerSearchVisible"
      class="dialogTreeBox"
      width="600px"
    >
      <div class="dialogCustomer">
        <div class="customerTop">
          <span>查找指定的客户/客户群</span>
        </div>
        <div class="pdt-10">
          <div class="addCenterBox">
            <p>需要转接的客户</p>
            <div class="addBox">
              <div class="pdl-10">
                <div v-if="selectCustomer.length === 0">
                  <i class="el-icon-plus iconPlus"></i>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="dialogSearchCustomerVisible=true"
                  >查找客户</el-link>
                </div>
                <div v-else class="resultBox">
                  <div>
                    {{ selectCustomer[0].name }}
                    <span
                      v-show="selectCustomer.length > 1"
                    >等{{ selectCustomer.length }}位客户</span>
                  </div>
                  <div>
                    <i class="el-icon-arrow-right" @click="dialogSearchCustomerVisible= true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="addCenterBox">
            <p>需要转接的客户群</p>
            <div class="addBox">
              <div class="pdl-10">
                <div v-if="selectCustomerGroup.length === 0">
                  <i class="el-icon-plus iconPlus"></i>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="dialogSearchCustomerGroupVisible=true"
                  >查找客户群</el-link>
                </div>
                <div v-else class="resultBox">
                  <div>
                    {{ selectCustomerGroup[0].group_name }}
                    <span
                      v-show="selectCustomerGroup.length > 1"
                    >等{{ selectCustomerGroup.length }}个客户群</span>
                  </div>
                  <div>
                    <i class="el-icon-arrow-right" @click="dialogSearchCustomerGroupVisible=true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(8)">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogCustomerSearchVisible = false"
          :disabled="
            selectCustomer.length === 0 && selectCustomerGroup.length === 0
          "
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查找指定客户 -->
    <el-dialog
      title="查找客户"
      :visible.sync="dialogSearchCustomerVisible"
      class="dialogTreeBox"
      width="700px"
    >
      <div class="dialogSearchCustomer">
        <div class="customerTop">
          <el-select
            v-model="searchCustomer"
            filterable
            remote
            reserve-keyword
            placeholder="输入客户昵称/备注"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="selectCustomerData"
          >
            <el-option
              v-for="item in searchCustomerRes"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">由{{ item.userName }}添加</span>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-table :data="selectCustomer" style="width: 100%" size="mini">
            <el-table-column prop="date" label="客户">
              <template slot-scope="scope">
                <div class="uesr-infos">
                  <div class="text">
                    <div class="titles">
                      <p>{{ scope.row.name }}</p>
                      <span
                        :style="{
                          color:
                            scope.row.addType === '微信'
                              ? ' #67C23A'
                              : '#E6A23C',
                        }"
                      >@{{ scope.row.addType }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="tag" label="标签">
              <template slot-scope="scope">
                <el-tag v-for="v in scope.row.tag.split(',')" :key="v" size="mini">{{ v }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="添加人"></el-table-column>
            <el-table-column prop="addTime" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="delSearchCustomer(scope.row.externalUserid)"
                >删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(9)">取 消</el-button>
        <el-button type="primary" @click="dialogSearchCustomerVisible = false">
          确 定
          <span v-show="selectCustomer.length > 0">({{ selectCustomer.length }})</span>
        </el-button>
      </span>
    </el-dialog>
    <!-- 查找指定客户群 -->
    <el-dialog
      title="查找客户群"
      :visible.sync="dialogSearchCustomerGroupVisible"
      class="dialogTreeBox"
      width="700px"
    >
      <div class="dialogSearchCustomer">
        <div class="customerTop">
          <el-select
            v-model="searchCustomerGroup"
            filterable
            remote
            reserve-keyword
            placeholder="输入客户群名称"
            :remote-method="remoteMethodGroup"
            :loading="loading"
            @change="selectCustomerDataGroup"
          >
            <el-option
              v-for="item in searchCustomerGroupRes"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">由{{ item.userName }}添加</span>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-table
            :loading="tableLoading"
            :data="selectCustomerGroup"
            style="width: 100%"
            size="mini"
          >
            <el-table-column prop="group_name" label="客户群"></el-table-column>
            <el-table-column prop="user_name" label="群主"></el-table-column>
            <el-table-column prop="num" label="群人数"></el-table-column>
            <el-table-column prop="create_time" label="创建时间"></el-table-column>
            <el-table-column prop="addTime" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="delSearchCustomerGroup(scope.row.group_id)"
                >删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handledialogClose(10)">取 消</el-button>
        <el-button type="primary" @click="dialogSearchCustomerGroupVisible = false">
          确 定
          <span v-show="selectCustomerGroup.length > 0">({{ selectCustomerGroup.length }})</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";
import { queryCustomerManagePageList } from "@/service/customerManage";
import RecordAllocation from "./recordAllocation";
import {
  getDepartmentInfo,
  getGroupUserGroupInfo,
  postCustomerTransfer,
  postGroupTransfer
} from "@/service/enterprise";
export default {
  data() {
    return {
      dialogChooseCustomVisible: false,
      searchIcon: require("@/assets/search.png"),
      userIcon: require("@/assets/user-circle.png"),
      dialogMemberVisible: false,
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      selectTreeNode: [],
      dialogCustomerVisible: false,
      dialogCustomerChooseVisible: false,
      tableCustomerData: [],
      totalCustomer: [],
      pageNo: 1,
      pageSize: 10,
      tableLoading: false,
      selectCustomer: [],
      dialogCustomerGroupChooseVisible: false,
      selectCustomerGroup: [],
      pageGroupNo: 1,
      dialogReplaceMemberVisible: false,
      selectReplaceTreeNode: [],
      dialogComfirmVisible: false,
      inputVisible: true,
      transfer_success_msg: "",
      dialogCustomerSearchVisible: false,
      type: 1,
      dialogSearchCustomerVisible: false,
      searchCustomer: "",
      searchCustomerRes: [],
      loading: false,
      dialogSearchCustomerGroupVisible: false,
      searchCustomerGroup: "",
      searchCustomerGroupRes: [],
      recordShow: false
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getDepartmentInfo();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    confirmInheriteJob() {
      var result = 0;
      var num = 0;
      // 转客户
      console.log(this.selectCustomer, this.selectCustomerGroup);
      if (this.selectCustomer.length > 0) {
        num++;
        var external_userid = [];
        this.selectCustomer.map(item => {
          external_userid.push(item.externalUserid);
        });
        var customerData = {
          handover_userid: this.selectTreeNode[0]
            ? this.selectTreeNode[0].userId
            : null,
          takeover_userid: this.selectReplaceTreeNode[0].userId,
          external_userid: external_userid,
          transfer_success_msg: this.transfer_success_msg
        };
        postCustomerTransfer(customerData).then(res => {
          result++;
        });
      }
      // 转组
      if (this.selectCustomerGroup.length > 0) {
        num++;
        var chat_id_list = [];
        this.selectCustomerGroup.map(item => {
          chat_id_list.push(item.group_id);
        });
        var groupData = {
          chat_id_list: chat_id_list,
          new_owner: this.selectReplaceTreeNode[0].userId,
          before_owner: this.selectTreeNode[0]
            ? this.selectTreeNode[0].userId
            : null
        };
        postGroupTransfer(groupData).then(res => {
          result++;
        });
      }

      setTimeout(() => {
        if (result === num) {
          this.selectCustomer = [];
          this.selectCustomerGroup = [];
          this.selectReplaceTreeNode = [];
          this.selectTreeNode = [];
          Message.success("成功！");
          this.handledialogClose(7);
        }
      }, 3000);
    },
    handledialogClose(type) {
      // 1.选择方式  2.选择用户 3.指定客户
      switch (type) {
        case 1:
          this.dialogChooseCustomVisible = false;
          break;
        case 2:
          this.dialogMemberVisible = false;
          this.selectTreeNode = [];
          break;
        case 3:
          this.dialogCustomerVisible = false;
          this.resetCustomer();
          break;
        case 4:
          this.dialogCustomerChooseVisible = false;
          this.selectCustomer = [];
          this.$refs.CustomerTable.clearSelection();
          this.pageNo = 1;
          break;
        case 5:
          this.dialogCustomerGroupChooseVisible = false;
          this.selectCustomerGroup = [];
          this.$refs.CustomerGroupTable.clearSelection();
          this.pageGroupNo = 1;
          break;
        case 6:
          this.dialogReplaceMemberVisible = false;
          break;
        case 7:
          this.dialogComfirmVisible = false;
          this.inputVisible = true;
          break;
        case 8:
          this.dialogCustomerSearchVisible = false;
          break;
        case 9:
          this.dialogSearchCustomerVisible = false;
          this.selectCustomer = [];
          this.searchCustomerRes = [];
          this.searchCustomer = "";
          break;
        case 10:
          this.dialogSearchCustomerGroupVisible = false;
          this.selectCustomerGroup = [];
          this.searchCustomerGroupRes = [];
          this.searchCustomerGroup = "";
          break;
        default:
          this.dialogChooseCustomVisible = false;
          this.dialogMemberVisible = false;
          this.dialogCustomerVisible = false;
          break;
      }
    },
    //  选择用户确定方法
    handleMemberConfirm() {
      this.dialogCustomerVisible = true;
    },
    //  选择方式确定方法
    chooseType(type) {
      this.type = type;
      this.selectCustomer = [];
      this.selectCustomerGroup = [];
      this.selectReplaceTreeNode = [];
      this.selectTreeNode = [];
      this.dialogChooseCustomVisible = false;
      if (type === 1) {
        this.dialogMemberVisible = true;
      } else {
        this.dialogCustomerSearchVisible = true;
      }
    },
    getDepartmentInfo() {
      getDepartmentInfo().then(res => {
        this.setTreeData(res);
      });
    },
    setTreeData(arr, parentId) {
      if (parentId) {
        arr.map((item, index) => {
          item.id = parentId + "-" + index;
          item.label = item.userName;
        });
      } else {
        arr.map((item, index) => {
          item.id = item.departmentId;
          item.label = item.departmentName;
          if (item.children.length > 0) {
            this.setTreeData(item.children, item.departmentId);
          }
        });
      }
      this.treeData = arr;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data, node, element) {
      var res = this.selectReplaceTreeNode.filter(i => i.userId === data.userId)
        .length;
      if (data.userId && res === 0) {
        this.selectTreeNode = [];
        this.selectTreeNode.push(data);
      }
    },
    handleReplaceNodeClick(data, node, element) {
      var res = this.selectTreeNode.filter(i => i.userId === data.userId)
        .length;
      if (data.userId && res === 0) {
        this.selectReplaceTreeNode = [];
        this.selectReplaceTreeNode.push(data);
      }
    },
    delSelectTreeNode(id) {
      this.selectTreeNode = this.selectTreeNode.filter(i => i.id != id);
    },
    delSelectReplaceTreeNode(id) {
      this.selectReplaceTreeNode = this.selectReplaceTreeNode.filter(
        i => i.id != id
      );
    },
    // 重置选择
    resetCustomer() {
      this.dialogMemberVisible = true;
      this.dialogCustomerVisible = false;
      this.selectCustomerGroup = [];
      this.selectCustomer = [];
      this.$refs.CustomerTable.clearSelection();
      this.$refs.CustomerGroupTable.clearSelection();
      this.pageNo = 1;
      this.pageGroupNo = 1;
    },
    // 获取客户列表
    getCustomerList() {
      this.dialogCustomerChooseVisible = true;
      this.tableLoading = true;
      queryCustomerManagePageList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        userId: this.selectTreeNode[0].userId
      }).then(res => {
        this.tableCustomerData = res.list;
        this.totalCustomer = res.total;
        this.tableLoading = false;
      });
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getCustomerList();
    },
    handleCurrentGroupChange(pageNo) {
      this.pageGroupNo = pageNo;
      this.getCustomerGroupList();
    },
    handleSelectionChange(val) {
      this.selectCustomer = val;
    },
    getRowKeys(row) {
      return row.externalUserid;
    },
    getRowGroupKeys(row) {
      return row.group_id;
    },
    handleSelectionGroupChange(val) {
      this.selectCustomerGroup = val;
    },
    // 获取客户群列表
    getCustomerGroupList() {
      this.dialogCustomerGroupChooseVisible = true;
      this.tableLoading = true;
      getGroupUserGroupInfo({
        pageNo: this.pageGroupNo,
        pageSize: this.pageSize,
        userId: this.selectTreeNode[0].userId
      }).then(res => {
        this.tableCustomerData = res.list;
        this.totalCustomer = res.num;
        this.tableLoading = false;
      });
    },
    opendialogComfirm() {
      this.transfer_success_msg =
        "您好，您的服务已升级，后续将由我的同事 " +
        this.selectReplaceTreeNode[0].userName +
        " 接替我的工作，继续为您服务。";
      this.dialogComfirmVisible = true;
    },
    handleInputConfirm() {
      // let transfer_success_msg = this.transfer_success_msg;
      // if (transfer_success_msg) {
      //   this.dynamicTags.push(transfer_success_msg);
      // }
      this.inputVisible = true;
      // this.transfer_success_msg = transfer_success_msg;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        var data = [];
        queryCustomerManagePageList({
          pageNo: 1,
          pageSize: 10,
          name: query
        }).then(res => {
          data = res.list;
          this.searchCustomerRes = data.map(item => {
            return {
              label: item.name,
              value: { ...item },
              id: item.externalUserid,
              userName: item.userName
            };
          });
          this.loading = false;
        });
      } else {
        this.searchCustomerRes = [];
      }
    },
    selectCustomerData(val) {
      if (
        this.selectCustomer.filter(i => i.externalUserid === val.externalUserid)
          .length === 0
      ) {
        this.selectCustomer.push(val);
      }
    },
    delSearchCustomer(id) {
      this.selectCustomer = this.selectCustomer.filter(
        i => i.externalUserid !== id
      );
    },
    remoteMethodGroup(query) {
      if (query !== "") {
        this.loading = true;
        var data = [];
        getGroupUserGroupInfo({
          pageNo: 1,
          pageSize: 10,
          groupName: query
        }).then(res => {
          data = res.list;
          this.searchCustomerGroupRes = data.map(item => {
            return {
              label: item.group_name,
              value: { ...item },
              id: item.group_id,
              userName: item.user_name
            };
          });
          this.loading = false;
        });
      } else {
        this.searchCustomerGroupRes = [];
      }
    },
    selectCustomerDataGroup(val) {
      if (
        this.selectCustomerGroup.filter(i => i.group_id === val.group_id)
          .length === 0
      ) {
        this.selectCustomerGroup.push(val);
      }
    },
    delSearchCustomerGroup(id) {
      this.selectCustomerGroup = this.selectCustomerGroup.filter(
        i => i.group_id !== id
      );
    },
    recordAllocatShow(val) {
      this.recordShow = val;
    },
    resetChoose() {
      if (this.type === 1) {
        this.dialogCustomerVisible = true;
      } else {
        this.dialogCustomerSearchVisible = true;
      }
    }
  },
  components: {
    RecordAllocation
  }
};
</script>

<style lang='less'>
@import url("./index.less");
</style>