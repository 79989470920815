<!-- 指标 -->
<template>
  <div class="event-condition">
    <div class="condition-title">
      <span>指标</span>
    </div>
    <div class="indicate">
      <div class="indicate-valule-list">
        <div class="values-item" v-for="(item, i) in indicateTotal" :key="item">
          <div class="values-selects">
            <div class="index">{{ item }}</div>
            <el-dropdown size="mini" trigger="click" @command="changeIndicate">
              <el-tag size="small">{{
                  eventCodes[i]
                    ? eventCodes[i].event_name
                      ? eventCodes[i].event_name
                      : "请选择"
                    : "请选择"
                }}
              </el-tag>
              <el-tag size="small" @click="removeTag(item)"><i class="el-icon-delete"></i></el-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="
                    beforeHandleCommand(item, v.event_code, v.event_name, i)
                  " v-for="v in indicateData" :key="v.event_code">{{ v.event_name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="values-info">
            <p>步骤重命名：{{ eventCodes[i] && eventCodes[i].event_name }}</p>
          </div>
        </div>
      </div>
      <div class="indicate-select">
        <div class="icon-add">
          <i class="iconfont icon-xinjian"></i>
        </div>
        <el-tag size="small" @click="addIndicate">添加步骤</el-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['eventData'],
  data () {
    /* eslint-disable */
    return {
      indicateData: [],
      indicateTotal: [],
      defaultCode: 1,
      eventCodes: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this._initIndicateData()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    _initIndicateData () {
      this.$http.post('https://cdpengine.cn/scrm-platform/v1/DataAnalysis/eventList').then(data => {
        this.indicateData = data.data.data
      })
    },
    //点击增加指标个数
    addIndicate () {
      if (this.indicateTotal.length === 0) {
        this.indicateTotal.push(this.defaultCode)
        return this.defaultCode++
      }
      this.$message.warning('指标只能唯一')
      // if (this.eventCodes.length === 0) return
      // if (this.eventCodes.length !== this.indicateTotal.length) return
      // if (this.eventCodes.every(item => (item.event_name))) {
      //   this.indicateTotal.push(this.defaultCode)
      //   return this.defaultCode++
      // }
    },
    beforeHandleCommand (code, eventCode, event_name, index) {
      return {
        'code': code,
        'eventCode': eventCode,
        'index': index,
        'event_name': event_name
      }
    },
    changeIndicate (command) {
      const { code, eventCode, event_name, index } = command
      const i = this.eventCodes.findIndex(item => item.code === code)
      if (i >= 0) {
        this.eventCodes[i].eventCode = eventCode
        this.eventCodes[i].event_name = event_name
        this.eventCodes.reverse().reverse()
      } else {
        this.eventCodes.push(command)
      }
    },
    removeTag (item) {
      let index1 = this.indicateTotal.findIndex(v => v === item)
      // this.eventCodes = this.eventCodes.filter(v => v.code !== item)
      if (index1 !== -1) {
        this.indicateTotal.splice(index1, 1)
      }
      let index2 = this.eventCodes.findIndex(v => v.code === item)
      if (index2 !== -1) {
        this.eventCodes.splice(index2, 1)
      }
    }
  },
  watch: {
    eventCodes (newValue) {
      this.$emit('getEventCodesValue', this.eventCodes)
    },
    eventData: {
      immediate: true,
      deep: true,
      handler (val) {
        var res = []
        this.indicateTotal = []
        this.defaultCode = 1
        setTimeout(() => {
          var t = this.indicateData.filter(value => value.event_code === val)
          if (t.length > 0) {
            res.push({
              event_name: t[0].event_name,
              eventCode: val,
              code: this.defaultCode
            })
            this.indicateTotal.push(this.defaultCode)
            this.defaultCode++
            this.eventCodes = res
          }
        }, 500);
      }
    }
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
@import url("../../index.css");
</style>