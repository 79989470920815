<template>
  <el-upload class="upload-demo" action="#" :list-type="hasImg? 'picture-card' : 'text'" :auto-upload="false" :show-file-list="false" :on-preview="handlePreview" :on-change="handleChange" :on-remove="handleRemove" :before-remove="beforeRemove" :file-list="fileList">
    <el-button v-if="!hasImg" size="small" type="primary">点击上传</el-button>
    <div v-else class="img-box">
      <img v-if="imageUrl" :src="imageUrl" class="avatar" width="100%">
      <span v-else class="upload-plus">点击上传图片</span>
    </div>
  </el-upload>
</template>
<script>
import { uploadImg } from '@/service/materialLibrary'
export default {
  data () {
    return {
      fileList: [],
      imageUrl: '',
      url: ''
    };
  },
  props: {
    hasImg: {
      type: Boolean,
      default: false
    },
    introduction: {
      type: String,
      default: '表单内图片'
    },
    imgUrl: {
      type: String,
      default: ''
    }
  },
  watch: {
    url (val) {
      if (val) {
        this.$emit('change', val)
      }
    },
    imgUrl (val) {
      if (val) {
        this.imageUrl = val
      }
    }
  },
  methods: {
    async handleChange (file, fileList) {
      const res = await uploadImg({ file: file.raw, introduction: '表单内图片' })
      this.url = res
      this.imageUrl = res
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file) {
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    }
  }
}
</script>
<style lang="less" scoped>
.upload-plus {
  font-size: 12px;
  color: #8c939d;
}
.img-box {
  width: 200px;
  height: 200px;
  display: block;
  border: 1px solid #178fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-upload--picture-card {
  width: auto;
  height: auto;
  border: none;
  img {
    width: 200px;
  }
}
</style>
