<!-- 线性图 -->
<template>
  <div>
    <div id="map">暂无数据...</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getAutoReplyDetail } from '@/service/reply'
export default {
  /* eslint-disable */
  props: ["mapData"],
  data() {
    return {
      option: {
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          bottom: 80
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              normal: {
                color: '#3A4267',
                lineStyle: {
                  color: '#3A4267'
                }
              }
            }
          },
        ]
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    // console.log(this.LineMap);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.flush();
  },
  methods: {
    refreshMap() {
      const myChart = echarts.init(document.getElementById("map"));
      myChart.setOption(this.option);
    },
    flush() {
      if (this.mapData) {
        let datadate = []
        let num = []
        getAutoReplyDetail({ name: this.mapData }).then(res => {
          res && res.map(item => {
            datadate.push(item.datadate);
            num.push(item.num);
          })
          this.option.xAxis.data = datadate;
          this.option.series[0].data = num;
          this.refreshMap();

        })
      }
    }
  },
  watch: {
    mapData() {
      this.flush();
    }
  }
};
</script>
<style  scoped lang='less'>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
p {
  text-align: left;
  font-weight: bold;
}
</style>