<template >
  <div class="attention-reply">
    <el-container>
      <el-header class="groupheader">消息回复</el-header>
      <div class="topSetting">
        <div><i class="el-icon-setting"></i>回复内容设置</div>
        <div>
          <el-button class="topBtn" type="primary" @click="addGroupWel(data)"
            >编辑</el-button
          >
        </div>
      </div>
      <el-card class="box-card" shadow="never" v-show="data.type == 'text'">
        <div slot="header" class="clearfix">
          <span><i class="iconfont icon-A"></i>文字消息</span>
        </div>
        <div>
          {{data.reply_info}}
        </div>
      </el-card>
      <el-card class="box-card" shadow="never" v-show="data.type == 'image'">
        <div slot="header" class="clearfix">
          <span><i class="el-icon-picture-outline"></i>图片消息</span>
        </div>
        <div>
         <img :src='data.url' class="img">
        </div>
      </el-card>
      <el-card class="box-card" shadow="never" v-show="data.type == 'news'">
        <div slot="header" class="clearfix">
          <span>图文消息</span>
        </div>
        <div class="flex">
             <div class="news" slot="reference">
              <img :src="data.pic_url" />
              <div class="newsTitle">
                <span>{{ data.title }}</span>
              </div>
            </div>
            <div class="deW">
              <el-descriptions class="margin-top" title="" :column="1" direction="vertical">
              <el-descriptions-item label="封面介绍" label-class-name="my-label">{{ data.title }}</el-descriptions-item>
              <el-descriptions-item label="摘要" label-class-name="my-label">{{ data.reply_info }}</el-descriptions-item>
              <el-descriptions-item label="设置链接" label-class-name="my-label">{{ data.url }}</el-descriptions-item>
            </el-descriptions>
            </div>
        </div>
      </el-card>
    </el-container>
  </div>
</template>

<script>
import { getReplyList } from '@/service/reply'

export default {
  data() {
    return {
      data: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      kewords: '',
      typeInfo: 'subscribe'
    }
  },
  computed: {

  },
  created() {
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      getReplyList({ pageNo: this.pageNo, pageSize: this.pageSize, typeInfo: this.typeInfo, search: '' }).then(res => {
        this.data = res.list[res.list.length - 1]
        loading.close()
      })
    },
    addGroupWel(row) {
      console.log('row', row)
      if (row) {
        this.$router.push({
           path: '/Wechat/AutomaticReply/editAttention',
           query: {
             row: row
           }
        })
      } else {
        this.$router.push('/Wechat/AutomaticReply/editAttention')
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
