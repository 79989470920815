<template>
  <div class="behaviorChart">
    <div class="name">{{ source.index }}</div>
    <div class="flexbox">
      <div :id="source.id" class="myChart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["source"],
  data() {
    return {
      option: {
        grid: { containLabel: true },
        xAxis: {
          show: true,
          minInterval: 1,
          axisLabel: {
            formatter: "{value} 人",
            show: true
          },
          splitLine: { show: false },
          axisLine: {
            show: true
          }
        },
        yAxis: { data: [] },
        series: [
          {
            type: "bar",
            itemStyle: {
              color: "#23b03f"
            },
            data: []
          }
        ]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.setEchart();
  },
  watch: {
    source() {
      this.setEchart();
    }
  },
  methods: {
    refreshMap() {
      const myChart = echarts.init(document.getElementById(this.source.id));
      var yAxis = [];
      var data = [];
      this.source.item_list &&
        this.source.item_list.map(item => {
          yAxis.push(item.key);
          data.push(item.value);
        });
      this.option.yAxis.data = yAxis;
      this.option.series[0].data = data;
      myChart.setOption(this.option);
    },
    setEchart() {
      this.refreshMap();
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
