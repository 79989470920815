<!-- 用户管理 -->
<template>
  <div class="userSystem">
    <el-row class="top">
      <el-col :span="4" class="newBtn">
        <el-button type="primary" icon="el-icon-plus" @click="addUserBtn"
          >新增</el-button
        >
      </el-col>
      <el-col :span="18" class="search"> </el-col>
    </el-row>
    <el-card>
      <el-row>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="Id" width="100"> </el-table-column>
          <el-table-column prop="user" label="账号" width="100">
          </el-table-column>
          <el-table-column prop="name" label="用户名" width="180">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="100">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status === 0"
                type="success"
                size="small"
                >正常</el-button
              >
              <el-button
                v-if="scope.row.status === 1"
                type="warning"
                size="small"
                >失效</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="180">
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间" width="180">
          </el-table-column>
          <el-table-column
            prop="lastLoginTime"
            label="最后登陆时间"
            width="180"
          >
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-row>
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="editUser(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="warning"
                  size="mini"
                  plain
                  @click="assignRole(scope.row)"
                  >分配角色</el-button
                >
                <el-popconfirm
                  title="确定删除吗？"
                  @confirm="deleteUser(scope.row)"
                >
                  <el-button
                    type="danger"
                    class="confBtn"
                    size="mini"
                    plain
                    slot="reference"
                    >删除</el-button
                  >
                </el-popconfirm>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryUserList.pageNum"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="queryUserList.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!-- ----------------------------新增对话框 -->
    <AddUser
      @closeDialog="closeDialog"
      :dialogShow="dialogVisible"
      :updateTable="_geInitDataList"
      :userData="userData"
    />
    <el-drawer
      @close="BackcloseDrawer"
      class="drawer"
      title="分配用户角色"
      :with-header="false"
      :visible.sync="drawer"
      ref="drawerRef"
      direction="rtl"
    >
      <h1>分配用户角色</h1>
      <h2>用户名：{{ currentTitle }}</h2>
      <el-radio-group v-model="radioData">
        <el-radio :label="item.id" v-for="item in rolesList" :key="item.id">{{
          item.name
        }}</el-radio>
      </el-radio-group>
      <el-row class="drawerConfirm">
        <el-col :span="4">
          <el-button @click="closeDrawer">取消</el-button>
        </el-col>
        <el-col :span="18">
          <el-button type="primary" @click="verify">确认</el-button>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import AddUser from './components/AddUser'
import {
  delUser,
  getUserList,
  getRoleListAll,
  getUserRoleList,
  updateUserRole,
  addUserRole
} from '@/service/roles'
export default {
  data() {
    return {
      searchData: '',
      tableData: [],
      queryUserList: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dialogVisible: false,
      userData: {},
      drawer: false,
      rolesList: [],
      currentTitle: '',
      radioData: '',
      currentRoleData: {},
      thenCurrentId: '',
      newFlage: false
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this._geInitDataList()
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  methods: {
    handleSizeChange(newSize) {
      this.queryUserList.pageSize = newSize
      this._geInitDataList()
    },
    handleCurrentChange(NewPageNum) {
      this.queryUserList.pageNum = NewPageNum
      this._geInitDataList()
    },
    _geInitDataList() {
      getUserList({ ...this.queryUserList }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
      // this.$http.get('http://8.134.11.223:8080/userprofile-platform/api/user/list', {
      //   params: this.queryUserList
      // }).then(data => {
      //   this.tableData = data.data.data.list
      //   this.total = data.data.data.total
      // })
    },
    addUserBtn() {
      this.dialogVisible = true
      this.userData = {}
    },
    closeDialog(bool) {
      this.dialogVisible = false
    },
    editUser(row) {
      this.dialogVisible = true
      this.userData = row
    },
    deleteUser(row) {
      const { id } = row
      delUser({ id: id }).then(res => {
        this.$message.success('删除成功')
        this._geInitDataList()
      })
    },
    assignRole(row) {
      this.newFlage = false
      const { name, id } = row
      this.thenCurrentId = id
      this.currentTitle = name
      this.drawer = true
      if (this.rolesList.length <= 0) {
        this._getRolesList()
      }
      this._getCurrentRoleData(id)
    },
    _getRolesList() {
      getRoleListAll().then(res => {
        this.rolesList = res
      })
    },
    _getCurrentRoleData(id) {
      getUserRoleList({ pageNum: 1, pageSize: 10, userId: id }).then(data => {
        if (data.list.length === 0) {
          this.newFlage = true
          return
        }
        const { roleId, userId, id } = data.list[0]
        this.currentRoleData = {
          roleId, userId, id
        }
        this.radioData = roleId
      })
    },
    closeDrawer() {
      this.$refs.drawerRef.closeDrawer()
    },
    _getUpdataCurrentRole() {
      var data = { id: this.currentRoleData.id, roleId: this.radioData, userId: this.thenCurrentId }
      updateUserRole({ ...data }).then(res => {
        this.$message.success('分配成功')
      })
    },
    _getCreatedRoles() {
      var data = { roleId: this.radioData, userId: this.thenCurrentId }
      addUserRole({ ...data }).then(res => {
        this.$message.success('分配成功')
      })
    },
    BackcloseDrawer() {
      this.radioData = ''
      this._geInitDataList()
    },
    verify() {
      console.log(this.newFlage, this.radioData)
      if (!this.newFlage && this.radioData) {
        this._getUpdataCurrentRole()
      }
      if (this.newFlage && this.radioData) {
        this._getCreatedRoles()
      }
      this.closeDrawer()
    }
  },
  components: {
    AddUser
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.top {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  /* display: flex;
  align-content: center;
  justify-content: center; */
}

.search .el-input {
  width: 250px;
  float: right;
}
.el-table {
  padding-left: 20px;
}
.newBtn .el-button {
  float: left;
}
.pagination {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0px 0px 10px;
}
.el-drawer__wrapper h2 {
  text-align: left !important;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
.el-drawer__wrapper h1 {
  text-align: left !important;
  padding-left: 20px;
  font-size: 25px;
}
.drawerConfirm {
  margin-top: 60px;
  padding-left: 20px;
}
.drawerConfirm el-button {
  width: 100px;
}
.el-col {
  text-align: left !important;
}
.confBtn {
  margin-left: 10px;
}
.el-radio-group {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: flex-start;
}
.el-radio {
  padding: 10px;
}
h1 {
  padding: 20px;
}
h2 {
  padding: 10px 0;
}
</style>
