<template>
  <div class="recordAllocation">
    <div class="addContent">
      <div class="addTop">
        <div class="back">
          <el-button @click="goBack" size="mini">
            <i class="el-icon-d-arrow-left"></i>返回
          </el-button>
        </div>
        <div class="title">已转接的客户/客户群</div>
      </div>
      <div class="searchBox">
        <span>
          <span>时间：</span>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
            value-format="yyyyMMdd"
          ></el-date-picker>
        </span>
        <span class="searchText">
          <span style="width:60px">搜索：</span>
          <el-input v-model="searchValue" @change="updataTable(tabs)"></el-input>
        </span>
        <span @click="updateList" class="dataRe">
          <i class="el-icon-refresh-right"></i>
          更新数据
        </span>
      </div>
      <div class="addCenter">
        <el-tabs type="border-card" @tab-click="tabClick" v-model="tabs">
          <el-tab-pane label="客户" name="客户">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="name" label="客户"></el-table-column>
              <el-table-column prop="handoverUserName" label="原添加人"></el-table-column>
              <el-table-column prop="takeoverUserName" label="接替成员"></el-table-column>
              <el-table-column prop="status" label="转接状态"></el-table-column>
              <el-table-column prop="createTime" label="分配时间"></el-table-column>
            </el-table>
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :current-page="pageNo"
              :total="total"
            ></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="客户群" name="客户群">
            <el-table :data="groupTableData" style="width: 100%">
              <el-table-column prop="groupName" label="客户群"></el-table-column>
              <el-table-column prop="handoverUserName" label="原群主"></el-table-column>
              <el-table-column prop="takeoverUserName" label="接替成员"></el-table-column>
              <el-table-column prop="status" label="转接状态"></el-table-column>
              <el-table-column prop="createTime" label="转接时间"></el-table-column>
            </el-table>
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentGroupChange"
              :current-page="pageGroupNo"
              :total="totalGroup"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";
import {
  getGroupTransferStatus,
  getCustomerTransferResultList,
  getCustomerTransferResultExternal
} from "@/service/enterprise";
export default {
  data() {
    return {
      searchValue: "",
      date: null,
      tableData: [],
      groupTableData: [],
      pageNo: 1,
      pageGroupNo: 1,
      pageSize: 10,
      totalGroup: 0,
      total: 0,
      startDate: null,
      endDate: null,
      tabs: "客户"
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.init();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  watch: {},
  methods: {
    tabClick(val) {
      console.log("tabClick -> val", val);
      this.updataTable(val.label);
    },
    init() {
      var data = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        nickName: this.searchValue
      };
      getCustomerTransferResultList(data).then(res => {
        this.tableData = res.list;
        this.total = res.num;
      });
    },
    initGroup() {
      var data = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageNo: this.pageGroupNo,
        pageSize: this.pageSize,
        name: this.searchValue
      };
      getGroupTransferStatus(data).then(res => {
        this.groupTableData = res.list;
        this.totalGroup = res.num;
      });
    },
    handleCurrentGroupChange(pageNo) {
      this.pageGroupNo = pageNo;
      this.initGroup();
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.init();
    },
    goBack() {
      this.$emit("goBack", false);
    },
    updataTable(tabs) {
      if (tabs === "客户群") {
        this.initGroup();
      } else {
        this.init();
      }
    },
    updateList() {
      getCustomerTransferResultExternal().then(res => {
        Message.success("更新成功");
        this.updataTable(this.tabs);
      });
    },
    dateChange() {
      this.startDate = this.date ? this.date[0] : null;
      this.endDate = this.date ? this.date[1] : null;
      this.updataTable(this.tabs);
    }
  },
  components: {}
};
</script>

<style lang='less'>
@import url("./index.less");
</style>