<template>
  <div class="form-list">
    <!-- 自定义表单 -->
    <div class="search-box">
      <el-input @blur="search" class="left" placeholder="输入搜索关键词" v-model="form.formName">
        <i @click="search" slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button type="success" @click="add">创建表单</el-button>
    </div>
    <div class="table-list" v-for="item in formDataList" :key="item.formId">
      <div class="form-name">{{item.formName}}</div>
      <el-table :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" :data="[item]" style="width: 100%">
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="untildate" label="有效日期"></el-table-column>
        <el-table-column prop="name" label="创建人"></el-table-column>
        <el-table-column prop="showNum" label="浏览人次"></el-table-column>
        <el-table-column prop="num" label="填写人数"></el-table-column>
        <el-table-column label="渠道跟踪">
          <template v-slot="{row}">
            <el-button type="text" @click="goDetails(row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="链接">
          <template v-slot="{row}">
            <el-button type="text" @click="copy(row)">复制</el-button>
          </template>
        </el-table-column>
        <el-table-column label="二维码">
          <template v-slot="{row}">
              <el-image
              @click="downLoadCode(row.qrcodeUrl)"
              class="code-img"
                style="width: 50px; height: 50px"
                :src="row.qrcodeUrl">
              </el-image>
          </template>
        </el-table-column>
        <el-table-column label="数据">
          <template v-slot="{row}">
            <el-button type="text" @click="getData(row)">明细</el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template v-slot="{row}">
           {{statusObj[row.status]||'-'}}
          </template>
        </el-table-column>
        <el-table-column label="设置">
          <template v-slot="{row}">
            <el-button type="text" @click="edit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total">
    </el-pagination>
    <Detail ref="detail" />
    <Data ref="data" />
  </div>
</template>

<script>
import { queryCustomerForm, delCustomerForm } from '@/service/customerForm'
import Detail from './Detail'
import Data from './Data'
export default {
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: { formName: '' },
      formDataList: [],
      statusObj: {
        0: '草稿',
        1: '已发布',
        2: '已结束'
      }
    }
  },
  components: { Detail, Data },
  created() {
    this.getList()
  },
  methods: {
    downLoadCode(urlString) {
      const url = 'https://cdpengine.cn/scrm-platform/qrcode/generate/download/pic?urlString=' + urlString;
      window.open(url, '_self');
    },
    getList(params) {
      queryCustomerForm({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.formDataList = res.list
        this.total = res.num
      })
    },
    add() {
      this.$emit('row', {})
      this.$parent.changeActive(2)
    },
    goDetails(row) {
      this.$refs.detail.openDialog(row)
    },
    getData(row) {
      this.$refs.data.openDialog(row)
    },
    copy(row) {
      var input = document.createElement('input'); // 创建input对象
      input.value = row.url; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功！');
    },
    edit(row) {
      // this.id = row.
      this.$parent.changeActive(2)
      this.$emit('row', row)
    },
    del(row) {
      this.$confirm('确定删除所选表单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCustomerForm(row.formId).then(res => {
          this.$message.success('删除成功!')
          this.search()
        })
      }).catch(() => {
      })
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getList({
        ...this.form
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getList({
        ...this.form
      })
    },
    search() {
      this.getList({
        ...this.form
      })
    }
  }
}
</script>

<style  lang="less" scoped>
</style>
