<template>
  <div class="script-material-library">
    <el-tabs
      v-model="editableTabsValue"
      tab-position="left"
      :before-leave="beforeLeave"
    >
      <el-tab-pane
        v-for="item in editableTabs"
        :key="item.group"
        :label="item.group"
        :name="item.group"
      >
        <el-card class="box-card" v-for="o in item.details" :key="o.id">
          <div style="width: 90%">
            <div @click="editSprict(item, o)" v-if="!o.show">
              {{ o.content }}
            </div>
            <el-input
              v-if="o.show"
              v-model="o.content"
              :ref="'saveContentInput' + o.id"
              @keyup.enter.native="handleInputContentConfirm(item.group, o)"
              @blur="handleInputContentConfirm(item.group, o)"
            ></el-input>
            <span v-for="i in setLabel(o.labels)" :key="i" class="tagspan">
              <i class="el-icon-price-tag" style="color: #409eff"></i> {{ i }}
            </span>
          </div>
          <i
            class="el-icon-error"
            style="color: #409eff; font-size: 24px"
            @click="handleRemove(o.id)"
          ></i>
        </el-card>
      </el-tab-pane>
      <el-tab-pane key="add" name="add">
        <el-button slot="label" type="primary" icon="el-icon-plus"
          >新增</el-button
        >
      </el-tab-pane>
    </el-tabs>

    <!-- 新增弹窗 -->
    <el-dialog title="新增话术" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-position="left">
        <el-form-item label="分组">
          <el-input v-model="form.group" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="话术">
          <el-input
            type="textarea"
            :rows="3"
            v-model="form.content"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-tag
            :key="tag"
            v-for="tag in form.labels"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ New Tag</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addScript">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSpeechcraftList, postSpeechcraftInsert, getSpeechcraftDel, postSpeechcraftUpdate } from '@/service/materialLibrary'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      editableTabs: [],
      editableTabsValue: '',
      dialogFormVisible: false,
      form: {
        group: '',
        content: '',
        labels: []
      },
      inputVisible: false,
      inputValue: ''
    }
  },
  computed: {

  },
  created() {
    this.init()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    init() {
      getSpeechcraftList().then(res => {
        res.map(item => {
          item.details.map(i => {
            i.show = false
          })
        })
        this.editableTabs = res
        if (this.editableTabsValue === '0') {
          this.editableTabsValue = res[0].group
        }
      })
    },
    setLabel(row) {
      var labels = []
      if (row) {
        labels = row.split(',')
      }
      return labels
    },
    addScript() {
      if (this.form.group === '') {
        Message.warning('请输入分组')
        return false
      }
      if (this.form.content === '') {
        Message.warning('请输入话术')
        return false
      }
      if (this.form.labels.length === 0) {
        Message.warning('请添加标签')
        return false
      }
      postSpeechcraftInsert({ ...this.form }).then(res => {
        Message.success('新增成功');
        this.init()
        this.dialogFormVisible = false
      })
    },
    beforeLeave(currentName) {
      // 重点，如果name是add，则什么都不触发
      if (currentName === "add") {
        this.dialogFormVisible = true
        return false
      } else {
        this.editableTabsValue = currentName
      }
    },
    handleClose(tag) {
      this.form.labels.splice(this.form.labels.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    editSprict(val, i) {
      var arr = this.editableTabs
      arr.map(item => {
        if (item.group === val.group) {
          item.details.map(t => {
            if (i.id === t.id) {
              t.show = true
            }
          })
        }
      })
      this.editableTabs = arr
      this.$nextTick(_ => {
        this.$refs['saveContentInput' + i.id].$refs.input.focus();
      });
    },
    handleInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        this.form.labels.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleInputContentConfirm(group, o) {
      var data = { id: o.id, labels: o.labels.split(','), content: o.content, group: group }
      postSpeechcraftUpdate(data).then(res => {
        Message.success('编辑成功');
        var arr = this.editableTabs
        arr.map(item => {
          if (item.group === group) {
            item.details.map(t => {
              if (o.id === t.id) {
                t.show = false
              }
            })
          }
        })
        this.editableTabs = arr
      })
    },
    handleRemove(id) {
      getSpeechcraftDel({ id: id }).then(res => {
        this.init()
        Message.success('删除成功');
      })
    }
  },
  components: {
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
