
<template>
  <div class="channel">
    <el-card>
      <p>请选择日期：</p>
      <el-date-picker class="dataInput" @change="getData" v-model="dayValue" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
      </el-date-picker>
      <div id="mains" style="width: 1200px; height: 400px"></div>
      <el-row>
        <el-col>
          <el-date-picker @change="handelDate" v-model="tableDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
        <el-col>
          <el-select v-model="selectValue" placeholder="请选择" @change="handelSelect">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="data_date" label="日期" width="180">
            </el-table-column>
            <el-table-column prop="active_num" label="活跃用户量">
            </el-table-column>
            <el-table-column prop="increased_num" label="新增用户量">
            </el-table-column>
            <el-table-column prop="increased_rate" label="日新增占比">
            </el-table-column>
            <el-table-column prop="paid_num" label="付费用户量">
            </el-table-column>
            <el-table-column prop="order_num" label="订单量"> </el-table-column>
            <el-table-column prop="arpu" label="客单价"> </el-table-column>
            <el-table-column prop="revenue" label="渠道收入"> </el-table-column>
            <el-table-column prop="publicity_cost" label="渠道成本">
            </el-table-column>
            <el-table-column prop="1d_retention" label="次日留存">
            </el-table-column>
            <el-table-column prop="7d_retention" label="7日留存">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      /* eslint-disable */
      dayValue: '',
      option: {
        title: {
          text: '渠道分析数据表'
        },
        tooltip: {},
        legend: {
          data: ['活跃用户量', '新增用户量', '新增比例']
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: '#000000',
            fontSize: 12, // 如果全部显示不开,可以选择字体大小
            interval: 0, // 控制是否全部显示
            rotate: 0 // 控制字体倾斜
          }
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "15%",
          containLabel: true
        },
        yAxis: [{
          type: "value",
          name: "渠道值"
        },
        {
          type: "value",
          name: "渠道百分比",
          min: 0,
          max: 100,
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}%"
          }
        }
        ],
        series: [{
          name: '活跃用户量',
          type: 'bar',
          data: [],
          barWidth: '25%',
          itemStyle: {
            emphasis: {
              barBorderRadius: 10
            },
            normal: {
              barBorderRadius: 10,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: '#91B6FD'
                },
                {
                  offset: 0.5,
                  color: '#719FFD'
                },
                {
                  offset: 1,
                  color: '#4A86FF'
                }
                ]
              )
            }
          }
        },
        {
          name: '新增用户量',
          type: 'bar',
          data: [],
          itemStyle: {
            emphasis: {
              barBorderRadius: 10
            },
            normal: {
              barBorderRadius: 10,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: '#FE7E66'
                },
                {
                  offset: 0.5,
                  color: '#FE8C77'
                },
                {
                  offset: 1,
                  color: '#FF9B89'
                }
                ]
              )
            }
          }
        },
        {
          name: "新增比例",
          yAxisIndex: 1,
          type: "line",
          stack: "新增比例",
          smooth: true,
          symbolSize: 8,
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#EFE3BD'
                }, {
                  offset: 1,
                  color: '#FFFBF2'
                }],
                globalCoord: false
              }
            }
          },
          itemStyle: {
            normal: {
              color: '#F8C166',
              lineStyle: {
                color: '#F8C166'
              }
            }
          },
          data: []
        }
        ]
      },
      tableDate: [],
      // 下拉菜单配置
      options: [],
      // 下拉菜单数据源
      selectValue: '58同城',
      // 表格
      tableData: [],
      // 表格参数
      tableParams: {
        channel: "",
        endDate: "",
        startDate: ""
      }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    //  初始化下拉菜单数据
    this._getTableInfos()
    // 初始获取表单数据
    this.dayValue = this.getDefaultDate
    this.tableDate = [this.fun_date, this.getDefaultDate]
    this.tableParams.channel = this.selectValue
    this.tableParams.startDate = this.fun_date
    this.tableParams.endDate = this.getDefaultDate
    // 初始化echart
    this._getMapInfo()
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this._getTableDatas()
  },
  methods: {
    // 获取echart数据数据
    async _getMapInfo () {
      const { data } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/Report/channel/mainReport?dataDate=' + this.dayValue, {})
      this.option.xAxis.data = []
      this.option.series[0].data = []
      this.option.series[1].data = []
      this.option.series[2].data = []
      data.data.info.forEach((item, i) => {
        this.option.xAxis.data.push(item.channel_name)
        this.option.series[0].data.push(item.active_num)
        this.option.series[1].data.push(item.increased_num)
        item.increased_rate = item.increased_rate * 100
        this.option.series[2].data.push(item.increased_rate)
      })
      // 渲染表单
      const myChart = echarts.init(document.getElementById('mains'));
      myChart.setOption(this.option, true);
    },
    // 改变时间，就重新获取数据，重新渲染数据表
    getData () {
      this._getMapInfo()
    },
    // 表 chart 渲染
    getChart () {

    },
    async _getTableInfos () {
      const {
        data
      } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/Report/channel/list')
      data.data.info.forEach((item) => {
        this.options.push({
          value: item,
          label: item
        })
      })
    },
    // 日期改变触发事件
    handelDate () {
      this.tableParams.startDate = this.tableDate[0]
      this.tableParams.endDate = this.tableDate[1]
      this._getTableDatas()
    },
    // 下拉菜单改变触发事件
    handelSelect () {
      this.tableParams.channel = this.selectValue
      this._getTableDatas()
    },
    async _getTableDatas () {
      const isData = this.tableParams.channel !== '' && this.tableParams.endDate !== '' && this.tableParams
        .startDate !== ''
      console.log(this.tableParams)
      console.log(isData)
      if (isData) {
        console.log(this.tableParams.channel);
        const {
          data
        } = await this.$http.post(`https://cdpengine.cn/scrm-platform/v1/Report/channel/searchTable`,
          this.tableParams)
        this.tableData = data.data.info
      }
    }
  },
  computed: {
    getDefaultDate () {
      const date = new Date()
      const y = date.getFullYear()
      const m = (date.getMonth() + 1 + '').padStart(2, '0')
      const d = (date.getDate() + '').padStart(2, '0')
      return y + '-' + m + '-' + d
    },
    fun_date () {
      var date1 = new Date()
      // var time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(); // time1表示当前时间
      var date2 = new Date(date1);
      date2.setDate(date1.getDate() - 7);
      var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1 + '').padStart(2, '0') + "-" + date2.getDate();
      return time2;
    }
  }
}
// "2021-04-24"
</script>
<style scoped>
.el-card {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.el-card p {
  text-align: left;
  font-weight: bold;
}

.dataInput {
  margin-bottom: 15px;
}

.el-row {
  display: flex;
  flex-direction: row;
}

.el-table {
  margin-top: 30px;
}
</style>
