<template>
  <el-dialog custom-class="check-dialog" :visible.sync="dialogVisible" title="人气排行" width="35%">
    <el-table :data="popularityRank" :header-cell-style="{ background: '#F9F9F9' }">
      <el-table-column class-name="rank-box" type="index" width="50" label="排名" align="center">
        <template v-slot="{row,$index}">
          <img class="rank-icon" v-if="$index===0" src="../../assets/rank1.svg" alt="">
          <img class="rank-icon" v-else-if="$index===1" src="../../assets/rank2.svg" alt="">
          <img class="rank-icon" v-else-if="$index===2" src="../../assets/rank3.svg" alt="">
          <span v-else>{{$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息" align="left">
        <template slot-scope="{row}">
          <div class="rankUser">
            <img :src="row.openidPicUrl" class="avatar">
            <span>{{row.nickname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="邀请" align="center" width="150">
        <template slot-scope="{row}">
          <span class="blue">{{row.num}}人</span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      popularityRank: []
    }
  },
  methods: {
    open (cur) {
      this.dialogVisible = true
      this.popularityRank = cur.popularityRank
    }
  }
}
</script>

<style lang="less" scoped>
.check-dialog {
  .rankUser {
    display: flex;
    align-items: center;
  }
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .rank-icon {
    width: 30px;
    height: 30px;
  }
  .blue {
    color: #1673ff;
  }
}
::v-deep .el-table .rank-box .cell {
  display: flex;
}
</style>
