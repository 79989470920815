<template >
  <div class="group-welcome">
    <el-container>
      <el-header class="groupheader">群欢迎语</el-header>
      <el-main>
        <el-button class="topBtn" type="primary" @click="addGroupWel(null)"
          ><i class="el-icon-plus"></i>新建群欢迎语</el-button
        >
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#F9F9F9'}"
        >
          <el-table-column prop="media_type" width="110px" label="欢迎语类型">
          </el-table-column>
          <el-table-column
            prop="content"

            label="欢迎语内容"
          >
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间"  width="160px"> </el-table-column>
          <el-table-column label="操作" width="130" >
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="addGroupWel(scope.row.template_id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="medium"
                @click="deleteItem(scope.row.template_id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          :hide-on-single-page="true"
        >
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { postGroupWelList, getGroupWelDel } from '@/service/group'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {

  },
  created() {
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
     postGroupWelList({ pageNo: this.pageNo, pageSize: this.pageSize }).then(res => {
          this.tableData = res.info
          this.total = res.num
     })
    },
    addGroupWel(id) {
      if (id) {
          this.$router.push(`/group/addwelcome?id=${id}`)
      } else {
          this.$router.push('/group/addwelcome')
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem(id) {
      getGroupWelDel({ template_id: id }).then(res => {
         Message.success('删除成功')
         this.getPageList()
      })
    }
  },
  components: {

  }
}
</script>

<style scoped lang="less">
@import url("./index.less");
</style>
