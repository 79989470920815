import {
  get,
  post
} from '../Api/axios'
import {
  URL_WECHAT_CUSTOMER_CONFIG_LIST,
  URL_WECHAT_CUSTOMER_GET_JSAPI_TICKET,
  URL_WECHAT_CUSTOMER_CONFIG_UPDATE,
  URL_WECHAT_CUSTOMER_ADDRESS_LIST,
  URL_WECHAT_CUSTOMER_DEPARTMENT_LIST,
  URL_OFFICIAL_ACCOUNT_UPDATE_CONFIG,
  URL_OFFICIAL_ACCOUNT_UPDATE_LIST,
  URL_WECHAT_CONTACT_INTERCEPT_LIST,
  URL_WECHAT_CONTACT_ADD_INTERCEPT_RULE,
  URL_WECHAT_CONTACT_UPDATE_INTERCEPT_RULE,
  URL_WECHAT_CONTACT_DEL_INTERCEPT_RULE,
  URL_WECHAT_CONTACT_DEPARTMENT_INFO,
  URL_WECHAT_GROUP_GET_USERGROUP_INFO,
  URL_WECHAT_CUSTOMER_TRANSFER_CUSTOMER,
  URL_WECHAT_CUSTOMER_GROUP_ONJOB_TRANSFER,
  URL_WECHAT_CUSTOMER_GROUP_TRANSFER_STATUS,
  URL_WECHAT_CUSTOMER_TRANSFER_RESULT_LIST,
  URL_WECHAT_CUSTOMER_EXTERNALCONTACT_TRANSFER_RESULT,
  URL_WECHAT_CUSTOMER_GET_USER_ALLINFOS
} from '@/Api/api.js'

export function getEnterpriseWechatConfig (params = {}) {
  return get(URL_WECHAT_CUSTOMER_CONFIG_LIST, {
    ...params
  })
}

export function getTicket (params = {}) {
  return get(URL_WECHAT_CUSTOMER_GET_JSAPI_TICKET, {
    ...params
  })
}
export function getUserAllInfo (params = {}) {
  return get(URL_WECHAT_CUSTOMER_GET_USER_ALLINFOS, {
    ...params
  })
}

export function postEnterpriseWechatConfig (params = {}) {
  return post(URL_WECHAT_CUSTOMER_CONFIG_UPDATE, {
    ...params
  })
}

export function postEnterpriseAddressList (params = {}) {
  return post(URL_WECHAT_CUSTOMER_ADDRESS_LIST, {
    ...params
  })
}

export function getEnterpriseDepartmentList (params = {}) {
  return get(URL_WECHAT_CUSTOMER_DEPARTMENT_LIST, {
    ...params
  })
}

export function getOfficialAccountUpdateConfig (params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_UPDATE_CONFIG, {
    ...params
  })
}

export function getOfficialAccountUpdateList (params = {}) {
  return get(URL_OFFICIAL_ACCOUNT_UPDATE_LIST, {
    ...params
  })
}

export function getContactInterceptList (params = {}) {
  return get(URL_WECHAT_CONTACT_INTERCEPT_LIST, {
    ...params
  })
}

export function postAddInterceptRule (params = {}) {
  return post(URL_WECHAT_CONTACT_ADD_INTERCEPT_RULE, {
    ...params
  })
}

export function postUpdateInterceptRule (params = {}) {
  return post(URL_WECHAT_CONTACT_UPDATE_INTERCEPT_RULE, {
    ...params
  })
}

export function getDelInterceptRule (params = {}) {
  return get(URL_WECHAT_CONTACT_DEL_INTERCEPT_RULE, {
    ...params
  })
}

export function getDepartmentInfo (params = {}) {
  return get(URL_WECHAT_CONTACT_DEPARTMENT_INFO, {
    ...params
  })
}

export function getGroupUserGroupInfo (params = {}) {
  return get(URL_WECHAT_GROUP_GET_USERGROUP_INFO, {
    ...params
  })
}

export function postCustomerTransfer (params = {}) {
  return post(URL_WECHAT_CUSTOMER_TRANSFER_CUSTOMER, {
    ...params
  })
}

export function postGroupTransfer (params = {}) {
  return post(URL_WECHAT_CUSTOMER_GROUP_ONJOB_TRANSFER, {
    ...params
  })
}

export function getGroupTransferStatus (params = {}) {
  return get(URL_WECHAT_CUSTOMER_GROUP_TRANSFER_STATUS, {
    ...params
  })
}

export function getCustomerTransferResultList (params = {}) {
  return get(URL_WECHAT_CUSTOMER_TRANSFER_RESULT_LIST, {
    ...params
  })
}

export function getCustomerTransferResultExternal (params = {}) {
  return get(URL_WECHAT_CUSTOMER_EXTERNALCONTACT_TRANSFER_RESULT, {
    ...params
  })
}
