<template>
  <Block title="创建规则">
    <div class="create-rule-wrapper">
      <div class="rule-name">
        <label for="rule-name"
          class="rule-names">
          <span>规则名称:</span>
          <el-input v-model="form.sopName"
            id="rule-name"
            placeholder="请输入规则名称"></el-input>
        </label>
      </div>
      <div class="creatSop_headerExplain_container">
        <h2>设置推送周期及文案</h2>
        <p>设置规则后将会通过「提醒」提醒群主发送推送内容</p>
      </div>
      <div class="create-btns">
        <el-button type="primary"
          icon="el-icon-plus"
          @click="addRuleBtn">添加</el-button>
        <el-button type="primary"
          @click="dialogVisible = true">创建规则</el-button>
      </div>
      <el-dialog title="创建规则"
        :visible.sync="dialogVisible"
        width="45%">
        <div class="new-rule-content">
          <h2 class="pushSOP_title">设置推送内容</h2>
          <el-form ref="form"
            :model="form"
            label-position="top"
            label-width="80px">
            <el-form-item label="活动名称">
              <el-input v-model="form.description"
                placeholder="设置内容名称，仅内部可见"></el-input>
            </el-form-item>
            <el-form-item label="设置发送时间">
              <div class="rule-time rule-time-one">
                <el-radio v-model="form.sendTimeType"
                  label="当日">
                  <span>加入规则后</span>
                  <el-input size="mini"
                    v-model="thatDay.hour"></el-input>
                  <span>小时</span>
                  <el-input size="mini"
                    v-model="thatDay.minute"></el-input>
                  <span>分钟后提醒</span>
                </el-radio>
              </div>
              <div class="rule-time rule-time-two">
                <el-radio v-model="form.sendTimeType"
                  label="非当日">
                  <span>加入规则后</span>
                  <el-input size="mini"
                    v-model="nonDay.day"></el-input>
                  <span>天后，当日</span>
                  <el-time-picker size="mini"
                    v-model="nonDay.thatDayTime">
                  </el-time-picker>
                  <span>提醒发送</span>
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="设置发送内容">
              <div class="setting-content">
                <div class="select-radios">
                  <el-radio v-model="form.msgType"
                    label="文字">文字</el-radio>
                  <el-radio v-model="form.msgType"
                    label="图片">图片</el-radio>
                  <el-radio v-model="form.msgType"
                    label="链接">链接</el-radio>
                </div>
                <div class="content">
                  <el-input v-if="form.msgType === '文字'"
                    placeholder="请输入内容"
                    v-model="form.msgDetail"></el-input>
                  <el-input v-if="form.msgType === '链接'"
                    placeholder="请输入链接"
                    v-model="form.msgDetail"></el-input>
                  <UploadPicture v-if="form.msgType === '图片'"></UploadPicture>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="添加执行员工">
              <el-select v-model="form.responsibleId"
                filterable
                placeholder="请选择">
                <el-option style="width:'200px'"
                  v-for="item in personOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="添加标签">
              <el-select v-model="form.tags"
                multiple
                filterable
                placeholder="请选择">
                <el-option v-for="item in tagsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
          class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary"
            @click="onFinish">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/Block'
import UploadPicture from '@/components/UploadPicture'
import { getSOPPersonList, addPersonalSOPRule } from '@/service/group'
import { getLableList } from '@/service/label'
import moment from 'moment'
export default {
  props: {

  },
  data () {
    return {
      dialogVisible: false,
      form: {
        msgDetail: '',
        sopName: '',
        sendTimeType: '当日',
        msgType: '文字',
        sendTime: '',
        responsibleId: '',
        tags: ''
      },
      thatDay: {
        hour: '',
        minute: ''
      },
      nonDay: {
        day: '',
        thatDayTime: ''
      },
      personOptions: [],
      tagsOptions: []
    }
  },
  computed: {

  },
  created () {
    this.getPersonList()
    this.tagPageList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    getPersonList () {
      getSOPPersonList().then(res => {
        this.personOptions = res.map(v => ({ value: v.userId, label: v.userName }))
      })
    },
    tagPageList () {
      getLableList().then(res => {
        const allTagList = [].concat.apply([], res.map(v => [...v.tagList]))
        this.tagsOptions = allTagList.map(v => ({ value: v.tagId, label: v.tagName }))
      })
    },
    onFinish () {
      if (this.form.sendTimeType === '当日') {
        this.form.sendTime = this.thatDay.hour + ',' + this.thatDay.minute
      } else {
        this.form.sendTime = `${this.nonDay.day},${moment(this.nonDay.thatDayTime).format('HH:mm:ss')}`
      }
      this.form.tags = this.form.tags.join(',')
      this.dialogVisible = false
    },
    addRuleBtn () {
      if (this.form.sopName) {
        addPersonalSOPRule(this.form).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$message.error('请输入规则名称')
      }
    }

  },
  components: {
    Block,
    UploadPicture
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
