<template>
  <div>
    <el-card>
      <div id="chartTagTree" style="width: 100%; height: 800px"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartTagTree: null,
      optionTagTree: {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            data: [],
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 10,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 14
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      }
    }
  },
  created () {
  },
  mounted () {
    this.chartTagTree = this.$echarts.init(document.getElementById('chartTagTree'))
    this.setChartTagTree()
  },
  methods: {
    async setChartTagTree () {
      var that = this
      this.chartTagTree.showLoading();
      const { data: res } = await this.$http.post('https://cdpengine.cn/scrm-platform/v1/UserProfile/searchUserTagView')
      this.optionTagTree.series[0].data = [res.data]
      this.chartTagTree.hideLoading();
      // echarts.util.each(data.children, function (item, index) {
      //   index % 2 === 0 && (item.collapsed = true);
      // });
      this.chartTagTree.setOption(this.optionTagTree)
      this.chartTagTree.on('click', function (params) {
        if (params.value.length > 4) {
          window.localStorage.setItem('tagId', params.value)
          that.$router.push({ name: 'TagDetails' })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
