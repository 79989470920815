<!-- 线性图 -->
<template>
  <div id="map">暂无数据...</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['mapData'],
  data () {
    /* eslint-disable */
    return {
      option: {
        color: ['#6E9CFA', '#FE5377', '#95FFEC', '#F86F91', '#5C8BFF', '#FCBE64', '#8BE0F0', '#9B81FB', '#FD765D', '#4AB664', '#F38AC3', '#AC71FF', '#F4E56E'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
        ]
      }
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.refreshMap()
    this.flush()
  },
  methods: {
    refreshMap () {
      const myChart = echarts.init(document.getElementById('map'))
      myChart.setOption(this.option,true);
    },
    flush () {
      // (this.mapData.transform.legend.length == this.mapData.transform.numUser.length)
      if ((this.mapData.transform)) {
        this.option.xAxis.data = this.mapData.transform.legend
        const legendX = this.mapData.transform.numUser.slice(0, this.mapData.transform.numUser.length - 1)
        this.option.legend.data = legendX.map(item => item.type)
        this.option.series = legendX.map(item => {
          return {
            name: item.type,
            type: 'bar',
            data: item.num
          }
        })
        this.refreshMap()
      } else {
        if (this.mapData.transform) {
          this.option.xAxis.data = this.mapData.transform.legend
          this.option.legend.data = this.mapData.transform.legend
          const Xdata = this.mapData.transform.numUser.map(item => item.num)
          this.option.series = [{
            data: Xdata,
            type: 'bar'
          }]
          this.refreshMap()
        }
      }
    }
  },
  watch: {
    mapData () {
      this.flush()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>