<!-- 线性图 -->
<template>
  <div id="map">暂无数据...</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['mapData'],
  data() {
    /* eslint-disable */
    return {
      option: {
        color: ['#6E9CFA', '#FE5377', '#95FFEC', '#F86F91', '#5C8BFF', '#FCBE64', '#8BE0F0', '#9B81FB', '#FD765D', '#4AB664', '#F38AC3', '#AC71FF', '#F4E56E'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
        ]
      }
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.refreshMap()
    this.flush()
  },
  methods: {
    refreshMap() {
      const myChart = echarts.init(document.getElementById('map'))
      myChart.setOption(this.option);
    },
    flush() {
      if (this.mapData) {
        let key = []
        let legend = new Set()
        let legends = []
        let isEvent = []
        this.mapData.all && this.mapData.all.forEach(item => {
          key = [...key, ...Object.keys(item)]
        })
        this.mapData.all && this.mapData.all.forEach((item, i) => {
          item[key[i]].forEach(v => {
            legend.add(v.legend)
          })
        })
        legends = Array.from(legend)
        // console.log(legends, key);
        if (legends.length > 1) {
          this.option.xAxis.data = legends
          this.option.legend.data = key;
          this.mapData.all && this.mapData.all.forEach((item, i) => {
            let flag = []
            item[key[i]].forEach(v => {
              flag.push(v.total)
            })
            isEvent.push({
              name: key[i],
              type: 'bar',
              barWidth: '20%',
              data: flag
            })
          })
          this.option.series = isEvent
          // console.log(isEvent);
        } else {
          this.option.xAxis.data = key
          // console.log(this.option.xAxis.data);
          this.mapData.all && this.mapData.all.forEach((item, i) => {
            item[key[i]].forEach(v => {
              isEvent.push(v.total)
            })
          })
          // console.log(isEvent);
          this.option.series = [{
            name: '所有用户',
            type: 'bar',
            barWidth: '10%',
            data: isEvent
          }]
        }

        this.refreshMap()
      }
    }
  },
  watch: {
    mapData() {
      this.flush()
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
</style>