<!-- 添加/编辑用户 -->
<template>
  <div>
    <el-dialog
      title="提示"
      @open="openDialog"
      @close="closeDialog"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form
        ref="addRolesFormRef"
        :model="addRolesForm"
        :rules="addRolesFormRules"
        label-width="80px"
      >
        <el-form-item label="角色" prop="memo">
          <el-input
            placeholder="请输入角色"
            v-model="addRolesForm.memo"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            placeholder="请输入名称"
            v-model="addRolesForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="flag" label="状态" props="status">
          <el-select v-model="addRolesForm.status" placeholder="请选择状态">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="postAddRoles">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  postRoleUpdate,
  postRoleUAdd
} from '@/service/roles'

export default {
  props: {
    dialogShow: {
      type: Boolean,
      defalut: false
    },
    rolesData: Object,
    updateTable: Function
  },
  data() {
    return {
      addRolesForm: {
        name: '',
        status: '',
        memo: ''
      },
      addRolesFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        memo: [{ required: true, message: '请输入角色', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: ['change', 'blur'] }]
      },
      options: [
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '失效'
        }
      ],
      flag: false
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
  },
  methods: {
    openDialog() {
      console.log(this.rolesData)
      if (this.rolesData.id) {
        this.addRolesForm = this.rolesData
        this.flag = true
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.addRolesForm = {}
      // this.rolesData = {}
      this.flag = false
    },
    postAddRoles() {
      this.$refs.addRolesFormRef.validate(async valid => {
        if (valid) {
          if (this.flag) {
            var parm = { id: this.addRolesForm.id, name: this.addRolesForm.name, memo: this.addRolesForm.memo, status: this.addRolesForm.status }
            postRoleUpdate({ ...parm }).then(res => {
              if (res.msg !== '修改成功') {
                this.$message.success('修改成功')
              } else {
                this.$message.warning('修改失败')
              }
            })
          } else {
            var addParm = { name: this.addRolesForm.name, memo: this.addRolesForm.memo }
            postRoleUAdd({ ...addParm }).then(res => {
              if (res.msg !== '添加成功') {
                this.$message.success('添加成功')
              } else {
                this.$message.warning('添加失败')
              }
            })
          }
          setTimeout(() => {
            this.$emit('closeDialog', false)
            this.updateTable()
          }, 500)
        }
      })
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
.el-select {
  float: left;
}
</style>
