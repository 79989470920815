<template>
  <div class="Automation">
    <div class="btnBox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="任务名称">
          <el-input
            v-model="formInline.missionName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件夹">
          <el-select v-model="formInline.classify" placeholder="请选择文件夹">
            <el-option
              v-for="item in folderList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-image
            @click="onSubmit"
            style="width: 30px; height: 36px; cursor: pointer"
            :src="url"
            fit="cover"
          ></el-image>
        </el-form-item>
      </el-form>
      <el-button type="text" @click="back">返回列表页</el-button>
    </div>
    <FlowPanel ref="flowPanel" :dataEdit="data"></FlowPanel>
  </div>
</template>

<script>
// getAutomationFolderList,
import { postAutomationCreate } from '@/service/auto';
import FlowPanel from '@/components/auto/panel';
import { Message } from 'element-ui';
export default {
  props: ['folderList', 'editData'],
  data () {
    return {
      data: {},
      folder: [],
      url: require('@/assets/save.png'),
      formInline: {
        missionName: '',
        classify: ''
      },
      id: null
    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    this.init();
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
  watch: {
    folderList (val) {
      console.log('folderList -> val', val);
      this.folder = val;
    },
    editData (val) {
      console.log('editData -> val', val);
      this.editData = val;
    }
  },
  methods: {
    init () {
      if (this.editData) {
        this.data.lineList = this.editData.lineList;
        this.data.nodeList = this.editData.nodeList;
        this.data.name = this.editData.missionName;
        this.id = this.editData.id;
        this.formInline = {
          missionName: this.editData.missionName,
          classify: this.editData.classify
        };
      } else {
        this.id = null;
        this.data = {};
        this.formInline = {
          missionName: '',
          classify: ''
        };
      }
    },
    onSubmit () {
      console.log('dddd', this.$refs.flowPanel.data.lineList);
      // if()
      var lineList = this.$refs.flowPanel.data.lineList;
      //
      var nodeList = this.$refs.flowPanel.data.nodeList;
      if (this.formInline.missionName === '') {
        Message.warning('请输入任务名称');
      } else if (this.formInline.classify === '') {
        Message.warning('请选择文件夹');
      } else if (nodeList.length === 0) {
        Message.warning('流程不可为空,请选择组件进行编排');
      } else if (nodeList.filter((i) => i.type === 'start').length === 0) {
        Message.warning('必须要有开始流程，请选择');
      } else {
        var data = {
          missionName: this.formInline.missionName,
          classify: this.formInline.classify,
          nodeList: nodeList,
          lineList: lineList
        };
        if (this.id) {
          data.id = this.id;
        }
        var lineArr = [];
        const judgeArr = []
        lineList.map((item, index) => {
          const { from, to } = item
          const nodeType = nodeList.find((n) => to === n.id).nodeType
          if (index === 0) {
            lineArr.push(nodeList.find((n) => from === n.id).nodeType);
            if (item.nodeType !== 'judgeNode') {
              lineArr.push(nodeType);
            } else {
              judgeArr.push(nodeType)
            }
          } else {
            if (item.nodeType !== 'judgeNode') {
              lineArr.push(nodeType);
            } else {
              judgeArr.push(nodeType)
            }
          }
        });
        const judgeIndex = lineArr.findIndex(l => l === 'judgeNode')
        if (judgeIndex !== -1 && judgeArr.length) {
          lineArr[judgeIndex] = `IF(${[lineArr[judgeIndex], ...judgeArr].join()})`
        }
        if (lineArr.length === 0) {
          Message.warning('请连接相关节点！');
          return false;
        } else {
          data.grammar = 'THEN(' + lineArr.join(',') + ')';
        }
        var res = [];
        nodeList.map((item) => {
          switch (item.nodeType) {
            case 'startNode':
              if (item.data) {
                data.startNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写开始节点表单！');
              }
              break;
            case 'timeDelayNode':
              if (item.data) {
                data.timeDelayNode = item.timeDelay;
              } else {
                res.push(false);
                Message.warning('请填写等待节点表单！');
              }
              break;
            case 'wechatPersonSelect':
              if (item.data) {
                data.wechatPersonSelect = item.data;
              } else {
                res.push(false);
                Message.warning('请填写微信好友节点表单！');
              }
              break;
            case 'accountEventNode':
              if (item.data) {
                data.accountEventNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号事件节点表单！');
              }
              break;
            case 'wechatPersonNode':
              if (item.data) {
                data.wechatPersonNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写微信消息节点表单！');
              }
              break;
            case 'accountMessageNode':
              if (item.data) {
                data.accountMessageNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号消息节点表单！');
              }
              break;
            case 'accountPersonSelect':
              if (item.data) {
                data.accountPersonSelect = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号粉丝节点表单！');
              }
              break;
            case 'wechatGroupSelect':
              if (item.data) {
                data.wechatGroupSelect = item.data;
              } else {
                res.push(false);
                Message.warning('请填写微信群节点表单！');
              }
              break;
            case 'wechatEventNode':
              if (item.data) {
                data.wechatEventNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写微信事件节点表单！');
              }
              break;
            case 'miniproEventNode':
              if (item.data) {
                data.miniproEventNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写小程序事件节点表单！');
              }
              break;
            case 'formEventNode':
              if (item.data) {
                data.formEventNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写填写表单节点表单！');
              }
              break;
            case 'accountPersonSelect':
              if (item.data) {
                data.accountPersonSelect = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号粉丝节点表单！');
              }
              break;
            case 'wechatGroupNode':
              if (item.data) {
                data.wechatGroupNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写微信群消息节点表单！');
              }
              break;
            case 'miniproMessageNode':
              if (item.data) {
                data.miniproMessageNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写通知服务节点表单！');
              }
              break;
            case 'accountFormworkNode':
              if (item.data) {
                if (!item.data.url) {
                  delete item.data.url
                }
                if (!item.data.miniprogram.appid) {
                  delete item.data.miniprogram
                }
                data.accountFormworkNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号模板消息节点表单！');
              }
              break;
            case 'shortMessageNode':
              if (item.data) {
                data.shortMessageNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写短信节点表单！');
              }
              break;
            case 'accountEventNode':
              if (item.data) {
                data.accountEventNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写公众号事件节点表单！');
              }
              break;
            case 'judgeNode':
              if (item.data) {
                data.judgeNode = item.data;
              } else {
                res.push(false);
                Message.warning('请填写判断条件节点表单！');
              }
              break;
            default:
              break;
          }
        });

        if (res.length !== 0) {
          return false;
        } else {
          postAutomationCreate(data).then((res) => {
            this.back();
            Message.success('成功');
          });
        }
      }
    },
    back () {
      this.$emit('visableAutomation', false);
    }
  },
  components: {
    FlowPanel
  }
};
</script>

<style  lang="less">
@import url("./index.less");
</style>
