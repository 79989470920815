/* eslint-disable camelcase */
import {
  get,
  post,
  create,
  upload
} from '../Api/axios'
import {
  URL_QUERYPERSONAL_PAGE_LIST,
  URL_WE_CHAT_SYNCHRONIZE_GROUP,
  URL_QUERY_GROUP_DETAILE,
  URL_GROUP_DATA_POLYMERIZATION,
  URL_GROUP_DATA_DAY_POLYMERIZATION,
  URL_GROUP_SOP_PAGE_LIST,
  URL_GET_SOP_PERSON_LIST,
  URL_CREATE_GROUP_SOP_RULE,
  URL_DELETE_GROUP_SOP_RULE,
  URL_GROUP_SOP_RULE_DETAILES,
  URLPERSONAL_SOP_PAGE_LIST,
  URL_CREATE_PERSONAL_SOP_RULE,
  URL_DELET_EPERSONAL_SOP_RULE,
  URL_PERSONAL_SOP_RULE_DETAILES,
  URL_GROUP_LABEL_LIST,
  URL_CREATE_GROUP_LABEL,
  URL_DELETE_GROUP_LABEL,
  URL_UPDATE_GROUP_LABEL,
  URL_GROUP_WELCOME_TEMPLATE_LIST,
  URL_ADD_FILE_MATERIAL,
  URL_GROUP_WELCOME_TEMPLATE_ADD,
  URL_GROUP_WELCOME_TEMPLATE_GET,
  URL_GROUP_WELCOME_TEMPLATE_EDIT,
  URL_GROUP_WELCOME_TEMPLATE_DEL,
  URL_UPDATE_CUSTOMER_TAGS_NUM,
  URL_MINI_SUBSCRIBE_EVENT_LIST,
  URL_QUERY_LABEL_LIST,
  URL_PERSONAL_SOP_EDIT,
  URL_PERSONAL_SOP_CREATE,
  URL_PERSONAL_SOP_DELETE,
  URL_GROUP_GROUPCHAT_LABEL_TAG,
  URL_MEDIA_UPLOAD_ATTACHMENT,
  URL_GROUP_SOP_EDIT,
  URL_GROUP_SOP_ADDUSER_LIST,
  URL_GROUP_TAGS_NUM,
  URL_GROUP_SOP_DELETE,
  URL_GROUP_ADDRESS_LIST
} from '@/Api/api.js'

export function getQueryGroupPageList(params = {}) {
  return post(URL_QUERYPERSONAL_PAGE_LIST, {
    ...params
  })
}

export function getSynchronizeGroup() {
  return post(URL_WE_CHAT_SYNCHRONIZE_GROUP, {
    limit: 1000
  })
}

export function getGroupDetailes(group_id) {
  return get(URL_QUERY_GROUP_DETAILE, {
    group_id
  })
}

// 群数据统计
export function groupDataStatisticsPolymerization() {
  return get(URL_GROUP_DATA_POLYMERIZATION)
}
export function groupDataStatisticsDayPolymerization(day_begin_time, day_end_time) {
  return post(URL_GROUP_DATA_DAY_POLYMERIZATION, {
    day_begin_time,
    day_end_time
  })
}

// 群统计 群SOP
export function getGroupSopPageList(params = {}) {
  return post(URL_GROUP_SOP_PAGE_LIST, {
    ...params
  })
}

export function addGroupSOPRule(params = {}) {
  return create(URL_CREATE_GROUP_SOP_RULE, {
    ...params
  })
}

export function removeGroupSOPRule(id) {
  return get(URL_DELETE_GROUP_SOP_RULE, {
    id
  })
}

export function getGropRuleDetails(id) {
  return get(URL_GROUP_SOP_RULE_DETAILES, {
    id
  })
}

export function postGroupSopEdit(params = {}) {
  return post(URL_GROUP_SOP_EDIT, {
    ...params
  })
}

// 个人SOP
export function getPersonalSopPageList(params = {}) {
  return post(URLPERSONAL_SOP_PAGE_LIST, {
    ...params
  })
}

export function addPersonalSOPRule(params = {}) {
  return create(URL_CREATE_PERSONAL_SOP_RULE, {
    ...params
  })
}

export function removePersonalSOPRule(id) {
  return get(URL_DELET_EPERSONAL_SOP_RULE, {
    id
  })
}

export function getPersonalRuleDetails(id) {
  return get(URL_PERSONAL_SOP_RULE_DETAILES, {
    id
  })
}
// 人员列表
export function getSOPPersonList() {
  return get(URL_GET_SOP_PERSON_LIST)
}

export function getSOPGroupList() {
  return get(URL_GROUP_SOP_ADDUSER_LIST)
}

export function getAddressList(params) {
  return post(URL_GROUP_ADDRESS_LIST, {
    ...params
  })
}

// 群标签
export function getGroupLabelList() {
  return get(URL_GROUP_LABEL_LIST)
}

export function addGroupLabel(params = {}) {
  return post(URL_CREATE_GROUP_LABEL, {
    ...params
  })
}

export function removeGroupLabel(params = {}) {
  return post(URL_DELETE_GROUP_LABEL, {
    ...params
  })
}

export function updateGroupLabel(params = {}) {
  return get(URL_UPDATE_GROUP_LABEL, {
    ...params
  })
}

// 群欢迎语链接
export function postGroupWelList(params = {}) {
  return post(URL_GROUP_WELCOME_TEMPLATE_LIST, {
    ...params
  })
}

// 上传图片
export function uploadFile(params = {}) {
  return upload(URL_ADD_FILE_MATERIAL, {
    ...params
  })
}

// 上传图片
export function uploadAttachmentFile(params = {}) {
  return upload(URL_MEDIA_UPLOAD_ATTACHMENT, {
    ...params
  })
}

// 添加群欢迎语
export function postGroupWelAdd(params = {}) {
  return post(URL_GROUP_WELCOME_TEMPLATE_ADD, {
    ...params
  })
}

// 获取群欢迎语
export function postGroupWelGet(params = {}) {
  return post(URL_GROUP_WELCOME_TEMPLATE_GET, {
    ...params
  })
}

//
export function postGroupWelEdit(params = {}) {
  return post(URL_GROUP_WELCOME_TEMPLATE_EDIT, {
    ...params
  })
}

export function getGroupWelDel(params = {}) {
  return get(URL_GROUP_WELCOME_TEMPLATE_DEL, {
    ...params
  })
}

export function getPersonalListAndDetails(params = {}) {
  return post(URLPERSONAL_SOP_PAGE_LIST, {
    ...params
  })
}

export function getTagsUserNum(params = {}) {
  return post(URL_UPDATE_CUSTOMER_TAGS_NUM, {
    ...params
  })
}

export function getMiniEventList(params = {}) {
  return get(URL_MINI_SUBSCRIBE_EVENT_LIST, {
    ...params
  })
}
export function getGroupTagsUserNum(params = {}) {
  return get(URL_GROUP_TAGS_NUM, {
    ...params
  })
}

export function getLabelList(params = {}) {
  return get(URL_QUERY_LABEL_LIST, {
    ...params
  })
}

export function postSopEdit(params = {}) {
  return post(URL_PERSONAL_SOP_EDIT, {
    ...params
  })
}

export function postSopCreate(params = {}) {
  return post(URL_PERSONAL_SOP_CREATE, {
    ...params
  })
}

export function removeSop(id) {
  return get(URL_PERSONAL_SOP_DELETE, {
    id
  })
}

export function removeGroupSop(id) {
  return get(URL_GROUP_SOP_DELETE, {
    id
  })
}

export function getGroupLabelTags(params = {}) {
  return get(URL_GROUP_GROUPCHAT_LABEL_TAG, {
    ...params
  })
}
