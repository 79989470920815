<template >
  <div class="activity-create">
    <el-container>
      <el-header class="groupheader">
        <el-alert title="裂变活动海报设计须知" type="warning" :closable="false" description=" 1、尺寸：建议720px*1280px，大小不超过500kb
        2、可自行拖拽二维码和头像元素的大小、位置
        3、海报中生成的二维码时临时码，有效期30天" show-icon>
        </el-alert>
      </el-header>
      <div class="content">
        <div class="canvas">
          <div class="box" ref="canvas_box" :style="{'backgroundImage':`url(${form.url})`}">
            <div class="upload" v-if="!form.url">
              <el-upload class="upload-demo" ref="uploadImgPoster" action="" :file-list="filePosterList" :show-file-list="false" :http-request="fileUploadPoster" :on-remove="removePosterFile" :limit="1">
                <div>
                  <div class="add">+</div>
                  <div>上传图片</div>
                </div>
              </el-upload>

            </div>
            <div class="tools" ref="tools" :style="{top:`${tools.top}px`,left:`${tools.left}px`}" v-if="tools.visible" @click.stop>
              <el-input-number v-model="curObj.width" :min="20" :max="200" size="mini" @blur="numberBlur" @change="updateTools"></el-input-number>
            </div>
            <div class="code" @click.stop="showToos('code')" draggable @dragstart.stop="e => dragStart(e,'code')" @dragend.stop="e=>dragEnd(e,'code')" :style="{top:`${code.top}px`,left:`${code.left}px`,width:`${code.width}px`,height:`${code.height}px`}">
              <img src="../../../assets/code.png" width="100%">
            </div>
            <div class="avatar" @click.stop="showToos('avatar')" draggable @dragstart.stop="e => dragStart(e,'avatar')" @dragend.stop="e=>dragEnd(e,'avatar')" :style="{top:`${avatar.top}px`,left:`${avatar.left}px`,width:`${avatar.width}px`,height:`${avatar.height}px`}">
              <img src="../../../assets/user_code.png" alt="" srcset="" width="100%">
            </div>
          </div>
        </div>
        <div class="form">
          <el-form v-model="form">
            <el-form-item label="活动名称" required>
              <el-input v-model="form.activityName"></el-input>
            </el-form-item>
            <el-form-item label="活动介绍" required>
              <el-input type="textarea" v-model="form.introduction"></el-input>
            </el-form-item>
            <el-form-item label="转发参与人数" required>
              <el-input v-model="form.friendNum"></el-input>
            </el-form-item>
            <el-form-item class="flex_form_item" label="截止时间" required>
              <el-date-picker v-model="form.endTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item class="flex_form_item" label="领奖回复口令" required>
              <el-input v-model="form.countersign"></el-input>
            </el-form-item>
            <el-form-item class="flex_form_item" label="测试人员账号id" required>
              <el-input v-model="form.openid"></el-input>
            </el-form-item>
            <el-form-item label="关注后推送文字" required>
              <el-input v-model="form.configSendText"></el-input>
            </el-form-item>
            <el-form-item label="关注后推送图文" required>
            </el-form-item>
            <el-col :span="11">
              <div class="img">
                <img :src="form.configSendArticle.pic" alt="" width="100%" height="100%">
                <div class="title" v-show="form.configSendArticle.title && form.configSendArticle.pic">{{form.configSendArticle.title}}</div>
              </div>
            </el-col>
            <el-col :span="13">
              <el-form-item label="封面介绍">
                <el-input v-model="form.configSendArticle.title"></el-input>
              </el-form-item>
              <el-form-item label="封面图片">
                <el-upload class="upload-demo" ref="uploadImg" action="" :file-list="fileList" :show-file-list="true" :http-request="fileUpload" :on-remove="removeFile" :limit="1">
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="摘要 (选填,该摘要只在发送图文消息为单条时显示)">
                <el-input type="textarea" v-model="form.configSendArticle.info"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
// import {
//   Message
// } from 'element-ui'
import { uploadImg } from "@/service/materialLibrary";
import { uploadTemp } from "@/service/sendtogroup";
import { getFissionActivityCreate } from "@/service/activity";
import { postUpload } from '@/service/code'

export default {
  data () {
    return {
      canvasBox: null,
      form: {
        activityName: '',
        endTime: '',
        countersign: '',
        openid: '',
        friendNum: '',
        introduction: '',
        configSendText: '',
        url: '',
        configSendArticle: {
          mediaId: '',
          pic: '',
          title: '',
          info: ''
        },
        positionInfo: {
          code: {},
          avatar: {}
        }
      },
      cur: '',
      curObj: {},
      tools: {
        top: 0,
        left: 0,
        visible: false
      },
      code: {
        top: 0,
        left: 0,
        height: 60,
        width: 60
      },
      avatar: {
        left: 0,
        top: 0,
        height: 40,
        width: 40
      },
      fileList: [],
      filePosterList: [],
      upload: {
        media: null,
        type: 'image'
      }
    }
  },
  computed: {

  },
  created () {
    window.addEventListener('click', () => {
      this.tools.visible = false
    })
  },
  mounted () {
    this.canvasBox = this.$refs.canvas_box
    const query = JSON.parse(this.$route.query.row)
    if (query !== null) {
      this.form = query
      this.code = query.positionInfo.code
      this.avatar = query.positionInfo.avatar
    } else {
      this.avatar.top = this.canvasBox.clientHeight - this.avatar.height
    }
  },
  watch: {
  },
  methods: {
    dragStart (e, a) {
      console.log(e);
      this.tools.visible = false
    },
    dragEnd (e, prop) {
      const left = e.pageX - this.canvasBox.getBoundingClientRect().left - e.srcElement.clientWidth / 2
      const top = e.pageY - this.canvasBox.getBoundingClientRect().top - e.srcElement.clientHeight / 2
      const leMax = this.canvasBox.clientWidth - e.srcElement.clientWidth
      const topMax = this.canvasBox.clientHeight - e.srcElement.clientHeight
      this[prop].top = top < 0 ? 0 : top > topMax ? topMax : top
      this[prop].left = left < 0 ? 0 : left > leMax ? leMax : left
    },
    showToos (prop) {
      this.cur = prop
      this.curObj = this[prop]
      this.tools.visible = true
      this.$nextTick(() => {
        const width = this.$refs.tools.clientWidth
        this.tools.left = this[prop].left - (width - this[prop].width) / 2
        this.tools.top = this[prop].top + this[prop].height + 10
      })
    },
    updateTools () {
      if (this[this.cur].width) {
        this[this.cur].height = this[this.cur].width
        const width = this.$refs.tools.clientWidth
        this.tools.left = this[this.cur].left - (width - this[this.cur].width) / 2
        this.tools.top = this[this.cur].top + this[this.cur].height + 10
      }
    },
    numberBlur () {
      if (!this.curObj.width) this.curObj.width = 60
    },
    removeFile () {
      this.fileList = []
      this.form.configSendArticle.pic = ''
      this.form.configSendArticle.mediaId = ''
      this.$refs.uploadImg.clearFiles()
    },
    async fileUpload (file, b) {
      console.log(file, b);
      const res = await uploadImg({
        file: file.file,
        introduction: '裂变海报'
      });
      this.form.configSendArticle.pic = res
      this.upload.media = file.file
      uploadTemp({ ...this.upload }).then(res => {
        this.form.configSendArticle.mediaId = res;
      });

      return true
    },
    removePosterFile () {
      this.filePosterList = []
      this.form.url = ''
      this.$refs.uploadImgPoster.clearFiles()
    },
    async fileUploadPoster (file) {
      postUpload({ file: file.file }).then((res) => {
        this.form.url = res
      })
      return true
    },
    save () {
      this.form.positionInfo.code = this.code
      this.form.positionInfo.avatar = this.avatar
      if (this.form.activityName && this.form.endTime && this.form.openid && this.form.countersign && this.form.introduction && this.form.configSendText && this.form.configSendArticle.mediaId) {
        getFissionActivityCreate(this.form).then(res => {
          this.$message.success('保存成功')
          this.$router.push('/Wechat/ParameterQRCode/activity')
        })
      } else {
        this.$message.error('请将表单填写完整')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
::v-deep .flex_form_item {
  display: flex;
  align-items: center;
  .el-form-item__content {
    flex: 1;
  }
}
</style>
