<template>
  <el-pagination @size-change="handleSizeChange"
    background
    @current-change="handleCurrentChange"
    :current-page="pageNo"
    :page-sizes="[10, 20, 40]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next"
    :total="total">
  </el-pagination>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      require: true
    },
    pageNo: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    runList: {
      type: Function,
      require: true
    }
  },
  data () {
    return {}
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.runList(this.pageNo, this.pageSize)
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.runList(this.pageNo, this.pageSize)
    }
  },
  components: {

  }
}
</script>

<style scoped lang="less">
</style>
