<!-- 细分维度选择 -->
<template>
  <div class="dimension-warapper">
    <div class="title">细分维度</div>
    <div class="dimension-tags">
      <el-tag :key="i" v-for="(tag, i) in tagsData" size="small" closable :disable-transitions="false" @close="handleClose(tag)">
        {{ tag.property_name }}
      </el-tag>
    </div>
    <el-dropdown @command="handleCommand" trigger="click" placement="bottom-start">
      <div class="indicate-select">
        <div class="icon-add">
          <i class="iconfont icon-xinjian"></i>
        </div>
        <el-tag size="small">请选择</el-tag>
      </div>
      <el-dropdown-menu class="dimension-selec" slot="dropdown">
        <el-tabs v-model="activeName" class="dimension-tabs">
          <el-tab-pane label="事件属性" name="first">
            <el-dropdown-item :command="item1" v-for="item1 in eventPros" :key="item1.property_code">
              {{ item1.property_name }}
            </el-dropdown-item>
          </el-tab-pane>
          <el-tab-pane label="用户属性" name="second">
            <el-dropdown-item :command="item2" v-for="item2 in usePros" :key="item2.property_code">
              {{ item2.property_name }}
            </el-dropdown-item>
          </el-tab-pane>
        </el-tabs>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: ['target'],
  data () {
    /* eslint-disable */
    return {
      //事件属性
      eventPros: [],
      //用户属性
      usePros: [],
      activeName: 'first',
      tagsData: [],
      data: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this._gitDimensionData()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    _gitDimensionData () {
      this.$http.post('https://cdpengine.cn/scrm-platform/v1/DataAnalysis/attributeList').then(res => {
        this.data = res.data.data
        res.data.data.forEach(item => {
          if (item.property_type_name === "用户属性") {
            this.usePros.push(item)
          } else {
            this.eventPros.push(item)
          }
        })
      })
    },
    handleCommand (command) {
      this.tagsData.push(command)
    },
    handleClose (tag) {
      this.tagsData.splice(this.tagsData.indexOf(tag), 1);
    }
  },
  watch: {
    tagsData () {
      this.$emit('getDimensionData', this.tagsData)
    },
    target: {
      immediate: true,
      deep: true,
      handler (val) {
        var res = []
        setTimeout(() => {
          val.forEach(t => {
            var item = this.data.filter(value => t === value.property_code)
            if (item.length !== 0) {
              res.push(item[0])
            }
          });
          this.tagsData = [...res]
        }, 1000)
      }
    }
  }
}
</script>
<style>
/* @import url(); 引入css类 */
@import url("../../index.css");
</style>