<!-- 列表 -->
<template>
  <div>
    <div class="scheduled-task" v-if="!showAdd">
      <div class="main-top">
        <el-input placeholder="输入搜索关键词" prefix-icon="el-icon-search" v-model="keywords" class="keywordsInput" @change="search"></el-input>
        <el-button type="success" @click="ShowAdd">创建任务</el-button>
      </div>
      <el-tabs v-model="status" @tab-click="handleClick" class="tabs250">
        <el-tab-pane label="进行中" name="0"></el-tab-pane>
        <el-tab-pane label="暂停" name="1"></el-tab-pane>
        <el-tab-pane label="已结束" name="2"></el-tab-pane>
        <div class="numtop">
          <span v-for="i in statusList" :key='i.status' :class="i.status == status?'active':'disactive'">{{i.num}}</span>
        </div>
      </el-tabs>
      <el-card class="box-card" v-for="item in tableData" :key="item.missionId" v-show="tableData.length > 0">
        <div slot="header" class="clearfix">
          <span>{{item.missionName}}</span>
        </div>
        <div>
          <el-table :data="changeMap(item)" style="width: 100%">
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="executeTime" label="执行时间"></el-table-column>
            <el-table-column prop="creator" label="创建人"></el-table-column>
            <el-table-column prop="groupName" label="目标人群"></el-table-column>
            <el-table-column prop="operationPeriod" label="运营周期"></el-table-column>
            <el-table-column label="活动分析" width="120">
              <template slot-scope="scope">
                <i class="iconfont icon-shujufenxi iconCF" @click="showEchat(scope)"></i>
              </template>
            </el-table-column>
            <el-table-column label="编辑任务" width="120">
              <template slot-scope="scope">
                <i class="iconfont icon-yanjing01-mianxing iconCF" @click="eidt(scope)"></i>
              </template>
            </el-table-column>
            <el-table-column label="设置" width="180">
              <template slot-scope="scope">
                <el-button @click="stopClick(scope.row,1)" type="text" size="small" v-show="status === '0'">暂停</el-button>
                <el-button @click="stopClick(scope.row,0)" type="text" size="small" v-show="status === '1'">开始</el-button>
                <el-button @click="stopClick(scope.row,2)" type="text" size="small" v-show="status !== '2'">结束</el-button>
                <el-button @click="stopClick(scope.row,3)" type="text" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-empty :image-size="200" v-show="tableData.length === 0"></el-empty>
    </div>
    <AddScheduled v-if="showAdd" v-on:goBack="Back" :editData="editData"></AddScheduled>
    <el-dialog title :visible.sync="showLineChart">
      <LineChart :mapData="mapData"></LineChart>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import AddScheduled from "./component/AddScheduled";
import LineChart from "./component/LineChart";
export default {
  data () {
    return {
      tableData: [],
      keywords: "",
      showAdd: false,
      status: 0,
      pageno: 1,
      pagesize: 10,
      showLineChart: false,
      mapData: null,
      editData: null,
      statusList: []
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getInitList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
  methods: {
    getInitList () {
      this.$http
        .get(
          `https://cdpengine.cn/scrm-platform/v1/Market/regular/mission/list?name=${this.keywords}&status=${this.status}&pageno=${this.pageno}&pagesize=${this.pagesize}`
        )
        .then(res => {
          this.tableData = res.data.data.list;
          this.statusList = res.data.data.status
        });
    },
    handleClick (tab, event) {
      console.log(tab, event);
      this.status = tab.name;
      this.getInitList();
    },
    showEchat (scope) {
      console.log("dddddd=>", scope);
      this.mapData = scope.row.missionId;
      this.showLineChart = true;
    },
    stopClick (row, status) {
      this.$http
        .get(
          `https://cdpengine.cn/scrm-platform/v1/Market/regular/mission/change/state?missionId=${row.missionId}&status=${status}`
        )
        .then(res => {
          if (res.data.flag) {
            this.$message.success("成功");
            this.getInitList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    ShowAdd () {
      this.editData = null;
      this.showAdd = true;
    },
    Back () {
      this.showAdd = false;
      this.getInitList();
    },
    changeMap (item) {
      let arr = [];
      arr.push(item);
      return arr;
    },
    search () {
      console.log("dddddd=>", this.keywords);
      this.getInitList();
    },
    eidt (scope) {
      this.editData = scope.row;
      this.showAdd = true;
    }
  },
  components: {
    AddScheduled,
    LineChart
  }
};
</script>

<style lang='less'>
@import url("./index.less");
</style>