<template>
  <div class="Sop-List">
    <div v-show="!detailsShow">
      <div class="topBox">
        <el-button type="primary" @click="goDetails(true, null)"
          >创建规则</el-button
        >
        <el-input
          placeholder="输入要搜索的名称"
          v-model="introduction"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getGroupSopList(introduction, pageNo, pageSize, sopType)"
          ></el-button>
        </el-input>
      </div>
      <el-timeline v-show="tableData.length > 0">
        <el-timeline-item
          timestamp="2018/4/12"
          v-for="(item, index) in tableData"
          :key="index"
          :color="index == 0 ? '' : '#178FFF'"
          size="large"
          :icon="index == 0 ? 'iconfont icon-qidian' : ''"
        >
          <el-card :class="item.state == 0 ? '' : 'disable'">
            <div class="box">
              <div class="mainlist">
                <p v-show="item.date > -1">
                  <span class="text">{{
                    item.date == 0 ? "今天" : item.date
                  }}</span>
                  {{ item.date == 0 ? "" : "天后" }} &nbsp;&nbsp;&nbsp;&nbsp;
                  <span class="text">{{ item.time }}</span>
                  发送
                </p>
                <p v-show="item.date < 0">
                  {{ item.timeDesc.substring(0, item.timeDesc.length - 2) }}
                  已发送
                </p>
                <div class="msgnum">
                  <span class="overEllipsis">{{ item.sopName }}</span>
                  <span>触达人数：{{ item.num }}</span>
                </div>
                <p class="sendType">{{ item.sendType }}</p>
                <p class="msgDetail">推送内容:{{ item.msgDetail }}</p>
              </div>
              <div class="btnlist">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="delListItem(item.id)"
                  >删除</el-button
                >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  v-show="item.state == 0"
                  @click="goDetails(true, item)"
                  >编辑</el-button
                >
              </div>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <el-empty :image-size="200" v-show="tableData.length == 0"></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <Details
      v-show="detailsShow"
      @visabledetails="visabledetails"
      :dataDetail="dataDetail"
    ></Details>
  </div>
</template>

<script>
import {
  getPersonalListAndDetails,
  removeSop
} from '@/service/group'
import { Message } from 'element-ui'
import Details from '@/views/Sop/Detail'
export default {
  props: ['sopType'],
  data() {
    return {
      introduction: null,
      pageNo: 1,
      pageSize: 5,
      tableData: [],
      detailsShow: false,
      dataDetail: {},
      total: 0
    }
  },
  computed: {},
  created() {
    this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
  },
  mounted() { },
  watch: {
    sopType(val) {
      this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, val)
    }
  },
  methods: {
    getGroupSopList(introduction, pageNo, pageSize, sopType) {
      getPersonalListAndDetails({ introduction, pageNo, pageSize, sopType }).then(
        res => {
          this.tableData = res.info
          this.total = res.num
          this.tableData &&
            this.tableData.map(item => {
              var t_ = ''
              t_ = item.timeDesc.split('日')
              item.time = t_[1] && t_[1].substring(0, t_[1].length - 2)
              item.date = this.DateDiffer(t_[0])
            })
        }
      )
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getGroupSopList(this.introduction, 1, val, this.sopType)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getGroupSopList(this.introduction, val, this.pageSize, this.sopType)
    },
    DateDiffer(end) {
      // date1结束时间
      let date1 = new Date(end)
      // date2当前时间
      let date2 = new Date()
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
      const diff = date1.getTime() - date2.getTime() // 目标时间减去当前时间
      const diffDate = diff / (24 * 60 * 60 * 1000) // 计算当前时间与结束时间之间相差天数
      return diffDate
    },
    goDetails(vis, item) {
      this.detailsShow = vis
      this.dataDetail = item
    },
    visabledetails(visable) {
      this.detailsShow = visable
      if (!visable) {
        this.dataDetail = {}
        this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
      }
    },
    delListItem(id) {
      removeSop(id).then(res => {
        this.getGroupSopList(this.introduction, this.pageNo, this.pageSize, this.sopType)
        Message.success('删除成功')
      })
    }
  },
  components: {
    Details
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
