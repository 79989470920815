import {
  post,
  get,
  update
} from '../Api/axios'
import {
  URL_CUSTOMER_MANAGE_LIST,
  URL_QUERY_ADD_WAY,
  URL_QUERY_ADD_USER_LIST,
  URL_QUERY_CUSTOMER_DETAILS,
  URL_UPDATE_CUSTOMER_LABEL,
  URL_REFRESH_DATA_LIST,
  URL_CUSTOMER_STAGE_LIST,
  URL_UPDATE_CUSTOMER_STAGE,
  URL_CUSTOMER_LOST_CUSTOMER,
  URL_CUSTOMER_STAGE_FILE_DOWNLOAD,
  URL_CUSTOMER_STAGE_NOTSET,
  URL_CUSTOMER_STAGE_LIST_SPREAD,
  URL_CUSTOMER_GET_GROUPINFO,
  URL_CUSTOMER_GET_ACTDETAIL,
  URL_CUSTOMER_CUSTOMER_REMARK,
  URL_CUSTOMER_CUSTOMER_STAGE_LIST,
  URL_CUSTOMER_CUSTOMER_STAGE_EDITANDADD,
  URL_CUSTOMER_CUSTOMER_STAGE_DELETE
} from '@/Api/api.js'
export function queryCustomerManagePageList({
  pageNo,
  pageSize,
  ...params
}) {
  return post(URL_CUSTOMER_MANAGE_LIST, {
    pageNo,
    pageSize,
    ...params
  })
}

export function queryAddWay() {
  return get(URL_QUERY_ADD_WAY)
}

export function queryAddUserList() {
  return get(URL_QUERY_ADD_USER_LIST)
}

export function queryCustomerDetails(userid) {
  return get(URL_QUERY_CUSTOMER_DETAILS, {
    userid
  })
}

export function updateCustomerLabel(params = {}) {
  return update(URL_UPDATE_CUSTOMER_LABEL, {
    ...params
  })
}

export function refreshDataList(params = {}) {
  return post(URL_REFRESH_DATA_LIST, {
    ...params
  })
}

export function getCustomerStage() {
  return get(URL_CUSTOMER_STAGE_LIST, {

  })
}

export function updateCustomerStage(params = {}) {
  return update(URL_UPDATE_CUSTOMER_STAGE, {
    ...params
  })
}

// 流失客户列表接口
export function getCustomerLostList(params = {}) {
  return get(URL_CUSTOMER_LOST_CUSTOMER, {
    ...params
  })
}

// 下载Excel
export function getCustomerStageFile() {
  return get(URL_CUSTOMER_STAGE_FILE_DOWNLOAD, {

  })
}

// 未设置客户列表
export function getCustomerStageNotset(params = {}) {
  return get(URL_CUSTOMER_STAGE_NOTSET, {
    ...params
  })
}

// 客户阶段列表
export function getCustomerStageListSpread(params = {}) {
  return get(URL_CUSTOMER_STAGE_LIST_SPREAD, {
    ...params
  })
}

// 获取所在群聊组
export function getCustomerGroup(params = {}) {
  return get(URL_CUSTOMER_GET_GROUPINFO, {
    ...params
  })
}

// URL_CUSTOMER_GET_ACTDETAIL
export function getCustomerActdetail(params = {}) {
  return get(URL_CUSTOMER_GET_ACTDETAIL, {
    ...params
  })
}

// URL_CUSTOMER_CUSTOMER_REMARK
export function postCustomerRemark(params = {}) {
  return post(URL_CUSTOMER_CUSTOMER_REMARK, {
    ...params
  })
}

// URL_CUSTOMER_CUSTOMER_STAGE_LIST
export function getCustomerStageList(params = {}) {
  return get(URL_CUSTOMER_CUSTOMER_STAGE_LIST, {
    ...params
  })
}

// URL_CUSTOMER_CUSTOMER_STAGE_EDITANDADD
export function postCustomerStageEditAndAdd(params = {}) {
  return post(URL_CUSTOMER_CUSTOMER_STAGE_EDITANDADD, {
    ...params
  })
}

export function getCustomerStageDel(params = {}) {
  return get(URL_CUSTOMER_CUSTOMER_STAGE_DELETE, {
    ...params
  })
}
