<template>
  <div class="form-create">
    <div class="top">
      <div class="left" @click="goBack">
        <span class="el-icon-arrow-left"></span>
        返回
      </div>
      <div class="right">
        <el-button type="primary" @click="preview">预览</el-button>
        <el-button type="primary" @click="handleSave(0)">存草稿</el-button>
        <el-button type="primary" @click="handleSave(1)">发布</el-button>
      </div>
    </div>
    <div class="content" :class="active == 1 ? 'no-padding-right' : ''">
      <First ref="first" class="first" :form="first" v-show="active === 0" />
      <Second class="second" ref="second" :formData="second" v-show="active === 1" />
      <Third ref="third" class="third" :form="third" v-show="active === 2" />
    </div>
    <div class="bottom">
      <el-steps class="step" simple :active="active" finish-status="success">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
        <el-step title="步骤 3"></el-step>
      </el-steps>
      <div class="btn-box">
        <el-button class="btn" plain type="primary" @click="back" :disabled="active <= 0">上一步</el-button>
        <el-button class="btn2" :disabled="active >= 2" type="primary" @click="next">下一步</el-button>
      </div>
    </div>
    <Preview ref="preview" />
  </div>
</template>

<script>
import First from './First.vue'
import Second from './Second.vue';
import Third from './Third.vue';
import Preview from '../components/preview.vue'

import { formCreate, formSearch, formUpdate } from '@/service/customerForm';
export default {
  components: { First, Second, Third, Preview },
  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      active: 0,
      menu: [{
        list: [{
          name: 'input',
          label: '单行文本',
          icon: 'input'
        },
        {
          name: 'inputNumber',
          label: '数字'
        },
        {
          name: 'select',
          label: '下拉选择'
        },
        {
          name: 'radio',
          label: '单选框组'
        },
        {
          name: 'checkbox',
          label: '多选框组'
        },
        {
          name: 'datePicker',
          label: '日期时间'
        },
        {
          name: 'rate',
          label: '评分'
        },
        {
          name: 'upload',
          label: '图片选择'
        },
        {
          name: 'span',
          label: '描述文字'
        }
        ]
      }
      ],
      formData: null,
      first: {},
      second: [],
      third: {}
    }
  },
  created () {
    if (this.id) {
      this.load()
    }
  },
  methods: {
    async load () {
      const data = await formSearch({ formId: this.id })
      const { formName, untildate, formIntroduction, h5FormName, formItems = [], skipForms = {} } = data
      this.first = { formName, untilDate: untildate, formIntroduction, h5FormName }
      this.second = formItems
      this.third = skipForms
    },
    next () {
      if (this.active === 0) {
        const formData = this.$refs.first.submitForm()
        if (formData) {
          // 通过校验
          this.active++
          this.formData = formData
        } else {
          this.$message.warning('请正确填写表单')
        }
      } else {
        this.active++
      }
    },
    back () {
      this.active--
    },
    goBack () {
      this.$parent.changeActive(1)
    },
    preview () {
      const form = this.$refs.second.formList
      this.$refs.preview.init({ show: true, formList: form })
    },
    async handleSave (status) {
      const formData = this.$refs.first.submitForm()
      const form = this.$refs.second.formList
      const skipForms = this.$refs.third.skipForms
      if (skipForms.activeTime?.length) {
        skipForms.activeTime = skipForms.activeTime + ''
      }
      if (form.length && formData) {
        let flag = true
        for (let i = 0; i < form.length; i++) {
          const e = form[i];
          e.id = i + 1
          if ((['textarea'].includes(e.type) && e.data[0] === '')) {
            this.$message.warning('请将表单中描述内容填写完整!')
            flag = false
            break
          }
          if ((['upload'].includes(e.type) && e.url === '')) {
            this.$message.warning('请将表单中图片选择填写完整!')
            flag = false
            break
          }
        }
        if (flag) {
          const reqObj = {
            status: status,
            ...formData,
            formItems: this.$refs.second.formList,
            skipForms: skipForms || {},
            formId: this.id || ''
          }
          const methods = this.id ? formUpdate : formCreate
          await methods(reqObj)
          this.$message.success('操作成功!')
          this.goBack()
        }
      } else {
        this.$message.warning('请先编辑表单!')
      }
    }
  }
};
</script>

<style lang='less' scoped>
.second {
  width: 100%;
}
</style>
