<template>
  <div class="customer-lost-main">
    <div class="user-list">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="全部客户" width="210">
          <template slot-scope="scope">
            <div class="uesr-infos">
              <img :src="scope.row.picUrl" alt="" />

              <div class="text">
                <div class="titles">
                  <p>{{ scope.row.name }}</p>
                  <span
                    :style="{
                      color:
                        scope.row.addType === '微信'
                          ? '#67C23A'
                          : '#E6A23C',
                    }"
                    >@{{ scope.row.addType }}</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="所属客户" width="180">
        </el-table-column>
        <el-table-column prop="tag" label="标签">
          <template slot-scope="scope">
            <el-tag
              v-for="v in scope.row.tag.split(',')"
              :key="v"
              size="mini"
              >{{ v }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="性别"> </el-table-column>
        <el-table-column prop="addTime" label="添加时间"> </el-table-column>
        <el-table-column prop="delTime" label="删除时间" align="center">
        </el-table-column>
        <el-table-column prop="addWay" label="添加渠道"> </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
      :hide-on-single-page="true"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getCustomerLostList } from '@/service/customerManage'
export default {
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    }
  },
  computed: {

  },
  created() {
    this.runList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    runList(params) {
      getCustomerLostList({
        pageno: this.pageNo, pagesize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    }
  }
}
</script>

<style  lang="less">
@import url("./index.less");
</style>
