<template>
  <div class="sms-statistics">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="短信统计" name="1">
        <el-date-picker
          v-model="dateMsg"
          value-format="yyyy-MM-dd"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="this.dataMsgChange"
        >
        </el-date-picker>
        <ul>
          <li>
            <a>{{ msgList.RequestSuccessCount }}</a>
            <p>短信提交成功数量</p>
          </li>
          <li>
            <a>{{ msgList.FeeCount }}</a>
            <p>短信计费数量</p>
          </li>
          <li>
            <a>{{ msgList.RequestCount }}</a>
            <p>短信提交数量</p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="回执分析" name="2">
        <el-date-picker
          v-model="dateRe"
          value-format="yyyy-MM-dd"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="this.dataReChange"
        >
        </el-date-picker>
        <ul>
          <li>
            <a>{{ reList.CallbackCount }}</a>
            <p>短信回执量</p>
          </li>
          <li>
            <a>{{ reList.RequestSuccessCount }}</a>
            <p>短信提交成功数量</p>
          </li>
          <li>
            <a>{{ reList.CallbackFailCount }}</a>
            <p>短信回执失败量</p>
          </li>
          <li>
            <a>{{ reList.CallbackSuccessCount }}</a>
            <p>短信回执成功量</p>
          </li>
          <li>
            <a>{{ reList.InternalErrorCount }}</a>
            <p>运营商内部错误量</p>
          </li>
          <li>
            <a>{{ reList.InvalidNumberCount }}</a>
            <p>号码无效或空号</p>
          </li>
          <li>
            <a>{{ reList.ShutdownErrorCount }}</a>
            <p>停机、关机等错误</p>
          </li>
          <li>
            <a>{{ reList.BlackListCount }}</a>
            <p>号码拉入黑名单</p>
          </li>
          <li>
            <a>{{ reList.FrequencyLimitCount }}</a>
            <p>运营商频率限制</p>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getMessageSendStatistic, getMessagePullReplyStatus } from '@/service/sms'
import { Message } from 'element-ui'
export default {
  components: {
  },
  data() {
    return {
      activeName: '1',
      reBackList: [],
      dateMsg: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().subtract(1, 'day').format('YYYY-MM-DD')],
      dateRe: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().subtract(1, 'day').format('YYYY-MM-DD')],
      msgList: {
        RequestSuccessCount: 0,
        FeeCount: 0,
        RequestCount: 0
      },
      reList: {
        RequestSuccessCount: 0,
        CallbackFailCount: 0,
        CallbackSuccessCount: 0,
        CallbackCount: 0,
        InternalErrorCount: 0,
        BlackListCount: 0,
        ShutdownErrorCount: 0,
        FrequencyLimitCount: 0,
        InvalidNumberCount: 0
      }
    }
  },
  computed: {

  },
  created() {
    this.getMessageSendStatistic()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getMessageSendStatistic() {
      if (!this.dateMsg) {
        Message.info('请选择时间')
        return
      }
      var data = { begintime: this.$moment(this.dateMsg[0]).format('YYYYMMDDHH'), endtime: this.$moment(this.dateMsg[1]).format('YYYYMMDDHH') }
      getMessageSendStatistic(data).then(res => {
        if (res) {
          this.msgList = res
        }
      })
    },
    getMessagePullReplyStatus() {
      if (!this.dateRe) {
        Message.info('请选择时间')
        return
      }
      var data = { begintime: this.$moment(this.dateRe[0]).format('YYYYMMDDHH'), endtime: this.$moment(this.dateRe[1]).format('YYYYMMDDHH') }
      getMessagePullReplyStatus(data).then(res => {
        if (res) {
          this.reList = res
        }
      })
    },
    dataMsgChange() {
      this.getMessageSendStatistic()
    },
    dataReChange() {
      this.getMessagePullReplyStatus()
    },
    handleClick(tab, event) {
      if (tab.index === '0') {
        this.getMessageSendStatistic()
      } else {
        this.getMessagePullReplyStatus()
      }
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
