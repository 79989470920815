<template >
  <div class="add-keyword-reply">
    <el-descriptions title="回复规则" direction="vertical" :column="1" border>
      <el-descriptions-item label="关键词"
        ><el-input placeholder="请输入关键词" v-model="keywords" clearable>
        </el-input
      ></el-descriptions-item>
      <el-descriptions-item label="回复内容">
        <el-tabs @tab-click="handleClick" v-model="type">
          <el-tab-pane name="text">
            <span slot="label" class="tabAdd">
              <i class="el-icon-edit"></i> 文字/超链接
            </span>
            <el-input
              type="textarea"
              :rows="8"
              placeholder="请填写内容"
              v-model="replyInfo"
            >
            </el-input>
          </el-tab-pane>
          <el-tab-pane name="news" class="newstabpane">
            <span slot="label" class="tabAdd">
              <i class="el-icon-tickets"></i>回复图文消息
            </span>
            <el-popover
              placement="right-start"
              width="400"
              trigger="manual"
              v-model="visible"
            >
              <el-form
                :label-position="labelPosition"
                label-width="80px"
                :model="form"
                class="replyFrom"
              >
                <el-form-item label="封面介绍">
                  <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="封面图片">
                  <el-upload
                    action="#"
                    class="upload-box"
                    ref="upload"
                    :auto-upload="true"
                    :limit="1"
                    :on-exceed="handleExceed"
                    accept=".jpg, .jpeg, .png, .gif"
                    :http-request="uploadPic"
                    :on-remove="handleRemove"
                    :before-upload="beforeAvatarUpload"
                  >
                    <el-button type="primary" class="uploadBtn">
                      <i class="iconfont icon-yunshangchuan"></i>
                      上传图片
                    </el-button>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  label="摘要（选填，该摘要只在发送图文信息为单条时显示）"
                >
                  <el-input
                    type="textarea"
                    :rows="5"
                    v-model="form.replyInfo"
                  ></el-input>
                </el-form-item>
                <el-form-item label="设置链接">
                  <el-input v-model="form.url">
                    <template slot="append">设置链接</template>
                  </el-input>
                </el-form-item>
              </el-form>
              <div class="news" slot="reference">
                <img :src="src" />
                <div class="newsTitle">
                  <span>{{ form.title }}</span>
                </div>
              </div>
            </el-popover>
          </el-tab-pane>
          <el-tab-pane name="image">
            <span slot="label" class="tabAdd">
              <i class="el-icon-picture"></i>回复图片
            </span>
            <el-upload
              action="#"
              list-type="picture-card"
              ref="uploadPic"
              :limit="1"
              :on-exceed="handleExceed"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemovePic"
              :http-request="uploadRePic"
              :file-list="fileList"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-tab-pane>
        </el-tabs>
        <el-button
          class="topBtn"
          type="primary"
          @click="save"
          :disabled="uploading"
          >保存</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { postForeverMaterial, postAddAutoReply, postEditAutoReply } from '@/service/reply'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      keywords: '',
      replyInfo: '',
      type: 'text',
      src: require('@/assets/error.jpg'),
      visible: false,
      fileList: [],
      form: {
        title: '标题',
        replyInfo: '',
        url: '',
        picUrl: '',
        typeInfo: 'keywords',
        type: 'news'
      },
      labelPosition: 'top',
      upload: {
        media: null,
        type: 'image'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: '',
      meidaId: '',
      uploading: false,
      id: null
    }
  },
  computed: {

  },
  created() {
    // this.getPageList()
  },
  mounted() {
    console.log(this.$route.query.row.url)
    if (this.$route.query.row) {
      this.type = this.$route.query.row.type
      this.keywords = this.$route.query.row.keywords
      this.replyInfo = this.$route.query.row.reply_info
      this.id = this.$route.query.row.id
      if (this.type === 'news') {
        this.visible = true
        this.form.title = this.$route.query.row.title
        this.form.replyInfo = this.$route.query.row.reply_info
        this.form.url = this.$route.query.row.url
        this.form.picUrl = this.$route.query.row.pic_url
        this.src = this.$route.query.row.pic_url
      }
      if (this.type === 'image') {
        this.imageUrl = this.$route.query.row.url
        this.meidaId = this.$route.query.media_id
        this.dialogImageUrl = this.$route.query.row.url
        this.fileList.push({
          name: this.$route.query.row.url,
          url: this.$route.query.row.url
        })
      }
    }
  },
  watch: {

  },
  methods: {
    handleClick(tab, event) {
      this.type = tab.name
      this.initData()
      if (tab.name === 'news') {
        this.visible = true
      } else {
        this.visible = false
      }
    },
    initData() {
      this.$refs.upload.clearFiles()
      this.$refs.uploadPic.clearFiles()
      this.imageUrl = ''
      this.meidaId = ''
      this.src = require('@/assets/error.jpg')
      this.replyInfo = ''
      this.form = {
        title: '标题',
        replyInfo: '',
        url: '',
        picUrl: '',
        typeInfo: 'keywords',
        type: 'news'
      }
    },
    save() {
      let data = null
      if (this.keywords === '') {
        Message.warning('请输入关键词')
        return false
      }
      if (this.type === 'text') {
        if (this.replyInfo === '') {
          Message.warning('请填写内容')
          return false
        } else {
          data = { type: this.type, typeInfo: 'keywords', replyInfo: this.replyInfo, keywords: this.keywords }
        }
      }
      if (this.type === 'news') {
        if (this.form.title === '' || this.form.url === '' || this.form.picUrl === '') {
          Message.warning('请检查填写项，未填写完整')
          return false
        } else {
          var reg = /^(((https?|ftp|news):\/\/|\w+(\.\w+)+)(:\w+)?).*/
          if (!reg.test(this.form.url)) {
            Message.warning('请检查链接格式是否正确')
            return false
          } else {
            data = this.form
            data.keywords = this.keywords
          }
        }
      }
      if (this.type === 'image') {
        if (this.imageUrl === '' || this.meidaId === '') {
          Message.warning('请上传图片')
          return false
        } else {
          data = { type: this.type, typeInfo: 'keywords', url: this.imageUrl, mediaId: this.meidaId, keywords: this.keywords }
        }
      }

      if (this.id) {
        data.id = this.id
        postEditAutoReply({ ...data }).then(res => {
          Message.success('编辑成功')
          this.$router.push('/Wechat/AutomaticReply/keyword')
        })
      } else {
        postAddAutoReply({ ...data }).then(res => {
          Message.success('添加成功')
          this.$router.push('/Wechat/AutomaticReply/keyword')
        })
      }
    },
    handleRemovePic(file, fileList) {
      this.$refs.uploadPic.clearFiles()
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    uploadRePic(val) {
      this.upload.media = val.file
      this.uploading = true
      postForeverMaterial({ ...this.upload }).then((res) => {
        this.imageUrl = res.url
        this.meidaId = res.mediaId
        this.uploading = false
      })
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles()
      this.src = require('@/assets/error.jpg')
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        Message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    async uploadPic(val) {
      this.uploading = true
      this.src = await this.getBase64(val.file)
      this.upload.media = val.file
      postForeverMaterial({ ...this.upload }).then((res) => {
        this.form.picUrl = res.url
        this.uploading = false
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }
  },
  components: {

  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
