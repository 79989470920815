<template>
  <div>
    <div class="ef-node-form">
      <div class="ef-node-form-header" v-show="type === 'node'">节点编辑</div>
      <div class="ef-node-form-header" v-show="type === 'line'">删除连接线</div>
      <div class="ef-node-form-body">
        <el-form :model="node" ref="dataForm" label-width="80px" v-show="type === 'node'">
          <el-form-item label="节点ID">
            {{ node.id }}
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="node.name"></el-input>
          </el-form-item>
          <!-- 短信节点条件 -->
          <span v-show="this.node.type === 'message'">
            <el-form-item label="投放渠道"> 短信 </el-form-item>
            <el-form-item label="消息标题">
              <el-input v-model="node.messageTitle"></el-input>
            </el-form-item>
            <el-form-item label="消息内容">
              <el-input v-model="node.messageInfo"></el-input>
            </el-form-item>
          </span>
          <!-- 行为节点条件 -->
          <span v-show="this.node.type === 'havoir'">
            <el-form-item label="进入时间">
              <el-date-picker v-model="node.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="dateStyle">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="触发行为">
              <el-select v-model="node.actInfo" filterable placeholder="请选择">
                <el-option v-for="item in optionsHavoir" :key="item.event_name" :label="item.event_name" :value="item.event_code">
                </el-option>
              </el-select>
            </el-form-item>
          </span>
          <!-- 等待节点条件 -->
          <el-form-item label="等待" v-show="this.node.type === 'wait'">
            <span class="waitBox">
              <el-input v-model="node.hours"></el-input>
              <span class="spanlable">小时</span>
              <el-input v-model="node.minutes"></el-input>
              <span class="spanlable">分钟</span>
            </span>
          </el-form-item>
          <!-- 黑名单节点条件 -->
          <el-form-item label="过滤人群" v-show="this.node.type === 'blacklist'">
            <el-select v-model="node.groupInfo" filterable placeholder="请选择">
              <el-option v-for="item in optionsPepole" :key="item.groupId" :label="item.groupName" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 属性节点满足 start -->
          <el-form-item label="属性条件" v-show="this.node.type === 'attribute'">
            <div class="group-analysis">
              <el-row style="margin-bottom: 10px">
                <!-- <el-card shadow="never"> -->
                <el-row type="flex" justify="space-between" class="user-prop-title">
                  <div class="add_rows" @click="handleAddPropsRow('propertyRules', 'ruleDetails')">
                    <i class="el-icon-plus add-all" />
                    <span>添加</span>
                  </div>
                </el-row>
                <el-row :gutter="20" type="flex">
                  <el-col :span="1" style="width: 40px" v-if="propertyRules.ruleDetails.length > 1">
                    <div class="relationship">
                      <div class="relationship-bar"></div>
                      <div class="relationship-text" @click="handleTurnOn('propertyRules')">
                        {{ propertyRules.relationship | relationship }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="propertyRules.ruleDetails.length > 1 ? 23 : 24">
                    <el-row style="margin: 10px 0" :gutter="20" v-for="(item, index) in propertyRules.ruleDetails" :key="index">
                      <el-col :span="24">
                        <el-select v-model="item.key" placeholder="请选择" @change="(e) => handelSelectKey(e, item)" size="small">
                          <el-row class="option-search">
                            <el-input v-model="levelTwoFilter" @change="handleChangeFilter" prefix-icon="el-icon-search" clearable size="small" />
                          </el-row>
                          <div v-for="(e, i) in levelOneOption" :key="i">
                            <el-row class="leveltwo-option-header">{{
                              `${e.levelOne}(${e.propertyList.length})`
                            }}</el-row>
                            <el-option v-for="item in e.propertyList" :key="item.leveltTwoCode" :label="item.leveltTwo" :value="item.leveltTwoCode"></el-option>
                          </div>
                        </el-select>
                      </el-col>
                      <el-col :span="24">
                        <el-select v-model="item.condition" placeholder="请选择" size="small" @change="clearGroupNum">
                          <el-option v-for="f in conditionOption" :key="f.value" :label="f.text" :value="f.value"></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="24">
                        <el-select v-if="item.enumValue.length" v-model="item.value" placeholder="请选择" size="small" @change="clearGroupNum">
                          <el-option v-for="f in item.enumValue" :key="f" :label="f" :value="f"></el-option>
                        </el-select>
                        <el-input v-else v-model="item.value" size="small" @input="clearGroupNum" />
                      </el-col>
                      <el-col :span="24" class="closeBtn">
                        <span @click="
                            handleDeletePropsRow(
                              index,
                              'propertyRules',
                              'ruleDetails'
                            )
                          " style="cursor: pointer">
                          <i class="el-tag__close el-icon-close"></i>
                        </span>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <!-- </el-card> -->
              </el-row>
            </div>
          </el-form-item>
          <!-- 用户属性满足 end -->
        </el-form>
        <el-button v-show="type === 'node'" type="primary" icon="el-icon-check" style="margin-left: 90px" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { cloneDeep } from 'lodash'
import './jsplumb'
export default {
  filters: {
    relationship (val) {
      if (val) {
        return val == "and" ? "且" : "或";
      }
    }
  },
  data () {
    return {
      visible: true,
      // node 或 line
      type: 'node',
      node: {},
      line: {},
      data: {},
      optionsPepole: [],
      optionsHavoir: [],
      propertyRules: {
        relationship: "and",
        ruleDetails: []
      },
      levelTwoFilter: "",
      levelOneOption: [],
      conditionOption: [
        {
          text: "等于",
          value: "="
        },
        {
          text: "不等于",
          value: "!="
        },
        {
          text: "大于等于",
          value: ">="
        },
        {
          text: "小于等于",
          value: "<="
        },
        {
          text: "包含",
          value: "like"
        },
        {
          text: "不包含",
          value: "not like"
        }
      ],
    }
  },
  mounted () {
    this.getPeopleList()
    this.getHavoirList()
    this.getFilterList()
  },
  methods: {
    /**
     * 表单修改，这里可以根据传入的ID进行业务信息获取
     * @param data
     * @param id
     */
    nodeInit (data, id) {
      console.log("🚀 ~ file: node_form.vue ~ line 292 ~ nodeInit ~ data, id", data, id)
      this.type = 'node'
      this.data = data
      data.nodeList.filter((node) => {
        if (node.id === id) {
          this.node = cloneDeep(node)
          if (node.type == 'attribute') {
            this.propertyRules = node.propertyRules ? node.propertyRules : {
              relationship: "and",
              ruleDetails: []
            },
              this.propertyRules.ruleDetails.forEach(e => {
                this.handelSelectKey(e.key, e, "edit");
              });
          }
        }
      })
    },

    lineInit (line) {
      this.type = 'line'
      this.line = line
    },
    // 修改连线
    saveLine () {
      this.$emit('setLineLabel', this.line.from, this.line.to, this.line.label)
    },
    save () {
      this.data.nodeList.filter((node) => {
        if (node.id === this.node.id) {
          node.name = this.node.name
          node.left = this.node.left
          node.top = this.node.top
          node.ico = this.node.ico
          node.state = this.node.state
          node.date = this.node.date
          node.actInfo = this.node.actInfo
          node.propertyRules = this.propertyRules
          node.messageTitle = this.node.messageTitle
          node.messageInfo = this.node.messageInfo
          node.hours = this.node.hours
          node.minutes = this.node.minutes
          node.groupInfo = this.node.groupInfo
          console.log('propertyRules', this.propertyRules)
          this.$emit('repaintEverything')
        }
      })
      window.localStorage.setItem('nodeList', JSON.stringify(this.data.nodeList))
      console.log('dddddd=>', this.data.nodeList, this.node)
    },
    // 删除激活的元素
    deleteElement () {
      if (this.activeElement.type === 'node') {
        this.deleteNode(this.activeElement.nodeId)
      } else if (this.activeElement.type === 'line') {
        this.$confirm('确定删除所点击的线吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var conn = this.jsPlumb.getConnections({
            source: this.activeElement.sourceId,
            target: this.activeElement.targetId
          })[0]
          this.jsPlumb.deleteConnection(conn)
        }).catch(() => {
        })
      }
    },
    // 获取人群列表
    getPeopleList () {
      this.$http
        .get(
          `https://cdpengine.cn/scrm-platform/v1/Market/regular/usergroup/list`
        )
        .then(res => {
          this.optionsPepole = res.data.data;
        });
    },
    // 获取行为列表
    async getHavoirList () {
      const { data } = await this.$http.post(
        "https://cdpengine.cn/scrm-platform/v1/DataAnalysis/eventList"
      );
      if (data.code == 0) {
        this.optionsHavoir = data.data;
      }
    },
    async getFilterList (callback) {
      const { data } = await this.$http.get(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/property/list"
      );
      if (data.flag) {
        this.levelOneOption = data.data;
        this.levelOne = [...this.levelOneOption];
        if (callback) callback();
      }
    },
    // 属性节点fangfa
    handleAddPropsRow (prop, prop2) {
      let obj = {};
      if (prop == "actRules") {
        obj = {
          date: [this.format(), this.format()],
          startDate: this.format(),
          endDate: this.format(),
          actType: "=",
          eventCode: this.eventListOption[0].event_code,
          num: "0"
        };
      } else {
        obj = {
          value: this.levelOneOption[0].propertyList[0]["enumValue"][0],
          key: this.levelOneOption[0].propertyList[0].leveltTwoCode,
          condition: "=",
          enumValue: this.levelOneOption[0].propertyList[0]["enumValue"]
        };
      }
      this[prop][prop2].push(obj);
      if (this[prop][prop2].length > 1 && !this[prop].relationship) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
    clearGroupNum () {
      // this.form.groupNum = "";
      this.calculateBtn = true;
      this.calculateBtn = false;
    },
    handleTurnOn (prop) {
      let data = this[prop];
      if (data.relationship == "and") {
        data.relationship = "or";
      } else {
        data.relationship = "and";
      }
    },
    handleChangeFilter () {
      if (!this.levelTwoFilter) return this.levelOne;
      let levelOne = [];
      for (let i = 0; i < this.levelOne.length; i++) {
        const element = this.levelOne[i];
        if (i != 0 && !levelOne[i - 1].propertyList.length) {
          levelOne.splice(i - 1, 1, null);
        }
        levelOne.push({
          levelOne: element.levelOne,
          propertyList: []
        });
        for (let j = 0; j < element.propertyList.length; j++) {
          const item = element.propertyList[j];
          if (item.leveltTwo.includes(this.levelTwoFilter)) {
            levelOne[i]["propertyList"].push(item);
          }
        }
      }
      if (!levelOne[levelOne.length - 1].propertyList.length) {
        levelOne.splice(levelOne.length - 1, 1);
      }
      if (levelOne.length == 0) {
        levelOne = [...this.levelOne];
      }
      this.levelOneOption = [...levelOne];
    },
    handelSelectKey (val, data, type = "add") {
      if (type == "add") this.clearGroupNum();
      let three = [];
      for (let i = 0; i < this.levelOneOption.length; i++) {
        const element = this.levelOneOption[i];
        for (let j = 0; j < element.propertyList.length; j++) {
          const item = element.propertyList[j];
          if (item.leveltTwoCode == val) {
            three = item.enumValue;
            break;
          }
        }
      }
      if (three.length) {
        if (type == "add") {
          data.value = three[0];
        }
        data.enumValue = three;
      } else {
        if (type == "add") {
          data.value = "";
        }
        data.enumValue = [];
      }
    },
    handleDeletePropsRow (i, prop, prop2) {
      this[prop][prop2].splice(i, 1);
      if (this[prop][prop2].length <= 1) {
        this[prop].relationship = "and";
      }
      this.clearGroupNum();
    },
  }
}
</script>

<style lang="less" scoped>
.el-node-form-tag {
  position: absolute;
  top: 50%;
  margin-left: -15px;
  height: 40px;
  width: 15px;
  background-color: #fbfbfb;
  border: 1px solid rgb(220, 227, 232);
  border-right: none;
  z-index: 0;
}
.group-analysis {
  text-align: left;
  padding: 12px 0;
  .user-prop-title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .add-all {
    background-color: #00bf8a;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    margin-right: 3px;
    cursor: pointer;
  }
  .delete-all {
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #000;
  }
  .add_rows {
    font-size: 14px;
    cursor: pointer;
  }
  .date_picker {
    &.el-input__inner {
      width: auto;
    }
  }

  .index {
    width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.input_num {
  margin-right: 5px;
}
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left !important;
}
.leveltwo-option-header {
  font-size: 12px !important;
  padding: 5px 0 !important;
  background-color: #f5f7fa;
  color: #888;
}
.relationship {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #38d474;
  .relationship-bar {
    height: 100%;
    width: 2px;
    background-color: rgba(53, 219, 171, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
  .relationship-text {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    background-color: #d6efe8;
    cursor: pointer;
  }
}
.option-search {
  .el-input__inner {
    border: 0;
    border-bottom: 1px solid #888;
  }
}
.closeBtn {
  position: absolute;
  left: 90%;
  top: 35%;
  bottom: 0;
}
.el-select-dropdown__list {
  padding: 10px;
}

.el-form-item {
  margin-bottom: 11px;
}
.dateStyle {
  width: 200px !important;
}
</style>
