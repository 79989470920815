<template>
  <div class="third-box">
    <div class="third">
      <el-input class="margin mb" type="text" v-model="skipForms.titleName" placeholder="请输入活动名称"></el-input>
      <el-date-picker class="margin" v-model="skipForms.activeTime" placeholder="请选择活动时间" type="datetime">
      </el-date-picker>
      <div class="line"></div>
      <Upload @change="handleFile" class="margin" hasImg :imgUrl="skipForms.wechatPic" introduction="自定义表单-二维码添加客服" />
      <el-input class="margin" type="text" v-model="skipForms.comment" placeholder="请输入文字"></el-input>
    </div>
  </div>
</template>

<script>
import Upload from '../components/Upload.vue'
export default {
  components: { Upload },
  props: {
    form: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      skipForms: {
      }
    }
  },
  watch: {
    form: {
      handler (val) {
        val.titleName && this.$set(this.skipForms, 'titleName', val.titleName)
        val.comment && this.$set(this.skipForms, 'comment', val.comment)
        val.activeTime && this.$set(this.skipForms, 'activeTime', val.activeTime)
        val.wechatPic && this.$set(this.skipForms, 'wechatPic', val.wechatPic)
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    handleFile (url) {
      this.skipForms.wechatPic = url
    }
  }
}
</script>

<style lang="less" scoped>
.line {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin: 30px 0 20px;
}
.margin {
  margin: 15px 0;
}
.mb {
  margin-bottom: 30px;
}
</style>
