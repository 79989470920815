<template>
  <div class="Sop-tabs">
    <el-tabs type="border-card" v-model="sopType" @tab-click="handleClick">
      <el-tab-pane name="目标客户">
        <span slot="label">
          <img src="@/assets/TargetCustomersSop.png" alt
        /></span>
        <div v-if="sopType === '目标客户'">
          <TargetList :sopType="sopType"></TargetList>
        </div>
      </el-tab-pane>
      <el-tab-pane name="新客户"
        ><span slot="label">
          <img src="@/assets/NewCustomersSop.png" alt
        /></span>
        <div v-if="sopType === '新客户'">
          <NewList :sopType="sopType"></NewList>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TargetList from '@/views/Sop/List'
import NewList from '@/views/Sop/NewList'
export default {
  props: {},
  data() {
    return {
      sopType: '目标客户'
    }
  },
  computed: {},
  created() {

  },
  mounted() { },
  watch: {},
  methods: {
    handleClick(tab, event) {
      this.sopType = tab.name
      console.log(tab, event);
    }
  },
  components: {
    TargetList,
    NewList
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
