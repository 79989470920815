<!-- 列表 -->
<template>
  <div class="add-AddBehavior">
    <flow-panel :dataEdit="data"></flow-panel>
    <div class="behaviorFrom">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm" size="mini">
        <el-form-item label="运营策略名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="简介" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <!-- <el-form-item> -->

        <!-- </el-form-item> -->
      </el-form>
      <el-button type="success" @click="submitForm('ruleForm')" class="addBtn">创建</el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import FlowPanel from '@/components/ef/panel'
export default {
  /* eslint-disable */
  props: ["editData"],
  data () {
    return {
      ruleForm: {
        name: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入运营策略名称', trigger: 'blur' },
        ],
      },
      missionId: null,
      data: {}
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.init();
  },
  computed: {

  },
  methods: {
    init () {
      if (this.editData) {
        console.log("🚀 ~ file: index.vue ~ line 66 ~ init ~ this.editData", this.editData)
        this.missionId = this.editData.missionId
        this.data.name = this.editData.missionName
        this.data.lineList = this.editData.sequence
        window.localStorage.setItem('lineList', JSON.stringify(this.data.lineList))
        this.data.nodeList = []
        this.editData.propertyJudge && this.data.nodeList.push(this.editData.propertyJudge)
        this.editData.putChannel && this.data.nodeList.push(this.editData.putChannel)
        this.editData.blacklistFilter && this.data.nodeList.push(this.editData.blacklistFilter)
        this.editData.actJudge && this.data.nodeList.push(this.editData.actJudge)
        this.editData.waitFilter && this.data.nodeList.push(this.editData.waitFilter)
        this.data.nodeList && this.data.nodeList.map(item => {
          item.id = item.nodeId
          item.name = item.nodeName
          if (item.type == 'blacklist') {
            item.groupInfo = { groupId: item.groupId, groupName: item.groupName }
          }
          if (item.type == 'havoir') {
            item.date = []
            item.date.push(item.startDate)
            item.date.push(item.endDate)
          }
          if (item.type == 'wait') {
            let time = this.minTime(item.minutes).split(':')
            item.hours = time[0]
            item.minutes = time[1]
          }
        })
        window.localStorage.setItem('nodeList', JSON.stringify(this.data.nodeList))
        this.ruleForm = {
          name: this.editData.missionName,
          desc: this.editData.introduction
        }
      } else {
        this.missionId = null
        this.data = {}
        this.ruleForm = {
          name: '',
          desc: ''
        }
      }
    },
    // 分钟转小时
    minTime (min) {
      let minTime = "";
      let h = Math.floor(min / 60);
      min -= h * 60;
      if (min == 0) {
        minTime = h ? "0" + h + ":00" : "";
      } else {
        if (min < 10) {
          min = "0" + min;
        }
        if (h < 10) {
          h = "0" + h;
        }
        minTime = (h ? h + ":" : "00:") + (min ? min + "" : "");
      }
      return minTime;
    },
    submitForm (formName) {
      var nodeList = JSON.parse(window.localStorage.getItem('nodeList'))
      var lineList = JSON.parse(window.localStorage.getItem('lineList'))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            missionName: this.ruleForm.name,
            introduction: this.ruleForm.desc,
            status: 0
          }
          if (this.missionId) {
            data.missionId = this.missionId
          }
          var nodePass = false
          var linePass = false
          if (nodeList && nodeList.length > 0) {
            nodePass = true
            nodeList.map(item => {
              switch (item.type) {
                case 'havoir':
                  var actJudge = {
                    nodeId: item.id,
                    nodeName: item.name,
                    startDate: item.date && moment(item.date[0]).format("YYYY-MM-DD"),
                    endDate: item.date && moment(item.date[1]).format("YYYY-MM-DD"),
                    actInfo: item.actInfo,
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.actJudge = actJudge
                  break;
                case 'attribute':
                  var propertyJudge = {
                    nodeId: item.id,
                    nodeName: item.name,
                    propertyRules: item.propertyRules,
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.propertyJudge = propertyJudge
                  break;
                case 'blacklist':
                  var blacklistFilter = {
                    nodeId: item.id,
                    nodeName: item.name,
                    groupId: item.groupInfo.groupId,
                    groupName: item.groupInfo.groupName,
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.blacklistFilter = blacklistFilter
                  break;
                case 'wait':
                  var waitFilter = {
                    nodeId: item.id,
                    nodeName: item.name,
                    minutes: parseInt(item.hours) * 60 + parseInt(item.minutes),
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.waitFilter = waitFilter
                  break;
                case 'message':
                  var putChannel = {
                    nodeId: item.id,
                    nodeName: item.name,
                    channel: "短信",
                    messageTitle: item.messageInfo,
                    messageInfo: item.messageTitle,
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.putChannel = putChannel
                  break;
                case 'outbound':
                  var putChannel = {
                    nodeId: item.id,
                    nodeName: item.name,
                    channel: "外呼",
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.putChannel = putChannel
                  break;
                case 'push':
                  var putChannel = {
                    nodeId: item.id,
                    nodeName: item.name,
                    channel: "push",
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.putChannel = putChannel
                  break;
                case 'position':
                  var putChannel = {
                    nodeId: item.id,
                    nodeName: item.name,
                    channel: "运营位",
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.putChannel = putChannel
                  break;
                case 'popup':
                  var putChannel = {
                    nodeId: item.id,
                    nodeName: item.name,
                    channel: "弹窗",
                    type: item.type,
                    left: item.left,
                    top: item.top,
                  }
                  data.putChannel = putChannel
                  break;
                default:
                  break;
              }
            })
          } else {
            nodePass = false
            this.$message.warning('请构建流程图！')
          }

          if (lineList && lineList.length > 0) {
            linePass = true
            data.sequenceInfos = lineList
          } else {
            linePass = false
            this.$message.warning('请连接各个步骤！')
          }

          if (nodePass && linePass) {
            this.$http
              .post(
                "https://cdpengine.cn/scrm-platform/v1/Market/act/mission/create",
                data
              )
              .then(res => {
                if (res.data.flag) {
                  this.$message.success(res.data.message);
                  this.goBack()
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          } else {

          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    goBack () {
      this.$emit("goBack", false);
    }
  },
  watch: {
    editData () {
      this.init();
    }
  },
  components: {
    FlowPanel
  }
};
</script>

<style lang="less" >
@import url("./index.less");
</style>