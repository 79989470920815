<template>
  <div class="MiniAccessTable">
    <div class="selectRow">
      <div>
        <el-select
          v-model="selectValue"
          placeholder="请选择"
          :clearable="false"
          @change="typeChange"
        >
          <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="pd10">
        <el-select
          v-model="dateType"
          placeholder="请选择"
          :clearable="false"
          @change="dateTypeChange"
        >
          <el-option
            v-for="item in selectdateTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="false"
          @change="dateChange"
          :disabled="dateType === 1"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
    </div>
    <div class="tableChart">
      <el-table :data="tableData" style="width: 100%" size="mini">
        <el-table-column prop="date" label="时间"></el-table-column>
        <el-table-column prop="key0" label="活跃用户数">
          <template slot-scope="scope">
            <div v-if="scope.row['key' + item]">
              {{ scope.row["key" + item][0] }}
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in tableColumn"
          :key="item"
          :prop="'key' + item"
          :label="item + '天后'"
        >
          <template slot-scope="scope">
            <div
              class="chartTd"
              v-if="scope.row['key' + item]"
              :style="{ background: scope.row['key' + item][1] }"
            >
              {{ scope.row["key" + item][0] }}
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getDailyRetaininfo } from "@/service/minipro";
import moment from "moment";
export default {
  data() {
    return {
      selectValue: 1,
      selectOption: [
        { label: "活跃留存", value: 1 },
        { label: "新增留存", value: 2 }
      ],
      date: [
        moment(new Date())
          .add(-7, "d")
          .format("YYYY-MM-DD"),
        moment(new Date())
          .add(-1, "d")
          .format("YYYY-MM-DD")
      ],
      dateType: 1,
      selectdateTypeOption: [
        {
          value: 1,
          label: "近7天"
        },
        {
          value: 2,
          label: "自定义"
        }
      ],
      tableData: [],
      tableColumn: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 30],
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) < new Date(v);
        }
      }
    };
  },
  computed: {},
  created() { },
  mounted() {
    var date = this.getBetweenDate(this.date[0], this.date[1]);
    this.init(date);
  },
  watch: {},
  methods: {
    init(dateList) {
      const data = [];
      dateList.map(item => {
        var obj = {
          begin_date: moment(item).format("YYYYMMDD"),
          end_date: moment(item).format("YYYYMMDD")
        };
        getDailyRetaininfo(obj).then(res => {
          var list = [];
          switch (this.selectValue) {
            case 1:
              list = res.visit_uv
              break;
            case 2:
              list = res.visit_uv_new
              break;
            default:
              break;
          }
          var obj = { date: res.ref_date }
          list.map(item => {
            var arr = []
            arr.push(item.value)
            arr.push(this.setColor(item.value))
            obj['key' + item.key] = arr
          })
          data.push(obj)
          if (data.length === dateList.length) {
            var newData = data.sort(function (a, b) {
              var x = a.date.replace(/:/g, "").replace(/-/g, "").replace(" ", "")
              var y = b.date.replace(/:/g, "").replace(/-/g, "").replace(" ", "")
              return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            });
            this.tableData = newData
          }
        });
      });
    },
    setColor(i) {
      // 229,240,229
      // 103,203,116
      const min = { r: 229, g: 240, b: 229 };
      const max = { r: 103, g: 203, b: 116 };
      const dist = { r: max.r - min.r, g: max.g - min.g, b: max.b - min.b };
      var colors = { r: min.r + i * dist.r, g: min.g + i * dist.g, b: min.b + i * dist.b }
      return "rgb(" + colors.r + ',' + colors.g + ',' + colors.b + ")"
    },
    getBetweenDate(startTime, endTime) {
      // 校验时间格式
      if (typeof startTime === "string") {
        const reg = /^\d{4}-(0?[1-9]|1[0-2])-((0?[1-9])|((1|2)[0-9])|30|31)$/g;
        var msg = { message: `开始时间：${startTime}时间格式错误` };
        if (!reg.test(startTime)) throw msg;
      }
      if (typeof endTime === "string") {
        const reg = /^\d{4}-(0?[1-9]|1[0-2])-((0?[1-9])|((1|2)[0-9])|30|31)$/g;
        var msg_ = { message: `结束时间：${endTime}时间格式错误` };
        if (!reg.test(endTime)) throw msg_;
      }
      var start = new Date(startTime);
      var end = new Date(endTime);
      const resultTime = [];
      // 当 开始时间小于结束时间的时候进入循环
      while (start <= end) {
        var getDay = start.getDate();
        // 月份需要加 1
        var getMonth = start.getMonth() + 1;
        var getYear = start.getFullYear();
        /**
         * 拼接时间格式
         * (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) 自动给 小于10的时间前面补0
         */
        var setTime =
          `${getYear}-` +
          (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) +
          "-" +
          (getDay >= 10 ? `${getDay}` : `0${getDay}`);

        resultTime.push(setTime);
        /**
         * 重新设置开始时间
         * 使用 setFullYear() 方法会自动将时间累加，返回的是时间戳格式
         * 使用 new Date() 将时间重新设置为标准时间
         * getMonth - 1 将月份时间重新还原
         */
        start = new Date(start.setFullYear(getYear, getMonth - 1, getDay + 1));
      }
      console.log("getBetweenDate -> resultTime", resultTime);
      return resultTime;
    },
    dateTypeChange() {
      if (this.dateType === 1) {
        this.date = [
          moment(new Date())
            .add(-7, "d")
            .format("YYYY-MM-DD"),
          moment(new Date())
            .add(-1, "d")
            .format("YYYY-MM-DD")
        ];
        var date = this.getBetweenDate(this.date[0], this.date[1]);
        this.init(date);
      }
    },
    typeChange() {
      var date = this.getBetweenDate(this.date[0], this.date[1]);
      this.init(date);
    },
    dateChange() {
      var date = this.getBetweenDate(this.date[0], this.date[1]);
      this.init(date);
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
