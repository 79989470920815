<template>
  <div class="group-list">
    <el-card>
      <!--搜索与添加区域-->
      <el-row style="margin-bottom: 20px" type="flex" justify="space-between">
        <div class="group_header">
          <span>用户分群</span>
        </div>
        <div class="group_btn">
          <el-button type="primary" @click="handleAddGroup()" style="float: left">创建分群</el-button>
        </div>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-form label-position="left" label-width="70px">
          <el-form-item label="人群名称">
            <el-input placeholder="请输入人群名称" v-model="queryInfo.groupInfo" clearable class="w300" size="small" @change="handelChagneGroupName" />
          </el-form-item>
        </el-form>
      </el-row>
      <el-table :data="groupList" border>
        <el-table-column prop="groupId" label="分群" sortable></el-table-column>
        <el-table-column prop="groupName" label="人群描述"></el-table-column>
        <el-table-column prop="num" label="人数" sortable></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="creator" label="创建者"></el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleAddGroup(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeGroupById(scope.row.groupId)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <!-- <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>-->
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryInfo.pageNo" :page-sizes="[5, 10, 15, 20]" :page-size.sync="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    /* eslint-disable */
    return {
      queryInfo: {
        groupInfo: "",
        pageNo: 1,
        pageSize: 10
      },
      groupList: [],
      total: 0,
      timeout: null
    };
  },
  created () {
    this.getGroupList();
  },
  methods: {
    async getGroupList () {
      const { data: res } = await this.$http.post(
        "https://cdpengine.cn/scrm-platform/v1/UserGroup/list",
        this.queryInfo
      );
      this.groupList = res.data.list;
      this.total = res.data.num;
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGroupList();
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage;
      this.getGroupList();
    },
    handleAddGroup (groupInfo = null) {
      if (!groupInfo) {
        // window.sessionStorage.clear();
        this.$router.push({ name: "GroupAnalysis" });
      } else {
        this.$router.push({ name: "GroupAnalysis", params: groupInfo });
      }
    },
    async removeGroupById (id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该人群, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);
      // 如果确认删除返回值是 'confirm'
      // 取消了删除，则返回值为字符串 cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除！");
      }
      const { data: res } = await this.$http.get(
        `https://cdpengine.cn/scrm-platform/v1/UserGroup/delete?groupId=${id}`
      );
      this.$message.success("删除人群成功！");
      console.log(this.groupList);
      if (this.groupList.length == 1) {
        const totalPage = Math.ceil((this.total - 1) / this.queryInfo.pageSize); // 总页数
        this.queryInfo.pageNo =
          this.queryInfo.pageNo > totalPage ? totalPage : this.queryInfo.pageNo;
        this.queryInfo.pageNo =
          this.queryInfo.pageNo < 1 ? 1 : this.queryInfo.pageNo;
      }
      this.getGroupList();
    },
    handelChagneGroupName () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getGroupList();
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
.group-list {
  .group_header {
    font-size: 24px;
  }
}
.el-card {
  padding: 5px;
}
.el-select {
  width: 100%;
}
.w300 {
  width: 300px;
}
</style>
