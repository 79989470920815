/* eslint-disable vue/valid-template-root */
<template>
  <el-container class="basic-layout-main">
    <el-aside width="200px">
      <div class="logo">
        <img src="@/assets/logo.png" alt />
      </div>
      <div class="menu-list">
        <!-- default-active="2" -->
        <el-menu
          :default-active="$route.path"
          ref="menus"
          class="el-menu-vertical-demo"
          @close="handleClose"
          background-color="#F5F7F8"
          text-color="#333"
          active-text-color="#333"
          :default-openeds="opends"
          router
        >
          <!-- @open="handleOpen" -->
          <!-- @close="handleClose" -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuData"
            :key="item.id"
            :route="item.id"
            v-show="
              menuList.some((t) => t.menusEntity.name === prefix + item.title)
            "
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item
              :index="value.path"
              v-for="value in item.children"
              :key="value.title"
              v-show="
                menuList.some(
                  (t) => t.menusEntity.name === prefix + value.title
                )
              "
            >
              <template slot="title">
                <!-- <i :class="`iconfont ${value.icon}`"></i> -->
                <span>{{ value.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-aside>
    <el-container>
      <el-header class="tabHeader">
        <el-tabs v-model="activeName" @tab-click="handleClick" class>
          <el-tab-pane
            :label="item.text"
            :name="item.id"
            v-for="(item,index) in headerMeanList"
            :key="index"
          ></el-tab-pane>
        </el-tabs>
        <div class="acount">
          <!-- 营销自动化 -->
          <el-image class="autoMarketing" @click="showMarketingAutomation" :src="workflow" fit="contain"></el-image>
          <!-- 系统接入 -->
          <el-popover placement="bottom-end" width="400" trigger="click" style="margin-right: 10px">
            <div class="jieruPopover">
              <div
                @click="handleCommand(item.id)"
                v-for="(item,index) in accessSystemList"
                :key="index"
              >
                <el-image :src="item.src"></el-image>
                <span>{{item.text}}</span>
              </div>
            </div>
            <el-button
              slot="reference"
              type="primary"
              round
              plain
              icon="el-icon-coin"
              class="AccessSystem"
            >系统接入</el-button>
          </el-popover>
          <!-- 系统管理 -->
          <el-dropdown trigger="click" placement="bottom-start" @command="handleMangeCommand">
            <el-button round plain icon="el-icon-s-tools" class="AccessSystem">系统管理</el-button>
            <el-dropdown-menu class="AccessSystemDropdown">
              <el-dropdown-item command="1">
                <el-image :src="user"></el-image>
                <span>用户管理</span>
              </el-dropdown-item>
              <el-dropdown-item command="2">
                <el-image :src="role"></el-image>
                <span>角色管理</span>
              </el-dropdown-item>
              <el-dropdown-item command="3">
                <el-image :src="access"></el-image>
                <span>权限管理</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 头像 退出 -->
          <el-image class="heaed" :src="headurl"></el-image>
          <el-dropdown @command="dropOut">
            <span class="el-dropdown-link">
              <span class="admin">
                {{ name }}
                <i class="el-icon-caret-bottom el-icon-caret-top"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </span> -->
        </div>
      </el-header>
      <EnterpriseSettings :show="enterpriseSettingsShow" @visableShow="visableShow"></EnterpriseSettings>
      <SmsSettings :smsShow="smsSettingsShow" @visableMessageShow="visableMessageShow"></SmsSettings>
      <OfficialAccountSetting
        :officialShow="officialSettingsShow"
        @visableOfficialShow="visableOfficialShow"
      ></OfficialAccountSetting>
      <MiniProSettings :officialShow="miniproSettingsShow" @visableMiniproShow="visableMiniproShow"></MiniProSettings>
      <DouyinSettings :show="douyinSettingsShow" @visableDouyinShow="visableDouyinShow"></DouyinSettings>
      <XiaoESettings :show="xiaoeSettingsShow" @visableXiaoeShow="visableXiaoeShow"></XiaoESettings>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- MarketingAutomation -->
  </el-container>
</template>

<script>
import menu from "./Scrm-MenuConfig.json";
import wechatmenu from "./Wechat-MenuConfig.json";
import appmenu from "./App-MenuConfig.json";
import minimenu from "./Mini-MenuConfig.json";
import othermenu from "./Other-MenuConfig.json";
import { LogOut } from "@/service/login";
import { getRoleMenusList } from "@/service/roles";
import EnterpriseSettings from "@/views/EnterpriseManage/Settings";
import SmsSettings from "@/views/Sms/Settings";
import OfficialAccountSetting from "@/views/OfficialAccountSetting";
import MiniProSettings from "@/views/MiniProgram/Setting";
import DouyinSettings from "@/views/OthersChannels/DouYin/Settings";
import XiaoESettings from "@/views/OthersChannels/XiaoErtong/Settings";
export default {
  data() {
    return {
      menuData: menu,
      headurl: require("@/assets/users.jpg"),
      name: "",
      menuList: [],
      opends: [],
      activeName: "1",
      prefix: "",
      officialAccount: require("@/assets/officialAccount.png"),
      enterpriseWechat: require("@/assets/enterpriseWechat.png"),
      tencentCloud: require("@/assets/tencentCloud.png"),
      miniPro: require("@/assets/minipro.png"),
      user: require("@/assets/user.png"),
      role: require("@/assets/role.png"),
      access: require("@/assets/access.png"),
      enterpriseSettingsShow: false,
      smsSettingsShow: false,
      officialSettingsShow: false,
      miniproSettingsShow: false,
      douyinSettingsShow: false,
      xiaoeSettingsShow: false,
      douyin: require("@/assets/douyin.png"),
      xiaoe: require("@/assets/xiaoe.png"),
      workflow: require("@/assets/workflow.png")
    };
  },
  mounted() {
    this.name = window.sessionStorage.getItem("name");
  },
  created() {
    const roleId = window.sessionStorage.getItem("roleId") || null;
    this.getRouter();
    // menu.map(item => {
    //   this.opends.push(item.id + '')
    // })
    if (roleId) {
      getRoleMenusList({ roleId: roleId }).then(res => {
        this.menuList = res;
        // console.log(JSON.stringify(this.menuList));
      });
    }
  },
  computed: {
    headerMeanList() {
      const list = [
        {
          text: "企业微信",
          id: "1"
        },
        {
          text: "公众号",
          id: "2"
        },
        {
          text: "小程序",
          id: "4"
        },
        {
          text: "APP",
          id: "3"
        },
        {
          text: "其他渠道",
          id: "5"
        }
      ];
      const res = list.filter(item => {
        return this.menuList.some(t => {
          return t.menusEntity.name === item.text + "-模块展示";
        });
      });
      return res;
    },
    accessSystemList() {
      const list = [
        {
          text: "抖音",
          id: "5",
          src: this.douyin
        },
        {
          text: "小鹅通",
          id: "6",
          src: this.xiaoe
        },
        {
          text: "小程序",
          id: "4",
          src: this.miniPro
        },
        {
          text: "公众号",
          id: "1",
          src: this.officialAccount
        },
        {
          text: "企业微信",
          id: "2",
          src: this.enterpriseWechat
        },
        {
          text: "腾讯云",
          id: "3",
          src: this.tencentCloud
        }
      ];
      const res = list.filter(item => {
        // console.log("accessSystemList -> this.menuList", this.menuList);
        return this.menuList.some(t => {
          return t.menusEntity.name === item.text + "-接入";
        });
      });
      return res;
    }
  },
  methods: {
    dropOut() {
      this.$router.push("/login");
      LogOut().then(res => {
        window.sessionStorage.removeItem("cookie");
        window.sessionStorage.removeItem("name");
      });
    },
    getRouter() {
      var hash = window.location.hash;
      if (hash.indexOf("Wechat") !== -1) {
        this.menuData = wechatmenu;
        this.prefix = "wechat-";
        this.activeName = "2";
        wechatmenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (hash.indexOf("/App") !== -1) {
        this.menuData = appmenu;
        this.prefix = "app-";
        this.activeName = "3";
        appmenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (hash.indexOf("/Mini") !== -1) {
        this.menuData = minimenu;
        this.prefix = "minipro-";
        this.activeName = "4";
        minimenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (hash.indexOf("/Other") !== -1) {
        this.menuData = othermenu;
        this.prefix = "其他渠道-";
        this.activeName = "5";
        othermenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else {
        this.menuData = menu;
        this.prefix = "";
        this.activeName = "1";
        menu.map(item => {
          this.opends.push(item.id + "");
        });
      }
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name === "2") {
        this.menuData = wechatmenu;
        this.prefix = "wechat-";
        this.$router.push("/Wechat/AutomaticReply/keyword");
        wechatmenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (tab.name === "3") {
        this.menuData = appmenu;
        this.prefix = "app-";
        this.$router.push("/App/kpi");
        appmenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (tab.name === "4") {
        this.menuData = minimenu;
        this.prefix = "minipro-";
        this.$router.push("/Mini/personas");
        minimenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else if (tab.name === "5") {
        console.log("ddddd=>", 12312);
        this.menuData = othermenu;
        this.prefix = "其他渠道-";
        this.$router.push("/Other/tiktok/fanslist");
        othermenu.map(item => {
          this.opends.push(item.id + "");
        });
      } else {
        this.menuData = menu;
        this.prefix = "";
        this.$router.push("/home");
        menu.map(item => {
          this.opends.push(item.id + "");
        });
      }
    },
    handleCommand(command) {
      switch (command) {
        case "1":
          this.officialSettingsShow = true;
          break;
        case "2":
          this.enterpriseSettingsShow = true;
          break;
        case "3":
          this.smsSettingsShow = true;
          break;
        case "4":
          this.miniproSettingsShow = true;
          break;
        case "5":
          this.douyinSettingsShow = true;
          break;
        case "6":
          this.xiaoeSettingsShow = true;
          break;
        default:
          break;
      }
    },
    handleMangeCommand(command) {
      switch (command) {
        case "1":
          this.$router.push("/SystemUse");
          break;
        case "2":
          this.$router.push("/RolesSystem");
          break;
        case "3":
          this.$router.push("/AuthorityManagement");
          break;
        default:
          break;
      }
    },
    visableShow(val) {
      this.enterpriseSettingsShow = val;
    },
    visableMessageShow(val) {
      this.smsSettingsShow = val;
    },
    visableOfficialShow(val) {
      this.officialSettingsShow = val;
    },
    visableMiniproShow(val) {
      this.miniproSettingsShow = val;
    },
    visableDouyinShow(val) {
      this.douyinSettingsShow = val;
    },
    visableXiaoeShow(val) {
      this.xiaoeSettingsShow = val;
    },
    showMarketingAutomation() {
      this.$router.push("/MarketingAutomation");
    }
  },
  components: {
    EnterpriseSettings,
    SmsSettings,
    OfficialAccountSetting,
    MiniProSettings,
    DouyinSettings,
    XiaoESettings
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
