import { post, get } from '../Api/axios'
import {
  URL_MINI_PROGRAM_GETUSERPORTRAIT,
  URL_MINI_PROGRAM_GETVISITPAGE,
  URL_MINI_PROGRAM_GETVISITDISTRIBUTION,
  URL_MINI_PROGRAM_GETDAILYVISITTREND,
  URL_MINI_PROGRAM_GETDAILYRETAININFO,
  URL_MINI_PROGRAM_GET_CONFIG,
  URL_MINI_PROGRAM_UPDATE_CONFIG,
  URL_MINI_PROGRAM_USERINFO_LIST,
  URL_MINI_SUBSCRIBE_SEND_LIST,
  URL_MINI_SUBSCRIBE_SEND_DELETE
} from '@/Api/api.js'

export function getUserportrait(params = {}) {
  return post(URL_MINI_PROGRAM_GETUSERPORTRAIT, {
    ...params
  })
}

export function getVisitpage(params = {}) {
  return post(URL_MINI_PROGRAM_GETVISITPAGE, {
    ...params
  })
}

export function getVisitDistribution(params = {}) {
  return post(URL_MINI_PROGRAM_GETVISITDISTRIBUTION, {
    ...params
  })
}

export function getDailyVisitTrend(params = {}) {
  return post(URL_MINI_PROGRAM_GETDAILYVISITTREND, {
    ...params
  })
}

export function getDailyRetaininfo(params = {}) {
  return post(URL_MINI_PROGRAM_GETDAILYRETAININFO, {
    ...params
  })
}

export function updateMiniProgramConfig(params = {}) {
  return get(URL_MINI_PROGRAM_UPDATE_CONFIG, {
    ...params
  })
}

export function getMiniProgramConfig(params = {}) {
  return get(URL_MINI_PROGRAM_GET_CONFIG, {
    ...params
  })
}

export function getUserinfoList(params = {}) {
  return post(URL_MINI_PROGRAM_USERINFO_LIST, {
    ...params
  })
}

export function getSubscribeSendList(params = {}) {
  return get(URL_MINI_SUBSCRIBE_SEND_LIST, {
    ...params
  })
}

export function getSubscribeSendDelete(params = {}) {
  return get(URL_MINI_SUBSCRIBE_SEND_DELETE, {
    ...params
  })
}
