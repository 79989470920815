<template >
  <div class="activity-reply">
    <el-container>
      <el-header class="groupheader"></el-header>
      <div class="replytop">
        <el-input placeholder="搜索关键词/规则名称" prefix-icon="el-icon-search" v-model="kewords" @focus="getPageList" :clearable="true" @clear="getPageList" @change="getPageList">
        </el-input>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="当前只能有一个任务在进行中，如果创建新的任务会自动将历史任务置为已结束" placement="top-start">
            <span class="tooltip">i</span>
          </el-tooltip>
          <el-button class="topBtn" type="primary" @click="addGroupWel(null)"><i class="el-icon-plus"></i>新建活动</el-button>
        </div>
      </div>
      <el-table :data="tableData" :header-cell-style="{ background: '#F9F9F9' }">
        <el-table-column prop="activityName" label="活动名称" width="200" align="center">
        </el-table-column>
        <el-table-column prop="creator" label="创建人" width="150" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="开始时间" width="200" align="center"> </el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="200" align="center">
        </el-table-column>
        <el-table-column label="状态" width="150" align="center">
          <template slot-scope="scope">
            {{scope.row.status == '0' ? '进行中' : '已结束' }}
          </template>
        </el-table-column>status
        <el-table-column label="人气排行" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="showLine(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="addGroupWel(scope.row)">编辑</el-button>
            <el-button type="text" size="medium" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="pageNo" :page-size="10" layout="prev, pager, next" background :total="total" :hide-on-single-page="true">
      </el-pagination>
    </el-container>
    <checkDialog ref="checkDialog" />
  </div>
</template>

<script>
import { getFissionActivityList, getFissionActivityDelete } from '@/service/activity'
import checkDialog from './check-dialog'
import {
  Message
} from 'element-ui'
export default {
  components: { checkDialog },
  data () {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      kewords: '',
      typeInfo: 'keywords',
      mapData: null
    }
  },
  computed: {

  },
  created () {
    this.getPageList()
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    getPageList () {
      getFissionActivityList({ pageNo: this.pageNo, pageSize: this.pageSize, name: this.kewords }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    addGroupWel (row) {
      console.log('row', row)
      this.$router.push({
        path: '/Wechat/ParameterQRCode/create',
        query: {
          row: JSON.stringify(row)
        }
      })
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange (pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem (id) {
      getFissionActivityDelete({ id: id }).then(res => {
        Message.success('删除成功')
        this.getPageList()
      })
    },
    showLine (row) {
      this.mapData = row.keywords;
      this.$refs.checkDialog.open(row)
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
