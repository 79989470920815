<template>
  <!-- 开始条件 -->
  <div class="startNode">
    <div class="nodeTitle">消息类型</div>
    <el-form-item>
      <el-radio-group v-model="node.msgType">
        <el-radio label="实时消息"></el-radio>
        <el-radio label="定时任务"></el-radio>
      </el-radio-group>
    </el-form-item>
    <span v-show="node.msgType === '定时任务'">
      <el-form-item>
        <el-radio-group v-model="typeTime" @change="typeTimeChange">
          <el-radio label="一次性任务"></el-radio>
          <el-radio label="周期任务"></el-radio>
        </el-radio-group>
      </el-form-item>
      <span>
        <el-form-item v-if="typeTime==='一次性任务'">
          <el-col :span="12">
            <el-form-item>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="node.date"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1">&nbsp;</el-col>
          <el-col :span="10">
            <el-form-item>
              <el-time-picker
                placeholder="选择时间"
                v-model="node.time"
                style="width: 100%;"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item v-else>
          <el-row>
            <!-- <el-col :span="20"> -->
            <el-date-picker
              v-model="node.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              style="width: 100%;"
            ></el-date-picker>
            <!-- </el-col> -->
          </el-row>
          <el-row class="disflexRow">
            <el-col :span="3">每天</el-col>
            <el-col :span="14">
              <el-form-item>
                <el-time-picker
                  placeholder="选择时间"
                  v-model="node.time"
                  style="width: 100%;"
                  format="HH:mm:ss"
                  value-format="HH:mm:ss"
                ></el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </span>
    </span>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
export default {
  props: ["editnode"],
  data() {
    return {
      node: {
        msgType: "实时消息",
        date: null,
        time: null
      },
      typeTime: "一次性任务",
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) > new Date(v);
        }
      }
    };
  },
  mounted() {},
  created() {},
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    typeTimeChange() {
      this.date = null;
      this.time = null;
    },
    init(val) {
      console.log("this.editNode", this.editnode);
      if (val) {
        let date = null;
        let time = new Date(2021, 9, 10, 9, 0);
        if (val.regularTime) {
          this.typeTime = "一次性任务";
          date = val.regularTime.substring(0, val.regularTime.indexOf(" "));
          time = moment(val.regularTime).format("HH:mm:ss");
          console.log("init -> time", time);
        }
        if (val.rangeTime) {
          this.typeTime = "周期任务";
          let dateList = val.rangeTime.split(";");
          date = [];
          date.push(
            moment(dateList[0]).format("YYYY-MM-DD"),
            moment(dateList[1]).format("YYYY-MM-DD")
          );
          time = dateList[2];
        }
        this.node = {
          msgType: val.msgType,
          date: date,
          time: time
        };
      }else{
        this.node= {
          msgType: "实时消息",
          date: null,
          time: null
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.startNode {
  .el-form-item {
    margin-bottom: 0px;
  }
  .disflexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
</style>
