<template>
  <div class="doughnutChart">
    <div class="name">{{ source.title }}</div>
    <div class="flexbox">
      <div :id="source.id" class="myChart"></div>
      <div class="table">
        <el-table :data="source.data" style="width: 100%" size="mini">
          <el-table-column prop="name" :label="source.label" ></el-table-column>
          <el-table-column prop="value" label="用户数" ></el-table-column>
          <el-table-column label="占比">
            <template slot-scope="scope">
               <span v-if='total>0'>{{(scope.row.value / total ).toFixed(4)*100 }} %</span>
               <span v-else>0</span>
              </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["source"],
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "0",
          left: "center",
          icon: "circle",
          type: 'scroll'
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: "男" },
              { value: 735, name: "女" }
            ]
          }
        ]
      },
      total: 0
    };
  },
  computed: {
  },
  created() {},
  mounted() {
    this.setEchart();
  },
  watch: {
    source() {
      this.setEchart();
    }
  },
  methods: {
    refreshMap() {
      const myChart = echarts.init(document.getElementById(this.source.id));
      this.option.series[0].data = this.source.data;
      this.total = this.source.data.map(row => row.value).reduce((acc, cur) => cur + acc, 0);
      myChart.setOption(this.option);
    },
    setEchart() {
      this.refreshMap();
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
